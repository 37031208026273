import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { fetchProduct } from "../../../services/Inventory/inventory"
import CustomerType from "../../../components/Common/chooseFields/customerType"
import {
  fetchCustomer,
  postCustomer,
} from "../../../services/projectManager/customer"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"

const schema = yup.object().shape({
  customer_type: yup.mixed().required("Customer Type is Required"),
  customer_name: yup.string().required("Customer Name is Required"),
  contact_person: yup.string().required("Contact Person is Required"),
  customer_number: yup
    .string()
    .required("Customer Number is Required")
    .matches(/^([+]?)?[0-9]+$|^$/, "Contact Number must be only digits")
    .min(9, "Minimum limit is 7")
    .max(15, "Maximum limit is 15"),
  contact_person_designation: yup
    .string()
    .required("Contact Person Designation is Required"),
  email_id: yup
    .string()
    .email("Must be a valid Email")
    .required("Email ID is Required"),
  customer_name_two: yup.string(),
  // customer_number_two: yup
  //   .string()
  //   .matches(/^([+]?)?[0-9]+$|^$/, "Contact Number must be only digits")
  //   .min(9, "Minimum limit is 7")
  //   .max(15, "Maximum limit is 15"),
  contact_person_designation_two: yup.string(),
  account_group_id: yup.mixed().required("Nature of Account is Required")
  // fax_number: yup
  //   .string()
  //   .matches(/^([+]?)?[0-9]+$|^$/, "Fax Number must be only digits")
  //   .required("Fax Number is Required"),
  // address: yup.string().required("Address is Required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      customer_type: null,
      customer_name: "",
      contact_person: "",
      customer_number: "",
      contact_person_designation: "",
      email_id: "",
      customer_name_two: "",
      contact_person_designation_two: "",
      customer_number_two: "",
      address: "",
      account_group_id: null,
    },
  })
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  // })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue) {
      reset({
        ...getEditValue?.data,
        customer_type: {
          label: getEditValue?.data?.customer_type?.label,
          value: getEditValue?.data?.customer_type?.id,
          id: getEditValue?.data?.customer_type?.id,
        },
        contact_person_designation_two:
          getEditValue?.data?.contact_person_designation_two != null
            ? getEditValue?.data?.contact_person_designation_two
            : "",
        customer_number_two:
          getEditValue?.data?.customer_number_two != null
            ? getEditValue?.data?.customer_number_two
            : "",
        account_group_id: {
          label: getEditValue?.data?.account_group_id?.label,
          value: getEditValue?.data?.account_group_id?.value,
        },
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postCustomer({
        ...data,
        customer_type: data?.customer_type?.id,
        account_group_id: data?.account_group_id?.value,
      })
      toastSuccess(updateDepartment?.message)
      onUpdate()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{getEditValue?.data?.id ? "Edit Customer" : "New Customer"}</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Customer Type<span className="mandatory">*</span>
                      </Label>
                      <CustomerType
                        control={control}
                        name={"customer_type"}
                        invalid={!!errors.customer_type}
                        disabled={getEditValue?.data?.id}
                      />
                      <FormFeedback>
                        {errors.customer_type?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Customer Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Customer Name"
                        name={"customer_name"}
                        invalid={!!errors.customer_name}
                      />
                      <FormFeedback invalid>
                        {errors.customer_name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Email ID<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Email ID"
                        name={"email_id"}
                        invalid={!!errors.email_id}
                      />
                      <FormFeedback invalid>
                        {errors.email_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Contact Person<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Contact person"
                        name={"contact_person"}
                        invalid={!!errors.contact_person}
                      />
                      <FormFeedback invalid>
                        {errors.contact_person?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Contact Number<span className="mandatory">*</span>
                      </Label>
                      {/* <UFInput
                        control={control}
                        placeholder="Enter the Contact Number"
                        name={"customer_number"}
                        invalid={!!errors.customer_number}
                      /> */}
                      <PhoneInputField
                        control={control}
                        name={"customer_number"}
                        invalid={!!errors.customer_number}
                      />
                      <FormFeedback invalid>
                        {errors.customer_number?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Contact Person Designation
                        <span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Designation"
                        name={"contact_person_designation"}
                        invalid={!!errors.contact_person_designation}
                      />
                      <FormFeedback invalid>
                        {errors.contact_person_designation?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>Contact Person 2</Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Contact person"
                        name={"contact_person_two"}
                        invalid={!!errors.contact_person_two}
                      />
                      <FormFeedback invalid>
                        {errors.contact_person_two?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>Contact Number 2</Label>
                      <PhoneInputField
                        control={control}
                        name={"customer_number_two"}
                        invalid={!!errors.customer_number_two}
                      />
                      {/* <UFInput
                        control={control}
                        placeholder="Enter the Contact Number"
                        name={"customer_number_two"}
                        invalid={!!errors.customer_number_two}
                      /> */}
                      <FormFeedback invalid>
                        {errors.customer_number_two?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>Contact Person Designation 2</Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Designation"
                        name={"contact_person_designation_two"}
                        invalid={!!errors.contact_person_designation_two}
                      />
                      <FormFeedback invalid>
                        {errors.contact_person_designation_two?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup className="mb-4">
                      <Label>
                        Address<span className="mandatory">*</span>
                      </Label>
                      <UFEditor
                        control={control}
                        placeholder="Enter the Address"
                        rows="3"
                        name={"address"}
                        invalid={!!errors.address}
                      />
                      <FormFeedback invalid>
                        {errors.address?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label>
                        Nature Of Account
                        <span className="mandatory">*</span>
                      </Label>
                      <FinanceGroupType
                        control={control}
                        placeholder="select"
                        name={"account_group_id"}
                        invalid={!!errors.account_group_id}
                        // target={"body"}
                      />
                      <FormFeedback invalid>
                        {errors.account_group_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditCustomer = ({ buttonLabel, className, editValue, ...props }) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCustomer(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditCustomer
