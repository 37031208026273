import React, { useState, useEffect } from "react"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import RequestType from "../../../components/Common/chooseFields/requestType"
import { Col, Form, Label, Row } from "reactstrap"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import ProductList from "../../../components/Common/chooseFields/productList"
import TransactionHistoryList from "../../../components/Common/chooseFields/transactionHistory"
import CostCenterList from "../../../components/Common/chooseFields/costCenterList"
import FinanceSubCat from "../../../components/Common/chooseFields/financeSubCat"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"
import moment from "moment"

const StockSummaryFilter = ({
  //   handleCCChange,
  handleSearchChange,
  // handleProductChange,
  //   handleTHChange,
  handleFinacialChange,
  handleDateChange,
  // handleCompanyChange,
}) => {
  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: [moment().startOf("month").toDate(), moment().toDate()],
    },
  })

  const loggedUser = useSelector(state => state.Login.loggedUser)
  const [companyCheck, setCompanyCheck] = useState(false)
  const [finacialCheck, setfinacialCheck] = useState(true)
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  )
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))

  //   const handleTransactionHistory = data => {
  //     handleTHChange(data?.label)
  //   }
  //   const handleCCNum = data => {
  //     handleCCChange(data?.value)
  //   }
  // const handleProducttNum = data => {
  //   handleProductChange(data?.value)
  // }
  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])
  const handleReset = () => {
    // handleCCChange()
    // handleTHChange()
    // handleProductChange()
    handleFinacialChange(true)
    // handleCompanyChange(false)
    setfinacialCheck(true)
    reset({
      costcenter_id: null,
      product_id: null,
      search: "",
      th_no: null,
    //   req_date: [moment().startOf("month").toDate(), moment().toDate()],
    })
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"))
    setEndDate(moment().format("YYYY-MM-DD"))
  }

  const handleFinacialCheck = dt => {
    handleFinacialChange(!dt)
    setfinacialCheck(!dt)
  }

  const handleRequestdate = data => {
    console.log(data, "data")
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
    handleDateChange(startDate, endDate)
  }

  useEffect(() => {
    handleDateChange(startDate, endDate)
  }, [startDate, endDate])

  return (
    <div className="d-flex justify-content-between align-items-center my-2 me-2">
      <Form id="user-form" className="filter-wrapper">
        <Row className="mx-0">
          <Col sm={3} className="pe-1">
            <UFDate
              control={control}
              placeholder="Date"
              name={"req_date"}
              mode="range"
              className="ufinput-dashboard"
              onDateChange={e => handleRequestdate(e)}
              defaultValue={[
                moment().startOf("month").toDate(),
                moment().toDate(),
              ]}
            />
          </Col>
          {/* <Col sm={3} className="pe-1">
            <TransactionHistoryList
              control={control}
              name={"th_no"}
              placeholder={"TH No"}
              target={"body"}
              onSelectChange={e => handleTransactionHistory(e)}
            />
          </Col>
          <Col sm={3} className="pe-1">
            <CostCenterList
              control={control}
              name={"costcenter_id"}
              placeholder={"Project Code"}
              target={"body"}
              onSelectChange={e => handleCCNum(e)}
            />
          </Col> */}
          {/* <Col sm={3} className="pe-1">
            <FinanceSubCat
              control={control}
              name={"product_id"}
              placeholder={"Sub-cat"}
              target={"body"}
              onSelectChange={e => handleProducttNum(e)}
              filter={true}
            />
          </Col> */}
        </Row>
      </Form>
      <div className="d-flex">
        {/* <Col
          lg={"auto"}
          onClick={() => handleCompanyCheck(companyCheck)}
          className=" d-flex align-items-center pe-3"
        >
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_finacial_year"}
            checked={companyCheck}
          />
          <Label className="ms-2 mb-0">Company</Label>
        </Col> */}
        {/* <Col
          lg={"auto"}
          onClick={() => handleFinacialCheck(finacialCheck)}
          className=" d-flex align-items-center"
        >
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_finacial_year"}
            checked={finacialCheck}
          />
          <Label className="ms-2 mb-0">
            FY {loggedUser?.finance?.replace(/\//g, "")}
          </Label>
        </Col> */}
        <div
          className="export-div pointer me-2 reset-img"
          onClick={() => handleReset()}
        >
          <img src={resetIcon} height={15} width={15} className="me-2" /> Reset
        </div>
        <UFInput
          control={control}
          placeholder="Search"
          name={"search"}
          className={"search-input"}
        />
      </div>
    </div>
  )
}

export default StockSummaryFilter
