import React from "react"

const EditIcon = ({ color = "#8B909A", size = 18, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M8.83432 1.94854H2.93844C2.49168 1.94854 2.06321 2.12602 1.7473 2.44193C1.43138 2.75785 1.25391 3.18631 1.25391 3.63308V15.4248C1.25391 15.8716 1.43138 16.3001 1.7473 16.616C2.06321 16.9319 2.49168 17.1094 2.93844 17.1094H14.7302C15.177 17.1094 15.6054 16.9319 15.9213 16.616C16.2373 16.3001 16.4147 15.8716 16.4147 15.4248V9.52896M14.2038 1.63269C14.5389 1.29762 14.9933 1.10938 15.4672 1.10938C15.9411 1.10938 16.3955 1.29762 16.7306 1.63269C17.0657 1.96777 17.2539 2.42223 17.2539 2.8961C17.2539 3.36996 17.0657 3.82442 16.7306 4.1595L9.13922 11.7517C8.93923 11.9515 8.69215 12.0978 8.42077 12.1771L6.00093 12.8846C5.92845 12.9057 5.85163 12.907 5.7785 12.8882C5.70536 12.8695 5.63861 12.8314 5.58523 12.7781C5.53184 12.7247 5.49379 12.6579 5.47505 12.5848C5.45632 12.5117 5.45759 12.4348 5.47872 12.3624L6.18623 9.94251C6.26585 9.67134 6.41241 9.42457 6.61242 9.2249L14.2038 1.63269Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        // fill="currentColor"
      />
    </svg>
  )
}

export default EditIcon
