import { Col, Form, Label, Row } from "reactstrap"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import moment from "moment"
import { isEmpty } from "lodash"
import AccountsLi from "../../../components/Common/chooseFields/accountsLi"
import FinanceLedgersLi from "../../../components/Common/chooseFields/ledgers"
import { useNavigate } from "react-router-dom"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"

const JournalEntryFilter = ({
  handleSearchChange,
  handleDateChange,
  handleAccChange,
  initialLedger,
  initialDate,
}) => {
  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: [moment().startOf("month").toDate(), moment().toDate()],
    },
  })
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(
      moment().startOf("month").format("YYYY-MM-DD")
    )
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const handleReset = () => {
    navigate(`/finance-ledger-acc`)
    handleDateChange()
    handleSearchChange()
    handleAccChange()
    reset({
      search: "",
      ledger_id: null,
    })
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"))
        setEndDate(moment().format("YYYY-MM-DD"))
  }
  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  useEffect(() => {
    if (initialLedger != null) {
      reset({ ledger_id: initialLedger || null, req_date: initialDate })
    }
  }, [initialLedger, initialDate])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
    handleDateChange(startDate, endDate)
  }

   useEffect(() => {
      handleDateChange(startDate, endDate)
    }, [startDate, endDate])

  const handleAccno = data => {
    handleAccChange(data?.value)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2 py-3">
        <Form id="user-form" className="">
          <Row className="mx-0">
            <Col sm={12} className="mb-2">
              <FinanceGroupType
                control={control}
                placeholder="select"
                name={"account_id"}
                // invalid={!!errors.account_group_id}
                onSelectChange={e => handleAccno(e)}
                target={"body"}
              />
              {/* <FinanceLedgersLi
                control={control}
                placeholder="select"
                name={"ledger_id"}
                target={"body"}
                onSelectChange={e => handleAccno(e)}
              /> */}
              {/* <AccountsLi
                control={control}
                name={"account_code"}
                placeholder={"Code"}
                target={"body"}
                fromFilter={true}
              /> */}
            </Col>
            <Col sm={12} className="mb-2">
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </Col>
            <Col sm={12} className="mb-2">
              <UFInput
                control={control}
                placeholder="Search"
                name={"search"}
                className={"search-input"}
              />
            </Col>
          </Row>
          <div className="d-flex ">
            <div
              className="export-div pointer me-2 reset-img py-2 ms-auto"
              onClick={() => handleReset()}
            >
              <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
              Reset
            </div>
          </div>
        </Form>

        {/* <div className="d-flex">
          <div
            className="export-div pointer me-2 reset-img"
            onClick={() => handleReset()}
          >
            <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
            Reset
          </div>
          <UFInput
            control={control}
            placeholder="Search"
            name={"search"}
            className={"search-input"}
          />
        </div> */}
      </div>
    </>
  )
}

export default JournalEntryFilter
