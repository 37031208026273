import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import EditLeaveManagement from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import ProductFilter from "./filter"
import {
  fetchHrmsOtherTransaction,
  fileDownload,
  updateLeaveManagementStatus,
} from "../../../services/Hrms/hrms"
import { capitalize } from "lodash"
import moment from "moment"
import EditTransactionManagement from "./edit"
import DownloadIcon from "../../../assets/icons/download.svg"
import { tradeDocDownload } from "../../../services/procurement/supplier"
import NumberFormat from "../../../utils/numberFormat"

const TransactionManagement = () => {
  document.title = "Other Transaction | Ever Rise"
  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [leaveId, setLeaveId] = useState("")
  const [employeeId, setEmployeeId] = useState("")
  const [page, setPage] = useState("")
  const [dateFrom, setDatefrom] = useState("")
  const [dateTo, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)

  console.log("view data :", viewData)
  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleView = item => {
    setViewData(item)
    toggle()
  }
  const handleDocumentDownload = ({ path, url, downloadName, extension }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: extension,
      })
    }
    // const fileExtension = viewData?.document.split(".").pop()
    handleDownload()
  }
  const getExtension = filePath => {
    const parts = filePath.split(".")
    return parts[1]
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateLeaveManagementStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Transaction ID",
      renderCell: item => {
        return (
          <div onClick={() => handleView(item)} className="pointer fw-bold">
            {item?.code}
          </div>
        )
      },
    },
    {
      label: "Date",
      renderCell: item => moment(item?.date).format("DD-MM-YYYY"),
    },
    {
      label: "Employee ID",
      renderCell: item => item?.employee?.employee_code,
    },
    {
      label: "Employee Name",
      renderCell: item => (
        <div className="capitalize-letter">{item?.employee?.first_name}</div>
      ),
    },
    {
      label: "Transaction Type",
      renderCell: item =>
        item?.transaction_type?.name
          ? capitalize(item?.transaction_type?.name)
          : "-",
    },
    {
      label: "Payment Type",
      renderCell: item => (
        <div className="capitalize-letter">
          {item?.transaction_type?.is_deduction != 1
            ? "Bonus"
            : item?.is_loan == 0
            ? "Instant Deduction"
            : "Loan"}
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.is_paid == 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.is_paid == 1 ? "Paid" : "Pending"}
          </span>
        </div>
      ),
    },
    // {
    //   label: "Action",
    //   renderCell: item => (
    //     <>
    //       <div>
    //         <span id={`PopoverLegacy-${item?.id}`}>
    //           <img src={action} alt="" className="pointer" />
    //         </span>
    //       </div>
    //       <UncontrolledPopover
    //         placement="bottom"
    //         target={`PopoverLegacy-${item?.id}`}
    //         trigger="legacy"
    //       >
    //         <PopoverBody>
    //           <div
    //             className="table-popup-content"
    //             onClick={() => handleEdit(item)}
    //           >
    //             Edit
    //           </div>
    //           {/* <div
    //             className={`${
    //               item?.status === 1 ? "popover-border" : ""
    //             } table-popup-content`}
    //             onClick={() => handleActivate(item?.id)}
    //           >
    //             {item?.status === 1 ? "Reject" : "Accept"}
    //           </div> */}
    //         </PopoverBody>
    //       </UncontrolledPopover>
    //     </>
    //   ),
    // },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchHrmsOtherTransaction({
        params: `${
          status == "0" || status == "1" || status == "2"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          leaveId ? `&code=${leaveId}` : ""
        }${employeeId ? `&employee=${employeeId}` : ""}${
          dateFrom ? `&from_date=${dateFrom}` : ""
        }${dateTo ? `&to_date=${dateTo}` : ""}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setClassData(response)
      // ${`&financial=${financial}`}
    }
    fetchClass()
  }, [
    refresh,
    status,
    search,
    leaveId,
    employeeId,
    page,
    dateFrom,
    dateTo,
    financial,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleLeaveIdChange = id => {
    setLeaveId(id)
    setPage(1)
  }
  const handleEmployeeIdChange = id => {
    setEmployeeId(id)
    setPage(1)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                {
                  title: "Other Transactions",
                  link: "/hrms-other-transactions",
                },
              ]}
            />
            <EditTransactionManagement
              key={"x"}
              onUpdate={handleUpdate}
              onReset={handleReset}
              editValue={editClass}
              buttonLabel="+&nbsp; Other Transformation"
              className="px-2 mb-3 me-2 btn btn-primary"
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <ProductFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleLeaveIdChange={handleLeaveIdChange}
              handleEmployeeIdChange={handleEmployeeIdChange}
              handleDateChange={handleDateChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/products.xlsx"}
              importUrl={"products/import"}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 15% 15% 15% 15% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-4">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Transaction Code
              </Col>
              <Col className="primary-text">{viewData?.code}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Employee Code
              </Col>
              <Col className="primary-text">
                {viewData?.employee?.employee_code}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Employee Name
              </Col>
              <Col className="primary-text">
                {viewData?.employee?.first_name}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Date
              </Col>
              <Col className="primary-text">
                {moment(viewData?.date).format("DD-MM-YYYY")}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Transaction Type
              </Col>
              <Col className="primary-text">
                {viewData?.transaction_type?.name}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Document
              </Col>
              <Col className="primary-text">
                <span
                  className="pointer view-eye"
                  onClick={() =>
                    handleDocumentDownload({
                      id: viewData?.id,
                      path: viewData?.document?.split("/").pop(),
                      url: `other-payments/download/${viewData?.id}`,
                      downloadName: `other_payment`,
                      extension: getExtension(viewData?.document),
                    })
                  }
                >
                  <img src={DownloadIcon} />
                </span>
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Payment Type
              </Col>
              <Col className="primary-text">
                {viewData?.is_loan == 0 ? "Instant Payment" : "Loan"}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Description
              </Col>
              <Col className="primary-text">{viewData?.description}</Col>
            </Row>
            {viewData?.is_loan == 1 ? (
              <>
                <Row className="py-3 ms-3">
                  <Col lg={3} className="secondary-text">
                    Tenure
                  </Col>
                  <Col className="primary-text">{viewData?.temure} Months</Col>
                </Row>
                <Row className="py-3 ms-3">
                  <Col lg={3} className="secondary-text">
                    Monthly Pay
                  </Col>
                  <Col className="primary-text">
                    {NumberFormat((Number(viewData?.month_amount)), 3, 3)} Months
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default TransactionManagement
