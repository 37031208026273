import React, { useEffect } from "react"

import { UFInput } from "../../components/Common/Fields/Input"
import { useForm } from "react-hook-form"
import { Col, Form, Row } from "reactstrap"
import ProjectNo from "../../components/Common/chooseFields/projectno"
import resetIcon from "../../assets/icons/reset.svg"

const HrmsSearch = ({ handleSearchChange, placeholder, handleProjectCode, status }) => {
  const { control, watch, reset } = useForm()

  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  const handlePorjectChange = data => {
    handleProjectCode(data?.value)
  }
  
  const handleReset = () => {
    handlePorjectChange()
    handleSearchChange()
    reset({
      search: "",
      project_code: null,
  })
}

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2">
        <Form id="user-form" className="filter-wrapper">
          <Row className="mx-0">
            <Col sm={3} className="pe-1">
            {status === 1 && (
              <ProjectNo
                control={control}
                placeholder="Project Code"
                name={"project_code"}
                target={"body"}
                onSelectChange={e => handlePorjectChange(e)}
              />
            )}
            </Col>
            {/* <Col sm={3} className="pe-1">
              <CustomerType
                control={control}
                name={"customer_type"}
                placeholder={"Customer Type"}
                target={"body"}
                onSelectChange={e => handleCat(e)}
              />
            </Col> */}
            <Col sm={3} className="pe-1">
              {/* <CustomerType
                control={control}
                name={"customer_type"}
                placeholder={"Customer Type"}
                target={"body"}
                onSelectChange={e => handleCat(e)}
              /> */}
              {/* <Location
                control={control}
                placeholder="Project Location"
                name={"location_id"}
                target={"body"}
                onSelectChange={e => handleLocation(e)}
              /> */}
            </Col>
            <Col sm={2} className="pe-1">
              {/* <Customer
                control={control}
                placeholder="Customer"
                name={"client_code"}
                target={"body"}
                onSelectChange={e => handleCodeChange(e)}
              /> */}
            </Col>
            <Col sm={2} className="pe-1">
              {/* <Status
                control={control}
                name={"status"}
                onSelectChange={e => handleStatus(e)}
              /> */}
            </Col>
          </Row>
        </Form>
        <div className="d-flex">
          <div
            className="export-div pointer me-2 reset-img"
            onClick={() => handleReset()}
          >
            <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
            Reset
          </div>
          <UFInput
          control={control}
          placeholder="Search..."
          name={"search"}
          className={"search-input"}
        />
          {/* {isImport && (
            <div className="export-div pointer" onClick={() => toggle()}>
              <img src={exportIcon} />
            </div>
          )} */}
        </div>
      </div>
    </>
  )
}

export default HrmsSearch;
