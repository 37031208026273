import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  UFDate,
  UFEditor,
  UFInput,
  UFMonthYear,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
// import closeIcon from "../../assets/icons/close.svg"
// import lightTrash from "../../assets/icons/light-trash.svg"
// import editIcon from "../../assets/images/editIcon.svg"
// import uploadicon from "../../assets/icons/import.svg"
import infoDark from "../../../assets/icons/info-dark.svg"
import {
  importFileData,
  sampleFileDownload,
} from "../../../services/Inventory/inventory"
import {
  deleteTask,
  fetchEstimation,
  postEstimation,
} from "../../../services/estimation/estimation"
import EstimationClass from "../../../components/Common/chooseFields/estimateClass"
import Customer from "../../../components/Common/chooseFields/customer"
import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory"
import EstimationProduct from "../../../components/Common/chooseFields/estimationProduct"
import Location from "../../../components/Common/chooseFields/location"
import EstimationSubCategory from "../../../components/Common/chooseFields/estimationSubCategory"
import { fetchCustomer } from "../../../services/projectManager/customer"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import EstimationMaterialCategory from "../../../components/Common/chooseFields/EstimationMaterialDrop"
import moment from "moment"
import { isEmpty } from "lodash"
import TasksList from "../../../components/Common/chooseFields/tasksList"
import PaymentTermsTempList from "../../../components/Common/chooseFields/paymentTermTempLi"
import RenderAccordion from "./renderAccord"
import MarginLi from "../../../components/Common/chooseFields/marginLi"
import TrashIcon from "../../../assets/icons/iconComponents/trashIcon"
import EstProductsLi from "../../../components/Common/chooseFields/estProductLists"
import Designation from "../../../components/Common/chooseFields/designation"
import SupplierCodeList from "../../../components/Common/chooseFields/supplierCodeList"
import { postProjectMaster } from "../../../services/projectManager/projectMaster"

const EditEstimation = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isRevisionLoading, setIsRevisionLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [action, setAction] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const [contractCheck, setContractCheck] = useState(false)
  const [manpowerCheck, setManpowerCheck] = useState(false)
  const [overheadsCheck, setOverheadsCheck] = useState(false)
  const [prelimsCheck, setPrelimssCheck] = useState(false)
  const [materialCheck, setMaterialCheck] = useState(false)
  const [clientData, setClientData] = useState()
  const [materialToManCheck, setMaterialToManCheck] = useState([false])
  const [materialList, setMaterialList] = useState([])
  const [materialsRemove, setMaterialRemove] = useState([])
  const [manPowersRemove, setManpowersRemove] = useState([])
  const [subContractsRemove, setSubContractsRemove] = useState([])
  const [projectDuration, setProjectDuration] = useState({
    fromDate: "",
    toDate: "",
  })
  const [projectTasks, setProjectTasks] = useState([])
  const [tasks, setTasks] = useState([])
  const [currTask, setCurrTask] = useState({})
  const [activeTab, setActiveTab] = useState("1")
  const [projectProfit, setProjectProfit] = useState(
    loggedUser?.settings?.percentage ? loggedUser?.settings?.percentage : 0
  )
  const location = useLocation()

  const schema = yup.object().shape({
    client_code: yup.mixed().required("Customer Code is Required"),
    location_id: yup.mixed().required("Location is Required"),
    project_name: yup.string().required("Project Name is Required"),
    bid_date: yup.mixed().required("Bid Date is Required"),
    // discount: yup
    //   .string()
    //   .matches(/^([+]?)?[0-9]+$|^$/, "Discount must be only digits")
    //   .min(0, "Minimum limit is 0")
    //   .max(100, "Maximum limit is 100"),
    // estimate_created_by: yup
    //   .string()
    //   .required("Estimate Created By is Required"),

    ...(materialCheck && {
      estimation_materials: yup.array().of(
        yup.object().shape({
          // class_id: yup.mixed().required("Class Code is Required"),
          // category_id: yup.mixed().required("Category Code is Required"),
          product_id: yup.mixed().required("Product Code is Required"),
          quantity: yup
            .string()
            .matches(/^([+]?)?[0-9]+$|^$/, "Quantity must be only digits")
            .required("Quantity is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
    ...(overheadsCheck && {
      overheads_per: yup
        .string()
        .matches(
          /^([+]?)\d+(\.\d{1,2})?$/,
          "Price must be a valid number with up to 2 digits after the decimal point"
        )
        .required("Price is required"),
    }),
    ...(prelimsCheck && {
      preliminary_per: yup
        .string()
        .matches(
          /^([+]?)\d+(\.\d{1,2})?$/,
          "Price must be a valid number with up to 2 digits after the decimal point"
        )
        .required("Price is required"),
    }),
    //   {
    //   estimation_overheads: yup.array().of(
    //     yup.object().shape({
    //       class_id: yup.mixed().required("Class Code is Required"),
    //       category_id: yup.mixed().required("Category Code is Required"),
    //       description: yup.string().required("Description is required"),
    //       price: yup
    //         .string()
    //         .matches(/^([+]?)?[0-9]+$|^$/, "Price must be only digits")
    //         .required("Price is required"),
    //     })
    //   ),
    // }
    ...(contractCheck && {
      estimation_subcontracts: yup.array().of(
        yup.object().shape({
          // class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          duration: yup.string().required("Duration is required"),
          // description: yup.string().required("Description is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
    ...(manpowerCheck && {
      estimation_manpowers: yup.array().of(
        yup.object().shape({
          // class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          no_of_labours: yup.string().required("Number Of Labours is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
          // price: yup
          //   .string()
          //   .matches(/^([+]?)?[0-9]+$|^$/, "Price must be only digits")
          //   .required("Price is required"),
        })
      ),
    }),
    ...(materialList?.length && {
      product_manpowers: yup.array().of(
        yup.object().shape({
          class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          no_of_labours: yup.string().required("Number Of Labours is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
  })

  const toggle = () => {
    setModal(!modal)
    setFile()
    setFileName()
  }

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  const now = moment()

  // let fromDate = ""
  // let toDate = ""

  const onFileChange = event => {
    setFileName(event)
    setFile(event?.target?.files?.[0])
  }

  const handleImport = () => {
    const importFile = async () => {
      try {
        const response = await importFileData({
          importfile: file,
          url: "estimation/import",
        })
        toastSuccess(response?.message)
        setModal(false)
      } catch (err) {
        toastError(err?.message)
      }
    }
    importFile()
  }

  const handleSampleFile = () => {
    const handleCommonExport = async () => {
      const response = await sampleFileDownload("download/estimation.xlsx")
    }
    handleCommonExport()
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_name: "",
      client_code: null,
      client_name: "",
      location_id: null,
      bid_date: null,
      client_contact_person: "",
      contact_number: "",
      estimate_created_by: "",
      tax: 0,
      preliminary_per: 0,
      discount: 0,
      preliminary_val: 0,
      year_month: null,
      margin_type: { label: "By Percentage", value: 1 },
      estimation_manpowers: [
        {
          class_id: null,
          category_id: null,
          description: "",
          no_of_labours: "",
          price: "",
        },
      ],
      estimation_subcontracts: [
        {
          class_id: null,
          category_id: null,
          description: "",
          duration: "",
          price: "",
          unit: null,
        },
      ],
      estimation_materials: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
          is_manpower_material: false,
          id: "",
        },
      ],
      // estimation_overheads: {
      overheads_per: loggedUser?.settings?.overheads_per,
      preliminary_per: loggedUser?.settings?.preliminary_per,
      // },
    },
  })

  const {
    fields: manpowerFields,
    append: manpowerAppend,
    remove: manpowerRemove,
  } = useFieldArray({
    control,
    name: "estimation_manpowers",
  })
  const {
    fields: contractFields,
    append: contractAppend,
    remove: contractRemove,
  } = useFieldArray({
    control,
    name: "estimation_subcontracts",
  })
  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "estimation_materials",
  })
  const {
    fields: overheadsFields,
    append: overheadsAppend,
    remove: overheadsRemove,
  } = useFieldArray({
    control,
    name: "estimation_overheads",
  })

  const handleCodeChange = () => {
    const { client_code } = getValues()
    fetchCodeData(client_code?.value)
    setValue("tax", loggedUser?.settings?.tax)
    setValue("preliminary_per", loggedUser?.settings?.preliminary_per)
  }
  const fetchCodeData = async idData => {
    const response = await fetchCustomer(idData)
    if (idData == response.data?.id) {
      setClientData(response.data)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        client_name: response.data?.customer_name,
        client_contact_person: response.data?.contact_person,
        contact_number: response.data?.customer_number,
      })
    }
  }

  const calculateTotal = item => {
    const sumArray = (arr, multiplierKey) =>
      Array.isArray(arr)
        ? arr.reduce(
            (total, current) =>
              total +
              Number(current?.price || 0) *
                Number(current?.[multiplierKey] || 1),
            0
          )
        : 0

    const totalMaterial = sumArray(item?.estimation_material, "quantity")
    const totalManpower = sumArray(item?.estimation_manpower, "no_of_labours")
    const totalSubcontract = sumArray(item?.estimation_subcontract, "duration")

    // Recursively calculate total for nested tasks
    // const totalSubTasks = Array.isArray(item?.tasks)
    //   ? item.tasks.reduce((sum, subTask) => sum + calculateTotal(subTask), 0)
    //   : 0

    // Return the final numeric total
    return totalMaterial + totalManpower + totalSubcontract
    // + totalSubTasks
  }

  useEffect(() => {
    const handleFetchEstimation = async () => {
      const ids = id ? id : location?.state?.estimation_revision_id
      const response = await fetchEstimation(ids)
      setProjectTasks(response?.data)
      setProjectDuration({
        fromDate: response?.data?.client_code?.from_date,
        toDate: response?.data?.client_code?.to_date,
      })
      console.log("dadadata", response)
      const sumTotal = calculateTotal(projectTasks)
      //  +
      // calculateTotal(projectTasks) * (projectProfit / 100)
      // console.log(`sumTotal`, sumTotal)

      setTasks(response?.data?.tasks)

      let marginValuePercent = 0
      if (response?.data?.estimation?.margin_value != 0) {
        marginValuePercent =
          (Number(response?.data?.estimation?.margin_value) /
            Number(sumTotal)) *
          100
      } else {
        marginValuePercent = 0
      }

      setProjectProfit(
        response?.data?.estimation?.margin_percentage != 0
          ? response?.data?.estimation?.margin_percentage
          : response?.data?.estimation?.margin_value != 0
          ? marginValuePercent
          : loggedUser?.settings?.percentage
          ? loggedUser?.settings?.percentage
          : 0
      )
      const materialArray = response?.data?.estimation_material?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        quantity: dt?.quantity,
        product_id: {
          value: dt?.products?.id,
          label: dt?.products?.product_code,
        },
        price: dt?.price,
        unit: dt?.unit?.unit_code,
        unit_id: dt?.unit?.id,
        rowprice: dt?.price && Number(dt?.price) * Number(dt?.quantity),
        group: dt?.products?.group_label,
        // unit: {
        //   value: dt?.unit?.id,
        //   id: dt?.unit?.id,
        //   label: dt?.unit?.unit_code,
        // },
        is_manpower_material: dt?.is_manpower_material == 1 ? true : false,
        //  setMaterialToManCheck(
        //   [...materialToManCheck],
        //   dt?.is_manpower_material == 1 ? true : false
        // ),
        description: dt?.products?.description,
        id: dt?.id,
        table_id: dt?.id,
      }))
      // const overheadsArray = response?.data?.estimation_overhead?.map(dt => ({
      //   class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      //   category_id: {
      //     value: dt?.category_id?.id,
      //     label: dt?.category_id?.category_code,
      //   },
      //   description: dt?.description,
      //   price: dt?.price,
      //   id: dt?.id,
      // }))
      const contractArray = response?.data?.estimation_subcontract?.map(dt => ({
        class_id: {
          value: dt?.class_id?.id,
          label: dt?.class_id?.class_code,
        },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        duration: dt?.duration,
        description: dt?.description,
        price: dt?.price,
        unit: {
          value: dt?.unit?.id,
          id: dt?.unit?.id,
          label: dt?.unit?.unit_code,
        },
        id: dt?.id,
        table_id: dt?.id,
      }))
      const withMaterialId = []
      const withoutMaterialId = []

      response?.data?.estimation_manpower.forEach(obj => {
        if (obj.hasOwnProperty("material_id") && obj.material_id !== null) {
          withMaterialId.push(obj)
        } else {
          withoutMaterialId.push(obj)
        }
      })

      const manpowerArray = withoutMaterialId?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        description: dt?.category_id?.description,
        no_of_labours: dt?.no_of_labours,
        price: dt?.price,
        id: dt?.id,
        table_id: dt?.id,
      }))
      const materialManpowerArr = withMaterialId?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        description: dt?.category_id?.description,
        no_of_labours: dt?.no_of_labours,
        price: dt?.price,
        material_id: dt?.material_id,
        id: dt?.id,
      }))
      const payTermArr = response?.data?.estimation_payment_terms?.map(dt => ({
        description: dt?.description,
        invoice_portion: dt?.invoice_portion,
        payterms_element_id: dt?.payterms_element_id,
        payterms_template_id: dt?.payterms_template_id,
        due_date: moment(now, "YYYY-MM-DD").toDate(),
      }))
      setMaterialList(materialManpowerArr)
      reset({
        ...response?.data?.client_code,
        pay_terms: payTermArr,
        revision: response?.data?.id,
        margin_type:
          response?.data?.estimation?.margin_percentage != 0
            ? { label: "By Percentage", value: 1 }
            : response?.data?.estimation?.margin_value != 0
            ? { label: "By Amount", value: 2 }
            : { label: "By Percentage", value: 1 },
        margin_percentage:
          response?.data?.estimation?.margin_percentage != 0
            ? response?.data?.estimation?.margin_percentage
            : loggedUser?.settings?.percentage
            ? loggedUser?.settings?.percentage
            : 0,
        margin_value: response?.data?.estimation?.margin_value
          ? response?.data?.estimation?.margin_value
          : 0,
        estimation_id: response?.data?.estimation_id,
        client_code: {
          label: response?.data?.client_code?.customers?.customer_code,
          value: response?.data?.client_code?.customers?.id,
        },
        location_id: {
          label: response?.data?.client_code?.location?.name,
          value: response?.data?.client_code?.location?.id,
        },
        pay_term_temp_id: {
          value:
            response?.data?.estimation_payment_terms[0]?.payterms_template?.id,
          label:
            response?.data?.estimation_payment_terms[0]?.payterms_template
              ?.name,
          payment_terms: [...response?.data?.estimation_payment_terms],
        },
        year_month: response?.data?.client_code?.from_date != null && response?.data?.client_code?.to_date != null ?  [
          new Date(
            new Date(response?.data?.client_code?.from_date).setMonth(
              new Date(response?.data?.client_code?.from_date).getMonth()
            )
          ),
          new Date(
            new Date(response?.data?.client_code?.to_date).setMonth(
              new Date(response?.data?.client_code?.to_date).getMonth()
            )
          ),
          // moment(response?.data?.client_code?.from_date).format(
          //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
          // ),
          // moment(response?.data?.client_code?.to_date).format(
          //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
          // ),
        ] : null,
        product_manpowers: withMaterialId?.length && materialManpowerArr,
        estimation_materials: response?.data?.estimation_material?.length
          ? materialArray
          : [
              {
                class_id: null,
                category_id: null,
                quantity: "",
                product_id: null,
                price: "",
                id: "",
              },
            ],
        overheads_per: response?.data?.overheads_per,
        preliminary_per: response?.data?.preliminary_per,
        // tax: response?.data?.tax,
        // estimation_overheads: response?.data?.estimation_overhead?.length
        //   ? overheadsArray
        //   : [
        //       {
        //         class_id: null,
        //         category_id: null,

        //         description: "",
        //         price: "",
        //       },
        //     ],
        estimation_subcontracts: response?.data?.estimation_subcontract?.length
          ? contractArray
          : [
              {
                class_id: null,
                category_id: null,
                description: "",
                duration: "",
                price: "",
              },
            ],
        estimation_manpowers: withoutMaterialId?.length
          ? manpowerArray
          : [
              {
                class_id: null,
                category_id: null,
                no_of_labours: "",
                description: "",
                price: "",
              },
            ],
      })
      setMaterialToManCheck(materialArray?.map(dt => dt?.is_manpower_material))
      setClientData(response?.data?.client_code)
      // if (response?.data?.estimation_material?.length) {
      //   setMaterialCheck(true)
      // }
      // if (response?.data?.estimation_manpower?.length) {
      //   setManpowerCheck(true)
      // }
      // if (response?.data?.estimation_subcontract?.length) {
      //   setContractCheck(true)
      // }
      if (response?.data?.client_code?.overheads_per != null) {
        setOverheadsCheck(true)
      }
      if (response?.data?.client_code?.preliminary_per != null) {
        setPrelimssCheck(true)
      }
    }
    if (id || location?.state?.estimation_revision_id) {
      handleFetchEstimation()
    }
  }, [id, location])

  // const calculateTotalCost = (tasks, index) => {
  //   console.log("indexindex", index)
  //   let totalCost = 0

  //   tasks.forEach(task => {
  //     // Calculate manpower costs
  //     if (task.estimation_manpower && task.estimation_manpower.length > 0) {
  //       if (index?.estimation_manpowers?.length?.id === task.estimation_manpower.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_manpower.forEach(manpower => {
  //         const quantity = parseFloat(manpower.no_of_labours) || 0
  //         const price = parseFloat(manpower.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Calculate material costs
  //     if (task.estimation_material && task.estimation_material.length > 0) {
  //       if (index?.estimation_material?.length?.id === task.estimation_material.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_material.forEach(material => {
  //         const quantity = parseFloat(material.quantity) || 0
  //         const price = parseFloat(material.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Calculate subcontract costs
  //     if (
  //       task.estimation_subcontract &&
  //       task.estimation_subcontract.length > 0
  //     ) {
  //       if (index?.estimation_subcontract?.length?.id === task.estimation_subcontract.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_subcontract.forEach(subcontract => {
  //         const quantity = parseFloat(subcontract.duration) || 0
  //         const price = parseFloat(subcontract.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Recursively calculate costs for subtasks
  //     if (task.tasks && task.tasks.length > 0) {
  //       totalCost += calculateTotalCost(task.tasks)
  //     }
  //   })

  //   // Assuming projectProfit is defined in the scope
  //   // const profit = totalCost * (projectProfit / 100);
  //   // totalCost += profit;

  //   console.log("Total cost including profit:", totalCost)
  //   return totalCost
  // }

  {
    console.log("pay_term_temp_id", watch("pay_term_temp_id"))
  }
  const calculateTotalCost = (tasks, index) => {
    console.log("indexindex", index)
    let totalCost = 0

    tasks.forEach(task => {
      // Calculate manpower costs
      if (task.estimation_manpower && task.estimation_manpower.length > 0) {
        task.estimation_manpower.forEach(manpower => {
          if (index?.estimation_manpowers?.some(m => m.id === manpower.id)) {
            totalCost += 0
          } else {
            const quantity = parseFloat(manpower.no_of_labours) || 0
            const price = parseFloat(manpower.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (task.estimation_material && task.estimation_material.length > 0) {
        task.estimation_material.forEach(material => {
          if (index?.estimation_materials?.some(m => m.id === material.id)) {
            totalCost += 0
          } else {
            const quantity = parseFloat(material.quantity) || 0
            const price = parseFloat(material.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (
        task.estimation_subcontract &&
        task.estimation_subcontract.length > 0
      ) {
        task.estimation_subcontract.forEach(subcontract => {
          if (
            index?.estimation_subcontracts?.some(s => s.id === subcontract.id)
          ) {
            totalCost += 0
          } else {
            const quantity = parseFloat(subcontract.duration) || 0
            const price = parseFloat(subcontract.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (task.tasks && task.tasks.length > 0) {
        totalCost += calculateTotalCost(task.tasks, index)
      }
    })

    console.log("Total cost including profit:", totalCost)
    return totalCost
  }

  console.log("data", projectTasks)

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      event.returnValue = "" // This triggers the browser's built-in alert
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  const handleRegistration = async data => {
    // let data = getValues()
    console.log(`submitdata`, data)

    if (
      materialCheck ||
      overheadsCheck ||
      contractCheck ||
      manpowerCheck ||
      prelimsCheck ||
      data?.payTermUpdate ||
      data?.marginUpdate
    ) {
      try {
        if (
          Number(
            Number(currTask?.unit_price || 0) * Number(currTask?.qty || 0)
          ) -
            (Number(
              data?.estimation_materials?.reduce(
                (sum, est) => sum + parseFloat(est?.rowprice || 0),
                0
              ) || 0
            ) +
              Number(
                data?.estimation_manpowers?.reduce(
                  (sum, est) => sum + parseFloat(est?.total || 0),
                  0
                ) || 0
              ) +
              Number(
                data?.estimation_subcontracts?.reduce(
                  (sum, est) => sum + parseFloat(est?.totalprice || 0),
                  0
                ) || 0
              )) >=
          0
        ) {
          // setIsLoading(true)
          action === 1 ? setIsLoading(true) : setIsRevisionLoading(true)

          // Get old total amount before editing
          // const previousAmount = calculateTotal(projectTasks)
          const overheadProfit =
            Number(
              Number(currTask?.unit_price || 0) * Number(currTask?.qty || 0)
            ) -
            (Number(
              data?.estimation_materials?.reduce(
                (sum, est) => sum + parseFloat(est?.rowprice || 0),
                0
              ) || 0
            ) +
              Number(
                data?.estimation_manpowers?.reduce(
                  (sum, est) => sum + parseFloat(est?.total || 0),
                  0
                ) || 0
              ) +
              Number(
                data?.estimation_subcontracts?.reduce(
                  (sum, est) => sum + parseFloat(est?.totalprice || 0),
                  0
                ) || 0
              ))
          const previousAmount = calculateTotalCost(tasks)

          const totalAmountCost = calculateTotal(projectTasks)
          // +
          // (calculateTotal(projectTasks) * projectProfit) / 100

          console.log("TotalAmountCost", totalAmountCost)

          // let sumTotalCostdata = 0
          // console.log("hello hai wrold", sumTotalCostdata)
          const newAmountdata = calulation("estimate_value")
          const findata = calculateTotalCost(tasks, data)
          const addpres = findata
          // + (findata * projectProfit) / 100
          const newamountdata = addpres + newAmountdata
          let tototaldata = newamountdata
          console.log("nevergiveup", tototaldata)

          // Calculate new amount for the current data
          const newAmount = calulation("estimate_value")
          console.log("newamount", newAmount)

          // 🔹 Handle Price Reduction: Adjusting Previous Items
          // let adjustmentAmount = 0

          // // Loop through materials to check if existing ones have price changes
          // if (projectTasks?.estimation_materials) {
          //   projectTasks.estimation_materials.forEach(oldItem => {
          //     const newItem = data?.estimation_materials?.find(
          //       item => item.id === oldItem.id
          //     )

          //     if (newItem) {
          //       // If price is reduced, subtract the difference
          //       const priceDifference =
          //         oldItem.price * oldItem.quantity -
          //         newItem.price * newItem.quantity

          //       adjustmentAmount += priceDifference
          //     }
          //   })
          // }

          // if (projectTasks?.estimation_manpowers) {
          //   projectTasks.estimation_manpowers.forEach(oldItem => {
          //     const newItem = data?.estimation_manpowers?.find(
          //       item => item.id === oldItem.id
          //     )

          //     if (newItem) {
          //       const priceDifference =
          //         oldItem.price * oldItem.no_of_labours -
          //         newItem.price * newItem.no_of_labours

          //       adjustmentAmount += priceDifference
          //     }
          //   })
          // }

          // if (projectTasks?.estimation_subcontracts) {
          //   projectTasks.estimation_subcontracts.forEach(oldItem => {
          //     const newItem = data?.estimation_subcontracts?.find(
          //       item => item.id === oldItem.id
          //     )

          //     if (newItem) {
          //       const priceDifference =
          //         oldItem.price * oldItem.duration -
          //         newItem.price * newItem.duration

          //       adjustmentAmount += priceDifference
          //     }
          //   })
          // }

          // // 🔹 Final Amount Calculation: Apply Adjustments
          // const totalAmount = previousAmount + newAmount - adjustmentAmount

          // const sumTotalAmount =
          //   totalAmount + (totalAmount * Number(projectProfit)) / 100
          // console.log("Sumtotal", sumTotalAmount)

          let adjustmentAmount = 0

          // Loop through materials to check if existing ones have price changes
          if (tasks?.estimation_materials) {
            tasks.estimation_materials.forEach(oldItem => {
              const newItem = data?.estimation_materials?.find(
                item => item.id === oldItem.id
              )
              if (newItem) {
                // If price is reduced, subtract the difference
                const priceDifference =
                  oldItem.price * oldItem.quantity -
                  newItem.price * newItem.quantity
                adjustmentAmount += priceDifference
              }
            })
          }

          // Repeat for manpower and subcontract
          if (tasks?.estimation_manpowers) {
            tasks.estimation_manpowers.forEach(oldItem => {
              const newItem = data?.estimation_manpowers?.find(
                item => item.id === oldItem.id
              )
              if (newItem) {
                const priceDifference =
                  oldItem.price * oldItem.no_of_labours -
                  newItem.price * newItem.no_of_labours
                adjustmentAmount += priceDifference
              }
            })
          }

          if (tasks?.estimation_subcontracts) {
            tasks.estimation_subcontracts.forEach(oldItem => {
              const newItem = data?.estimation_subcontracts?.find(
                item => item.id === oldItem.id
              )
              if (newItem) {
                const priceDifference =
                  oldItem.price * oldItem.duration -
                  newItem.price * newItem.duration
                adjustmentAmount += priceDifference
              }
            })
          }

          // 🔹 Final Amount Calculation: Apply Adjustments
          const totalAmount = previousAmount + newAmount - adjustmentAmount
          console.log("totalAmountkkkkkk", totalAmount)

          const sumTotalAmount = totalAmount
          console.log("Sumtotalkkkkkk", sumTotalAmount)

          const materialArray = data?.estimation_materials?.map((dt, i) => ({
            class_id: dt?.class_id?.value,
            category_id: dt?.category_id?.value,
            quantity: dt?.quantity,
            product_id: dt?.product_id?.value,
            price: dt?.price,
            unit: dt?.unit_id,
            is_manpower_material: materialToManCheck[i] ? 1 : 0,
            id: dt?.id ? dt?.id : "",
            // estimation_revision_id: projectTasks?.estimation_id
          }))
          // const overheadsArray = data?.estimation_overheads?.map(dt => ({
          //   class_id: dt?.class_id?.value,
          //   category_id: dt?.category_id?.value,

          //   description: dt?.description,
          //   price: dt?.price,
          //   id: dt?.id ? dt?.id : "",
          // }))
          const contractArray = data?.estimation_subcontracts?.map(dt => ({
            class_id: dt?.class_id?.value,
            category_id: dt?.category_id?.value,
            duration: dt?.duration,
            description: dt?.description,
            price: dt?.price,
            unit: dt?.unit?.value,
            id: dt?.id ? dt?.id : "",
          }))
          const manpowerArray = data?.estimation_manpowers?.map(dt => ({
            class_id: dt?.class_id?.value,
            category_id: dt?.category_id?.value,
            no_of_labours: dt?.no_of_labours,
            price: dt?.price,
            id: dt?.id ? dt?.id : "",
          }))
          const productManpowersArray = data?.product_manpowers
            ? data?.product_manpowers?.map(dt => ({
                class_id: dt?.class_id?.value,
                category_id: dt?.category_id?.value,
                no_of_labours: dt?.no_of_labours,
                price: dt?.price,
                material_id: dt?.material_id?.id,
                id: dt?.id ? dt?.id : "",
              }))
            : []

          const payTermArray = data?.pay_terms
            ? data?.pay_terms?.map(dt => ({
                payterms_template_id: dt?.payterms_template_id,
                payterms_element_id: dt?.payterms_element_id,
                invoice_portion: dt?.invoice_portion,
                description: dt?.description,
                due_date: moment(dt?.due_date).format("YYYY-MM-DD"),
                estimation_id: data?.estimation_id,
                revision: data?.revision,
              }))
            : []
          // console.log("dadadadad", sumTotalCost)
          const updateEstimation = await postEstimation({
            ...(id && { id: data?.id }),
            ...(data?.id && { alredy_created: true }),
            margin_percentage:
              data?.margin_percentage && data?.margin_type?.value == 1
                ? data?.margin_percentage
                : !data?.margin_value && data?.margin_type?.value != 2
                ? loggedUser?.settings?.percentage
                : 0,
            margin_value:
              data?.margin_value && data?.margin_type?.value == 2
                ? data?.margin_value
                : 0,
            ...(!modal && { payTermUpdate: data?.payTermUpdate }),
            ...(!modal && { marginUpdate: data?.marginUpdate }),
            pay_terms: payTermArray,
            edit: data?.edit,
            estimation_id: data?.estimation_id,
            estimation_revision_id: projectTasks?.id,
            bid_date: moment(data?.bid_date).format("YYYY-MM-DD"),

            client_code: data?.client_code?.value,
            location_id: data?.location_id?.value,
            project_name: data?.project_name,
            client_name: data?.client_name,
            client_contact_person: data?.client_contact_person,
            contact_number: data?.contact_number,
            estimate_created_by: loggedUser?.data?.name,
            tax: data?.tax ? data?.tax : 0,
            // discount: data?.discount ? data?.discount : 0,
            // amount: calulation("estimate_value").toFixed(3),
            // amount: sumTotalAmount.toFixed(3),
            amount: projectTasks?.amount,
            from_date: projectDuration?.fromDate,
            to_date: projectDuration?.toDate,
            preliminary_val: data?.preliminary_val ? data?.preliminary_val : 0,
            ...(modal && {
              tasks: [
                {
                  ...(materialCheck && { estimation_materials: materialArray }),

                  ...(contractCheck && {
                    estimation_subcontracts: contractArray,
                  }),
                  ...(manpowerCheck && {
                    estimation_manpowers: [
                      ...manpowerArray,
                      ...productManpowersArray,
                    ],
                  }),
                  removed_materials: materialsRemove,
                  removed_manpowers: manPowersRemove,
                  removed_subcontracts: subContractsRemove,
                  name: data?.name,
                  parent_id: data?.parent_id?.value,
                  description: data?.description,
                  id: data?.task_id,
                  overhead_profit_budget: overheadProfit,
                  material: currTask?.material,
                  fittings: currTask?.fittings,
                  consumables: currTask?.consumables,
                  manpower: currTask?.manpower,
                  unit_price: currTask?.unit_price,
                  overhead_profit: currTask?.overhead_profit,
                  sub_contract: currTask?.sub_contract,
                },
              ],
            }),
            ...(overheadsCheck && { overheads_per: data?.overheads_per }),
            ...(prelimsCheck && { preliminary_per: data?.preliminary_per }),
            action: action,
            // ...(materialList?.length && {
            //   product_manpowers: productManpowersArray,
            // }),
          })
          navigate(
            id
              ? `/estimation/budget-edit/${updateEstimation?.estimation_revision_id}`
              : "/estimation/budget-add",
            { state: updateEstimation }
          )
          toastSuccess(updateEstimation?.message)
          // toggle()
          setModal(false)
        } else {
          toastError("Please Match the OHP")
        }
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
        // setIsUpdateLoading(false)
        setIsRevisionLoading(false)
      }
    } else {
      toastError("Any one estimation should be added")
    }
  }

  const handleContractCheck = dt => {
    setContractCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_subcontracts: [
          {
            class_id: null,
            category_id: null,
            description: "",
            duration: "",
            price: "",
          },
        ],
      })
    }
  }
  const handleManpowerCheck = dt => {
    setManpowerCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_manpowers: [
          {
            class_id: null,
            category_id: null,
            no_of_labours: "",
            description: "",
            price: "",
          },
        ],
      })
    }
  }

  const handleMaterialCheck = dt => {
    setMaterialCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_materials: [
          {
            class_id: null,
            category_id: null,
            quantity: "",
            product_id: null,
            price: "",
          },
        ],
      })
    }
  }

  const handleMaterialToManCheck = (e, index) => {
    // const updateManCheck = [...materialToManCheck, ]
    setMaterialToManCheck(materialToMan => {
      const updatedValues = [...materialToMan]
      updatedValues[index] = !materialToMan[index]
      return updatedValues
    })
    setManpowerCheck(true)

    const data = getValues()
    const materials = data.estimation_materials[index]

    const materialWithId = {
      chk_id: index,
      class_id: {
        label: id
          ? materials?.class_id?.label
          : materials?.class_id?.class_code,
        value: id ? materials?.class_id?.value : materials?.class_id?.id,
      },
      // category_id: {
      //   label: id
      //     ? materials?.category_id?.label
      //     : materials?.category_id?.category_code,
      //   value: id ? materials?.category_id?.value : materials?.category_id?.id,
      // },
      // description: id
      //   ? materials?.description
      //   : materials?.category_id?.description,
      material_id: materials?.product_id,
    }
    if (!materialToManCheck[index] && e != false) {
      setMaterialList(prevMaterialList => [...prevMaterialList, materialWithId])
      reset({
        ...data,
        product_manpowers: [...materialList, materialWithId],
      })
    } else {
      const removedData = materialList?.filter((dt, index) => {
        return dt?.material_id?.id !== materialWithId?.material_id?.value
      })
      setMaterialList(removedData)

      reset({
        ...data,
        product_manpowers: [...removedData],
      })
    }
  }

  const handleMaterialRemove = e => {
    setMaterialRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  const handleManPowerRemove = e => {
    setManpowersRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  const handleSubContractsRemove = e => {
    setSubContractsRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  const handleOverheadsCheck = dt => {
    setOverheadsCheck(!dt)

    if (dt == false) {
      // const getValue = getValues()
      setValue("overheads_per", loggedUser?.settings?.overheads_per)
      // reset({
      //   // ...getValue,
      //   overheads_per: loggedUser?.settings?.overheads_per,
      // })
    }
  }

  const handlePrelimsCheck = dt => {
    setPrelimssCheck(!dt)
    if (dt == false) {
      setValue("preliminary_per", loggedUser?.settings?.preliminary_per)
      console.log("perlimsCkeck", loggedUser?.settings?.preliminary_per)
      const perlimspresentage = watch("preliminary_per")
      console.log("preliminary_per", perlimspresentage)
    }
    setValue("preliminary_val", 0)
  }

  const calulation = type => {
    const materialAmount = watch("estimation_materials")
    const manpowerAmount = watch("estimation_manpowers")
    const contractAmount = watch("estimation_subcontracts")
    const overheadsAmount = watch("overheads_per")
    const taxAmount = watch("tax")
    // const discountAmount = watch("discount")
    const materialManpowerWatch = watch("product_manpowers")
    const prelimsBWatch = watch("preliminary_val")

    switch (type) {
      case "material": {
        return (
          materialAmount?.reduce(
            (a, b) => Number(b.price) * Number(b.quantity) + a,
            0
          ) || 0
        )
      }
      // case "materialMan": {
      //   return materialManpowerWatch?.reduce((a, b) => Number(b.price) + a, 0) || 0
      // }
      case "materialMan": {
        if (Array.isArray(materialManpowerWatch)) {
          return materialManpowerWatch.reduce((a, b) => Number(b.price) + a, 0)
        } else {
          return 0
        }
      }
      case "manpower": {
        return (
          manpowerAmount?.reduce(
            (a, b) => Number(b?.price) * Number(b?.no_of_labours) + a,
            0
          ) || 0
        )
      }
      case "contract": {
        return (
          contractAmount?.reduce(
            (a, b) => Number(b.price) * Number(b?.duration) + a,
            0
          ) || 0
        )
      }
      case "overheads": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("contract") +
            calulation("prelims") +
            (prelimsCheck ? calulation("materialMan") : 0)) *
            Number(watch("overheads_per"))) /
          100
        )
        // overheadsAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "prelims": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("materialMan")) *
            Number(watch("preliminary_per"))) /
          100
        )
      }
      // case "subtotal": {
      //   return (
      //     calulation("material") +
      //     calulation("manpower") +
      //     calulation("materialMan") +
      //     calulation("contract") +
      //     {overheadsCheck && calulation("overheads")}
      //   )
      // }
      case "subtotal": {
        let subtotal =
          calulation("material") +
          calulation("manpower") +
          calulation("materialMan") +
          calulation("contract") +
          Number(prelimsBWatch)

        if (overheadsCheck) {
          subtotal += calulation("overheads")
        }
        if (prelimsCheck) {
          subtotal += calulation("prelims")
        }

        return subtotal
      }
      case "estimate_value": {
        return calulation("subtotal")
        //  +
        // (calulation("subtotal") * Number(projectProfit)) / 100
        // +
        // (calulation("subtotal") +
        //   (calulation("subtotal") * Number(projectProfit)) / 100) *
        //   (Number(loggedUser?.settings?.tax) / 100) -
        // (calulation("subtotal") +
        //   (calulation("subtotal") * Number(projectProfit)) / 100) *
        //   (Number(discountAmount) / 100)
      }
      default:
        return 0
    }
  }

  const [productPrice, setProductPrice] = useState([])

  const handleCategoryChange = (e, i) => {
    setValue(`estimation_manpowers.${i}.description`, e?.description)
  }
  const handleManpowerCategoryChange = (e, i) => {
    setValue(`product_manpowers.${i}.description`, e?.description)
  }
  const handleOverheadsCatChange = (e, i) => {
    setValue(`estimation_overheads.${i}.description`, e?.description)
  }
  const handleContractCatChange = (e, i) => {
    setValue(`estimation_subcontracts.${i}.description`, e?.description)
  }

  const handleProductChange = (e, index) => {
    const getData = getValues()
    console.log(`getData`, getData, e)

    setValue(
      `estimation_materials.${index}.price`,
      getData?.estimation_materials?.[index]?.quantity
        ? getData?.estimation_materials?.[index]?.quantity * e?.price_aed
        : e?.price_aed
    )
    setValue(
      `estimation_materials.${index}.group`,
      e?.group_label ? e?.group_label : ""
    )
    setValue(
      `estimation_materials.${index}.rowprice`,
      getData?.estimation_materials?.[index]?.quantity
        ? getData?.estimation_materials?.[index]?.quantity * e?.price_aed
        : e?.price_aed
    )
    setValue(`estimation_materials.${index}.description`, e?.description)
    setValue(
      `estimation_materials.${index}.unit`,
      e?.unit?.unit_code
      // value: e?.unit?.id,
    )
    setValue(`estimation_materials.${index}.unit_id`, e?.unit?.id)
    if (productPrice.length <= index) {
      setProductPrice(prevPrices => [
        ...prevPrices,
        ...Array(index - prevPrices.length + 1).fill(null),
      ])
    }
    setProductPrice(prevPrices => {
      const updatedPrices = [...prevPrices]
      updatedPrices[index] = e?.price_aed
      return updatedPrices
    })
  }

  const handleQuantityChange = (e, index) => {
    const amt = Number(getValues(`estimation_materials.${index}.price`))
    const quy = Number(e.target.value)
    const total = (amt * quy).toFixed(3)

    setValue(`estimation_materials.${index}.rowprice`, total)

    // setValue(
    //   `estimation_materials.${index}.rowprice`,
    //   productPrice?.[index]
    //     ? Number(Number(e?.target?.value) * Number(amt)).toFixed(3)
    //     : 0
    // )

    // setValue(
    //   `estimation_materials.${index}.price`,
    //   productPrice?.[index]
    //     ? Number(
    //         Number(e?.target?.value) * Number(productPrice?.[index])
    //       ).toFixed(4)
    //     : 0
    // )
  }

  const handleMaterialPrice = (e, index) => {
    console.log(`productPrice?.[index]`, productPrice?.[index])
    const qty = Number(getValues(`estimation_materials.${index}.quantity`))
    const amt = Number(e.target.value) || 0
    const total = (qty * amt).toFixed(3)

    setValue(`estimation_materials.${index}.rowprice`, total)

    // setValue(
    //   `estimation_materials.${index}.rowprice`,
    //   productPrice?.[index]
    //     ? Number(Number(e?.target?.value) * Number(qty)).toFixed(3)
    //     : 0
    // )
  }

  const handleClassChange = (e, i) => {
    setValue(`estimation_materials.${i}.category_id`, null)
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleCategoryReset = (e, i) => {
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleManpowerClass = i => {
    setValue(`estimation_manpowers.${i}.category_id`, null)
    setValue(`estimation_manpowers.${i}.description`, "")
    setValue(`estimation_manpowers.${i}.no_of_labours`, "")
    setValue(`estimation_manpowers.${i}.price`, "")
  }
  const handleManpowerMaterialClass = i => {
    setValue(`product_manpowers.${i}.category_id`, null)
    setValue(`product_manpowers.${i}.description`, "")
    setValue(`product_manpowers.${i}.no_of_labours`, "")
    setValue(`product_manpowers.${i}.price`, "")
  }

  const handleContractClass = i => {
    setValue(`estimation_subcontracts.${i}.category_id`, null)
    setValue(`estimation_subcontracts.${i}.description`, "")
    setValue(`estimation_subcontracts.${i}.unit`, null)
    setValue(`estimation_subcontracts.${i}.duration`, "")
    setValue(`estimation_subcontracts.${i}.price`, "")
  }

  const handleOverheadsClass = i => {
    setValue(`estimation_overheads.${i}.category_id`, null)
    setValue(`estimation_overheads.${i}.description`, "")
    setValue(`estimation_overheads.${i}.price`, "")
  }

  // const handleUpdate = async data => {
  // }

  const handledate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data)
      ? moment(data[1]).endOf("month").format("YYYY-MM-DD")
      : ""
    setProjectDuration({ fromDate, toDate })
  }

  const scrollContainer = document.querySelector(".scroll-container")

  // function handleScroll() {
  //   console.log(`scrolling`)
  // }

  // if (scrollContainer) {
  //   scrollContainer.addEventListener("scroll", handleScroll)
  // }

  const handleAddTask = () => {
    const data = getValues()

    // List of required fields
    const requiredFields = [
      { name: "client_code", label: "Customer" },
      { name: "project_name", label: "Project Name" },
      { name: "location_id", label: "Location" },
      { name: "bid_date", label: "Bid Date" },
      // { name: "year_month", label: "Project Duration" },
      // Add more fields if needed
    ]

    // Check if any required field is empty
    for (const field of requiredFields) {
      if (!data[field.name]) {
        // Set the error for validation feedback
        setError(field.name, {
          type: "manual",
          message: `Please fill in ${field.label}`,
        })

        // Focus on the first empty field
        const element = document.querySelector(`[name="${field.name}"]`)
        if (element) element.focus()

        return // Stop execution if a required field is empty
      }
    }
    toggle()
    // const data = getValues()
    setMaterialCheck(false)
    setManpowerCheck(false)
    setContractCheck(false)
    reset({
      ...data,
      edit: 0,
      task_id: "",
      name: "",
      parent_id: null,
      description: "",
      estimation_materials: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
          id: "",
        },
      ],
      estimation_manpowers: [
        {
          class_id: null,
          category_id: null,
          description: "",
          no_of_labours: "",
          price: "",
        },
      ],
      estimation_subcontracts: [
        {
          class_id: null,
          category_id: null,
          description: "",
          duration: "",
          price: "",
          unit: null,
        },
      ],
    })
  }

  const dataArray = [
    {
      id: 1,
      head: "Task 1",
      amount: "1000",
      subgroupArray: [
        { id: 101, head: "Sub Head 1", amount: "Sub Amount 1" },
        { id: 102, head: "Sub Head 2", amount: "Sub Amount 2" },
      ],
    },
    { id: 2, head: "Task 1", amount: "200" },
    {
      id: 3,
      head: "Task 1",
      amount: "4000",
      subgroupArray: [{ id: 103, head: "Sub Head 3", amount: "Sub Amount 3" }],
    },
  ]

  const [open, setOpen] = useState("")
  const [subOpen, setSubOpen] = useState({})
  const toggleAccord = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const toggleSubAccord = id => {
    setSubOpen(prevState => ({
      ...prevState,
      [id]: prevState[id] ? null : id,
    }))
  }

  const handleStore = () => {
    const data = getValues()
    setProjectTasks([...projectTasks, data])
    toggle()
  }

  const handleRemoveItem = async id => {
    const responce = await deleteTask(id)
    navigate(
      id
        ? `/estimation/budget-edit/${responce?.estimation_revision_id}`
        : "/estimation/budget-add",
      { state: responce }
    )
    toastSuccess(responce?.message)
  }

  const handleEditItem = itm => {
    console.log(`handleEditItem handleEditItem`, itm)

    const data = getValues()
    const materialArray = itm?.estimation_material?.map(dt => ({
      class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.category_code,
      },
      quantity: dt?.quantity,
      product_id: {
        value: dt?.products?.id,
        label: dt?.products?.product_code,
      },
      price: dt?.price,
      rowprice: dt?.price * dt?.quantity,
      group: dt?.products?.group_label,
      unit: dt?.unit?.unit_code,
      unit_id: dt?.unit?.id,
      description: dt?.products?.description,
      id: dt?.id,
      table_id: dt?.id,
    }))

    const contractArray = itm?.estimation_subcontract?.map(dt => ({
      // class_id: {
      //   value: dt?.class_id?.id,
      //   label: dt?.class_id?.class_code,
      // },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.suppliers_code,
      },
      duration: dt?.duration,
      // description: dt?.description,
      price: dt?.price,
      totalprice: dt?.duration * dt?.price,
      unit: {
        value: dt?.unit?.id,
        id: dt?.unit?.id,
        label: dt?.unit?.unit_code,
      },
      id: dt?.id,
      table_id: dt?.id,
    }))

    const manpowerArray = itm?.estimation_manpower?.map(dt => ({
      // class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.name,
      },
      // description: dt?.category_id?.description,
      no_of_labours: dt?.no_of_labours,
      price: dt?.price,
      total: dt?.no_of_labours * dt?.price,
      id: dt?.id,
      table_id: dt?.id,
    }))

    setMaterialCheck(itm?.estimation_material?.length ? true : false)
    setManpowerCheck(itm?.estimation_manpower?.length ? true : false)
    setContractCheck(itm?.estimation_subcontract?.length ? true : false)

    reset({
      ...data,
      task_id: itm?.id,
      edit: 1,
      name: itm?.name ? itm?.name : "",
      parent_id: itm?.parent_id
        ? { value: itm?.parent_id, label: itm?.parent?.name }
        : null,
      description: itm?.description ? itm?.description : "",
      estimation_materials: itm?.estimation_material?.length
        ? materialArray
        : [
            {
              class_id: null,
              category_id: null,
              quantity: "",
              product_id: null,
              price: "",
              id: "",
            },
          ],
      estimation_subcontracts: itm?.estimation_subcontract?.length
        ? contractArray
        : [
            {
              class_id: null,
              category_id: null,
              description: "",
              duration: "",
              price: "",
            },
          ],
      estimation_manpowers: itm?.estimation_manpower?.length
        ? manpowerArray
        : [
            {
              class_id: null,
              category_id: null,
              no_of_labours: "",
              description: "",
              price: "",
            },
          ],
    })
    setCurrTask(itm)
    toggle()
  }

  // const calculateTotal = item => {
  //   const sumArray = arr =>
  //     Array.isArray(arr)
  //       ? arr.reduce((total, current) => total + Number(current?.price || 0), 0)
  //       : 0

  //   const totalMaterial = sumArray(item?.estimation_material)
  //   const totalManpower = sumArray(item?.estimation_manpower)
  //   const totalSubcontract = sumArray(item?.estimation_subcontract)

  //   return (totalMaterial + totalManpower + totalSubcontract).toLocaleString(
  //     "en",
  //     {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }
  //   )
  // }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handlePayTermChange = e => {
    const values = getValues()
    const payTermArr = e?.payment_terms?.map(dt => ({
      description: dt?.description,
      invoice_portion: dt?.invoice_portion,
      payterms_element_id: dt?.id,
      payterms_template_id: values?.pay_term_temp_id?.value,
      due_date: moment(now, "YYYY-MM-DD").toDate(),
    }))

    reset({ ...values, payTermUpdate: true, pay_terms: payTermArr })
  }

  useEffect(() => {
    if (watch("margin_type")?.value) {
      setValue("marginUpdate", true)
    }
  }, [watch("margin_type")])

  const handleQuantityChangess = (e, index) => {
    const qty = Number(e.target.value) || 0
    const price = getValues(`estimation_manpowers.${index}.price`) || 0

    const total = (qty * price).toFixed(3)
    console.log("lalala", total)
    setValue(`estimation_manpowers.${index}.total`, total)
  }

  const handleMaterialPricess = (e, index) => {
    const price = Number(e.target.value) || 0
    const qty = getValues(`estimation_manpowers.${index}.no_of_labours`) || 0
    const total = (qty * price).toFixed(3)
    console.log("kakaka", total)

    setValue(`estimation_manpowers.${index}.total`, total)
  }

  const handilDuration = (e, index) => {
    const quy = Number(e.target.value) || 0
    const prise = getValues(`estimation_subcontracts.${index}.price`) || 0
    const total = (quy * prise).toFixed(3)

    setValue(`estimation_subcontracts.${index}.totalprice`, total)
  }

  const handilPrise = (e, index) => {
    const prise = Number(e.target.value) || 0
    const quy = getValues(`estimation_subcontracts.${index}.duration`) || 0
    const total = (quy * prise).toFixed(3)

    setValue(`estimation_subcontracts.${index}.totalprice`, total)
  }

  const perlimspresentage = watch("preliminary_per")
  console.log("preliminary_per", perlimspresentage)

  const handleConvertToProject = async id => {
    try {
      const createdSeries = await postProjectMaster({ id: id })
      toastSuccess(createdSeries?.message)
      navigate("/project-master")
    } catch (err) {
      toastError(err?.message)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                <Breadcrumbs
                  title="Budget"
                  breadcrumbObject={[
                    { title: "Estimation", link: "/estimation" },
                    { title: "Budget", link: "/estimation/budget" },
                    {
                      title: id ? "Edit Budget" : "Create Budget",
                      link: id
                        ? `estimation/quotation/${id}`
                        : "estimation/budget-add",
                    },
                  ]}
                />
                <div>
                  {loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  ) || loggedUser?.data?.id === 1 ? (
                    <LoadingButton
                      form={`supplier-form`}
                      color={"primary"}
                      loading={isLoading}
                      disabled={isLoading}
                      className={"px-2 mb-3 btn btn-primary"}
                      // type="submit"
                      onClick={() => navigate(`/project-master/add/${id}`)}
                    >
                      Convert to Project
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                  {/* <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                    onClick={() => setAction(1)}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton> */}
                  {/* <LoadingButton
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded waves-effect waves-light mb-3 me-2"
                    onClick={() => handleUpdate(1)}
                    loading={isUpdateLoading}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton> */}
                  {/* {id &&
                  (loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to revision"
                  ) ||
                    loggedUser?.data?.id === 1) ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      className="btn-sm btn-rounded waves-effect waves-light mb-3 ms-2"
                      onClick={() => setAction(2)}
                      loading={isRevisionLoading}
                    >
                      Create Revision
                    </LoadingButton>
                  ) : (
                    <></>
                  )} */}
                  {/* <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update Revision
                  </LoadingButton> */}
                </div>
              </div>
              <Container fluid className="ps-lg-0 sticky-wrapper">
                <Card className="invoice-main-card scroll-container">
                  <div className="p-3 pt-4">
                    <Row className="mb-4 mt-2">
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Customer Code<span className="mandatory">*</span>
                          </Label>
                          <Customer
                            control={control}
                            placeholder="Select"
                            name={"client_code"}
                            invalid={!!errors.client_code}
                            target={"body"}
                            onSelectChange={handleCodeChange}
                            disabled={id ? true : false}
                          />
                          <FormFeedback invalid>
                            {errors.client_code?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Name<span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Name"
                              name={"client_name"}
                              invalid={!!errors.client_name}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_name?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Contact Person
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Customer Contact Person"
                              name={"client_contact_person"}
                              invalid={!!errors.client_contact_person}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_contact_person?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Number
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Number"
                              name={"contact_number"}
                              invalid={!!errors.contact_number}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.contact_number?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Name<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Project Name"
                            name={"project_name"}
                            invalid={!!errors.project_name}
                          />
                          <FormFeedback invalid>
                            {errors.project_name?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project location<span className="mandatory">*</span>
                          </Label>
                          <Location
                            control={control}
                            placeholder="Enter the Project Location"
                            name={"location_id"}
                            invalid={!!errors.location_id}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.location_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Bid Date
                            <span className="mandatory">*</span>
                          </Label>
                          <UFDate
                            control={control}
                            placeholder="Select Date"
                            name={"bid_date"}
                            invalid={!!errors.bid_date}
                          />
                          <FormFeedback invalid>
                            {errors.bid_date?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Duration
                            {/* <span className="mandatory">*</span> */}
                          </Label>
                          <UFMonthYear
                            control={control}
                            name={"year_month"}
                            invalid={!!errors.year_month}
                            month={true}
                            placeholder={"Select Date"}
                            // defaultValue={moment().format("YYYY-MM-DD")}
                            mode={"range"}
                            onDateChange={e => handledate(e)}
                          />
                          <FormFeedback invalid>
                            {errors.year_month?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {console.log(
                      `project_name`,
                      watch("project_name"),
                      watch("location_id"),
                      watch("bid_date"),
                      watch("year_month")
                    )}
                    <div className="estimation-stickey">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "1"
                                ? "active pointer py-2"
                                : "pointer py-2"
                            }
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            Tasks
                          </NavLink>
                        </NavItem>
                        {projectTasks?.tasks?.length ? (
                          <>
                            {/* <NavItem>
                              <NavLink
                                className={
                                  activeTab === "2"
                                    ? "active pointer py-2"
                                    : "pointer py-2"
                                }
                                onClick={() => {
                                  toggleTab("2")
                                }}
                              >
                                Addons
                              </NavLink>
                            </NavItem> */}
                            <NavItem>
                              <NavLink
                                className={
                                  activeTab === "3"
                                    ? "active pointer py-2"
                                    : "pointer py-2"
                                }
                                onClick={() => {
                                  toggleTab("3")
                                }}
                              >
                                Payment Terms
                              </NavLink>
                            </NavItem>
                            {/* <NavItem>
                              <NavLink
                                className={
                                  activeTab === "4"
                                    ? "active pointer py-2"
                                    : "pointer py-2"
                                }
                                onClick={() => {
                                  toggleTab("4")
                                }}
                              >
                                Margin
                              </NavLink>
                            </NavItem> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab} className="pt-3">
                      <TabPane tabId="1">
                        <>
                          {/* <div className="my-2 d-flex justify-content-between">
                            <div>Task Details</div>
                            <div
                              className="yellow-btn pointer"
                              onClick={() => handleAddTask()}
                            >
                              + Task
                            </div>
                          </div> */}
                          {projectTasks?.tasks?.length ? (
                            <RenderAccordion
                              tasks={projectTasks?.tasks}
                              handleEditItemAcc={handleEditItem}
                              handleRemoveTask={handleRemoveItem}
                              // projectPercentage={0}
                              // projectPercentage={projectProfit}
                            />
                          ) : (
                            <></>
                          )}
                          {/* {projectTasks?.tasks?.map((item, index) => (
                            <div className="d-flex">
                              <Accordion
                                open={open}
                                toggle={toggleAccord}
                                className="est-accord my-2"
                              >
                                <AccordionItem className="m-0">
                                  <AccordionHeader
                                    targetId={index}>
                                    <div className="wdt-90">
                                      Task name: {item?.name}
                                    </div>
                                    <div>{calculateTotal(item)}</div>
                                  </AccordionHeader>
                                  <AccordionBody accordionId={index}>
                                    <Row className="">
                                      <Col sm={6}>
                                        {item?.description
                                          ? item?.description
                                          : ""}
                                      </Col>
                                    </Row>
                                    <Row>
                                      {item?.estimation_material?.length ? (
                                        <>
                                          <Row className="py-2 mt-1">
                                            <Col className="text-bold">
                                              Material Details
                                            </Col>
                                          </Row>

                                          <Row className="mb-1">
                                            <Col lg={12}>
                                              <table className="estimation-table mb-4">
                                                <thead>
                                                  <tr>
                                                    <th>Class Code</th>
                                                    <th>Category Code</th>
                                                    <th>Product</th>
                                                    <th>Unit</th>
                                                    <th>Quantity</th>
                                                    <th>Price(AED)</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item?.estimation_material?.map(
                                                    (dt, index) => (
                                                      <tr>
                                                        <td>
                                                          {
                                                            dt?.class_id
                                                              ?.class_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            dt?.category_id
                                                              ?.category_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            dt?.products
                                                              ?.product_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {dt?.unit?.unit_code}
                                                        </td>
                                                        <td>{dt?.quantity}</td>
                                                        <td>
                                                          {Number(
                                                            Number(
                                                              dt?.price
                                                            ).toFixed(4)
                                                          ).toLocaleString(
                                                            "en",
                                                            {
                                                              minimumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {item?.estimation_manpower?.length ? (
                                        <>
                                          <Row className="py-2">
                                            <Col className="text-bold">
                                              Manpower Details
                                            </Col>
                                          </Row>
                                          <Row className="mb-1">
                                            <Col lg={12}>
                                              <table className="estimation-table mb-4">
                                                <thead>
                                                  <tr>
                                                    <th>Class Code</th>
                                                    <th>Category Code</th>
                                                    <th>Number of labours</th>
                                                    <th>Price(AED)</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item?.estimation_manpower?.map(
                                                    (dt, index) => (
                                                      <tr>
                                                        <td>
                                                          {
                                                            dt?.class_id
                                                              ?.class_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            dt?.category_id
                                                              ?.category_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {dt?.no_of_labours}
                                                        </td>
                                                        <td>
                                                          {Number(
                                                            Number(
                                                              dt?.price
                                                            ).toFixed(4)
                                                          ).toLocaleString(
                                                            "en",
                                                            {
                                                              minimumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {item?.estimation_subcontract?.length ? (
                                        <>
                                          <Row className="py-2">
                                            <Col className="text-bold">
                                              Subcontract Details
                                            </Col>
                                          </Row>
                                          <Row className="mb-4">
                                            <Col lg={12}>
                                              <table className="estimation-table mb-4">
                                                <thead>
                                                  <tr>
                                                    <th>Class Code</th>
                                                    <th>Category Code</th>
                                                    <th>Description</th>
                                                    <th>Unit</th>
                                                    <th>Quantity</th>
                                                    <th>Price(AED)</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item?.estimation_subcontract?.map(
                                                    (dt, index) => (
                                                      <tr>
                                                        <td>
                                                          {
                                                            dt?.class_id
                                                              ?.class_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            dt?.category_id
                                                              ?.category_code
                                                          }
                                                        </td>
                                                        <td>
                                                          {dt?.description}
                                                        </td>
                                                        <td>
                                                          {dt?.unit?.unit_code}
                                                        </td>
                                                        <td>{dt?.duration}</td>
                                                        <td>
                                                          {Number(
                                                            Number(
                                                              dt?.price
                                                            ).toFixed(4)
                                                          ).toLocaleString(
                                                            "en",
                                                            {
                                                              minimumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Row>
                                    {item?.tasks?.length > 0 && (
                                      <div className="nested-accordions">
                                        {item.tasks.map((subItem, subIndex) => {
                                          const subId = `Sub_${subIndex}-${index}`
                                          return (
                                            <Accordion
                                              key={subItem.id}
                                              open={subOpen[subId]}
                                              toggle={() =>
                                                toggleSubAccord(subId)
                                              }
                                              className="est-accord-sub my-2"
                                            >
                                              <AccordionItem className="m-0">
                                                <AccordionHeader
                                                  targetId={subId}
                                                >
                                                  <div className="wdt-90">
                                                    Subtask name:{" "}
                                                    {subItem?.name}{" "}
                                                    {subItem?.amount}
                                                  </div>
                                                </AccordionHeader>
                                                <AccordionBody
                                                  accordionId={subId}
                                                >
                                                  <Row className="">
                                                    <Col sm={6}>
                                                      {subItem?.description
                                                        ? subItem?.description
                                                        : ""}
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    {subItem
                                                      ?.estimation_material
                                                      ?.length ? (
                                                      <>
                                                        <Row className="py-2 mt-1">
                                                          <Col className="text-bold">
                                                            Material Details
                                                          </Col>
                                                        </Row>

                                                        <Row className="mb-1">
                                                          <Col lg={12}>
                                                            <table className="estimation-table mb-4">
                                                              <thead>
                                                                <tr>
                                                                  <th>
                                                                    Class Code
                                                                  </th>
                                                                  <th>
                                                                    Category
                                                                    Code
                                                                  </th>
                                                                  <th>
                                                                    Product
                                                                  </th>
                                                                  <th>Unit</th>
                                                                  <th>
                                                                    Quantity
                                                                  </th>
                                                                  <th>
                                                                    Price(AED)
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {subItem?.estimation_material?.map(
                                                                  (
                                                                    dt,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.class_id
                                                                            ?.class_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.category_id
                                                                            ?.category_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.products
                                                                            ?.product_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.unit
                                                                            ?.unit_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt?.quantity
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {Number(
                                                                          Number(
                                                                            dt?.price
                                                                          ).toFixed(
                                                                            4
                                                                          )
                                                                        ).toLocaleString(
                                                                          "en",
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                          }
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )}
                                                                
                                                              </tbody>
                                                            </table>
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {subItem
                                                      ?.estimation_manpower
                                                      ?.length ? (
                                                      <>
                                                        <Row className="py-2">
                                                          <Col className="text-bold">
                                                            Manpower Details
                                                          </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                          <Col lg={12}>
                                                            <table className="estimation-table mb-4">
                                                              <thead>
                                                                <tr>
                                                                  <th>
                                                                    Class Code
                                                                  </th>
                                                                  <th>
                                                                    Category
                                                                    Code
                                                                  </th>
                                                                  <th>
                                                                    Number of
                                                                    labours
                                                                  </th>
                                                                  <th>
                                                                    Price(AED)
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {subItem?.estimation_manpower?.map(
                                                                  (
                                                                    dt,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.class_id
                                                                            ?.class_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.category_id
                                                                            ?.category_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt?.no_of_labours
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {Number(
                                                                          Number(
                                                                            dt?.price
                                                                          ).toFixed(
                                                                            4
                                                                          )
                                                                        ).toLocaleString(
                                                                          "en",
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                          }
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {subItem
                                                      ?.estimation_subcontract
                                                      ?.length ? (
                                                      <>
                                                        <Row className="py-2">
                                                          <Col className="text-bold">
                                                            Subcontract Details
                                                          </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                          <Col lg={12}>
                                                            <table className="estimation-table mb-4">
                                                              <thead>
                                                                <tr>
                                                                  <th>
                                                                    Class Code
                                                                  </th>
                                                                  <th>
                                                                    Category
                                                                    Code
                                                                  </th>
                                                                  <th>
                                                                    Description
                                                                  </th>
                                                                  <th>Unit</th>
                                                                  <th>
                                                                    Quantity
                                                                  </th>
                                                                  <th>
                                                                    Price(AED)
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {subItem?.estimation_subcontract?.map(
                                                                  (
                                                                    dt,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.class_id
                                                                            ?.class_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.category_id
                                                                            ?.category_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt?.description
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt
                                                                            ?.unit
                                                                            ?.unit_code
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          dt?.duration
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {Number(
                                                                          Number(
                                                                            dt?.price
                                                                          ).toFixed(
                                                                            4
                                                                          )
                                                                        ).toLocaleString(
                                                                          "en",
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                          }
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )}
                                                                
                                                              </tbody>
                                                            </table>
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </Row>
                                                </AccordionBody>
                                              </AccordionItem>
                                            </Accordion>
                                          )
                                        })}
                                      </div>
                                    )}
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                              <div className="mt-3">
                                <div className="accord-img">
                                  <img
                                    src={editIcon}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => {
                                      handleEditItem(item)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="accord-img">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => {
                                      handleRemoveItem(item?.id)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))} */}
                        </>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row className="py-2 mt-4 estimation-stickey">
                          <Col lg={1}>
                            <h5>Select</h5>
                          </Col>

                          <Col
                            lg={"auto"}
                            onClick={() => handlePrelimsCheck(prelimsCheck)}
                          >
                            <FormGroup>
                              <input
                                type="checkbox"
                                className="ever-rise-checkbox"
                                name={"is_prelims"}
                                checked={prelimsCheck}
                              />

                              <Label className="ms-2">Prelims</Label>
                            </FormGroup>
                          </Col>
                          <Col
                            lg={"auto"}
                            onClick={() => handleOverheadsCheck(overheadsCheck)}
                          >
                            <FormGroup>
                              <input
                                type="checkbox"
                                className="ever-rise-checkbox"
                                name={"is_overheads"}
                                checked={overheadsCheck}
                              />

                              <Label className="ms-2">Overheads</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        {projectTasks?.tasks?.length ? (
                          <>
                            <div className="d-flex justify-content-end">
                              <LoadingButton
                                form={`supplier-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 mb-3 btn btn-primary"}
                                type="submit"
                                onClick={() => setAction(1)}
                              >
                                {"Update"}
                              </LoadingButton>
                            </div>
                            <div className="p-3">
                              <Row className="mb-2 mt-2">
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Payment Terms
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <PaymentTermsTempList
                                      control={control}
                                      placeholder="Select"
                                      name={"pay_term_temp_id"}
                                      invalid={!!errors.pay_term_temp_id}
                                      target={"body"}
                                      onSelectChange={e =>
                                        handlePayTermChange(e)
                                      }
                                      // disabled={id ? true : false}
                                    />
                                    <FormFeedback invalid>
                                      {errors.pay_term_temp_id?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {watch("pay_term_temp_id")?.value ? (
                                <>
                                  <Row className="mb-4">
                                    <Col lg={12}>
                                      <table className="estimation-table mb-4">
                                        <thead>
                                          <tr>
                                            <th>Term Name</th>
                                            <th>Description</th>
                                            <th>Invoice Portion</th>
                                            <th>Due Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {watch(
                                            "pay_term_temp_id"
                                          )?.payment_terms?.map((dt, i) => (
                                            <tr key={`payTerm${i}`}>
                                              <td className="ps-1">
                                                {dt?.payterms_element?.name
                                                  ? dt?.payterms_element?.name
                                                  : dt?.name
                                                  ? dt?.name
                                                  : "-"}
                                              </td>
                                              <td className="ps-1">
                                                <UFInput
                                                  control={control}
                                                  placeholder="Description"
                                                  name={`pay_terms.${i}.description`}
                                                />
                                              </td>
                                              <td className="ps-1">
                                                <UFInput
                                                  control={control}
                                                  placeholder="Description"
                                                  name={`pay_terms.${i}.invoice_portion`}
                                                />
                                              </td>
                                              <td className="ps-1">
                                                <UFDate
                                                  control={control}
                                                  placeholder="Select Date"
                                                  name={`pay_terms.${i}.due_date`}
                                                  invalid={!!errors.bid_date}
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </TabPane>
                      {/* <TabPane tabId="4">
                        {projectTasks?.tasks?.length ? (
                          <>
                            <div className="d-flex justify-content-end">
                              <LoadingButton
                                form={`supplier-form`}
                                color={"primary"}
                                loading={isLoading}
                                disabled={isLoading}
                                className={"px-2 mb-3 btn btn-primary"}
                                type="submit"
                                onClick={() => setAction(1)}
                              >
                                {"Update"}
                              </LoadingButton>
                            </div>
                            <div className="p-3">
                              <Row className="mb-2 mt-2">
                                <Col sm={4}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Margin Type
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <MarginLi
                                      control={control}
                                      placeholder="Select"
                                      name={"margin_type"}
                                      invalid={!!errors.margin_type}
                                      target={"body"}
                                      // onSelectChange={e =>
                                      //   handlePayTermChange(e)
                                      // }
                                      // disabled={id ? true : false}
                                    />
                                    <FormFeedback invalid>
                                      {errors.margin_type?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                {watch("margin_type")?.value == 1 ? (
                                  <Col sm={4}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Margin By Percentage
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFInput
                                        control={control}
                                        placeholder="Enter Percentage"
                                        name={`margin_percentage`}
                                      />
                                      <FormFeedback invalid>
                                        {errors.margin_percentage?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                ) : watch("margin_type")?.value == 2 ? (
                                  <Col sm={4}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Margin By Percentage
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFInput
                                        control={control}
                                        placeholder="Enter Percentage"
                                        name={`margin_value`}
                                      />
                                      <FormFeedback invalid>
                                        {errors.margin_value?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </Row>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </TabPane> */}
                    </TabContent>

                    {projectTasks?.tasks?.length &&
                    activeTab != 3 &&
                    activeTab != 4 ? (
                      <>
                        {/* {!prelimsCheck && (
                          <Row className="d-flex align-items-center py-3">
                            <Col lg={9} className="text-end dark-text">
                              Prelims B
                            </Col>
                            <Col lg={3} className="ps-5 dark-text ">
                              <UFInput
                                control={control}
                                placeholder="Enter the Prelims"
                                name={"preliminary_val"}
                                invalid={!!errors.preliminary_val}
                              />
                            </Col>
                          </Row>
                        )} */}
                        {prelimsCheck && (
                          <Row className="d-flex align-items-center py-3">
                            <Col lg={9} className="text-end dark-text">
                              Prelims ({watch("preliminary_per")}%)
                            </Col>
                            <Col lg={3} className="ps-5 dark-text ">
                              <UFInput
                                control={control}
                                placeholder="Enter the Prelims"
                                name={"preliminary_per"}
                                invalid={!!errors.preliminary_per}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className="d-flex align-items-center py-3">
                          <Col lg={9} className="text-end dark-text">
                            Net Total (AED)
                          </Col>
                          <Col lg={3} className="ps-4 text-center dark-text ">
                            {/* {Number(
                              calulation("subtotal") +
                                (calulation("subtotal") *
                                  Number(projectProfit)) /
                                  100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })} */}
                            {perlimspresentage == null
                              ? calculateTotal(projectTasks)
                                  // +
                                  // (calculateTotal(projectTasks) *
                                  //   Number(projectProfit)) /
                                  //   100
                                  .toLocaleString("en", {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                  })
                              : calculateTotal(projectTasks)
                                  // +
                                  // (calculateTotal(projectTasks) *
                                  //   Number(projectProfit)) /
                                  //   100 +
                                  // (calculateTotal(projectTasks) *
                                  //   Number(perlimspresentage)) /
                                  //   100
                                  .toLocaleString("en", {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                  })}
                            {/* {(
                              calculateTotal(projectTasks) +
                              (calculateTotal(projectTasks) *
                                Number(projectProfit)) /
                                100 + (calculateTotal(projectTasks) * Number(perlimspresentage)) / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })} */}
                          </Col>
                        </Row>
                        {/* <Row className="d-flex align-items-center py-3">
                          <Col lg={9} className="text-end dark-text">
                            Discount({watch("discount")}%)
                          </Col>
                          <Col lg={3} className="ps-5 dark-text ">
                            <UFInput
                              control={control}
                              placeholder="Enter the Discount"
                              name={"discount"}
                              invalid={!!errors.discount}
                            />
                          </Col>
                        </Row> */}
                        {/* <Row className="d-flex align-items-center py-3">
                          <Col lg={9} className="text-end dark-text">
                            VAT ({loggedUser?.settings?.tax}%)
                          </Col>

                          <Col lg={3} className="ps-5 dark-text ">
                            {(
                              ((calculateTotal(projectTasks) +
                                (calculateTotal(projectTasks) *
                                  Number(projectProfit)) /
                                  100) *
                                Number(loggedUser?.settings?.tax)) /
                              100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </Col>
                        </Row> */}
                        {/* 
                        <Row className="d-flex align-items-center py-3">
                          <Col lg={9} className="text-end dark-text">
                            Estimate Value(AED)
                          </Col>
                          <Col lg={3} className="ps-5 dark-text ">
                            {calulation("estimate_value").toLocaleString("en", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </Col>
                        </Row> */}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <hr className="m-0" />
                    <Row className="py-4 mt-4 estimation-stickey">
                      <Col lg={1}>
                        <h5>Select</h5>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleMaterialCheck(materialCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_material"}
                            checked={materialCheck}
                          />
                          <Label className="ms-2">Material</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleManpowerCheck(manpowerCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_manpower"}
                            checked={manpowerCheck}
                          />
                          <Label className="ms-2">Manpower</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleContractCheck(contractCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_subcontract"}
                            checked={contractCheck}
                          />
                          <Label className="ms-2">Subcontract</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handlePrelimsCheck(prelimsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_prelims"}
                            checked={prelimsCheck}
                          />

                          <Label className="ms-2">Prelims</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleOverheadsCheck(overheadsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_overheads"}
                            checked={overheadsCheck}
                          />

                          <Label className="ms-2">Overheads</Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    {materialCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Material Details</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-15">C Code</th>
                                  <th className="wdt-20">Cat Code</th>
                                  <th className="wdt-20">Product</th>
                                  <th>Description</th>
                                  <th className="wdt-7">Unit</th>
                                  <th className="wdt-5">Qty</th>
                                  <th className="wdt-8">Price(AED)</th>
                                  <th>
                                    <img
                                      src={infoDark}
                                      className="info-icon"
                                      id={`PopoverLegacy-head`}
                                    />
                                    <UncontrolledPopover
                                      placement="bottom"
                                      target={`PopoverLegacy-head`}
                                      trigger="hover"
                                    >
                                      <PopoverBody>
                                        <div className="table-popup-content">
                                          <div className="">
                                            Check to add manpower for particular
                                            Product
                                          </div>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {materialFields.map((item, index) => (
                                  <tr key={`materialFields${index}`}>
                                    <td className="ps-1">
                                      <EstimationClass
                                        control={control}
                                        placeholder="Class"
                                        name={`estimation_materials.${index}.class_id`}
                                        type={"materials"}
                                        onSelectChange={e =>
                                          handleClassChange(e, index)
                                        }
                                      />
                                      
                                    </td>
                                    <td className="ps-1">
                                      <EstimationMaterialCategory
                                        control={control}
                                        placeholder="Category"
                                        name={`estimation_materials.${index}.category_id`}
                                        type={"materials"}
                                        classCode={watch(
                                          `estimation_materials.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleCategoryReset(e, index)
                                        }
                                      />
                                     
                                    </td>
                                    <td className="ps-1">
                                      <EstimationProduct
                                        control={control}
                                        placeholder="Product"
                                        name={`estimation_materials.${index}.product_id`}
                                        catCode={watch(
                                          `estimation_materials.${index}.category_id`
                                        )}
                                        onSelectChange={e =>
                                          handleProductChange(e, index)
                                        }
                                        selectedProduct={watch(
                                          `estimation_materials`
                                        )}
                                        index={index}
                                      />
                                      
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_materials.${index}.description`}
                                      />
                                      
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Unit"
                                        name={`estimation_materials.${index}.unit`}
                                        disabled={true}
                                        className="disable-unit"
                                      />
                                      
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Qty"
                                        name={`estimation_materials.${index}.quantity`}
                                        onChange={e =>
                                          handleQuantityChange(e, index)
                                        }
                                      />
                                     
                                    </td>

                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_materials.${index}.price`}
                                      />
                                      
                                    </td>
                                    <td>
                                      <div
                                        className="px-2 d-flex justify-content-center"
                                        onClick={e =>
                                          handleMaterialToManCheck(e, index)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          className="ever-rise-checkbox"
                                          name={`estimation_materials.${index}.is_manpower_material`}
                                          checked={materialToManCheck[index]}
                                          disabled={
                                            watch(
                                              `estimation_materials.${index}.product_id`
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="ps-0">
                                      <div className="pb-1 px-2 d-flex justify-content-center">
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => {
                                            materialRemove(index)
                                            handleMaterialToManCheck(
                                              false,
                                              index
                                            )
                                            handleMaterialRemove(item?.table_id)
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() => {
                                  materialAppend({
                                    class_id: "",
                                    category_id: "",
                                    quantity: "",
                                    product_id: "",
                                    price: "",
                                    is_manpower_material: false,
                                  })
                                  
                                }}
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {calulation("material").toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {manpowerCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Manpower</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-20">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th>No.of Hours</th>
                                  <th>Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {materialList?.length ? (
                                  materialList?.map((dt, i) => (
                                    <tr>
                                      <td className="ps-1">
                                        <EstimationClass
                                          control={control}
                                          placeholder="Class"
                                          name={`product_manpowers.${i}.class_id`}
                                          type={"manpowers"}
                                          disabled={true}
                                          onSelectChange={e =>
                                            handleManpowerMaterialClass(i)
                                          }
                                        />
                                      </td>
                                      <td className="ps-1">
                                        <EstimationCategory
                                          control={control}
                                          placeholder="Catagory"
                                          name={`product_manpowers.${i}.category_id`}
                                          type={"manpowers"}
                                          classCode={watch(
                                            `product_manpowers.${i}.class_id`
                                          )}
                                          onSelectChange={e =>
                                            handleManpowerCategoryChange(e, i)
                                          }
                                        />
                                      </td>
                                      <td className="ps-1">
                                        <UFInput
                                          className="product-manpowers"
                                          control={control}
                                          placeholder="Description"
                                          name={`product_manpowers.${i}.description`}
                                        />
                                      </td>
                                      <td className="ps-1">
                                        <UFInput
                                          control={control}
                                          placeholder="No.of Hours"
                                          name={`product_manpowers.${i}.no_of_labours`}
                                        />
                                      </td>
                                      <td className="ps-1">
                                        <UFInput
                                          control={control}
                                          placeholder="Price"
                                          name={`product_manpowers.${i}.price`}
                                        />
                                      </td>
                                      <td>
                                        <div className="pb-1 px-2 d-flex justify-content-center">
                                          <img
                                            src={infoDark}
                                            className="info-icon"
                                            id={`PopoverLegacy-${i}`}
                                          />
                                          <UncontrolledPopover
                                            placement="bottom"
                                            target={`PopoverLegacy-${i}`}
                                            trigger="hover"
                                          >
                                            <PopoverBody>
                                              <div className="table-popup-content">
                                                <div className="">
                                                  Manpower for{" "}
                                                  {dt?.material_id?.description}
                                                </div>
                                              </div>
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <></>
                                )}
                                {manpowerFields.map((item, index) => (
                                  <tr key={`estimation_manpowers${index}`}>
                                    <td className="ps-1">
                                      <EstimationClass
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_manpowers.${index}.class_id`}
                                        type={"manpowers"}
                                        onSelectChange={e =>
                                          handleManpowerClass(index)
                                        }
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <EstimationCategory
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_manpowers.${index}.category_id`}
                                        type={"manpowers"}
                                        classCode={watch(
                                          `estimation_manpowers.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleCategoryChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_manpowers.${index}.description`}
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="No.of Hours"
                                        name={`estimation_manpowers.${index}.no_of_labours`}
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_manpowers.${index}.price`}
                                      />
                                    </td>
                                    <td>
                                      <div className="pb-1 px-2 d-flex justify-content-center">
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => {
                                            manpowerRemove(index)
                                            handleManPowerRemove(item?.table_id)
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  manpowerAppend({
                                    class_id: "",
                                    category_id: "",
                                    no_of_labours: "",
                                    description: "",
                                    price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {(
                                    calulation("manpower") +
                                    calulation("materialMan")
                                  ).toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {contractCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>SubContract</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-15">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th>Unit</th>
                                  <th className="wdt-5">Qty</th>
                                  <th className="wdt-15">Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractFields.map((item, index) => (
                                  <tr key={`estimation_subcontracts${index}`}>
                                    <td className="ps-1">
                                      <EstimationClass
                                        control={control}
                                        placeholder="Class"
                                        name={`estimation_subcontracts.${index}.class_id`}
                                        type={"subcontracts"}
                                        onSelectChange={e =>
                                          handleContractClass(index)
                                        }
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <EstimationCategory
                                        control={control}
                                        placeholder="Category"
                                        name={`estimation_subcontracts.${index}.category_id`}
                                        type={"subcontracts"}
                                        classCode={watch(
                                          `estimation_subcontracts.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleContractCatChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_subcontracts.${index}.description`}
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <Unit
                                        control={control}
                                        placeholder={"Unit"}
                                        name={`estimation_subcontracts.${index}.unit`}
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Qty"
                                        name={`estimation_subcontracts.${index}.duration`}
                                      />
                                    </td>
                                    <td className="ps-1">
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_subcontracts.${index}.price`}
                                      />
                                    </td>
                                    <td>
                                      <div className="pb-1 px-2 d-flex justify-content-center">
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => {
                                            contractRemove(index)
                                            handleSubContractsRemove(
                                              item?.table_id
                                            )
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  contractAppend({
                                    class_id: "",
                                    category_id: "",
                                    description: "",
                                    duration: "",
                                    price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {calulation("contract").toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {prelimsCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Prelims</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                           
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-70"></th>
                                  <th>Prelims (%)</th>
                                  <th>Subtotal(AED)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="border-0 wdt-70 d-flex align-items-center justify-content-center">{`(Material + Manpower + SubContract)*${watch(
                                    "preliminary_per"
                                  )}/100`}</td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`preliminary_per`}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    {" "}
                                    {(
                                      ((calulation("material") +
                                        calulation("manpower") +
                                        calulation("materialMan")) *
                                        Number(watch("preliminary_per"))) /
                                      100
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            
                          </Col>
                        </Row>
                      </>
                    )}
                    {overheadsCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Overheads</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-70"></th>
                                  <th>Overheads (%)</th>
                                  <th>Subtotal(AED)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="border-0 wdt-70 d-flex align-items-center justify-content-center">{`(Material + Manpower + SubContract + Prelims )*${watch(
                                    "overheads_per"
                                  )}/100`}</td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`overheads_per`}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    {calulation("overheads").toFixed(2)}
                                    
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            
                          </Col>
                        </Row>
                      </>
                    )}
                    <hr className="m-0" />
                    {!prelimsCheck && (
                      <Row className="d-flex align-items-center py-3 mt-5">
                        <Col lg={9} className="text-end dark-text">
                          Prelims B
                        </Col>
                        <Col lg={3} className="ps-5 dark-text ">
                          <UFInput
                            control={control}
                            placeholder="Enter the Prelims"
                            name={"preliminary_val"}
                            invalid={!!errors.preliminary_val}
                          />
                          
                        </Col>
                      </Row>
                    )}
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Net Total(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("subtotal").toFixed(2)}
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Discount({watch("discount")}%)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        <UFInput
                          control={control}
                          placeholder="Enter the Discount"
                          name={"discount"}
                          invalid={!!errors.discount}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        VAT ({loggedUser?.settings?.tax}%)
                      </Col>
                      
                      <Col lg={3} className="ps-5 dark-text ">
                        {(
                          (calulation("subtotal") *
                            Number(loggedUser?.settings?.tax)) /
                          100
                        ).toFixed(2)}
                      </Col>
                    </Row>

                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Estimate Value(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("estimate_value").toFixed(2)}
                      </Col>
                    </Row> */}
                  </div>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
      <Offcanvas
        backdrop={false}
        direction="end"
        isOpen={modal}
        toggle={toggle}
        className="w-of-70"
      >
        <OffcanvasHeader toggle={toggle}>
          <div className="d-flex justify-content-between w-100">
            <div>Task Details</div>{" "}
            <div className="ms-auto">
              <div className="mt-1">
                <Button
                  color="secondary"
                  className="addButton mb-3 me-2"
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  form={`supplier-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  className={"px-2 mb-3 btn btn-primary"}
                  type="submit"
                  onClick={() => setAction(1)}
                >
                  {watch("edit") == 1 ? "Update" : "Save"}
                </LoadingButton>
              </div>
            </div>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody className="pt-0">
          {/* <ModalBody className="pt-0"> */}
          <div className="estimation-stickey">
            <Row className="pt-2">
              {/* <Col lg={2}>Task Name</Col> */}
              <Col lg={3}>
                <FormGroup className="mb-4">
                  <Label>
                    Task Name<span className="mandatory">*</span>
                  </Label>
                  <UFInput
                    control={control}
                    placeholder="Enter the Task Name"
                    name={"name"}
                    invalid={!!errors.name}
                  />
                  <FormFeedback invalid>{errors.name?.message}</FormFeedback>
                </FormGroup>
              </Col>
              {/* </Row>
          <Row>
            <Col lg={2}>Parent</Col> */}
              <Col lg={3}>
                <FormGroup className="mb-4">
                  <Label>Parent</Label>
                  <TasksList
                    control={control}
                    placeholder="Select"
                    name={"parent_id"}
                    invalid={!!errors.parent_id}
                    estimationId={projectTasks?.estimation_id}
                    currentTask={watch("task_id") ? watch("task_id") : ""}
                  />
                  <FormFeedback invalid>
                    {errors.parent_id?.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {/* </Row>
          <Row>
            <Col lg={2}>Description</Col> */}
              <Col lg={3}>
                <FormGroup className="mb-4">
                  <Label>Description</Label>
                  <UFEditor
                    row="5"
                    control={control}
                    placeholder="Enter the Description"
                    name={"description"}
                    invalid={!!errors.description}
                  />
                  <FormFeedback invalid>
                    {errors.description?.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={3}>
                <div>
                  OHP:{" "}
                  {Number(
                    Number(currTask?.unit_price || 0) *
                      Number(currTask?.qty || 0)
                  ) -
                    (Number(
                      watch("estimation_materials")?.reduce(
                        (sum, est) => sum + parseFloat(est?.rowprice || 0),
                        0
                      ) || 0
                    ) +
                      Number(
                        watch("estimation_manpowers")?.reduce(
                          (sum, est) => sum + parseFloat(est?.total || 0),
                          0
                        ) || 0
                      ) +
                      Number(
                        watch("estimation_subcontracts")?.reduce(
                          (sum, est) => sum + parseFloat(est?.totalprice || 0),
                          0
                        ) || 0
                      ))}
                </div>
              </Col>
            </Row>
            <Row className="pb-4 pt-0 estimation-stickey">
              <Col lg={1}>
                <h5>Select</h5>
              </Col>
              {Number(currTask?.material) +
                Number(currTask?.fittings) +
                Number(currTask?.consumables) !=
                0 && (
                <Col
                  lg={"auto"}
                  onClick={() => handleMaterialCheck(materialCheck)}
                >
                  <FormGroup>
                    <input
                      type="checkbox"
                      className="ever-rise-checkbox"
                      name={"is_material"}
                      checked={materialCheck}
                    />
                    <Label className="ms-2">Material</Label>
                  </FormGroup>
                </Col>
              )}
              {currTask?.manpower != 0 && (
                <Col
                  lg={"auto"}
                  onClick={() => handleManpowerCheck(manpowerCheck)}
                >
                  <FormGroup>
                    <input
                      type="checkbox"
                      className="ever-rise-checkbox"
                      name={"is_manpower"}
                      checked={manpowerCheck}
                    />
                    <Label className="ms-2">Manpower</Label>
                  </FormGroup>
                </Col>
              )}
              {currTask?.sub_contract != 0 && (
                <Col
                  lg={"auto"}
                  onClick={() => handleContractCheck(contractCheck)}
                >
                  <FormGroup>
                    <input
                      type="checkbox"
                      className="ever-rise-checkbox"
                      name={"is_subcontract"}
                      checked={contractCheck}
                    />
                    <Label className="ms-2">Subcontract</Label>
                  </FormGroup>
                </Col>
              )}
              {/* <Col
                        lg={"auto"}
                        onClick={() => handlePrelimsCheck(prelimsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_prelims"}
                            checked={prelimsCheck}
                          />

                          <Label className="ms-2">Prelims</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleOverheadsCheck(overheadsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_overheads"}
                            checked={overheadsCheck}
                          />

                          <Label className="ms-2">Overheads</Label>
                        </FormGroup>
                      </Col> */}
            </Row>
          </div>

          {materialCheck && (
            <>
              <Row className="mb-4">
                <h5>Material Details</h5>
              </Row>
              <Row className="mb-4">
                <Col lg={12}>
                  <table className="estimation-table mb-4">
                    <thead>
                      <tr>
                        {/* <th className="wdt-15">C Code</th>
                        <th className="wdt-20">Cat Code</th> */}
                        <th className="wdt-20">Product</th>
                        <th className="wdt-20">Product Group</th>
                        <th>Product Name</th>
                        <th className="wdt-7">Unit</th>
                        <th className="wdt-5">Qty</th>
                        <th className="wdt-8">Unit Price(AED)</th>
                        <th className="wdt-8">Price(AED)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {materialFields.map((item, index) => (
                        <tr key={`materialFields-${item.id || index}`}>
                          {/* <td className="ps-1">
                            <EstimationClass
                              control={control}
                              placeholder="Class"
                              name={`estimation_materials.${index}.class_id`}
                              type={"materials"}
                              onSelectChange={e => handleClassChange(e, index)}
                              dropView={true}
                            />
                          </td>
                          <td className="ps-1">
                            <EstimationMaterialCategory
                              control={control}
                              placeholder="Category"
                              name={`estimation_materials.${index}.category_id`}
                              type={"materials"}
                              classCode={watch(
                                `estimation_materials.${index}.class_id`
                              )}
                              onSelectChange={e =>
                                handleCategoryReset(e, index)
                              }
                              dropView={true}
                            />
                          </td> */}
                          <td className="ps-1">
                            <EstProductsLi
                              control={control}
                              placeholder="Product"
                              name={`estimation_materials.${index}.product_id`}
                              // catCode={watch(
                              //   `estimation_materials.${index}.category_id`
                              // )}
                              onSelectChange={e =>
                                handleProductChange(e, index)
                              }
                              selectedProduct={watch(`estimation_materials`)}
                              index={index}
                              dropView={true}
                              disabled={index == 0 ? true : false}
                              groupType={index == 0 ? 1 : "2,3"}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Description"
                              name={`estimation_materials.${index}.description`}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Group"
                              name={`estimation_materials.${index}.group`}
                              disabled={true}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Unit"
                              name={`estimation_materials.${index}.unit`}
                              disabled={true}
                              className="disable-unit"
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Qty"
                              name={`estimation_materials.${index}.quantity`}
                              onChange={e => handleQuantityChange(e, index)}
                            />
                          </td>

                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Price"
                              name={`estimation_materials.${index}.price`}
                              onChange={e => handleMaterialPrice(e, index)}
                            />
                          </td>

                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Price"
                              name={`estimation_materials.${index}.rowprice`}
                            />
                          </td>
                          <td className="ps-0">
                            <div className="pb-1 px-2 d-flex justify-content-center">
                              {/* <img
                                src={lightTrash}
                                alt="light-trash"
                                className="trash-img pointer"
                                onClick={() => {
                                  materialRemove(index)
                                  // handleMaterialToManCheck(false, index)
                                  handleMaterialRemove(item?.table_id)
                                }}
                              /> */}
                              {index == 0 ? (
                                <></>
                              ) : (
                                <span
                                  className="trash-img pointer"
                                  onClick={() => {
                                    materialRemove(index)
                                    // handleMaterialToManCheck(false, index)
                                    handleMaterialRemove(item?.table_id)
                                  }}
                                >
                                  <TrashIcon size={20} />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Col
                    lg="12"
                    xs={12}
                    md={2}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      color="primary"
                      className="addButton mt-2"
                      onClick={() => {
                        materialAppend({
                          class_id: "",
                          category_id: "",
                          quantity: "",
                          product_id: "",
                          price: "",
                          is_manpower_material: false,
                        })
                      }}
                    >
                      + Row
                    </Button>
                    {/* <Row className="w-50 d-flex align-items-center">
                      <Col lg={6} className="text-end dark-text">
                        Subtotal(AED)
                      </Col>
                      <Col lg={6} className="ps-5 dark-text">
                        {calulation("material").toFixed(2)}
                      </Col>
                    </Row> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
          {manpowerCheck && (
            <>
              <Row className="mb-4">
                <h5>Manpower</h5>
              </Row>
              <Row className="mb-4">
                <Col lg={12}>
                  <table className="estimation-table mb-4">
                    <thead>
                      <tr>
                        {/* <th className="wdt-20">Class </th> */}
                        <th className="wdt-20">Designation </th>
                        {/* <th>Description</th> */}
                        <th>No.of Hours</th>
                        <th>Price (AED)</th>
                        <th>Total Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {materialList?.length ? (
                        materialList?.map((dt, i) => (
                          <tr>
                            <td className="ps-1">
                              <EstimationClass
                                control={control}
                                placeholder="Class"
                                name={`product_manpowers.${i}.class_id`}
                                type={"manpowers"}
                                disabled={true}
                                onSelectChange={e =>
                                  handleManpowerMaterialClass(i)
                                }
                                dropView={true}
                              />
                            </td>
                            <td className="ps-1">
                              <EstimationCategory
                                control={control}
                                placeholder="Catagory"
                                name={`product_manpowers.${i}.category_id`}
                                type={"manpowers"}
                                classCode={watch(
                                  `product_manpowers.${i}.class_id`
                                )}
                                onSelectChange={e =>
                                  handleManpowerCategoryChange(e, i)
                                }
                                dropView={true}
                              />
                            </td>
                            <td className="ps-1">
                              <UFInput
                                className="product-manpowers"
                                control={control}
                                placeholder="Description"
                                name={`product_manpowers.${i}.description`}
                              />
                            </td>
                            <td className="ps-1">
                              <UFInput
                                control={control}
                                placeholder="No.of Hours"
                                name={`product_manpowers.${i}.no_of_labours`}
                              />
                            </td>
                            <td className="ps-1">
                              <UFInput
                                control={control}
                                placeholder="Price"
                                name={`product_manpowers.${i}.price`}
                              />
                            </td>
                            <td>
                              <div className="pb-1 px-2 d-flex justify-content-center">
                                <img
                                  src={infoDark}
                                  className="info-icon"
                                  id={`PopoverLegacy-${i}`}
                                />
                                <UncontrolledPopover
                                  placement="bottom"
                                  target={`PopoverLegacy-${i}`}
                                  trigger="hover"
                                >
                                  <PopoverBody>
                                    <div className="table-popup-content">
                                      <div className="">
                                        Manpower for{" "}
                                        {dt?.material_id?.description}
                                      </div>
                                    </div>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                      {manpowerFields.map((item, index) => (
                        <tr key={`estimation_manpowers${item.id || index}`}>
                          {/* <td className="ps-1">
                            <EstimationClass
                              control={control}
                              placeholder="Select"
                              name={`estimation_manpowers.${index}.class_id`}
                              type={"manpowers"}
                              onSelectChange={e => handleManpowerClass(index)}
                              dropView={true}
                            />
                          </td> */}
                          <td className="ps-1">
                            <Designation
                              control={control}
                              placeholder="Select"
                              name={`estimation_manpowers.${index}.category_id`}
                              type={"manpowers"}
                              // classCode={watch(
                              //   `estimation_manpowers.${index}.class_id`
                              // )}
                              onSelectChange={e =>
                                handleCategoryChange(e, index)
                              }
                              dropView={true}
                            />
                            {/* <EstimationCategory
                              control={control}
                              placeholder="Select"
                              name={`estimation_manpowers.${index}.category_id`}
                              type={"manpowers"}
                              classCode={watch(
                                `estimation_manpowers.${index}.class_id`
                              )}
                              onSelectChange={e =>
                                handleCategoryChange(e, index)
                              }
                              dropView={true}
                            /> */}
                          </td>
                          {/* <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Description"
                              name={`estimation_manpowers.${index}.description`}
                            />
                          </td> */}
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="No.of Hours"
                              name={`estimation_manpowers.${index}.no_of_labours`}
                              onChange={e => handleQuantityChangess(e, index)}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Price"
                              name={`estimation_manpowers.${index}.price`}
                              onChange={e => handleMaterialPricess(e, index)}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Total"
                              name={`estimation_manpowers.${index}.total`}
                              readOnly
                            />
                          </td>
                          <td className="p-0">
                            <div className="pb-1 px-2 d-flex justify-content-center">
                              {/* <img
                                src={lightTrash}
                                alt="light-trash"
                                className="trash-img pointer"
                                onClick={() => {
                                  manpowerRemove(index)
                                  handleManPowerRemove(item?.table_id)
                                }}
                              /> */}
                              <span
                                className="trash-img pointer"
                                onClick={() => {
                                  manpowerRemove(index)
                                  handleManPowerRemove(item?.table_id)
                                }}
                              >
                                <TrashIcon size={20} />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Col
                    lg="12"
                    xs={12}
                    md={2}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      color="primary"
                      className="addButton mt-2"
                      onClick={() =>
                        manpowerAppend({
                          class_id: "",
                          category_id: "",
                          no_of_labours: "",
                          description: "",
                          price: "",
                        })
                      }
                    >
                      + Row
                    </Button>
                    {/* <Row className="w-50 d-flex align-items-center">
                      <Col lg={6} className="text-end dark-text">
                        Subtotal{" "}(AED)
                      </Col>
                      <Col lg={6} className="ps-5 dark-text">
                        {(
                          calulation("manpower") + calulation("materialMan")
                        ).toFixed(3)}
                      </Col>
                    </Row> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
          {contractCheck && (
            <>
              <Row className="mb-4">
                <h5>SubContract</h5>
              </Row>
              <Row className="mb-4">
                <Col lg={12}>
                  <table className="estimation-table mb-4">
                    <thead>
                      <tr>
                        {/* <th className="wdt-15">Class </th> */}
                        <th className="wdt-20">Category </th>
                        {/* <th>Description</th> */}
                        <th>Unit</th>
                        <th className="wdt-5">Qty</th>
                        <th className="wdt-15">Price (AED)</th>
                        <th>Total Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractFields.map((item, index) => (
                        <tr key={`estimation_subcontracts${item.id || index}`}>
                          {/* <td className="ps-1">
                            <EstimationClass
                              control={control}
                              placeholder="Class"
                              name={`estimation_subcontracts.${index}.class_id`}
                              type={"subcontracts"}
                              onSelectChange={e => handleContractClass(index)}
                              dropView={true}
                            />
                          </td> */}
                          <td className="ps-1">
                            <SupplierCodeList
                              control={control}
                              placeholder="Select"
                              name={`estimation_subcontracts.${index}.category_id`}
                              subcontractor={true}
                              onSelectChange={e =>
                                handleContractCatChange(e, index)
                              }
                              dropView={true}
                            />
                          </td>
                          {/* <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Description"
                              name={`estimation_subcontracts.${index}.description`}
                            />
                          </td> */}
                          <td className="ps-1">
                            <Unit
                              control={control}
                              placeholder={"Unit"}
                              name={`estimation_subcontracts.${index}.unit`}
                              disabled={
                                watch(
                                  `estimation_subcontracts.${index}.category_id`
                                )?.value
                                  ? false
                                  : true
                              }
                              dropView={true}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Qty"
                              name={`estimation_subcontracts.${index}.duration`}
                              onChange={e => handilDuration(e, index)}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="Price"
                              name={`estimation_subcontracts.${index}.price`}
                              onChange={e => handilPrise(e, index)}
                            />
                          </td>
                          <td className="ps-1">
                            <UFInput
                              control={control}
                              placeholder="totalPrice"
                              name={`estimation_subcontracts.${index}.totalprice`}
                            />
                          </td>
                          <td className="p-0">
                            <div className="pb-1 px-2 d-flex justify-content-center">
                              {/* <img
                                src={lightTrash}
                                alt="light-trash"
                                className="trash-img pointer"
                                onClick={() => {
                                  contractRemove(index)
                                  handleSubContractsRemove(item?.table_id)
                                }}
                              /> */}
                              <span
                                className="trash-img pointer"
                                onClick={() => {
                                  contractRemove(index)
                                  handleSubContractsRemove(item?.table_id)
                                }}
                              >
                                <TrashIcon size={20} />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Col
                    lg="12"
                    xs={12}
                    md={2}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      color="primary"
                      className="addButton mt-2"
                      onClick={() =>
                        contractAppend({
                          class_id: "",
                          category_id: "",
                          description: "",
                          duration: "",
                          price: "",
                        })
                      }
                    >
                      + Row
                    </Button>
                    {/* <Row className="w-50 d-flex align-items-center">
                      <Col lg={6} className="text-end dark-text">
                        Subtotal (AED)
                      </Col>
                      <Col lg={6} className="ps-5 dark-text">
                        {calulation("contract").toFixed(3)}
                      </Col>
                    </Row> */}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </OffcanvasBody>
        {/* <ModalFooter>
          <LoadingButton
            form={`supplier-form`}
            color={"primary"}
            loading={isLoading}
            disabled={isLoading}
            className={"px-2 mb-3 btn btn-primary"}
            type="submit"
            onClick={() => setAction(1)}
          >
            {watch("edit") == 1 ? "Update" : "Save"}
          </LoadingButton>
          
        </ModalFooter> */}
      </Offcanvas>
      {/* <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{"Bulk Upload"}</h5>
            <img
              src={closeIcon}
              alt=""
              onClick={() => setModal(false)}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex p-5 my-3">
            <Col className="file-import-div me-3 ">
              <label htmlFor="upload" className="m-0 pointer">
                <input
                  type="file"
                  id="upload"
                  name="upload"
                  accept=".xls,.xlsx"
                  onChange={event => {
                    onFileChange(event)
                  }}
                />
                {fileName ? (
                  <span>{fileName?.target?.files?.[0]?.name}</span>
                ) : (
                  <>
                    <img
                      src={uploadicon}
                      alt="Upload Excel"
                      className="upload-icon me-2 "
                    />
                    <span className="upload">Upload&nbsp;</span>
                    <span>the file</span>
                  </>
                )}
              </label>
            </Col>
            <LoadingButton
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </LoadingButton>
          </div>
        </ModalBody>
        <ModalFooter className="bulk-upload-footer">
          <Row className="d-flex justify-content-center">
            <Col lg={8} className="text-center">
              Please download the sample file, add items, price etc & upload it
              back to create your Documents
            </Col>
          </Row>
          <Row
            className="fw-bold d-flex justify-content-center w-100 pointer"
            onClick={() => handleSampleFile()}
          >
            Download Sample File &gt;
          </Row>
        </ModalFooter>
      </Modal> */}
    </React.Fragment>
  )
}

export default EditEstimation
