import { del, get, post } from "../../helpers/api_helper"

export const postProjectMaster = data => {
  return !data?.id
    ? post(`project-master/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`project-master/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const postWorkProgress = data => {
  return post(`update-wp-value/${data?.id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const generateWorkProgress = data => {
  return post(`generate-wp-value/${data?.id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export const postIssueNote = data => {
  return !data?.id
    ? post(`store-issuenote/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`store-issuenote/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const postCentralRequest = data => {
  return !data?.id
    ? post(`central-store/request`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`central-store/request/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const fetchAllIssueNote = data => {
  return get(`store-issuenote/search?${data?.params}`)
}
export const fetchSingleIssueNote = id => {
  return get(`store-issuenote/fetch/${id}`)
}
export const searchProjectMaster = data => {
  return get(`project-master/search?${data?.params}`)
}

export const deleteProject = data => {
  return del(`project-master/${data}`)
}

export const searchProjectManager = data => {
  return get(`project-master/role-search?${data?.params}`)
}

export const fetchProjecrtMaster = data => {
  return get(`project-master/fetch/${data}`)
}

export const fetchProjectDashboard = data => {
  return get(`project-master/dashboard-search?${data?.params}`)
}

export const fetchProjectDetailDashboard = id => {
  return get(`project-master/fetch/${id}`)
}

export const fetchStoreMaterialRequest = data => {
  return get(`project-master/materialrequest-search?${data?.params}`)
}
export const fetchStoreIssueNote = data => {
  return get(`project-master/insearch-search?${data?.params}`)
}
export const fetchStoreCsRequest = data => {
  return get(`project-master/csrequest-search?${data?.params}`)
}
export const fetchStoreDashRequest = data => {
  return get(`project-master/dashboard-fetch/${data}`)
}
export const fetchStoreGraph = data => {
  return post(`project-master/dashboard-graph`, data)
}

export const fetchProcurementDashRequest = data => {
  return get(`project-master/procurement-fetch/${data}`)
}
export const searchProcurmentDashRequest = data => {
  return get(`project-master/procurement-search?${data?.params}`)
}

// manpower request
export const postRequestManpower = data => {
  return post(
    `project-master/employee-request
  `,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
}

export const searchManpowerhRequest = data => {
  return get(`project-master/manpowerreq-search?${data?.params}`)
}

// post time manager Attendance

export const postAttendance = data => {
  return !data?.id
    ? post(`attendances/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`attendances/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// current-emp
export const fetchCurrentEmp = data => {
  return get(`project-master/current-employee?${data?.params}`)
}
