import React from "react"

const ProjectManagementIcon = ({
  color = "#8B909A",
  size = 18,
  className = "",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M1.66856 2.96557C1.82307 2.96556 1.97126 2.90418 2.08052 2.79492C2.18978 2.68566 2.25117 2.53747 2.25117 2.38295V1.16524H15.5946V12.3734C15.5946 12.5279 15.656 12.6761 15.7653 12.7854C15.8745 12.8946 16.0227 12.956 16.1773 12.956C16.3318 12.956 16.48 12.8946 16.5892 12.7854C16.6985 12.6761 16.7599 12.5279 16.7599 12.3734V0.582618C16.7599 0.428101 16.6985 0.279914 16.5892 0.170653C16.48 0.061393 16.3318 7.7253e-06 16.1773 0H1.66856C1.51404 7.7253e-06 1.36585 0.061393 1.25659 0.170653C1.14733 0.279914 1.08595 0.428101 1.08594 0.582618V2.38295C1.08595 2.53747 1.14733 2.68566 1.25659 2.79492C1.36585 2.90418 1.51404 2.96556 1.66856 2.96557Z"
        fill="currentColor"
      />
      <path
        d="M16.1773 15.567C16.0227 15.567 15.8746 15.6284 15.7653 15.7377C15.656 15.8469 15.5946 15.9951 15.5946 16.1496V16.8342H2.25117V6.23496C2.25117 6.08044 2.18979 5.93225 2.08053 5.82299C1.97127 5.71373 1.82308 5.65234 1.66856 5.65234C1.51404 5.65234 1.36584 5.71373 1.25658 5.82299C1.14732 5.93225 1.08594 6.08044 1.08594 6.23496V17.4169C1.08595 17.5714 1.14733 17.7196 1.25659 17.8288C1.36585 17.9381 1.51404 17.9995 1.66856 17.9995H16.1773C16.3318 17.9995 16.48 17.9381 16.5892 17.8288C16.6985 17.7196 16.7599 17.5714 16.7599 17.4169V16.1496C16.7599 15.9951 16.6985 15.8469 16.5892 15.7377C16.48 15.6284 16.3318 15.567 16.1773 15.567Z"
        fill="currentColor"
      />
      <path
        d="M8.60685 4.2916C8.60684 4.13709 8.54545 3.9889 8.43619 3.87964C8.32693 3.77038 8.17875 3.70899 8.02423 3.70898H0.582618C0.428098 3.70898 0.279907 3.77037 0.170645 3.87963C0.0613828 3.98889 0 4.13708 0 4.2916C0 4.44612 0.0613828 4.59431 0.170645 4.70358C0.279907 4.81284 0.428098 4.87422 0.582618 4.87422H8.02423C8.17875 4.87421 8.32693 4.81283 8.43619 4.70357C8.54545 4.59431 8.60684 4.44612 8.60685 4.2916Z"
        fill="currentColor"
      />
      <path
        d="M9.44179 7.64121C9.44178 7.48669 9.3804 7.33851 9.27114 7.22925C9.16188 7.11999 9.01369 7.0586 8.85917 7.05859H3.64121C3.48669 7.05859 3.3385 7.11998 3.22924 7.22924C3.11998 7.3385 3.05859 7.48669 3.05859 7.64121C3.05859 7.79573 3.11998 7.94392 3.22924 8.05319C3.3385 8.16245 3.48669 8.22383 3.64121 8.22383H8.85917C9.01369 8.22382 9.16188 8.16244 9.27114 8.05318C9.3804 7.94392 9.44178 7.79573 9.44179 7.64121Z"
        fill="currentColor"
      />
      <path
        d="M4.66074 11.5734H9.73305C9.88757 11.5734 10.0358 11.5121 10.145 11.4028C10.2543 11.2935 10.3157 11.1453 10.3157 10.9908C10.3157 10.8363 10.2543 10.6881 10.145 10.5788C10.0358 10.4696 9.88757 10.4082 9.73305 10.4082H4.66074C4.50622 10.4082 4.35803 10.4696 4.24877 10.5788C4.13951 10.6881 4.07812 10.8363 4.07812 10.9908C4.07812 11.1453 4.13951 11.2935 4.24877 11.4028C4.35803 11.5121 4.50622 11.5734 4.66074 11.5734Z"
        fill="currentColor"
      />
      <path
        d="M17.2629 13.7598H6.40879C6.25427 13.7598 6.10608 13.8211 5.99682 13.9304C5.88755 14.0397 5.82617 14.1879 5.82617 14.3424C5.82617 14.4969 5.88755 14.6451 5.99682 14.7544C6.10608 14.8636 6.25427 14.925 6.40879 14.925H17.2629C17.4174 14.925 17.5656 14.8636 17.6749 14.7544C17.7841 14.6451 17.8455 14.4969 17.8455 14.3424C17.8455 14.1879 17.7841 14.0397 17.6749 13.9304C17.5656 13.8211 17.4174 13.7598 17.2629 13.7598Z"
        fill="currentColor"
      />
      <path
        d="M10.9746 5.81771C10.9863 5.81842 10.9981 5.81877 11.0098 5.81877C11.1526 5.81877 11.2904 5.7663 11.3971 5.67134L13.1693 4.09411C13.2264 4.04325 13.2731 3.98163 13.3064 3.91276C13.3398 3.84389 13.3593 3.76912 13.3637 3.69272C13.3682 3.61633 13.3575 3.5398 13.3324 3.46751C13.3073 3.39523 13.2682 3.3286 13.2173 3.27143C13.1664 3.21427 13.1048 3.16769 13.0359 3.13435C12.967 3.10102 12.8922 3.08158 12.8158 3.07715C12.7394 3.07272 12.6629 3.08338 12.5906 3.10854C12.5184 3.13369 12.4518 3.17283 12.3946 3.22373L11.0595 4.41187L10.4981 3.77527C10.4475 3.71789 10.3861 3.67104 10.3174 3.63739C10.2487 3.60374 10.174 3.58396 10.0976 3.57917C10.0213 3.57439 9.94469 3.58469 9.87231 3.60949C9.79993 3.63428 9.73314 3.6731 9.67576 3.72371C9.61837 3.77432 9.57152 3.83574 9.53787 3.90446C9.50423 3.97318 9.48445 4.04785 9.47966 4.12421C9.47487 4.20058 9.48517 4.27714 9.50997 4.34952C9.53477 4.4219 9.57358 4.48869 9.62419 4.54608L10.5728 5.62156C10.6236 5.67911 10.6852 5.72606 10.7541 5.75972C10.8231 5.79338 10.898 5.81309 10.9746 5.81771Z"
        fill="currentColor"
      />
      <path
        d="M11.5923 8.97117C11.6431 9.02872 11.7047 9.07567 11.7737 9.10933C11.8426 9.14299 11.9176 9.1627 11.9942 9.16732C12.0058 9.16803 12.0176 9.16838 12.0293 9.16838C12.1721 9.16838 12.3099 9.11591 12.4166 9.02095L14.1888 7.44371C14.246 7.39286 14.2926 7.33124 14.3259 7.26237C14.3593 7.1935 14.3788 7.11873 14.3832 7.04233C14.3877 6.96594 14.3771 6.88941 14.352 6.81712C14.3268 6.74484 14.2877 6.67821 14.2368 6.62104C14.186 6.56388 14.1243 6.5173 14.0554 6.48396C13.9865 6.45063 13.9118 6.43119 13.8354 6.42676C13.759 6.42233 13.6825 6.43299 13.6102 6.45815C13.5379 6.4833 13.4713 6.52244 13.4141 6.57334L12.0791 7.76148L11.5177 7.12488C11.467 7.0675 11.4056 7.02065 11.3369 6.987C11.2682 6.95335 11.1935 6.93357 11.1171 6.92878C11.0408 6.924 10.9642 6.93429 10.8918 6.95909C10.8195 6.98389 10.7527 7.02271 10.6953 7.07332C10.6379 7.12393 10.5911 7.18535 10.5574 7.25407C10.5238 7.32279 10.504 7.39746 10.4992 7.47382C10.4944 7.55019 10.5047 7.62675 10.5295 7.69913C10.5543 7.77151 10.5931 7.8383 10.6437 7.89569L11.5923 8.97117Z"
        fill="currentColor"
      />
      <path
        d="M12.3208 12.4673C12.3716 12.5248 12.4332 12.5718 12.5022 12.6054C12.5711 12.6391 12.6461 12.6588 12.7227 12.6634C12.7343 12.6641 12.7461 12.6645 12.7578 12.6645C12.9006 12.6645 13.0385 12.612 13.1451 12.517L14.9173 10.9398C14.9745 10.889 15.0211 10.8273 15.0545 10.7585C15.0878 10.6896 15.1073 10.6148 15.1118 10.5384C15.1162 10.462 15.1056 10.3855 15.0805 10.3132C15.0553 10.2409 15.0162 10.1743 14.9654 10.1171C14.9145 10.06 14.8528 10.0134 14.784 9.98006C14.7151 9.94672 14.6403 9.92728 14.5639 9.92285C14.4875 9.91842 14.411 9.92909 14.3387 9.95424C14.2664 9.97939 14.1998 10.0185 14.1427 10.0694L12.8076 11.2576L12.2462 10.621C12.1956 10.5636 12.1341 10.5167 12.0654 10.4831C11.9967 10.4494 11.922 10.4297 11.8457 10.4249C11.6914 10.4152 11.5397 10.4672 11.4238 10.5694C11.3079 10.6716 11.2374 10.8157 11.2277 10.9699C11.218 11.1241 11.27 11.2759 11.3722 11.3918L12.3208 12.4673Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ProjectManagementIcon
