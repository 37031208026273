import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import {
  fetchRfqReference,
  updateRfqAction,
  updateRfqReference,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import closeIcon from "../../../assets/icons/close.svg"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import NumberFormat from "../../../utils/numberFormat"

const ViewRFQ = ({ fromDashboard, viewValue }) => {
  const schema = yup.object().shape({})

  const { id } = useParams()
  const viewId = fromDashboard ? viewValue?.value?.id : id
  const [requestDetails, setRequestDetails] = useState()
  const [tabledata, setTabledata] = useState()
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRead, setIsLoadingRead] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const navigate = useNavigate()

  const toggle = () => setModal(!modal)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  useEffect(() => {
    const fetchPOdata = async () => {
      const response = await fetchRfqReference(viewId)
      setRequestDetails(response)
      setTabledata(response?.data?.materials)
    }
    if (viewId !== undefined) fetchPOdata()
  }, [refresh])

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const responce = await updateRfqReference(data)
      toastSuccess(responce?.message)
      toggle()
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAction = async data => {
    setIsLoadingRead(true)
    const response = await updateRfqAction(data)
    toastSuccess(response?.message)
    setRefresh(refresh + 1)
    setIsLoadingRead(false)
  }

  const handleReference = item => {
    setModal(!modal)
    reset({
      id: item?.id,
      ref_code: item?.val ? item?.val : "",
    })
  }

  const { loggedUser } = useSelector(LoginProperties)

  console.log(requestDetails, "requestDetails")
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          {!fromDashboard && (
            <div className="d-flex justify-content-between me-2">
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Request For Quote",
                    link: "/procurement-rfq",
                  },
                  {
                    title: "View Request For Quote",
                    link: "",
                  },
                ]}
              />
              <div>
                {requestDetails?.status == 1 && (
                  <LoadingButton
                    // form={`ev-form`}
                    color={"primary"}
                    loading={isLoadingRead}
                    disabled={isLoadingRead}
                    // type="submit"
                    onClick={() =>
                      handleAction({
                        materialReq: requestDetails?.id,
                        status: 2,
                      })
                    }
                  >
                    Mark as Read{" "}
                  </LoadingButton>
                )}
                {requestDetails?.status == 2 && (
                  <Button
                    color={"primary"}
                    className={"px-2 mb-3 btn btn-primary"}
                    onClick={() =>
                      navigate(`/finance-voucher/add`, {
                        state: requestDetails,
                      })
                    }
                  >
                    {"Convert to PO"}
                  </Button>
                )}
              </div>
            </div>
          )}
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className="bold-text px-4 py-2">
                <span className="pe-2">Request for Quote Details</span>
                {requestDetails?.status == 2 && (
                  <span className="font-13 status-active">Approved</span>
                )}
              </div>
              <hr className="mx-0" />
              <Row className="px-4 py-3">
                <Col sm={11}>
                  <Row>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">MR No</div>
                      <div className="supplier-td-body">
                        {requestDetails ? requestDetails?.code : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Date</div>
                      <div className="supplier-td-body">
                        {requestDetails
                          ? moment(requestDetails?.created_at).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Supplier Name</div>
                      <div className="supplier-td-body">
                        {requestDetails ? requestDetails?.vendor?.name : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Quote reference no</div>
                      <div className="supplier-td-body">
                        {requestDetails?.ref_code
                          ? requestDetails?.ref_code
                          : "-"}
                        <span
                          className="update-text ms-1 pointer"
                          onClick={() =>
                            handleReference({
                              val: requestDetails?.ref_code
                                ? requestDetails?.ref_code
                                : "",
                              id: requestDetails?.id,
                            })
                          }
                        >
                          Update
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="bold-text px-4 py-2">Materials List</div>

              <Row className="mb-4 px-4">
                <Col lg={12}>
                  <table className="estimation-table mb-4">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
                        <th>Ref. MR No</th>
                        <th className="text-end pe-5">Quantity </th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestDetails?.items?.map((item, index) => (
                        <tr key={`materialFields${index}`}>
                          <td className="ps-1">
                            <div className="fs-13">{index + 1}</div>
                          </td>
                          <td className="ps-1">
                            <div className="fs-13">
                              {item?.product?.description
                                ? item?.product?.description
                                : "-"}
                            </div>
                          </td>
                          <td className="ps-1">
                            <div className="fs-13">
                              {item?.material_request?.code
                                ? item?.material_request?.code
                                : "-"}
                            </div>
                          </td>
                          <td className="ps-1">
                            <div className="fs-13 text-end pe-5">
                              {item?.quantity ? NumberFormat((Number(item?.quantity))) : "-"}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Update Reference</h5>{" "}
            <img
              src={closeIcon}
              alt=""
              onClick={toggle}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
              <Row>
                <Col sm={12}>
                  <FormGroup className="mb-4">
                    <Label>
                      Quote reference no
                      <span className="mandatory">*</span>
                    </Label>
                    <UFInput
                      control={control}
                      placeholder="Enter Quote reference no"
                      name={"ref_code"}
                      invalid={!!errors.ref_code}
                    />
                    <FormFeedback invalid>
                      {errors.ref_code?.message}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            form={`ev-form`}
            color={"primary"}
            loading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            Update{" "}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ViewRFQ
