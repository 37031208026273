import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  FormFeedback,
  ModalFooter,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import {
  fetchProjectDashboard,
  fetchStoreCsRequest,
  fetchStoreDashRequest,
  fetchStoreGraph,
  fetchStoreIssueNote,
  fetchStoreMaterialRequest,
  postCentralRequest,
  postIssueNote,
  postRequestManpower,
} from "../../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import TimeLineDD from "../../../../components/Common/chooseFields/TimeLineDD"
import { FormProvider, useForm } from "react-hook-form"
import action from "../../../../assets/icons/action.svg"
import closeIcon from "../../../../assets/icons/close.svg"
import requestIcon from "../../../../assets/icons/request.svg"
import Chart from "react-apexcharts"
import { capitalize, isEmpty, noConflict } from "lodash"
import {
  UFDate,
  UFEditor,
  UFInput,
  UFInputDashboard,
} from "../../../../components/Common/Fields/Input"
import LoadingButton from "../../../../components/Common/LoadingButton"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import UsersList from "../../../../components/Common/chooseFields/userList"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import moment from "moment"
import EditMaterialRequest from "../../../Procurement/materialrequest/edit"
import ShopKeeperFilter from "./filter"
import ViewIssueNote from "../../../StoreKeeper/issueNote/view"
import NumberFormat from "../../../../utils/numberFormat"

const ShopKeeperIndex = () => {
  document.title = "Store Keeper Dashboard | Ever Rise"

  const { id } = useParams()
  const navigate = useNavigate()

  //refresh state
  const [refresh, setRefresh] = useState(0)
  const [refresh1, setRefresh1] = useState(0)
  const [refresh2, setRefresh2] = useState(0)
  const [refresh3, setRefresh3] = useState(0)
  const [refresh4, setRefresh4] = useState(0)
  const [refreshChart, setRefreshChart] = useState(0)

  //pagination state
  const [page, setPage] = useState("")
  const [materialPage, setMaterialRequestPage] = useState("")
  const [csRequestPage, setCsRequestPage] = useState("")
  const [issueNotePage, setIssueNotePage] = useState("")

  //view and model chart
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //response state
  const [materialData, setMaterialData] = useState()
  const [materialRequestData, setMaterialRequestData] = useState()
  const [csRequestData, setCsRequestData] = useState()
  const [issueNoteData, setIssueNoteData] = useState()
  const [storeDashData, setStoreDashData] = useState()
  const [graphData, setGraphData] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const [dateRange, setDateRange] = useState({})

  const [search, setSearch] = useState("")
  const [search1, setSearch1] = useState("")

  const schema1 = yup.object().shape({
    ...(viewData?.id === 4 && {
      project_quantity: yup
        .number()
        .min(1, "Quantity must be 1 or more")
        .max(
          Number(viewData?.value),
          `Quantity must be ${viewData?.value} or less`
        )
        .typeError("Quantity Must be Number")
        .required("Quantity is Required"),
      contact_person: yup.mixed().required("Contact Person is Required"),
      issue_date: yup.string().required("Issue Date is Required"),
    }),
    ...(viewData?.id === 5 && {
      central_quantity: yup
        .number()
        .min(1, "Quantity must be 1 or more")
        .max(
          Number(viewData?.value),
          `Quantity must be ${viewData?.value} or less`
        )
        .typeError(" Quantity Must be Number")
        .required(" Quantity is Required"),
      description: yup.string().required("Description is Required"),
    }),
    ...(viewData?.id === 7 && {
      req_manpower_no: yup
        .number()
        .min(1, "Number of Hours must be 1 or more")
        .max(
          Number(viewData?.req_manpower_no) -
            Number(
              viewData?.value?.manpower_requests_sum_req_manpower_no
                ? viewData?.value?.manpower_requests_sum_req_manpower_no
                : 0
            ),
          `Number of Hours must be ${
            Number(viewData?.req_manpower_no) -
            Number(
              viewData?.value?.manpower_requests_sum_req_manpower_no
                ? viewData?.value?.manpower_requests_sum_req_manpower_no
                : 0
            )
          } or less`
        )
        .typeError("Number of Hours Must be Number")
        .required("Number of Hours is Required"),
      req_remarks: yup.string().required("Remarks is Required"),
      request_date: yup.mixed().required("Date is Required"),
      exp_qty: yup
        .number()
        .min(1, "Expected Quantity must be 1 or more")
        .typeError("Expected Quantity must be Number"),
    }),
  })
  console.log(viewData, `viewData`)
  const toggle = () => {
    setModal(!modal)
    const controlValue = getValues1()

    reset1({
      ...controlValue,
      project_quantity: "",
      issue_date: "",
      // filter_date: "",
      contact_person: null,
      central_quantity: "",
      description: "",
      // end_date: "",
      // start_date: "",
    })
    if (!modal === false) {
      setViewData()
    }
  }
  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
    setRefresh(refresh + 1)
    setRefresh1(refresh1 + 1)
    setRefresh4(refresh4 + 1)
  }
  const handleSearchChange = data => {
    setSearch1(data)
    setIssueNotePage(1)
    setCsRequestPage(1)
  }

  const handleView = item => {
    console.log(`--itm`, item)
    setViewData(item)
    toggle()
    if (item?.id == 4) {
      setValue1("project_store", item?.value)
      setValue1("product_id", item?.product_id)
    } else if (item?.id == 5) {
      setValue1("central_store", item?.value)
      setValue1("product_id", item?.product_id)
    } else if (item?.id == 7) {
      // setValue1("manpower_req", item?.product_id)
      console.log(`item`, item)
      reset1({
        category_code: item?.category_code,
        req_manpower_no:
          Number(item?.req_manpower_no) -
          Number(
            item?.value?.manpower_requests_sum_req_manpower_no
              ? item?.value?.manpower_requests_sum_req_manpower_no
              : 0
          ),
        project_manpower_id: item?.project_manpower_id,
        designation_id: item?.value?.category?.id,
        project_id: id,
      })
    }
  }

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setDateRange(fromDate, toDate)
  }
  console.log(`setDateRange`, dateRange)
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    getValues: getValues1,
    setValue: setValue1,
    reset: reset1,
    watch: watch1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    setValue1("Time_1", { label: "Weekly", value: "thisweek" })
    setValue1("Time_2", { label: "Weekly", value: "thisweek" })
    setValue1("Time_3", { label: "Weekly", value: "thisweek" })
    setValue1("Time_chart", { label: "Weekly", value: "thisweek" })
  }, [])

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchProjectDashboard({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          id ? `&project_id=${id}` : ""
        }${page ? `&page=${page}` : ""}${
          activeTab
            ? `&type=${
                activeTab == 1
                  ? "material"
                  : activeTab == 2
                  ? "manpower"
                  : activeTab == 3
                  ? "subcontract"
                  : "overheads"
              }`
            : ""
        }`,
      })
      setMaterialData(response)
    }
    if (id) {
      fetchClass()
    }
  }, [refresh, id, activeTab, page, search])

  useEffect(() => {
    const controlValue = getValues1()
    const fetchMaterialSearch = async () => {
      const response = await fetchStoreMaterialRequest({
        params: `${"&searchText"}${
          id ? `&project_id=${id}` : ""
        }${`&type=materialrequest`}${`&date=${controlValue?.Time_1?.value}`}${
          materialPage ? `&page=${materialPage}` : ""
        }${
          controlValue?.start_date
            ? `&start_date=${controlValue?.start_date}`
            : ""
        }${
          controlValue?.end_date ? `&end_date=${controlValue?.end_date}` : ""
        }`,
      })
      setMaterialRequestData(response)
    }
    if (id && controlValue?.Time_1?.value) {
      fetchMaterialSearch()
    }
  }, [refresh1, id])
  useEffect(() => {
    const controlValue = getValues1()
    const fetchCsSearch = async () => {
      const response = await fetchStoreCsRequest({
        params: `${search1 ? `&searchText=${search1}` : ""}${
          id ? `&project_id=${id}` : ""
        }${
          csRequestPage ? `&page=${csRequestPage}` : ""
        }${`&type=centralstore`}${`&date=${controlValue?.Time_2?.value}`}${
          controlValue?.start_date
            ? `&start_date=${controlValue?.start_date}`
            : ""
        }${
          controlValue?.end_date ? `&end_date=${controlValue?.end_date}` : ""
        }`,
      })
      setCsRequestData(response)
    }
    if (id && controlValue?.Time_2?.value) {
      fetchCsSearch()
    }
  }, [refresh2, id, search1])
  useEffect(() => {
    const controlValue = getValues1()
    const fetchIssueNoteSearch = async () => {
      const response = await fetchStoreIssueNote({
        params: `${search1 ? `&searchText=${search1}` : ""}${
          id ? `&project_id=${id}` : ""
        }${
          issueNotePage ? `&page=${issueNotePage}` : ""
        }${`&type=issuenote`}${`&date=${controlValue?.Time_3?.value}`}${
          controlValue?.start_date
            ? `&start_date=${controlValue?.start_date}`
            : ""
        }${
          controlValue?.end_date ? `&end_date=${controlValue?.end_date}` : ""
        }`,
      })
      setIssueNoteData(response)
    }
    if (id && controlValue?.Time_3?.value) {
      fetchIssueNoteSearch()
    }
  }, [refresh3, id, search1])

  useEffect(() => {
    const fetchStoreDash = async () => {
      const response = await fetchStoreDashRequest(id)
      setStoreDashData(response)
    }
    if (id) {
      fetchStoreDash()
    }
  }, [refresh4, id])

  useEffect(() => {
    const controlValue = getValues1()
    const fetchGraph = async () => {
      const response = await fetchStoreGraph({
        date: controlValue?.Time_chart?.value,
        project_id: id,
      })
      setGraphData(response)
    }
    if (id && controlValue?.Time_chart?.value) {
      fetchGraph()
    }
  }, [id, refreshChart])

  const columns = [
    {
      label: "Product No",
      renderCell: item => item?.products?.product_code,
    },
    {
      label: "Product Description",
      renderCell: item => {
        return <div>{item?.products?.description}</div>
      },
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.quantity ? item?.quantity : 0)))}</div>,
    },
    {
      label: <div className="text-end pe-5">Issued Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{
        NumberFormat((Number(item?.issuenote_sum_quantity ? item?.issuenote_sum_quantity : 0)))
      }</div>,
    },
    {
      label: "Availability",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                <div
                  className={` table-popup-content d-flex align-items-center justify-content-between text-12`}
                  onClick={() =>
                    handleView({
                      id: 4,
                      name: "project_store",
                      value: item?.store_sum_quantity
                        ? item?.store_sum_quantity
                        : 0,
                      product_id: item?.product_id,
                    })
                  }
                >
                  Project Store -{" "}
                  <span className="fw-500 text-12 pe-1">
                    {item?.store_sum_quantity ? item?.store_sum_quantity : 0}
                  </span>
                  {"    "}
                  <img src={requestIcon} className="request-icon" />
                </div>
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content d-flex align-items-center justify-content-between text-12`}
                  onClick={() =>
                    handleView({
                      id: 5,
                      name: "central_store",
                      value: item?.centralstore_sum_quantity
                        ? item?.centralstore_sum_quantity
                        : 0,
                      product_id: item?.product_id,
                    })
                  }
                >
                  Central Store -{" "}
                  <span className="fw-500 text-12 pe-1">
                    {item?.centralstore_sum_quantity
                      ? item?.centralstore_sum_quantity
                      : 0}
                  </span>{" "}
                  <img src={requestIcon} className="request-icon" />
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const columnManPower = [
    {
      label: "Designation",
      renderCell: item => item?.category?.category_code,
    },
    {
      label: "Category Description",
      renderCell: item => {
        return (
          <div>
            {item?.category?.description
              ? capitalize(item?.category?.description)
              : "-"}
          </div>
        )
      },
    },
    {
      label: <div className="text-end pe=5">Number of Hours</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.no_of_labours ? item?.no_of_labours : 0)))}</div>,
    },
    {
      label: <div className="text-end pe-5">Issued Nos</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.hr_issued_count ? item?.hr_issued_count : 0)))}</div>,
    },
    // {
    //   label: "Requested",
    //   renderCell: item =>
    //     item?.manpower_requests_sum_req_manpower_no
    //       ? item?.manpower_requests_sum_req_manpower_no
    //       : 0,
    // },
    // {
    //   label: "Issued",
    //   renderCell: item =>
    //     // item?.manpower_requests_sum_isu_manpower_no
    //     //   ? item?.manpower_requests_sum_isu_manpower_no
    //     //   : 0,
    //     Number(
    //       item?.manpower_requests?.reduce((total, request) => {
    //         return total + request.hr_issued
    //       }, 0)
    //     ) +
    //     Number(
    //       item?.manpower_requests?.reduce((total, request) => {
    //         return total + request.pro_issued
    //       }, 0)
    //     ),
    // },
    {
      label: "Availability",
      renderCell: item => (
        <>
          <Button
            color="primary"
            onClick={() =>
              handleView({
                id: 7,
                name: "manpower_req",
                category_code: item?.category?.description,
                req_manpower_no: item?.no_of_labours,
                project_manpower_id: item?.id,
                value: item,
              })
            }
          >
            Request
          </Button>
          {/* {Number(item?.no_of_labours) !=
            Number(item?.manpower_requests_sum_req_manpower_no) &&
          Number(item?.no_of_labours) >
            Number(
              item?.manpower_requests?.reduce((total, request) => {
                return total + request.hr_issued
              }, 0)
            ) +
              Number(
                item?.manpower_requests?.reduce((total, request) => {
                  return total + request.pro_issued
                }, 0)
              ) ? (
            <Button
              color="primary"
              onClick={() =>
                handleView({
                  id: 7,
                  name: "manpower_req",
                  category_code: item?.category?.description,
                  req_manpower_no: item?.no_of_labours,
                  project_manpower_id: item?.id,
                  value: item,
                })
              }
            >
              Request
            </Button>
          ) : (
            <>
              {console.log(
                `item?.manpower_requests?.reduce(item => item?.hr_issued)`,
                item?.manpower_requests?.reduce(
                  (total, item) =>
                    total +
                    Number(item?.hr_issued != undefined ? item?.hr_issued : 0),
                  0
                )
              )}
            </>
          )} */}
        </>
      ),
    },
  ]

  const centralStoreColumns = [
    {
      label: "Product No",
      renderCell: item => item?.product_id?.product_code,
    },

    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.quantity)))}</div>,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 0
                ? "status-active bg-light text-warning"
                : item?.status == 1
                ? "status-active bg-light text-success"
                : item?.status == 2
                ? "status-active bg-light text-danger"
                : ""
            }`}
          >
            {item?.status == 0
              ? "New"
              : item?.status == 1
              ? "Issued"
              : item?.status == 2
              ? "Rejected"
              : ""}
          </span>
        </div>
      ),
    },
  ]
  const storeIssueColumns = [
    {
      label: "Product No",
      renderCell: item => (
        <div
          className="pointer fw-bold"
          onClick={() =>
            handleView({
              id: 8,
              name: "view_IssueNote",
              value: item,
              product_id: "",
            })
          }
        >
          {item?.product_id?.product_code}
        </div>
      ),
    },

    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.quantity)))}</div>,
    },
    {
      label: "Received By",
      renderCell: item => item?.issued_by?.name,
    },
  ]
  const recentManpowerColumns = [
    {
      label: "Designation",
      renderCell: item => item?.designation?.name,
    },
    {
      label: "Status",
      renderCell: item => (
        <div
          className={`font-13 ${
            item?.status === 1
              ? "status-active"
              : item?.status == 2
              ? "status-inactive"
              : item?.status == 0
              ? "status-draft"
              : "status-pending"
          }`}
        >
          {item?.status == 0
            ? "Requested"
            : item?.status == 1
            ? "Accepted"
            : item?.status == 2
            ? "Rejected"
            : "Forwarded"}
        </div>
      ),
    },
  ]
  const materialRequestColumns = [
    {
      label: "MR No",
      renderCell: item => item?.material_requests_code,
    },

    {
      label: "Request Date",
      renderCell: item => item?.request_date,
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.requests_sum_quantity)))}</div>,
    },
  ]
  const csRequestColumns = [
    {
      label: "Product No",
      renderCell: item => item?.product_id?.product_code,
    },

    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("YYYY-MM-DD"),
    },
    {
      label: "Staff Name",
      renderCell: item => item?.request_id?.requested_from?.name,
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.quantity)))}</div>,
    },
  ]
  const issueNoteColumns = [
    {
      label: "Product No",
      renderCell: item => item?.product_id?.product_code,
    },

    {
      label: "Date",
      renderCell: item => item?.issue_date,
    },
    {
      label: "Staff Name",
      renderCell: item => item?.contactperson?.name,
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.quantity)))}</div>,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleMaterialRequestPaginate = page => {
    setMaterialRequestPage(page)
  }
  const handleCsRequestPaginate = page => {
    setCsRequestPage(page)
  }
  const handleissueNotePaginate = page => {
    setIssueNotePage(page)
  }

  var Options = {
    Series: [
      {
        name: "New",
        type: "line",
        data: graphData?.data?.map(dt => dt?.new),
      },
      {
        name: "Issued",
        type: "line",
        data: graphData?.data?.map(dt => dt?.issued),
      },
      {
        name: "Rejected",
        type: "line",
        data: graphData?.data?.map(dt => dt?.rejected),
      },
    ],
    chart: {
      type: "line",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    colors: ["#989898", "#525252", "#171717"],
    xaxis: {
      categories: graphData?.data?.map(dt => dt?.label),
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "k"
          } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "l"
          } else {
            return value
          }
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    grid: {
      show: true,
    },
  }
  const mainCard = [
    {
      id: 1,
      name: "Total Material Request",
      dropdown: true,
      subName:
        watch1("Time_1")?.value == "today"
          ? "Last 24 Hours"
          : watch1("Time_1")?.value == "thisweek"
          ? "Last 7 days"
          : watch1("Time_1")?.value == "thismonth"
          ? "Last 30 days"
          : watch1("Time_1")?.value == "thisyear"
          ? "Last 365 days"
          : "",
      value: materialRequestData?.totalItems,
      viewAll: true,
    },
    {
      id: 2,
      name: "Total CS Request",
      dropdown: true,
      subName:
        watch1("Time_2")?.value == "today"
          ? "Last 24 Hours"
          : watch1("Time_2")?.value == "thisweek"
          ? "Last 7 days"
          : watch1("Time_2")?.value == "thismonth"
          ? "Last 30 days"
          : watch1("Time_2")?.value == "thisyear"
          ? "Last 365 days"
          : "",
      value: csRequestData?.totalItems,
      viewAll: true,
    },
    {
      id: 3,
      name: "Total Issue Note",
      dropdown: true,
      subName:
        watch1("Time_3")?.value == "today"
          ? "Last 24 Hours"
          : watch1("Time_3")?.value == "thisweek"
          ? "Last 7 days"
          : watch1("Time_3")?.value == "thismonth"
          ? "Last 30 days"
          : watch1("Time_3")?.value == "thisyear"
          ? "Last 365 days"
          : "",
      value: issueNoteData?.totalItems,
      viewAll: true,
    },
  ]

  const handleTimeChange = (e, id) => {
    if (id == 1) {
      setMaterialRequestPage(1)
      setRefresh1(refresh1 + 1)
    } else if (id == 2) {
      setCsRequestPage(1)
      setRefresh2(refresh2 + 1)
    } else if (id == 3) {
      setIssueNotePage(1)
      setRefresh3(refresh3 + 1)
    } else if (id == "chart") {
      setRefreshChart(refreshChart + 1)
    }
  }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      if (viewData?.id === 4) {
        const updateDepartment = await postIssueNote({
          quantity: data?.project_quantity,
          issue_date: moment(data?.issue_date).format("YYYY-MM-DD"),
          contactperson: data?.contact_person?.value,
          project_id: id,
          product_id: data?.product_id,
        })
        setRefresh(refresh + 1)
        setRefresh3(refresh3 + 1)
        setRefresh4(refresh4 + 1)
        setRefreshChart(refreshChart + 1)
      } else if (viewData?.id === 5) {
        const updateDepartment = await postCentralRequest({
          quantity: data?.central_quantity,
          description: data?.description,
          type: "centralrequest",
          project_id: id,
          product_id: data?.product_id,
        })
        setRefresh(refresh + 1)
        setRefresh2(refresh2 + 1)
        setRefresh4(refresh4 + 1)
        setRefreshChart(refreshChart + 1)
      } else if (viewData?.id === 7) {
        const requestManpower = await postRequestManpower({
          project_manpower_id: data?.project_manpower_id,
          from_date: !isEmpty(data)
            ? moment(data?.request_date[0]).format("YYYY-MM-DD")
            : "",
          to_date: !isEmpty(data)
            ? moment(data?.request_date[1]).format("YYYY-MM-DD")
            : "",
          designation_id: data?.designation_id,
          req_manpower_no: data?.req_manpower_no,
          req_remarks: data?.req_remarks,
          project_id: data?.project_id,
          exp_qty: data?.exp_qty,
        })
        setRefresh(refresh + 1)
        setRefresh4(refresh4 + 1)
        toastSuccess(requestManpower?.message)
      }
    } catch (err) {
      toastError(err?.message)
    } finally {
      toggle()
      setIsLoading(false)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const chartCard = [
    {
      name: "Requests",
      value: NumberFormat(
        (Number(graphData?.total?.new)) +
        (Number(graphData?.total?.issued)) +
        (Number(graphData?.total?.rejected))),
    },
    {
      name: "New",
      value: NumberFormat((Number(graphData?.total?.new))),
    },
    {
      name: "Issued",
      value: NumberFormat((Number(graphData?.total?.issued))),
    },
    {
      name: "Rejected",
      value: NumberFormat((Number(graphData?.total?.rejected))),
    },
  ]

  // const handleDateChange = e => {
  //   console.log(" handle change is working :", e[0], e[1], viewData)
  //   if (e[0] && e[1]) {
  //     setValue("start_date", moment(e[0]).format("YYYY-MM-DD"))
  //     setValue("end_date", moment(e[1]).format("YYYY-MM-DD"))
  //     if (viewData?.id == 1) {
  //       setRefresh1(refresh1 + 1)
  //     } else if (viewData?.id == 2) {
  //       setRefresh2(refresh2 + 1)
  //     } else if (viewData?.id == 3) {
  //       setRefresh3(refresh3 + 1)
  //     }
  //   }
  // }

  useEffect(() => {
    setSearch(watch1("search"))
    setPage(1)
  }, [watch1("search")])

  return (
    <React.Fragment>
      <div className="page-content dash-page-bg">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Store Keeper",
                  link: `/project-store-dash/${id}`,
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <Row className="mt-3 mx-1 mb-0">
              {mainCard?.map((dt, i) => (
                <Col md={4} xl={4} sm={4} xs={12}>
                  <Card className={`rounded-0 border-0 auto-cards `}>
                    <CardBody className={`${dt?.id == 1 && "dark-background"} card-body-dash-${i}`}>
                      <Row className="px-2">
                        <Col className="">
                          <Row className="bold-heading">{dt?.name}</Row>
                          <Row className="light-text pt-2">{dt?.subName}</Row>
                        </Col>
                        {dt?.dropdown && (
                          <Col className=" time-button ps-4 pe-0 align-items-start">
                            <TimeLineDD
                              control={control1}
                              name={`Time_${dt?.id}`}
                              onSelectChange={e => handleTimeChange(e, dt?.id)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="px-2 pt-3">
                        <Col className=" extra-dark-text p-0">{dt?.value}</Col>
                        {dt?.viewAll && (
                          <Col
                            className=" view-button p-0 pe-2 pointer"
                            onClick={() => handleView(dt)}
                          >
                            View All
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="mx-3 px-2 ">
              <Row>
                <Col lg={8} className=" pe-2 ps-0">
                  <div className="tab-bg tab-store border-rds-round px-2 mb-4">
                    <Row className="bold-heading  pe-3">
                      <Col className="p-0">
                        <div>
                          <Nav tabs className="tab-active-bg">
                            <NavItem>
                              <NavLink
                                className={
                                  activeTab === "1"
                                    ? "active pointer py-2 pad-nav"
                                    : "pointer py-2"
                                }
                                onClick={() => {
                                  toggleTab("1")
                                  setValue1("search", "")
                                }}
                              >
                                Material List
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={
                                  activeTab === "2"
                                    ? "active pointer py-2"
                                    : "pointer py-2"
                                }
                                onClick={() => {
                                  toggleTab("2")
                                  setValue1("search", "")
                                }}
                              >
                                Manpower
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-end p-0">
                        {storeDashData?.data?.listing_status != 0 &&
                          activeTab === "1" && (
                            <div className="d-flex align-items-center">
                              <Button
                                color="primary"
                                onClick={() =>
                                  handleView({
                                    id: 6,
                                    name: "material_request",
                                    value: "",
                                    product_id: "",
                                  })
                                }
                              >
                                Material Request
                              </Button>
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col sm={3} className="pt-2 ps-4">
                        {activeTab == 1
                          ? "Material List"
                          : activeTab == 2
                          ? "Manpower List"
                          : ""}
                      </Col>
                      <Col sm={4}>
                        <UFInputDashboard
                          control={control1}
                          placeholder="Search"
                          name={"search"}
                        />
                      </Col>
                    </Row>
                    {/* <hr className="m-0" /> */}
                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <Col xs={12}>
                        <TabContent activeTab={activeTab} className="pt-3">
                          <TabPane tabId="1">
                            {materialData && activeTab == "1" && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialData?.data?.data}
                                paginate={materialData?.data}
                                columns={columns}
                                CurrentPage={page}
                                handlePagination={handlePaginate}
                                tableWidth="15% 45% 15% 15% 10%"
                                mobileWidth="30% 45% 30% 30% 20%"
                              />
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {materialData && activeTab == "2" && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialData?.data?.data}
                                paginate={materialData?.data}
                                columns={columnManPower}
                                CurrentPage={page}
                                handlePagination={handlePaginate}
                                tableWidth="15% 25% 20% 20% 20%"
                                mobileWidth="30% 40% 40% 40% 40%"
                              />
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </div>
                  <div className="tab-bg border-rds-round px-2">
                    <Row className="bold-heading p-4 py-3 d-flex align-items-center">
                      <Col> Central Store Request</Col>
                      <Col
                        lg={2}
                        className=" time-button pe-0 align-items-start"
                      >
                        <TimeLineDD
                          control={control1}
                          name={`Time_chart`}
                          onSelectChange={e => handleTimeChange(e, "chart")}
                        />
                      </Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="p-4">
                      {chartCard?.map(dt => (
                        <Col className="chart-top-div" lg={"auto"}>
                          <Row className="light-text text-13">{dt?.name}</Row>
                          <Row className="bold-heading text-18 pt-1">
                            {dt?.value}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-2 ">
                      <Chart
                        options={Options}
                        series={Options?.Series}
                        type="line"
                        width="100%"
                        height={300}
                      />
                    </Row>
                  </div>
                </Col>
                <Col lg={4} className="ps-3 pe-0">
                  <div className="bg-dark  border-rds-round px-2 mb-4 bg-dark-yellow">
                    <Row className="bold-heading p-4 py-3 text-light text-mr-imp">
                      Latest Central Store MR
                    </Row>

                    <Row className="mt-2 ever-rise-table  dark-table pb-3">
                      <Col xs={12}>
                        {storeDashData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={storeDashData?.data?.store_history}
                            columns={centralStoreColumns}
                            tableWidth="50% 20% 30%"
                            mobileWidth="50% 20% 30%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="tab-bg  border-rds-round px-2 mb-4">
                    <Row className="bold-heading p-4 py-3">
                      Latest Issue Note
                    </Row>
                    <hr className="m-0" />
                    <Row className=" ever-rise-table  dashboardTable py-3 ">
                      <Col xs={12}>
                        {storeDashData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={storeDashData?.data?.issuenote}
                            columns={storeIssueColumns}
                            tableWidth="50% 20% 30%"
                            mobileWidth="50% 20% 30%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="tab-bg  border-rds-round px-2">
                    <Col className="bold-heading p-4 py-3 d-flex justify-content-between">
                      <div> Latest Manpower Requests</div>
                      <div className="assign-light-text cursor-pointer border-bottom">
                        {" "}
                        View
                      </div>
                    </Col>
                    <hr className="m-0" />
                    <Row className=" ever-rise-table  dashboardTable py-3 ">
                      <Col xs={12}>
                        {storeDashData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={storeDashData?.data?.manpower_requests}
                            columns={recentManpowerColumns}
                            tableWidth="50% 50%"
                            mobileWidth="50% 50%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={
              viewData?.id == 6 || viewData?.id == 8
                ? "xl"
                : viewData?.id !== 4 && viewData?.id !== 5 && viewData?.id !== 7
                ? "lg"
                : "md"
            }
            unmountOnClose={false}
          >
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-4">
                    {viewData?.id == 1
                      ? "Material Request"
                      : viewData?.id == 2
                      ? "Central Store Request"
                      : viewData?.id == 3
                      ? "Issue Note"
                      : viewData?.id == 4
                      ? "Store Issue Note"
                      : viewData?.id == 5
                      ? "Central Store Request"
                      : viewData?.id == 6
                      ? "Material Request"
                      : viewData?.id == 7
                      ? "Manpower Request"
                      : viewData?.id == 8
                      ? "Issue Note"
                      : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              <ModalBody>
                <FormProvider>
                  <Form
                    id={`ev-form`}
                    onSubmit={handleSubmit1(handleRegistration)}
                  >
                    {viewData?.id !== 4 &&
                      viewData?.id !== 5 &&
                      viewData?.id !== 6 &&
                      viewData?.id !== 7 &&
                      viewData?.id !== 8 && (
                        <Card className="me-2 px-2 pb-2">
                          {/* <Row className="mt-2 ">
                          <Col lg={3} className="d-flex align-items-center">
                            <h6 className="ms-3">
                              {viewData?.id == 1
                                ? "Requests"
                                : viewData?.id == 2
                                ? "Central Store"
                                : viewData?.id == 3
                                ? "Issue Notes"
                                : ""}
                            </h6>
                          </Col>
                          <Col lg={4}>
                            <UFDate
                              control={control}
                              mode={"range"}
                              name={"filter_date"}
                              onDateChange={handleDateChange}
                            />
                          </Col>
                        </Row> */}
                          {(csRequestData?.data && viewData?.id == 2) ||
                          (issueNoteData?.data && viewData?.id == 3) ? (
                            <>
                              <ShopKeeperFilter
                                handleSearchChange={handleSearchChange}
                              />
                              <hr className="table-divide" />
                            </>
                          ) : (
                            <></>
                          )}
                          <Row className="mt-2 ever-rise-table">
                            <Col xs={12}>
                              {materialRequestData?.data &&
                                viewData?.id == 1 && (
                                  <UpdateTable
                                    className="mt-5"
                                    nodes={materialRequestData?.data?.data}
                                    paginate={materialRequestData?.data}
                                    columns={materialRequestColumns}
                                    handlePagination={
                                      handleMaterialRequestPaginate
                                    }
                                    CurrentPage={materialPage}
                                    tableWidth="33% 33% 33%"
                                    mobileWidth="33% 33% 33%"
                                  />
                                )}
                              {csRequestData?.data && viewData?.id == 2 && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={csRequestData?.data?.data}
                                  paginate={csRequestData?.data}
                                  columns={csRequestColumns}
                                  CurrentPage={csRequestPage}
                                  handlePagination={handleCsRequestPaginate}
                                  tableWidth="25% 25% 25% 25%"
                                  mobileWidth="40% 40% 40% 40%"
                                />
                              )}
                              {issueNoteData?.data && viewData?.id == 3 && (
                                <>
                                  <UpdateTable
                                    className="mt-5"
                                    nodes={issueNoteData?.data?.data}
                                    paginate={issueNoteData?.data}
                                    columns={issueNoteColumns}
                                    CurrentPage={issueNotePage}
                                    handlePagination={handleissueNotePaginate}
                                    tableWidth="25% 25% 25% 25%"
                                    mobileWidth="40% 40% 40% 40%"
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      )}
                    {(viewData?.id === 4 ||
                      viewData?.id === 5 ||
                      viewData?.id === 7) && (
                      <>
                        <Row className="mt-2 ever-rise-table mx-2">
                          <Col xs={12}>
                            {viewData?.id == 4 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Project Store Availability
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Customer Name"
                                        name={"project_store"}
                                        invalid={!!errors1.project_store}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.project_store?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Quantity
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Quantity"
                                        name={"project_quantity"}
                                        invalid={!!errors1.project_quantity}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.project_quantity?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Issue Date
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFDate
                                        control={control1}
                                        name={"issue_date"}
                                        invalid={!!errors1.issue_date}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.issue_date?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Contact Person
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UsersList
                                        control={control1}
                                        placeholder="Select Contact Person"
                                        name={"contact_person"}
                                        invalid={!!errors1.contact_person}
                                      />

                                      <FormFeedback invalid>
                                        {errors1.contact_person?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {viewData?.id == 5 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Central Store Availability
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder=""
                                        name={"central_store"}
                                        invalid={!!errors1.central_store}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.central_store?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Quantity
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Quantity"
                                        name={"central_quantity"}
                                        invalid={!!errors1.central_quantity}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.central_quantity?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Description
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFEditor
                                        control={control1}
                                        placeholder="Enter the Description"
                                        rows="5"
                                        name={"description"}
                                        invalid={!!errors1.description}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.description?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {viewData?.id == 7 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Category Code
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFInput
                                        control={control1}
                                        placeholder=""
                                        name={"category_code"}
                                        invalid={!!errors1.category_code}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.category_code?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Number of Hours
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter Hour"
                                        name={"req_manpower_no"}
                                        invalid={!!errors1.req_manpower_no}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.req_manpower_no?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        From Date - To Date
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFDate
                                        control={control1}
                                        name={"request_date"}
                                        mode="range"
                                        minDate={moment().format("YYYY-MM-DD")}
                                        // onDateChange={e => handleRequestdate(e)}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.request_date?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Minimum Manpower (per day)</Label>
                                      <UFInput
                                        control={control1}
                                        placeholder="Expected Quantity"
                                        name={"exp_qty"}
                                        invalid={!!errors1.exp_qty}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.exp_qty?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Remarks
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFEditor
                                        control={control1}
                                        placeholder="Enter the Description"
                                        rows="5"
                                        name={"req_remarks"}
                                        invalid={!!errors1.req_remarks}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.req_remarks?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {(viewData?.id === 4 ||
                      viewData?.id === 5 ||
                      viewData?.id === 7) && (
                      <>
                        <hr />
                        <div className="pb-3 d-flex justify-content-end">
                          <LoadingButton
                            form={`ev-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            className={"me-4"}
                            type="submit"
                          >
                            {viewData?.id === 4 ? "Issue Note" : "Request"}
                          </LoadingButton>
                        </div>
                      </>
                    )}
                  </Form>
                </FormProvider>
                {viewData?.id == 6 && (
                  <EditMaterialRequest
                    fromDashboard={true}
                    projectValue={{
                      value: id,
                      label: storeDashData?.data?.project_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                )}
                {viewData?.id == 8 && (
                  <ViewIssueNote fromDashboard={true} viewValue={viewData} />
                )}
              </ModalBody>
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ShopKeeperIndex
