// import React from "react"

// const BackArrowIcon = ({ size = "13px", onClick }) => (
//   <svg
//     width="13"
//     height="13"
//     viewBox="0 0 13 13"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     className="backarrowcrumb"
//   >
//     <path
//       d="M6.73438 11.5L1.73438 6.5M1.73438 6.5L6.73438 1.5M1.73438 6.5H11.7344"
//       stroke="#23272E"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//       fill="#23272E"
//     />
//   </svg>
// )

// export default BackArrowIcon

import React from "react";

const BackArrowIcon = ({ size = "13px", onClick }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="currentColor" // Enables fill color control via CSS
    xmlns="http://www.w3.org/2000/svg"
    className="backarrowcrumb"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path
      d="M6.73438 11.5L1.73438 6.5M1.73438 6.5L6.73438 1.5M1.73438 6.5H11.7344"
      stroke="currentColor" // Enables stroke color control via CSS
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackArrowIcon;
