import React, { useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useFormContext } from "react-hook-form"
import { Row, Col } from "reactstrap"
import { toastError } from "../../../helpers/msg_helper"
import { UncontrolledTooltip } from "reactstrap"

export const FileInputDisbled = props => {
  const { downloadPath, downloadAttachment } = props
  let fileName = ""
  if (downloadPath) fileName = downloadPath?.split("/")?.slice(-1)?.join()
  const cloudIcon = "fa-upload"
  return (
    <>
      <div className="single-dropzone">
        <div className="upload-wrapper p-2 px-3">
          {/* <h6 className="m-0" style={{ width: '160px' }}>
            {label}
          </h6> */}

          <div className="no-file-label">no file choosen</div>

          <i className={`text-primary fas ${cloudIcon} cloud-upload`} />
          {downloadPath && (
            <Row className="align-items-center">
              <div
                role="button"
                onClick={() => downloadAttachment(downloadPath.id, fileName)}
                className="text-primary downloadable-file"
              >
                {fileName}
              </div>
            </Row>
          )}
        </div>
      </div>
    </>
  )
}
const SingleFileInput = props => {
  const {
    name,
    // label = name,
    mode = "update",
    onlyIcon = false,
    multiple = false,
    downloadPath,
    downloadAttachment,
    tooltip,
    id,
    onChange,
    placeholder,
    handleChange,
  } = props
  let fileName = ""
console.log(`multiple`, props);
  if (downloadPath)
    fileName = downloadPath?.document
      ? downloadPath?.document?.split("/")?.slice(-1)?.join()
      : downloadPath?.split("/")?.slice(-1)?.join()
  const { register, unregister, setValue, watch } = useFormContext()
  const files = watch(name)
  const validateImage = (imageList, multiple) => {
    if (imageList) {
      if (imageList.length > 1) {
        if (multiple === false) {
          toastError("You can upload one file at a time")
          return true
        } else {
          let selectedImage = imageList
          // console.log("Multiple: ", props.accept)
          let isValidFile = false
          props.accept.split(",").forEach(extension => {
            selectedImage.forEach(img => {
              // console.log("Upload FIle Name: ", img['name'])
              if (img["name"].toLowerCase().match(extension)) {
                isValidFile = true
              }
            })
          })
          if (!isValidFile) {
            toastError(`Only ${props.accept} files are allowed`)
            return true
          } else if (selectedImage.size > 1048576) {
            toastError("Maximum file size exceeded")
            return true
          } else {
            return false
          }
        }
      } else if (imageList.length === 1) {
        let selectedImage = imageList[0]
        let isValidFile = false
        // props.accept.split(',').forEach(extension => {
        //   if (selectedImage.type.match(extension)) {
        //     isValidFile = true;
        //   }
        // });
        props.accept.split(",").forEach(extension => {
          if (selectedImage["name"].toLowerCase().match(extension)) {
            isValidFile = true
          }
        })
        if (!isValidFile) {
          toastError(`Only ${props.accept} files are allowed`)
          return true
        } else if (selectedImage.size > 1048576) {
          toastError("Maximum file size exceeded")
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
  const onDrop = useCallback(
    droppedFiles => {
      let valid = validateImage(droppedFiles, multiple)
      if (!valid) {
        /*
           This is where the magic is happening.
           Depending upon the mode we are replacing old files with new one,
           or appending new files into the old ones, and also filtering out the duplicate files. 
        */
        let newFiles =
          mode === "update" ? droppedFiles : [...(files || []), ...droppedFiles]
        if (mode === "append") {
          newFiles = newFiles.reduce((prev, file) => {
            const fo = Object.entries(file)
            if (
              prev.find(e => {
                const eo = Object.entries(e)
                return eo.every(
                  ([key, value], index) =>
                    key === fo[index][0] && value === fo[index][1]
                )
              })
            ) {
              return prev
            } else {
              return [...prev, file]
            }
          }, [])
        }
        // End Magic.
        handleChange(name, newFiles)
        setValue(name, newFiles, { shouldValidate: true })
        // if (typeof onChange === "function") {
        //   onChange(newFiles)
        // }
      }
    },
    [setValue, name, mode, files, onChange]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: multiple,
  })
  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])
  // console.log("getInputProps:", getInputProps())
  //console.log("File Length:", files, "-", files?.length)
  return (
    <>
      {/*<label className=" " htmlFor={name}>
        {label}
      </label>*/}
      <div className="single-dropzone">
        <input {...getInputProps()} />
        <div className="upload-wrapper p-2 px-3">
          {/* <h6 className="m-0">{label}</h6> */}
          {onlyIcon && (
            <UncontrolledTooltip placement="bottom" target={id ? id : `excel`} trigger="hover">
              {tooltip && tooltip}
            </UncontrolledTooltip>
          )}
          {!onlyIcon && <div className="no-file-label">no file choosen</div>}
          <div className="m-0" {...getRootProps()}>
            <i className={`text-primary cloud-upload`} id={id ? id : `excel`} />
          </div>
          <div className="dropzone-previews" id="file-previews">
            {/*(!files?.length && files_url?.length) &&
                (<img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  src={files_url}
                />)
                */}
            {/* {(downloadPath && !files) || (downloadPath && files?.[0]?.docId) && ( */}
            {((downloadPath && !files) || files?.[0]?.docId) && (
              <Row className="align-items-center">
                <div
                  role="button"
                  // onClick={() =>
                  //   downloadAttachment(
                  //     downloadPath.docId ? downloadPath.docId : downloadPath.id,
                  //     fileName
                  //   )
                  // }
                  className="blue-text downloadable-file"
                >
                  {fileName}
                </div>
              </Row>
            )}

            {!!files?.length &&
              files?.map((f, i) => {
                return (
                  <div key={i + "-file"}>
                    <div className="">
                      <Row className="align-items-center">
                        {/*<Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={URL.createObjectURL(f)}
                          />
                        </Col>*/}
                        <Col>
                          <div className="text-primary downloadable-file">
                            {" "}
                            {f.name}{" "}
                          </div>
                          {/*<p className="mb-0" >
                            <strong>{f.formattedSize}</strong>
                            </p>*/}
                        </Col>
                      </Row>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleFileInput
