import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  postFinanceAccountGroup,
} from "../../../services/finance/finance"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"

const schema = yup.object().shape({
  name: yup.string().required("Account Name is Required"),
})

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  // const [groupFields, setGroupFields] = useState([])

  useEffect(() => {
    if (props?.editItem?.modal) {
      reset({
        ...props?.editItem?.value,
        parent_id: {
          value: props?.editItem?.value?.parent?.id,
          label: props?.editItem?.value?.parent?.name,
        },
        type: props?.editItem?.value?.type?.id,
        // group_fields: groupReset,
      })
    }
  }, [props?.editItem?.modal])

  const handleSeriesAdd = async data => {
    console.log(`regist---`, data)

    try {
      setIsLoading(true)
      const createdSeries = await postFinanceAccountGroup({
        ...data,
        parent_id: data?.parent_id?.value,
        type: data?.parent_id?.parent?.type
          ? data?.parent_id?.parent?.type
          : props?.editItem?.value?.type?.id,
        name: data?.name,
        // ...(groupFields && { group_fields: groupFields }),
      })
      toastSuccess(createdSeries?.message)
      onUpdate()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{props?.editItem?.modal ? "Edit Group" : "New Group"}</h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleSeriesAdd)}>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Group Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Acc Name"
                        name={"name"}
                        invalid={!!errors.name}
                      />
                      <FormFeedback invalid>
                        {errors.name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className="mb-4">
                      <Label>Group Type</Label>
                      <FinanceGroupType
                        control={control}
                        placeholder="Select"
                        name={"parent_id"}
                        clearable={true}
                        invalid={!!errors.parent_id}
                        // parentChk={true}
                      />
                      <FormFeedback invalid>
                        {errors.parent_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditFinanceGroup = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditFinanceGroup
