import { get, post } from "../../helpers/api_helper"

export const fetchDashboard = () => {
  return get(`dashboard/admin`)
}

export const fetchProjectDashboardSingle = id => {
  return get(`project-dashboard/${id}`)
}

export const fetchProjectDashboardTasks = data => {
  return get(
    `project-tasks/${data?.params?.id}/${
      data?.params?.revision_id
    }?sizePerPage=${data?.params?.sizePerPage}${
      data?.params?.page ? `&page=${data?.params?.page}` : ""
    }`
  )
}

export const fetchProjectDashboardPayterms = id => {
  return get(`project-master/payterms/${id}`)
}
