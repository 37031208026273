import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import { updateSupplierStatus } from "../../../services/procurement/supplier"
import { downloadgrn, searchGRN } from "../../../services/procurement/grn"
import GRNFilter from "./filter"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const GRN = () => {
  document.title = "GRN | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [grnData, setGRNData] = useState()
  const [search, setSearch] = useState("")
  const [grn, setGrn] = useState("")
  const [projectno, setProjectno] = useState("")
  const [purchaseno, setPurchaseno] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [company, setCompany] = useState(false)

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateSupplierStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "GRN No",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "grn_view"
            ) ? (
              <div
                onClick={() => navigate(`/procurement-grn/view/${item?.id}`)}
                className="pointer fw-bold"
              >
                {item?.grn_no}
              </div>
            ) : (
              <div>{item?.grn_no}</div>
            )}
          </>
        )
      },
    },
    {
      label: "GRN date",
      renderCell: item => moment(item?.recieved_date).format("DD/MM/YYYY"),
    },
    {
      label: "PO no",
      renderCell: item => item?.po_id?.purchase_orders_code,
    },
    {
      label: "Project Code",
      renderCell: item =>
        item?.project_id != null ? item?.project_id?.project_code : "-",
    },
    {
      label: "Received by",
      renderCell: item => item?.received_by,
      sortKey: "received_by",
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <div
                className="table-popup-content"
                onClick={() => navigate(`/procurement-grn/view/${item?.id}`)}
              >
                View
              </div>
              <div
                className="table-popup-content"
                onClick={() => downloadgrn(item?.id, "grn.pdf")}
              >
                Download
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchGRN = async () => {
      const response = await searchGRN({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          grn ? `&grn_no=${grn}` : ""
        }${projectno ? `&project_no=${projectno}` : ""}${
          purchaseno ? `&purchase_no=${purchaseno}` : ""
        }${datefrom ? `&from_date=${datefrom}` : ""}${
          dateto ? `&to_date=${dateto}` : ""
        }${page ? `&page=${page}` : ""}${
          company == true && `&company_search=""`
        }${`&sizePerPage=16`}`,
      })
      setGRNData(response)
      // ${`&financial=${financial}`}
    }
    fetchGRN()
  console.log(`financial`, financial);

  }, [
    refresh,
    search,
    grn,
    page,
    datefrom,
    dateto,
    projectno,
    purchaseno,
    financial,
    company,
  ])


  const handleGrnChange = data => {
    setGrn(data)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handlePurchaseChange = data => {
    setPurchaseno(data)
    setPage(1)
  }
  const handleProjectChange = data => {
    setProjectno(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Procurement", link: "/procurement" },
                { title: "GRN", link: "/procurement-grn" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "grn_create"
            ) && (
              <Link
                to="/procurement-grn/add"
                className={"px-2 mb-3 me-2 btn btn-primary"}
              >
                {"+ GRN"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <GRNFilter
              handleSearchChange={handleSearchChange}
              handleGrnChange={handleGrnChange}
              handleDateChange={handleDateChange}
              handlePurchaseChange={handlePurchaseChange}
              handleProjectChange={handleProjectChange}
              handleFinacialChange={handleFinacialChange}
              handleCompanyChange={handleCompanyChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {grnData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={grnData?.data?.data}
                    paginate={grnData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 15% 15% 15% 15% "
                    mobileWidth="40% 40% 30% 30% 30% 30% "
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GRN
