import { del, get, post, put } from "../../helpers/api_helper"

//sample file download
export const sampleFileDownload = url => {
  return get(`${url}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `sample_sheet_${Date.now()}.${"xlsx"}`)
    document.body.appendChild(link)
    link.click()
  })
}
export const samplePdfFileDownload = url => {
  return get(`${url}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `sample_sheet_${Date.now()}.${"pdf"}`)
    document.body.appendChild(link)
    link.click()
  })
}

//import file data
export const importFileData = data => {
  return post(data?.url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

//Dropdown

export const getClassList = id => {
  return get(`series/fetch/${id}`)
}
export const getCategoryList = () => {
  return get(`category/list`)
}
export const getMaterialList = () => {
  return get(`category/material-list`)
}
export const getGroupList = () => {
  return get(`group/list`)
}
export const getGroupsList = () => {
  return get(`product-groups`)
}
export const getBrandsList = () => {
  return get(`brands/list`)
}
export const getTypeList = () => {
  return get(`type/list/`)
}
export const getUnitList = () => {
  return get(`units/list`)
}

//series
export const fetchInventorySeries = data => {
  return get(`series/search?${data?.params}`)
}

export const postInventorySeries = data => {
  return !data?.id
    ? post(`series/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`series/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const getSeriesList = () => {
  return get(`series/list`)
}

export const updateStatus = id => {
  return get(`series/updatestatus/${id}`)
}

//User
export const fetchUser = data => {
  return get(`users/search?${data?.params}`)
}

export const postUser = data => {
  return !data?.id
    ? post(`user/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`user/update/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const getUserList = () => {
  return get(`user/list`)
}

export const updateUserStatus = id => {
  return get(`user/updatestatus/${id}`)
}

//class code
export const searchInventoryClass = data => {
  return get(`class/search?${data?.params}`)
}
export const fetchClass = data => {
  return get(`class/fetch/${data}`)
}
export const updateClassStatus = data => {
  return get(`class/updatestatus/${data}`)
}
export const postInventoryClass = data => {
  return !data?.id
    ? post(`class/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`class/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

//Group
export const searchInventoryGroup = data => {
  return get(`group/search?${data?.params}`)
}
export const fetchGroup = data => {
  return get(`group/fetch/${data}`)
}
export const updateGroupStatus = data => {
  return get(`group/updatestatus/${data}`)
}
export const postInventoryGroup = data => {
  return !data?.id
    ? post(`group/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`group/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

//Product
export const searchInventoryProduct = data => {
  return get(`products/search?${data?.params}`)
}
export const fetchProduct = data => {
  return get(`products/fetch/${data}`)
}
export const updateProductStatus = data => {
  return get(`products/updatestatus/${data}`)
}
export const postInventoryProduct = data => {
  return !data?.id
    ? post(`products/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`products/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// Expense Type Dropdown
export const getExpenseType = () => {
  return get("expense-type")
}

// Category
export const postInventoryCategory = data => {
  return !data?.id
    ? post(`category/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`category/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchCategory = data => {
  return get(`category/search?${data?.params}`)
}
export const updateCategoryStatus = id => {
  return get(`category/updatestatus/${id}`)
}
export const fetchCategoryId = id => {
  return get(`category/fetch/${id}`)
}

// Unit
export const postInventoryUnit = data => {
  return !data?.id
    ? post(`units/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`units/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchUnit = data => {
  return get(`units/search?${data?.params}`)
}
export const updateUnitStatus = id => {
  return get(`units/updatestatus/${id}`)
}

// Type
export const postInventoryType = data => {
  return !data?.id
    ? post(`type/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`type/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchType = data => {
  return get(`type/search?${data?.params}`)
}
export const updateTypeStatus = id => {
  return get(`type/updatestatus/${id}`)
}

// Brand
export const postInventoryBrand = data => {
  return !data?.id
    ? post(`brands/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`brands/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchBrand = data => {
  return get(`brands/search?${data?.params}`)
}
export const updateBrandStatus = id => {
  return get(`brands/updatestatus/${id}`)
}

//Modules
export const fetchModules = data => {
  return get(`permissions/search?${data?.params}`)
}
export const postModules = data => {
  return !data?.id
    ? post(`permission/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`permission/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const updateModuleStatus = id => {
  return get(`permission/updatestatus/${id}`)
}

export const fetchModuleData = data => {
  return get(`permission/fetch/${data}`)
}

export const fetchPermissions = data => {
  return get(`permissions/updated-search`)
}

//Roles
export const searchRoles = data => {
  return get(`roles/search?${data?.params}`)
}
export const postRoles = data => {
  return !data?.id
    ? post(`role/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`role/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
export const deleteRole = id => {
  return del(`role/remove/${id}`)
}

export const updateRolestatus = id => {
  return get(`roles/updatestatus/${id}`)
}

export const fetchRolesData = data => {
  return get(`role/fetch/${data}`)
}

// payment terms

export const postPaymentTerms = data => {
  return !data?.id
    ? post(`payemnt-terms/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`payemnt-terms/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchPaymentTerm = data => {
  return get(`payemnt-terms/fetch/${data}`)
}

export const searchPayTerm = data => {
  return get(`payemnt-terms/search?${data?.params}`)
}

// payment terms temp

export const postPaymentTermsTemplate = data => {
  return !data?.id
    ? post(`payment-terms-template/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`payment-terms-template/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchPaymentTermTemplate = data => {
  return get(`payment-terms-template/fetch/${data}`)
}

export const searchPayTermTemplate = data => {
  return get(`payment-terms-template/search?${data?.params}`)
}

// Terms

export const postTerms = data => {
  return !data?.id
    ? post(`terms-master/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`terms-master/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchTerms = data => {
  return get(`terms-master/search?${data?.params}`)
}

export const updateTermsStatus = data => {
  return post(`terms-master/updatestatus`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// Banks
export const postBanks = data => {
  return !data?.id
    ? post(`banks`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : put(`banks/${data?.id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
}

export const fetchBanks = data => {
  return get(`banks?${data?.params}`)
}
export const fetchSingleBank = id => {
  return get(`banks/${id}`)
}
export const delSinglebank = id => {
  return del(`banks/${id}`)
}
export const updateBankStatus = id => {
  return get(`banks/updatestatus/${id}`)
}
