import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  fetchStoreHistory,
  searchCentralStoreProductList,
} from "../../../services/centralStore/centralStore"
import { fetchTransactionHistory } from "../../../services/finance/transactionHistory"
import moment from "moment"
import { isEmpty } from "lodash"
import { UFDate } from "../../../components/Common/Fields/Input"
import { useForm } from "react-hook-form"
import resetIcon from "../../../assets/icons/reset.svg"
import StockSummaryFilter from "./finter"
import NumberFormat from "../../../utils/numberFormat"

const StockSummary = ({ fromDashboard, projectId }) => {
  document.title = "Stock Summary | Everise"

  const [activeTab, setActiveTab] = useState("1")
  const [search, setSearch] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [page, setPage] = useState("")
  const [ccNo, setCCNo] = useState("")
  const [type, setType] = useState("")
  // const [productNo, setProductNo] = useState("")
  const [thNo, setTHNo] = useState("")
  const [financial, setFinancial] = useState(true)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [company, setCompany] = useState(false)
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")

  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: null,
    },
  })

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleCCChange = id => {
    setCCNo(id)
    setPage(1)
  }

  const handleTypeChange = id => {
    setType(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  // const handleProductChange = data => {
  //   setProductNo(data)
  //   setPage(1)
  // }
  const handleTHChange = data => {
    setTHNo(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchCentralStoreProductList({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          ccNo ? `&costcenter_id=${ccNo}` : ""
        }${type ? `&type=${type}` : ""}${thNo ? `&th_no=${thNo}` : ""}${
          projectId ? `&project_id=${projectId}` : ""
        }${
          datefrom ? `&start_date=${datefrom}` : ""
        }${dateto ? `&end_date=${dateto}` : ""}${
          company == true ? `&company_search=""` : ""
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setFetchedData(response)
      // ${`&financial=${financial}`}
    }
    if (datefrom && dateto && moment(datefrom).isSameOrBefore(moment(dateto))) {
      fetchData(activeTab)
    }
  }, [
    activeTab,
    type,
    financial,
    ccNo,
    search,
    thNo,
    projectId,
    startDate,
    endDate,
    company,
    page,
    datefrom,
    dateto,
  ])

  const columns = [
    {
      label: "Product ID",
      renderCell: item =>
        item?.product_id?.product_code ? item?.product_id?.product_code : "-",
      footerContent: () => "Total",
      pinLeft: true,
    },
    {
      label: "Description",
      renderCell: item =>
        item?.product_id?.description ? item?.product_id?.description : "-",
      footerContent: "",
    },
    {
      label: <div className="text-end pe-5">Opening Quantity</div>,
      renderCell: item => (
        <div className="capitalize-letter text-end pe-5">
          {item?.opening_balance_qty ? NumberFormat((Number(item?.opening_balance_qty))) : 0}
        </div>
      ),
      footerContent: () => <div className="text-end pe-5">{
        fetchedData.overallOpeningBalanceQnt
          ? NumberFormat((Number(fetchedData.overallOpeningBalanceQnt)))
          : 0
      }</div>
    },
    {
      label: <div className="text-end pe-5">Opening Balance (AED)</div>,
      renderCell: item => (
        <div className="text-end pe-5">
          {item?.opening_balance_value
            ? NumberFormat((Number(item?.opening_balance_value)), 3, 3)
            : 0}
        </div>
      ),
      footerContent: () => <div className="text-end pe-5">{
        fetchedData.overallOpeningBalanceValue
          ? NumberFormat((Number(fetchedData.overallOpeningBalanceValue)), 3, 3)
          : 0
      }</div>
    },
    {
      label: <div className="text-end pe-5">Inwards Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.material_in_qty ? item?.material_in_qty : 0)))}</div>,
      footerContent: "",
      // `${Number(Number(item?.amount).toFixed(2)).toLocaleString("en", {
      //   minimumFractionDigits: 2,
      // })} AED`,
    },
    {
      label: <div className="text-end pe-5">Inwards Amount (AED)</div>,
      renderCell: item => <div className="text-end pe-5">{
        item?.material_in_value
          ? NumberFormat((Number(item?.material_in_value)), 3, 3)
          : 0
      }</div>,
      footerContent: "",
    },
    {
      label: <div className="text-end ">Outwards Quantity</div>,
      renderCell: item => <div className="text-end ">{NumberFormat((Number(item?.material_out_qty ? item?.material_out_qty : 0)))}</div>,
      footerContent: "",
    },
    {
      label: <di className="text-end pe-5">Outwards Amount (AED)</di>,
      renderCell: item => <div className="text-end pe-5">{
        item?.material_out_value
          ? NumberFormat((Number(item?.material_out_value)), 3, 3)
          : 0
      }</div>,
      footerContent: "",
    },
    {
      label: <div className="text-end pe-5">Closing Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(
        item?.closing_balance_qty ? item?.closing_balance_qty : 0
      )))}</div>,
      footerContent: () => <div className="text-end pe-5">{
        fetchedData.overallClosingBalanceQnt
          ?NumberFormat((Number(fetchedData.overallClosingBalanceQnt)))
          : 0
      }</div>,
    },
    {
      label: <div className="text-end pe-5">Closing Balance (AED)</div>,
      renderCell: item => <div className="text-end pe-5">
        {
          item?.closing_balance_value
          ? NumberFormat((Number(item?.closing_balance_value)), 3, 3)
          : 0
        }
      </div>,
      footerContent: () => <div className="text-end pe-5">{
        fetchedData.overallClosingBalanceValue
          ? NumberFormat((Number(fetchedData.overallClosingBalanceValue)), 3, 3)
          : 0
      }</div>,
    },
  ]

  const handleRequestdate = data => {
    console.log(data, "data")
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    console.log(fromDate, toDate, "toDate")
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const handleReset = () => {
    reset({ req_date: null })
    setStartDate("")
    setEndDate("")
  }

  const handleCompanyChange = id => {
    setCompany(id)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Stock Summary",
                    link: "/procurement-stocksummary",
                  },
                ]}
              />
            ) : (
              <></>
            )}
          </div>

          <Card
            className={`me-2 px-2 pb-2 ${fromDashboard ? "dash-table-bg" : ""}`}
          >
            {fromDashboard ? (
              <div className="px-2 py-3">Transaction History</div>
            ) : (
              <StockSummaryFilter
                // handleCCChange={handleCCChange}
                handleTypeChange={handleTypeChange}
                handleSearchChange={handleSearchChange}
                handleDateChange={handleDateChange}
                // handleTHChange={handleTHChange}
                handleFinacialChange={handleFinacialChange}
                handleCompanyChange={handleCompanyChange}
              />
            )}
            <hr className="table-divide" />
            <div>
              {/* <Nav tabs className={`${fromDashboard ? "tab-active-bg" : ""}`}>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "1" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("1")
                      setPage(1)
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "2" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("2")
                      setPage(1)
                    }}
                  >
                    Receivable
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      activeTab === "3" ? "active pointer" : "pointer"
                    } ${fromDashboard ? "py-2" : ""}`}
                    onClick={() => {
                      toggle("3")
                      setPage(1)
                    }}
                  >
                    Payable
                  </NavLink>
                </NavItem>
              </Nav> */}
              {fromDashboard && (
                <Row className="pt-2">
                  <Col sm={6}>
                    <UFDate
                      control={control}
                      placeholder="Date"
                      name={"req_date"}
                      mode="range"
                      className="ufinput-dashboard"
                      onDateChange={e => handleRequestdate(e)}
                    />
                  </Col>
                  <Col sm={3}></Col>
                  <Col sm={3}>
                    <div
                      className="pointer me-2 text-white btn btn-primary float-end"
                      onClick={() => handleReset()}
                    >
                      <img
                        src={resetIcon}
                        height={15}
                        width={15}
                        className="me-2 svg-clr-reset"
                      />{" "}
                      Reset
                    </div>
                  </Col>
                </Row>
              )}
              {/* dashboard-update-table */}
              {/* dashboard-table-pan */}

              {/* <TabContent activeTab={activeTab} className="pt-3 ">
                <TabPane tabId={activeTab} className=""> */}
              <Row
                className={`ever-rise-table ${
                  fromDashboard ? "dashboardTable" : ""
                } `}
              >
                <Col xs={12}>
                  {fetchedData?.data && (
                    <UpdateTable
                      className="mt-5"
                      nodes={fetchedData?.data}
                      paginate={fetchedData}
                      columns={columns}
                      CurrentPage={page}
                      handlePagination={handlePaginate}
                      tableWidth="10% 20% 20% 20% 15% 15%"
                      mobileWidth="40% 40% 40% 40%"
                      showFooter={true}
                    />
                  )}
                </Col>
              </Row>
              {/* </TabPane> */}
              {/* <TabPane tabId="2">

                </TabPane> */}
              {/* </TabContent> */}
            </div>
            {/* <Row className="mt-2 ever-rise-table">
              <Col xs={12}></Col>
            </Row> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StockSummary
