import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  fetchFinanceAccountFieldsSearch,
  fetchFinanceAccountGroupSearch,
  fetchFinanceSubCategory,
  updateCatStatus,
} from "../../../services/finance/finance"
import FinanceFilter from "../filter"
import { capitalize } from "lodash"
import EditFinanceGroupField from "./edit"

const FinanceAccountGroupFields = () => {
  document.title = "Finance Group Fields | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")

  const [series, setSeries] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [editSeries, setEditSeries] = useState({ modal: false, value: "" })
  const [catFil, setCatFil] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFinanceAccountFieldsSearch({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}${catFil ? `&cat_id=${catFil}` : ""}`,
      })
      setSeries(response)
    }
    fetchData()
  }, [count, status, search, page, catFil])

  const handleEdit = item => {
    setEditSeries({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateCatStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Label",
      renderCell: item => capitalize(item?.label),
    },
    {
      label: "Data Type",
      renderCell: item => capitalize(item?.data_type),
    },
    // {
    //   label: "Group Type",
    //   renderCell: item => (item?.parent ? capitalize(item?.parent?.name) : "-"),
    // },
    // {
    //   label: "Nature Of Account",
    //   renderCell: item => (item?.type?.name ? capitalize(item?.type?.name) : "-"),
    // },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {/* {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem =>
                    permissionItem?.name === "financesub-category_edit"
                ) && ( */}
              <div
                className="table-popup-content"
                onClick={() => handleEdit(item)}
              >
                Edit
              </div>
              {/* )} */}
              {/* <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div> */}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditSeries("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCategoryChange = data => {
    setCatFil(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                {
                  title: "Finance Group Fields",
                  link: "/finance-group-fields",
                },
              ]}
            />
            {/* {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "financesub-category_create"
            ) && ( */}
              <EditFinanceGroupField
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleResetValue}
                buttonLabel="+&nbsp; Group Fields"
                className="px-2 mb-3 me-2 btn btn-primary"
                editItem={editSeries}
              />
            {/* )} */}
          </div>
          <Card className="me-2 px-2 pb-2">
            <FinanceFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCategoryChange={handleCategoryChange}
              sampleUrl={"download/series.xlsx"}
              importUrl={"finance-sub-cat/import"}
              showExport={false}
              isCat={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {series?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={series?.data?.data}
                    paginate={series?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="45% 45% 10%"
                    mobileWidth="45% 45% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FinanceAccountGroupFields
