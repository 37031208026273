import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import {
  fetchTerms,
  updateTermsStatus,
} from "../../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import InventoryFilter from "../filter"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"
import EditTerms from "./edit"
import moment from "moment"
// import { driverSet } from "../../../store/guide/action"

const TermsSearch = () => {
  document.title = "Terms | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const dispatch = useDispatch()

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")

  const [series, setSeries] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [editSeries, setEditSeries] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTerms({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${"&sortOrder=asc"}${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setSeries(response)
    }
    fetchData()
    // dispatch(driverSet("series", true))
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditSeries({ value: item, modal: true })
  }

  const handleStatus = async data => {
    try {
      const response = await updateTermsStatus({
        id: data?.id,
        category_id: data?.category_id,
      })
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Code",
      renderCell: item => item?.code,
      sortKey: "code",
    },
    {
      label: "Type",
      renderCell: item =>
        item?.category_id == 1
          ? "General"
          : item?.category_id == 2
          ? "Delivery"
          : "Remarks",
      sortKey: "description",
    },
    {
      label: <div id="element3">Date</div>,
      renderCell: item => (
        <div>
          {item?.created_at
            ? moment(item?.created_at).format("DD-MM-YYYY")
            : "-"}
        </div>
      ),
      // sortKey: "description",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "series_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              {item?.status == 0 && (
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() => handleStatus(item)}
                >
                  {item?.status === 0 && "Activate"}
                </div>
              )}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditSeries("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Settings"
              breadcrumbObject={[
                { title: "Settings", link: "/settings" },
                { title: "Terms", link: "/settings-terms" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "series_create"
            ) && (
              <div id="element1">
                <EditTerms
                  key={"x"}
                  onUpdate={handleUpdate}
                  onReset={handleResetValue}
                  buttonLabel="+&nbsp; Terms"
                  className="px-2 mb-3 me-2 btn btn-primary"
                  editItem={editSeries}
                />
              </div>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <div id="element2">
              <InventoryFilter
                handleStatusChange={handleStatusChange}
                handleSearchChange={handleSearchChange}
                sampleUrl={"download/series.xlsx"}
                importUrl={"series/import"}
                showExport={false}
              />
            </div>
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {series?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={series?.data?.data}
                    paginate={series?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="20% 30% 20% 20% 10%"
                    mobileWidth="20% 40% 40% 20% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TermsSearch
