import React from "react";

const ArrowIcon = ({ size = 9, color = "currentColor", className = "" }) => (
  <svg
    className={`arrow-icon ${className}`}
    width={size}
    height={size}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.24219L8 1.24219"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.61523 1.24234L7.99985 1.24234L7.99985 6.62695"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
