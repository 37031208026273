import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { fetchSubcontractRequest } from "../../../services/procurement/subContracts"
import { Card, Col, Container, Row } from "reactstrap"
import NumberFormat from "../../../utils/numberFormat"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

const ViewSubcontractRequest = ({ fromDashboard }) => {
  const { id } = useParams()
  const [tabledata, setTabledata] = useState()
  const [requestDetails, setRequestDetails] = useState()
  useEffect(() => {
    const fetchSCdata = async () => {
      const responce = await fetchSubcontractRequest(id)
      setTabledata(responce?.data?.items)
      setRequestDetails(responce?.data)
      console.log("fetch", responce?.data?.items)
    }
    fetchSCdata()
  }, [])

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))
  const { loggedUser } = useSelector(LoginProperties)
  console.log("id", id)
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Subcontract Request",
                    link: "/procurement-subcontractreq",
                  },
                  {
                    title: "View Subcontract Request",
                    link: "",
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">Subcontract Request </h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>MR No</span>
                        <br />
                        <span className="fw-bold-700">
                          {/* {requestDetails?.code} */}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>MR Date</span>
                        <br />
                        {/* <span className="fw-bold-700">
                              {moment(requestDetails?.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </span> */}
                      </Col>
                      <Col lg="3">
                        <span>Project Code</span>
                        <br />
                        <span className="fw-bold-700">
                          {/* {requestDetails?.project?.project_code} */}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <h5 className="productDetail">Material Details</h5>
                      </Col>
                      <Col lg={12} className="table_height">
                        <table className="estimation-table mb-4">
                          <thead>
                            <tr>
                              <th>S No</th>
                              <th className="text-end pe-5">Total Prise</th>
                              <th>Description</th>
                              <th className="text-end pe-5">Ordered Qty</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tabledata?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="text-end pe-5">
                                    {NumberFormat(
                                      Number(data?.total_price),
                                      3,
                                      3
                                    )}
                                  </td>
                                  <td>{data?.item_description}</td>
                                  <td className="text-end pe-5">
                                    {NumberFormat(Number(data?.quantity))}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        +{loggedUser?.settings?.phone}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ViewSubcontractRequest
