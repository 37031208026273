import { get, post } from "../../helpers/api_helper"

export const fetchPurchase = data => {
  return get(`purchase-oreder/fetch/${data}`)
}

export const postPurchaseOrder = data => {
  return !data?.id
    ? post(`purchase-oreder/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`purchase-oreder/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchPurchaseOrder = data => {
  console.log(data, "data")
  return get(`purchase-oreder/search?${data?.params}`)
}
export const updatePOStatus = data => {
  return get(`purchase-oreder/updatestatus/${data}`)
}

// export const downloadpo = (id, fileName) => {
//   return get(`/purchase-oreder/pdf-statement/${id}`, {
//     responseType: "blob",
//   }).then(response => {
//     const url = window.URL.createObjectURL(new Blob([response]))
//     const link = document.createElement("a")
//     link.href = url
//     link.setAttribute("download", fileName)
//     document.body.appendChild(link)
//     link.click()
//   })
// }

export const downloadpo = (id, fileName) => {
  return get(`/purchase-oreder/pdf-statement/${id}`, {
    responseType: "blob",
    credentials: "include",
    headers: { "Accept": "application/pdf" },
  }).then(response => {
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    if (newTab) {
      setTimeout(() => {
        newTab.print();
      }, 1000); // Delay to ensure the PDF loads before printing
    } else {
      alert("Pop-up blocked! Please allow pop-ups.");
    }
  });
};

// export const downloadpo = (id, fileName) => {
//   return get(`/purchase-oreder/pdf-statement/${id}`, {
//     responseType: "blob", // Ensures binary data is received
//     withCredentials: true, // Required for authentication cookies
//     headers: { "Accept": "application/pdf" } // More appropriate header
//   })
//   .then(response => {
//     const blob = new Blob([response.data], { type: "application/pdf" }); // Use `response.data`
//     const url = window.URL.createObjectURL(blob);
//     const newTab = window.open(url, "_blank");

//     if (newTab) {
//       setTimeout(() => {
//         newTab.print();
//       }, 1000); // Delay for proper loading before printing
//     } else {
//       alert("Pop-up blocked! Please allow pop-ups.");
//     }
//   })
//   .catch(error => {
//     console.error("Error downloading PO:", error);
//     alert("Failed to download PO. Please check your network and try again.");
//   });
// };



// export const downloadpo = (id, fileName) => {
//   return get(`/purchase-oreder/pdf-statement/${id}`, {
//     responseType: "blob",
//     withCredentials: true,
//   }).then(response => {
//     const url = window.URL.createObjectURL(new Blob([response]))
//     const link = document.createElement("a")
//     link.href = url
//     link.setAttribute("download", fileName)
//     document.body.appendChild(link)
//     link.click()
//   })
// }

// export const downloadpo = (id, fileName) => {
//   return get(`/purchase-oreder/pdf-statement/${id}`, {
//     responseType: "blob",
//     withCredentials: true,
//   }).then(response => {
//     const blob = new Blob([response], { type: "application/pdf" }); // Ensure proper MIME type
//     const url = window.URL.createObjectURL(blob);

//     const printWindow = window.open(url, "_blank");
//     if (printWindow) {
//       printWindow.focus(); // Focus the new window/tab
//       // printWindow.print(); // Trigger print
//     }
//   }).catch(error => {
//     console.error("Error downloading and printing PO:", error);
//   });
// };

// manpower purchase order fetch

export const fetchManpowerPurchase = data => {
  return get(`manpower-request-po/fetch/${data}`)
}

export const fetchRequestManPower = data => {
  return get(`manpower-request/fetch/${data}`)
}

// manpower po create

export const postManpowerPurchaseOrder = data => {
  return !data?.id
    ? post(`manpower-request-po/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`purchase-oreder/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// finance PO Search New PO
export const fetchPoNewSearch = data => {
  return get(`new-purchase-order/search?${data?.params}`)
}


export const fetchPurchaseOrderVoucher = data => {
  return get(`purchase-orders/fetch/${data}`)
}