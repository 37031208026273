import { del, get, post } from "../../helpers/api_helper"

export const postEstimation = data => {
  console.log(`data chk`, data)

  return data?.edit == 0 ||
    data?.payTermUpdate == true ||
    data?.marginUpdate == true
    ? post(`estimation/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`edit-task`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
  // !data?.id
  //   ? post(`estimation/create`, data, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //   : data?.id && data?.action === 1
  //   ? post(`estimation/update/${data?.id}`, data, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //   : post(`estimation/edit-revision/${data?.id}`, data, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })

  // console.log(`data chk`, data)
}

export const searchEstimation = data => {
  return get(`estimation/search?${data?.params}`)
}
export const fetchEstimation = data => {
  return get(`estimation/fetch-revision/${data}`)
}
export const fetchRevisions = data => {
  return get(`estimation/revision/${data}`)
}
export const updateRevisionStatus = data => {
  return get(`estimation/status/${data}`)
}

export const updateEstimationStatus = data => {
  return get(`estimation/updatestatus/${data}`)
}

export const deleteTask = data => {
  return del(`delete-task/${data}`)
}

export const createRevision = data => {
  return get(`clone-estimation-revision/${data}`)
}

export const deleteEst = data => {
  return get(`estimation/delete/${data}`)
}
