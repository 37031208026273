import React from "react"

const TrashIcon = ({ color = "#8B909A", size = 20, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M12.2501 3.92578C12.2385 3.92578 12.221 3.92578 12.2035 3.92578C9.11763 3.61661 6.03763 3.49995 2.9868 3.80911L1.7968 3.92578C1.5518 3.94911 1.33597 3.77411 1.31263 3.52911C1.2893 3.28411 1.4643 3.07411 1.70347 3.05078L2.89347 2.93411C5.9968 2.61911 9.14097 2.74161 12.291 3.05078C12.5301 3.07411 12.7051 3.28995 12.6818 3.52911C12.6643 3.75661 12.4718 3.92578 12.2501 3.92578Z"
        fill="currentColor"
      />
      <path
        d="M4.95844 3.33797C4.93511 3.33797 4.91177 3.33797 4.88261 3.33214C4.64927 3.2913 4.48594 3.0638 4.52677 2.83047L4.65511 2.0663C4.74844 1.5063 4.87677 0.730469 6.23594 0.730469H7.76427C9.12927 0.730469 9.25761 1.53547 9.34511 2.07214L9.47344 2.83047C9.51427 3.06964 9.35094 3.29714 9.11761 3.33214C8.87844 3.37297 8.65094 3.20964 8.61594 2.9763L8.48761 2.21797C8.40594 1.71047 8.38844 1.6113 7.77011 1.6113H6.24177C5.62344 1.6113 5.61177 1.69297 5.52427 2.21214L5.39011 2.97047C5.35511 3.1863 5.16844 3.33797 4.95844 3.33797Z"
        fill="currentColor"
      />
      <path
        d="M8.8724 13.2717H5.1274C3.09157 13.2717 3.0099 12.1459 2.94573 11.2359L2.56657 5.36173C2.54907 5.12256 2.73573 4.91257 2.9749 4.89507C3.2199 4.8834 3.42407 5.06423 3.44157 5.3034L3.82073 11.1776C3.8849 12.0642 3.90823 12.3967 5.1274 12.3967H8.8724C10.0974 12.3967 10.1207 12.0642 10.1791 11.1776L10.5582 5.3034C10.5757 5.06423 10.7857 4.8834 11.0249 4.89507C11.2641 4.91257 11.4507 5.11673 11.4332 5.36173L11.0541 11.2359C10.9899 12.1459 10.9082 13.2717 8.8724 13.2717Z"
        fill="currentColor"
      />
      <path
        d="M7.96789 10.0625H6.02539C5.78622 10.0625 5.58789 9.86417 5.58789 9.625C5.58789 9.38583 5.78622 9.1875 6.02539 9.1875H7.96789C8.20706 9.1875 8.40539 9.38583 8.40539 9.625C8.40539 9.86417 8.20706 10.0625 7.96789 10.0625Z"
        fill="currentColor"
      />
      <path
        d="M8.45866 7.73047H5.54199C5.30283 7.73047 5.10449 7.53214 5.10449 7.29297C5.10449 7.0538 5.30283 6.85547 5.54199 6.85547H8.45866C8.69783 6.85547 8.89616 7.0538 8.89616 7.29297C8.89616 7.53214 8.69783 7.73047 8.45866 7.73047Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TrashIcon
