import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import moment from "moment"
import { downloadgrn, fetchGRN } from "../../../services/procurement/grn"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { capitalize } from "lodash"
import { fetchSingleInvoice } from "../../../services/finance/finance"
import NumberFormat from "../../../utils/numberFormat"
import MobileNumberFormat from "../../../utils/mobilenumberformat"

const InvoiceView = ({ fromDashboard, viewValue }) => {
  const { id } = useParams()

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const [invoiceDetails, setInvoiceDetails] = useState()
  const [tabledata, setTabledata] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchInvoiceData = async () => {
      const response = await fetchSingleInvoice(viewId)
      setInvoiceDetails(response?.data)
      setTabledata(response?.data?.purchase_order?.purchaseorder_lists)
    }
    if (viewId !== undefined) fetchInvoiceData()
  }, [])

  const result = tabledata?.reduce(
    (acc, item) => {
      const received = item.received_quantity || 0
      return {
        totalreceived: Number(acc.totalreceived) + Number(received),
      }
    },
    { totalreceived: 0 }
  )

  const { totalreceived } = result || {}
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  { title: "Invoice", link: "/finance-invoices" },
                  {
                    title: "Invoice View",
                    link: `/finance-invoice/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <div className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">Invoice </h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="6">
                        <div>From</div>
                        <div className="fw-bold-700">Everrise</div>
                        <div>
                          {loggedUser?.settings?.companyaddress
                            ? loggedUser?.settings?.companyaddress
                            : "-"}
                        </div>
                        <div>
                          Mobile: 
                          {loggedUser?.settings?.phone
                            ? MobileNumberFormat("+" + loggedUser?.settings?.phone)
                            : "-"}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div>To</div>
                        <div className="fw-bold-700">
                          {invoiceDetails?.entity?.name
                            ? invoiceDetails?.entity?.name
                            : "-"}
                        </div>
                        <div>
                          {invoiceDetails?.entity?.address
                            ? invoiceDetails?.entity?.address
                            : "-"}
                        </div>
                        <div>
                          Mobile: 
                          {invoiceDetails?.entity?.contact_number
                            ? MobileNumberFormat("+" + invoiceDetails?.entity?.contact_number)
                            : ""}
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <span>PO NO</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.po_id?.purchase_orders_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>PO Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(grnDetails?.po_id?.created_at).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col> */}
                    </Row>
                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Project No </span>
                        <br />
                        <span className="fw-bold-700">
                          {invoiceDetails?.project?.project_code
                            ? invoiceDetails?.project?.project_code
                            : "-"}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Project Name</span>
                        <br />
                        <span className="fw-bold-700">
                          {invoiceDetails?.project?.project_name
                            ? invoiceDetails?.project?.project_name
                            : "-"}
                        </span>
                      </Col>
                      <Col lg="6">
                        <span>Invoice Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {invoiceDetails?.created_at
                            ? moment(invoiceDetails?.created_at).format(
                                "DD-MM-YYYY"
                              )
                            : moment().format("DD-MM-YYYY")}
                        </span>
                      </Col>
                    </Row>
                    {/* <Row className="my-3">
                      <Col sm={4} className="fw-bold-700">
                        Project Value
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        1000000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Security Cheque
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        200000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Initial Payment (10%)
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        100000
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4} className="fw-bold-700">
                        Initial Payment (10%)
                      </Col>
                      <Col sm={1} className="fw-bold-700">
                        :
                      </Col>
                      <Col sm={4} className="fw-bold-700">
                        100000
                      </Col>
                    </Row> */}
                    <div className="table_height">
                      <Row className="mt-4">
                        <Col lg={12}>
                          <h5 className="productDetail">Material Details</h5>
                        </Col>
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>S No</th>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th className="text-end pe-5">Ordered Qty</th>
                                <th className="text-end pe-5">Price</th>
                                <th className="text-end pe-5">Sub Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tabledata?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="ps-1">{index + 1}</td>
                                    <td className="ps-1">
                                      {data?.product_code?.product_code}
                                    </td>
                                    <td className="ps-1">
                                      {data.description}{" "}
                                    </td>
                                    <td className="ps-1 text-end pe-5">{NumberFormat((Number(data.quantity)))}</td>
                                    <td className="ps-1 text-end pe-5">
                                      {NumberFormat(
                                        (Number(data.price)), 3, 3
                                      )}
                                    </td>
                                    <td className="ps-1 text-end pe-5">
                                      {NumberFormat((
                                          (Number(data.quantity)) *
                                            (Number(data.price))
                                        ), 3, 3)}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between me-2">
                        {/* <p className="quotation-ten-amount">
                          {grnDetails?.pay_terms &&
                            parse(grnDetails?.pay_terms)}
                        </p> */}
                        {/* <p>
                          Total Qty Received{" "}
                          <span className="bg-total">{totalreceived}</span>
                        </p> */}
                      </div>
                    </div>

                    <Row className="pt-5">
                      <Col sm={6}>
                        <div className="pb-2 pay-terms">Pay Terms:</div>
                        <div className="pay-terms">
                          {invoiceDetails?.purchase_order?.pay_terms}
                        </div>
                        <div className="pt-5 pb-2 pay-terms">Remarks:</div>
                        <div className="pay-terms">
                          {invoiceDetails?.purchase_order?.remarks}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Row className="d-flex align-items-center py-2">
                          <Col sm={7} className="text-end dark-text">
                            Sub total:
                          </Col>
                          <Col sm={5} className="ps-1 dark-text">
                            {NumberFormat(
                              (Number(
                                invoiceDetails?.purchase_order?.sub_total
                              )), 3, 3)}{" "}
                            AED
                          </Col>
                        </Row>
                        {invoiceDetails?.purchase_order?.tax != 0 && (
                          <Row className="d-flex align-items-center py-2">
                            <Col sm={7} className="text-end dark-text">
                              Tax:
                            </Col>
                            <Col sm={5} className="ps-1 dark-text">
                              {NumberFormat(
                                  (Number(
                                    invoiceDetails?.purchase_order?.sub_total
                                  )) *
                                    (Number(
                                      invoiceDetails?.purchase_order?.tax
                                    )/
                                    100), 3, 3
                              )}{" "}
                              AED
                            </Col>
                          </Row>
                        )}
                        {invoiceDetails?.purchase_order?.discount != 0 && (
                          <Row className="d-flex align-items-center py-2">
                            <Col sm={7} className="text-end dark-text">
                              Discount:
                            </Col>
                            <Col sm={5} className="ps-1 dark-text">
                              {NumberFormat(
                                  (Number(
                                    invoiceDetails?.purchase_order?.sub_total
                                  )) *
                                    (Number(
                                      invoiceDetails?.purchase_order?.discount
                                    ) /
                                    100)
                              )}{" "}
                              AED
                            </Col>
                          </Row>
                        )}
                        {invoiceDetails?.purchase_order?.shipping_charge !=
                          0 && (
                          <Row className="d-flex align-items-center py-2">
                            <Col sm={7} className="text-end dark-text">
                              Shipping Charges:
                            </Col>
                            <Col sm={5} className="ps-1 dark-text">
                              {NumberFormat(
                                (Number(
                                  invoiceDetails?.purchase_order
                                    ?.shipping_charge
                                )), 3, 3
                              )}{" "}
                              AED
                            </Col>
                          </Row>
                        )}
                        {invoiceDetails?.purchase_order?.other_charge != 0 && (
                          <Row className="d-flex align-items-center py-2">
                            <Col sm={7} className="text-end dark-text">
                              Other Charges:
                            </Col>
                            <Col sm={5} className="ps-1 dark-text">
                              {NumberFormat(
                                (Number(
                                  invoiceDetails?.purchase_order?.other_charge
                                )), 3, 3
                              )}{" "}
                              AED
                            </Col>
                          </Row>
                        )}
                        <Row className="d-flex align-items-center py-2">
                          <Col sm={7} className="text-end dark-text">
                            Total:
                          </Col>
                          <Col sm={5} className="ps-1 dark-text">
                            {NumberFormat(
                              (Number(
                                invoiceDetails?.purchase_order?.total_amount
                              )), 3, 3
                            )}{" "}
                            AED
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {MobileNumberFormat("+" + invoiceDetails?.entity?.contact_number)}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* {!fromDashboard && (
                <Row>
                  <Col lg={fromDashboard ? 12 : 8}>
                    <div
                      className={`d-flex justify-content-between me-2 ${
                        fromDashboard ? "pe-3" : "pr-50"
                      }`}
                    >
                      <span></span>
                      <Button
                        form={`supplier-form`}
                        color={"primary"}
                        className={"px-2 mb-3 btn btn-primary"}
                        onClick={() => downloadgrn(viewId, "grn.pdf")}
                      >
                        Download
                      </Button>
                    </div>
                  </Col>
                </Row>
              )} */}
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoiceView
