import { useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap"
import lightTrash from "../../../assets/icons/light-trash.svg"
import editIcon from "../../../assets/images/editIcon.svg"
import ViewEstimations from "./view"
import NumberFormat from "../../../utils/numberFormat"
import TrashIcon from "../../../assets/icons/iconComponents/trashIcon"
import EditIcon from "../../../assets/icons/iconComponents/editIcon"
import { capitalize } from "lodash"
// import TrashIcon from "../../../../assets/icons/iconComponents/trashIcon"
// import EditIcon from "../../../../assets/icons/iconComponents/editIcon"

const RenderAccordion = ({
  tasks,
  handleEditItemAcc,
  handleRemoveTask,
  // projectPercentage,
  permission,
}) => {
  const [open, setOpen] = useState("")
  const [subOpen, setSubOpen] = useState({})
  const toggleAccord = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const toggleSubAccord = id => {
    setSubOpen(prevState => ({
      ...prevState,
      [id]: prevState[id] ? null : id,
    }))
  }

  // const perCalc = Number(projectPercentage) / 100

  // const calculateTotal = item => {
  //   const sumArray = arr =>
  //     Array.isArray(arr)
  //       ? arr.reduce((total, current) => total + Number(current?.price || 0), 0)
  //       : 0

  //   const totalMaterial = sumArray(item?.estimation_material)
  //   const totalManpower = sumArray(item?.estimation_manpower)
  //   const totalSubcontract = sumArray(item?.estimation_subcontract)

  //   return (totalMaterial + totalManpower + totalSubcontract).toLocaleString(
  //     "en",
  //     {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }
  //   )
  // }
  const calculateTotal = item => {
    const sumArray = (arr, multiplierKey) =>
      Array.isArray(arr)
        ? arr.reduce(
            (total, current) =>
              total +
              Number(current?.price || 0) *
                Number(current?.[multiplierKey] || 1),
            0
          )
        : 0

    const totalMaterial = sumArray(item?.estimation_material, "quantity")
    const totalManpower = sumArray(item?.estimation_manpower, "no_of_labours")
    const totalSubcontract = sumArray(item?.estimation_subcontract, "duration")

    // Recursively calculate total for nested tasks
    const totalSubTasks = Array.isArray(item?.tasks)
      ? item.tasks.reduce((sum, subTask) => sum + calculateTotal(subTask), 0)
      : 0

    // Return the final numeric total
    const totalPrice =
      totalMaterial + totalManpower + totalSubcontract + totalSubTasks

    // const sumPer = totalPrice * perCalc

    return Number(totalPrice) 
    // + Number(sumPer)
  }

  // Example usage:
  // const totalCost = calculateTotal(taskItem) // This returns a number
  // console.log(totalCost.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })) // Format for display

  const handleEditItem = (e, item) => {
    e.stopPropagation()
    handleEditItemAcc(item)
  }

  const handleRemoveItem = (e, item) => {
    e.stopPropagation()
    handleRemoveTask(item)
  }
  return (
    <>
      {tasks?.map((item, index) => (
        <div className="">
          <Accordion
            open={open}
            toggle={toggleAccord}
            className="est-accord my-2"
          >
            <AccordionItem className="m-0">
              <AccordionHeader targetId={index} className="est-accord-head">
                {/* <div className="d-flex justify-content-between">  */}
                <div className="wdt-90 fs-13 fw-500 ps-1">{capitalize(item?.name)}</div>
                <div className="d-flex align-items-center">
                  <div className="fs-13 fw-600">
                    {NumberFormat(Number(calculateTotal(item)), 3, 3)}
                  </div>
                  {permission === true ? (
                    <></>
                  ) : (
                    <>
                      <div className="">
                        <div className="accord-img">
                          {/* <img
                            src={editIcon}
                            alt="light-trash"
                            className="trash-img pointer acc-btn-z"
                            onClick={e => {
                              handleEditItem(e, item)
                            }}
                          /> */}
                          <span
                            className="trash-img pointer"
                            onClick={e => {
                              handleEditItem(e, item)
                            }}
                          >
                            <EditIcon />
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <div className="accord-img">
                          <span
                            className="trash-img pointer"
                            onClick={e => {
                              handleRemoveItem(e, item?.id)
                            }}
                          >
                            <TrashIcon />
                          </span>
                          {/* <img
                            src={lightTrash}
                            alt="light-trash"
                            className="trash-img pointer"
                            onClick={e => {
                              handleRemoveItem(e, item?.id)
                            }}
                          /> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* </div> */}
              </AccordionHeader>
              <AccordionBody accordionId={index}>
                <Row className="px-4">
                  <Col sm={6}>{item?.description ? item?.description : ""}</Col>
                </Row>
                <Row className="px-4">
                  {item?.estimation_material?.length ? (
                    <>
                      <Row className="py-2 mt-1">
                        <Col className="text-bold">Material Details</Col>
                      </Row>

                      <Row className="mb-1">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                {/* <th>Class Code</th>
                                <th>Category Code</th> */}
                                <th>Product</th>
                                <th>Unit</th>
                                <th className="text-end pe-5">Quantity</th>
                                <th className="text-end pe-5">
                                  Unit Price (AED)
                                </th>
                                <th className="text-end pe-5">Price (AED)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.estimation_material?.map((dt, index) => (
                                <tr>
                                  {/* <td>{dt?.class_id?.class_code}</td>
                                  <td>{dt?.category_id?.category_code}</td> */}
                                  <td>{dt?.products?.product_code}</td>
                                  <td>{dt?.unit?.unit_code}</td>
                                  <td className="text-end pe-5">
                                    {dt?.quantity}
                                  </td>
                                  <td className="text-end pe-5">
                                    {NumberFormat(
                                      Number(
                                        Number(dt?.price)
                                        //  +
                                          // Number(dt?.price) * perCalc
                                      ),
                                      3,
                                      3
                                    )}
                                  </td>

                                  <td className="text-end pe-5">
                                    {NumberFormat(
                                      Number(
                                        Number(dt?.price) *
                                          Number(dt?.quantity)
                                          //  +
                                          // Number(dt?.price) *
                                          //   Number(dt?.quantity) *
                                          //   perCalc
                                      ),
                                      3,
                                      3
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {item?.estimation_manpower?.length ? (
                    <>
                      <Row className="py-2">
                        <Col className="text-bold">Manpower Details</Col>
                      </Row>
                      <Row className="mb-1">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                {/* <th>Class Code</th> */}
                                <th>Designation</th>
                                <th className="text-end pe-5">
                                  Number of labours
                                </th>
                                <th className="text-end pe-5">Price (AED)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.estimation_manpower?.map((dt, index) => (
                                <tr>
                                  {/* <td>{dt?.class_id?.class_code}</td> */}
                                  <td>{dt?.category_id?.name}</td>
                                  <td className="text-end pe-5">
                                    {NumberFormat(Number(dt?.no_of_labours))}
                                  </td>
                                  <td className="text-end pe-5">
                                    {NumberFormat(
                                      Number(
                                        Number(dt?.price)
                                        //  +
                                        //   Number(dt?.price) * perCalc
                                      ),
                                      3,
                                      3
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {item?.estimation_subcontract?.length ? (
                    <>
                      <Row className="py-2">
                        <Col className="text-bold">Subcontract Details</Col>
                      </Row>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                {/* <th>Class Code</th> */}
                                <th>Category Code</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th className="text-end pe-5">Quantity</th>
                                <th className="text-end pe-5">Price (AED)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.estimation_subcontract?.map(
                                (dt, index) => (
                                  <tr>
                                    {/* <td>{dt?.class_id?.class_code}</td> */}
                                    <td>{dt?.category_id?.suppliers_code}</td>
                                    <td>{dt?.category_id?.name}</td>
                                    <td>{dt?.unit?.unit_code}</td>
                                    <td className="text-end pe-5">
                                      {NumberFormat(Number(dt?.duration))}
                                    </td>
                                    <td className="text-end pe-5">
                                      {NumberFormat(
                                        Number(
                                          Number(dt?.price) 
                                          // +
                                          //   Number(dt?.price) * perCalc
                                        ),
                                        3,
                                        3
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>

                {item?.tasks?.length > 0 && (
                  <div className="ms-3">
                    <RenderAccordion
                      tasks={item?.tasks}
                      handleEditItemAcc={handleEditItemAcc}
                      handleRemoveTask={handleRemoveTask}
                      // projectPercentage={projectPercentage}
                      permission={permission}
                    />
                  </div>
                )}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      ))}
    </>
  )
}

export default RenderAccordion
