import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAccordion,
} from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  fetchRelivingControlLists,
  getEmploymentTypeList,
  getGratuityLists,
} from "../../../services/Settings/dropdown"
import GratuityYears from "../../../components/Common/chooseFields/gratuityYears"
import { UFInput, UFMonthYear } from "../../../components/Common/Fields/Input"
import {
  fetchAnnualLeaveControl,
  fetchGratuityControl,
  fetchRelivingControl,
  postHrmsAnnualLeaveControl,
  postHrmsGratuityControl,
  postHrmsRelovingControl,
  postHrmsYearMonthControl,
} from "../../../services/Hrms/hrms"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import ReliveType from "../../../components/Common/chooseFields/reliveType"
import EmploymentType from "../../../components/Common/chooseFields/employmentType"

const EditGratuityControl = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [gratuityList, setGratuityList] = useState()
  const [relivingLi, setRelivingLi] = useState([])
  const [employmentLi, setEmploymentLi] = useState([])
  const [combinationArr, setCombinationArr] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const [annualDays, setAnnualDays] = useState([
    { start: 0, end: 10 },
    { start: 11, end: 20 },
    { start: 21, end: 30 },
  ])

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))
  console.log(`combinationArr`, combinationArr)

  const { loggedUser } = useSelector(LoginProperties)

  const schema = yup.object().shape({})
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const getGratuityList = async () => {
      const responce = await getGratuityLists()
      setGratuityList(responce)
    }
    const fetchGratuityData = async () => {
      const responce = await fetchGratuityControl()
      const relivingResponce = await fetchRelivingControl()
      const annualResponce = await fetchAnnualLeaveControl()

      const resignationLimitedArr = responce?.data?.filter(
        item => item?.type === "Resignation" && item?.contract === "0"
      )
      const resignationUnLimitedArr = responce?.data?.filter(
        item => item?.type === "Resignation" && item?.contract === "1"
      )
      const terminationLimitedArr = responce?.data?.filter(
        item => item?.type === "Termination" && item?.contract === "0"
      )
      const terminationUnLimitedArr = responce?.data?.filter(
        item => item?.type === "Termination" && item?.contract === "1"
      )
      const resignationLiniteddata = resignationLimitedArr?.map((dt, i) => ({
        id: dt?.id,
        from: { label: dt?.from, value: dt?.from },
        to: { label: dt?.to, value: dt?.to },
        days: dt?.days,
      }))
      const resignationUnLimiteddata = resignationUnLimitedArr?.map(
        (dt, i) => ({
          id: dt?.id,
          from: { label: dt?.from, value: dt?.from },
          to: { label: dt?.to, value: dt?.to },
          days: dt?.days,
        })
      )
      const terminationLimitedArrdata = terminationLimitedArr?.map((dt, i) => ({
        id: dt?.id,
        from: { label: dt?.from, value: dt?.from },
        to: { label: dt?.to, value: dt?.to },
        days: dt?.days,
      }))
      const terminationUnLimitedArrdata = terminationUnLimitedArr?.map(
        (dt, i) => ({
          id: dt?.id,
          from: { label: dt?.from, value: dt?.from },
          to: { label: dt?.to, value: dt?.to },
          days: dt?.days,
        })
      )

      const relivingArr = combinationArr?.map((dt, i) => ({
        reliving_type: dt[0],
        contract_id: dt[1],
      }))

      const annualArr = annualDays?.map((dt, i) => ({
        start: dt?.start,
        end: dt?.end,
      }))

      reset({
        terminationLimited: terminationLimitedArrdata,
        resignationLimited: resignationLiniteddata,
        terminationUnLimited: terminationUnLimitedArrdata,
        resignationUnLimited: resignationUnLimiteddata,
        hrms_att_date: moment(loggedUser?.settings?.hrms_att_date)?._d,
        reliving_type_array: relivingResponce?.data?.length
          ? relivingResponce?.data
          : relivingArr,
        annual_leave_type_controll: annualResponce?.data?.length
          ? annualResponce?.data
          : annualArr,
      })
    }
    const fetchRelivingControlLi = async () => {
      const responce = await fetchRelivingControlLists()
      setRelivingLi(responce?.data)
    }
    const fetchEmploymentLi = async () => {
      const responce = await getEmploymentTypeList()
      setEmploymentLi(responce?.data)
    }
    getGratuityList()
    fetchGratuityData()
    fetchRelivingControlLi()
    fetchEmploymentLi()
  }, [loggedUser, activeTab])

  // useEffect(()=> {
  //   const relivingReset
  // },[activeTab])

  useEffect(() => {
    const combinations = relivingLi?.flatMap(firstSet =>
      employmentLi?.map(secondSet => [firstSet, secondSet])
    )
    setCombinationArr(combinations)
  }, [relivingLi, employmentLi])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleRegistration = async data => {
    setIsLoading(true)
    console.log(`data : chk`, data)

    try {
      if (data) {
        gratuityList?.data.forEach((item, index) => {
          if (data.resignationLimited[index]) {
            data.resignationLimited[index].gratuity_id = item.id
            data.resignationLimited[index].type = "Resignation"
            data.resignationLimited[index].contract = 0
          }
          if (data.resignationUnLimited[index]) {
            data.resignationUnLimited[index].gratuity_id = item.id
            data.resignationUnLimited[index].type = "Resignation"
            data.resignationUnLimited[index].contract = 1
          }
          if (data.terminationLimited[index]) {
            data.terminationLimited[index].gratuity_id = item.id
            data.terminationLimited[index].type = "Termination"
            data.terminationLimited[index].contract = 0
          }
          if (data.terminationUnLimited[index]) {
            data.terminationUnLimited[index].gratuity_id = item.id
            data.terminationUnLimited[index].type = "Termination"
            data.terminationUnLimited[index].contract = 1
          }
        })
      }
      const resignationLimitedArr = data?.resignationLimited?.map(item => ({
        ...item,
        from: item?.from?.label ? item?.from?.label : 0,
        to: item?.to?.label ? item?.to?.label : 0,
        days: item?.days ? item?.days : 0,
      }))
      const resignationUnLimitedArr = data?.resignationUnLimited?.map(item => ({
        ...item,
        from: item?.from?.label ? item?.from?.label : 0,
        to: item?.to?.label ? item?.to?.label : 0,
        days: item?.days ? item?.days : 0,
      }))
      const terminationLimitedArr = data?.terminationLimited?.map(item => ({
        ...item,
        from: item?.from?.label ? item?.from?.label : 0,
        to: item?.to?.label ? item?.to?.label : 0,
        days: item?.days ? item?.days : 0,
      }))
      const terminationUnLimitedArr = data?.terminationUnLimited?.map(item => ({
        ...item,
        from: item?.from?.label ? item?.from?.label : 0,
        to: item?.to?.label ? item?.to?.label : 0,
        days: item?.days ? item?.days : 0,
      }))

      const relivingPostArr = data?.reliving_type_array?.map(itm => ({
        contract_id: itm?.contract_id?.id,
        reliving_type: itm?.reliving_type?.id,
        days: itm?.days != "" ? itm?.days : 0,
        ...(itm?.id && { id: itm.id }),
      }))

      const annualLeavePostArr = data?.annual_leave_type_controll?.map(itm => ({
        ...itm,
        ...(itm?.id && { id: itm.id }),
      }))

      let responce
      if (activeTab == 1) {
        responce = await postHrmsGratuityControl({
          ...data,
          gratuities: [
            ...resignationLimitedArr,
            ...resignationUnLimitedArr,
            ...terminationLimitedArr,
            ...terminationUnLimitedArr,
          ],
        })
      } else if (activeTab == 2) {
        responce = await postHrmsYearMonthControl({
          hrms_att_date: moment(data?.hrms_att_date).format("YYYY-MM-DD"),
        })
      } else if (activeTab == 3) {
        responce = await postHrmsRelovingControl({
          reliving_type_array: relivingPostArr,
        })
      } else if (activeTab == 4) {
        responce = await postHrmsAnnualLeaveControl({
          annual_leave_type_controll: annualLeavePostArr,
        })
      }
      toastSuccess(responce?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`general-setting-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="HRMS"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      {
                        title: "HRMS Master",
                        link: "/hrms-master",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`general-setting-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 me-1 btn btn-primary"}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "1" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("1")
                          }}
                        >
                          Gratuity Control
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "2" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("2")
                          }}
                        >
                          Payroll Month Control
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "3" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("3")
                          }}
                        >
                          Releving Control
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "4" ? "active pointer" : "pointer"
                          }
                          onClick={() => {
                            toggle("4")
                          }}
                        >
                          Annual Leave Control
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="pt-3">
                      <TabPane tabId="1">
                        <Row className="mb-4 mt-2">
                          <Col sm={12}>
                            <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                              <AccordionItem>
                                <AccordionHeader targetId="1">
                                  <h6> For Limited Contract</h6>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                  <Row className="mb-4 mt-2">
                                    <Col sm={6}>
                                      <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={8}>
                                          <div className="pb-3">
                                            Termination
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="pb-2">
                                        <Col sm={4}>
                                          <div>Gratuity Year</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>From</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>To</div>
                                        </Col>
                                        <Col sm={4}>
                                          <div>Days</div>
                                        </Col>
                                      </Row>
                                      {gratuityList?.data ? (
                                        <Row>
                                          {gratuityList?.data?.map((dt, i) => (
                                            <Row className="py-2">
                                              <Col
                                                sm={4}
                                                className="d-flex align-items-center"
                                              >
                                                {dt?.gratuity}
                                              </Col>
                                              <Col sm={2}>
                                                <GratuityYears
                                                  control={control}
                                                  name={`terminationLimited.${i}.from`}
                                                  placeholder={"Select"}
                                                  target={"body"}
                                                  invalid={!!errors.from}
                                                />
                                              </Col>
                                              <Col sm={2}>
                                                <GratuityYears
                                                  control={control}
                                                  name={`terminationLimited.${i}.to`}
                                                  placeholder={"Select"}
                                                  target={"body"}
                                                  invalid={!!errors.to}
                                                />
                                              </Col>
                                              <Col sm={4}>
                                                <UFInput
                                                  className="product-manpowers"
                                                  control={control}
                                                  placeholder="Days"
                                                  name={`terminationLimited.${i}.days`}
                                                />
                                              </Col>
                                            </Row>
                                          ))}
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                    <Col sm={6}>
                                      <div className="pb-3">Resignation</div>
                                      <Row className="pb-2">
                                        <Col sm={2}>
                                          <div>From</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>To</div>
                                        </Col>
                                        <Col sm={4}>
                                          <div>Days</div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {gratuityList?.data ? (
                                          <Row>
                                            {gratuityList?.data?.map(
                                              (dt, i) => (
                                                <Row className="py-2">
                                                  <Col sm={2}>
                                                    <GratuityYears
                                                      control={control}
                                                      name={`resignationLimited.${i}.from`}
                                                      placeholder={"Select"}
                                                      target={"body"}
                                                      invalid={!!errors.from}
                                                    />
                                                  </Col>
                                                  <Col sm={2}>
                                                    <GratuityYears
                                                      control={control}
                                                      name={`resignationLimited.${i}.to`}
                                                      placeholder={"Select"}
                                                      target={"body"}
                                                      invalid={!!errors.to}
                                                    />
                                                  </Col>
                                                  <Col sm={4}>
                                                    <UFInput
                                                      className="product-manpowers"
                                                      control={control}
                                                      placeholder="Days"
                                                      name={`resignationLimited.${i}.days`}
                                                    />
                                                  </Col>
                                                </Row>
                                              )
                                            )}
                                          </Row>
                                        ) : (
                                          <></>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </AccordionBody>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionHeader targetId="2">
                                  <h6> For UnLimited Contract</h6>
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                  <Row className="mb-4 mt-2">
                                    <Col sm={6}>
                                      <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={8}>
                                          <div className="pb-3">
                                            Termination
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="pb-2">
                                        <Col sm={4}>
                                          <div>Gratuity Year</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>From</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>To</div>
                                        </Col>
                                        <Col sm={4}>
                                          <div>Days</div>
                                        </Col>
                                      </Row>
                                      {gratuityList?.data ? (
                                        <Row>
                                          {gratuityList?.data?.map((dt, i) => (
                                            <Row className="py-2">
                                              <Col
                                                sm={4}
                                                className="d-flex align-items-center"
                                              >
                                                {dt?.gratuity}
                                              </Col>
                                              <Col sm={2}>
                                                <GratuityYears
                                                  control={control}
                                                  name={`terminationUnLimited.${i}.from`}
                                                  placeholder={"Select"}
                                                  target={"body"}
                                                  invalid={!!errors.from}
                                                />
                                              </Col>
                                              <Col sm={2}>
                                                <GratuityYears
                                                  control={control}
                                                  name={`terminationUnLimited.${i}.to`}
                                                  placeholder={"Select"}
                                                  target={"body"}
                                                  invalid={!!errors.to}
                                                />
                                              </Col>
                                              <Col sm={4}>
                                                <UFInput
                                                  className="product-manpowers"
                                                  control={control}
                                                  placeholder="Days"
                                                  name={`terminationUnLimited.${i}.days`}
                                                />
                                              </Col>
                                            </Row>
                                          ))}
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                    <Col sm={6}>
                                      <div className="pb-3">Resignation</div>
                                      <Row className="pb-2">
                                        <Col sm={2}>
                                          <div>From</div>
                                        </Col>
                                        <Col sm={2}>
                                          <div>To</div>
                                        </Col>
                                        <Col sm={4}>
                                          <div>Days</div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {gratuityList?.data ? (
                                          <Row>
                                            {gratuityList?.data?.map(
                                              (dt, i) => (
                                                <Row className="py-2">
                                                  <Col sm={2}>
                                                    <GratuityYears
                                                      control={control}
                                                      name={`resignationUnLimited.${i}.from`}
                                                      placeholder={"Select"}
                                                      target={"body"}
                                                      invalid={!!errors.from}
                                                    />
                                                  </Col>
                                                  <Col sm={2}>
                                                    <GratuityYears
                                                      control={control}
                                                      name={`resignationUnLimited.${i}.to`}
                                                      placeholder={"Select"}
                                                      target={"body"}
                                                      invalid={!!errors.to}
                                                    />
                                                  </Col>
                                                  <Col sm={4}>
                                                    <UFInput
                                                      className="product-manpowers"
                                                      control={control}
                                                      placeholder="Days"
                                                      name={`resignationUnLimited.${i}.days`}
                                                    />
                                                  </Col>
                                                </Row>
                                              )
                                            )}
                                          </Row>
                                        ) : (
                                          <></>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </AccordionBody>
                              </AccordionItem>
                            </UncontrolledAccordion>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row className="pt-4">
                          <Col sm={4}>
                            <FormGroup className="">
                              <UFMonthYear
                                control={control}
                                name={"hrms_att_date"}
                                invalid={!!errors.hrms_att_date}
                                month={true}
                                placeholder={"Select Date"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                              />
                              <FormFeedback invalid>
                                {errors.hrms_att_date?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row className="pt-4">
                          {combinationArr?.map((dt, i) => (
                            <Row className="pt-4">
                              <Col sm={3}>
                                {/* <FormGroup className="mb-4"> */}
                                <Label>
                                  Relive Type
                                  <span className="mandatory">*</span>
                                </Label>
                                <ReliveType
                                  control={control}
                                  name={`reliving_type_array.${i}.reliving_type`}
                                  placeholder={"Select"}
                                  target={"body"}
                                  invalid={!!errors.reliving_type}
                                  disabled={true}
                                />
                                {/* <FormFeedback invalid>
                                    {errors.type?.message}
                                  </FormFeedback>
                                </FormGroup> */}
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  Contract
                                  <span className="mandatory">*</span>
                                </Label>
                                <EmploymentType
                                  control={control}
                                  placeholder="Select "
                                  name={`reliving_type_array.${i}.contract_id`}
                                  invalid={!!errors.contract_id}
                                  target={"body"}
                                  disabled={true}
                                />
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  Days
                                  <span className="mandatory">*</span>
                                </Label>
                                <UFInput
                                  className="product-manpowers"
                                  control={control}
                                  placeholder="Days"
                                  name={`reliving_type_array.${i}.days`}
                                />
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row className="pt-4">
                          {annualDays?.map((dt, i) => (
                            <>
                              <Row className="pt-0 mt-0" >
                              <Col className="mt-0">{i + 1} Section</Col>
                              </Row>
                              <Row className="pt-2 pb-2">
                                <Col sm={3}>
                                  {/* <FormGroup className="mb-4"> */}
                                  <Label>
                                    Start
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    className="product-manpowers"
                                    control={control}
                                    placeholder="Days"
                                    // disabled={true}
                                    name={`annual_leave_type_controll.${i}.start`}
                                  />
                                </Col>
                                <Col sm={3}>
                                  {/* <FormGroup className="mb-4"> */}
                                  <Label>
                                    End
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    className="product-manpowers"
                                    control={control}
                                    placeholder="Days"
                                    // disabled={true}
                                    name={`annual_leave_type_controll.${i}.end`}
                                  />
                                </Col>
                                <Col sm={3}>
                                  {/* <FormGroup className="mb-4"> */}
                                  <Label>
                                    Days
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    className="product-manpowers"
                                    control={control}
                                    placeholder="Days"
                                    name={`annual_leave_type_controll.${i}.days`}
                                  />
                                </Col>
                              </Row>
                            </>
                          ))}
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditGratuityControl
