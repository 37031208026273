import { get, post, put } from "../../helpers/api_helper"

export const postSubcontract = data => {
  return !data?.id
    ? post(`subcontract-request/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`subcontract-request/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchSubcontractRequest = data => {
  return get(`subcontract-request/search?${data?.params}`)
}

export const updateSubcontractStatus = data => {
  return post(`subcontract-request/updatestatus/${data.id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const fetchSubcontractRequest = data => {
  return get(`subcontract-request/fetch/${data}`)
}
