import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getFinanceVoucherLi } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const VoucherTypes = ({
  control,
  name,
  onSelectChange,
  dashboardBg,
  placeholder,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [countriesData, setCountriesData] = useState([])

  const loadOptions = async () => {
    // const data = [
    //   { label: "Contra", value: 1, type: "Accounting Voucher" },
    //   { label: "Credit Note", value: 2, type: "Inventory Voucher" },
    //   { label: "Debit Note", value: 3, type: "Inventory Voucher" },
    //   { label: "Delivery Note", value: 4, type: "" },
    //   { label: "Journal", value: 5, type: "Accounting Voucher" },
    //   { label: "Material In", value: 6, type: "" },
    //   { label: "Material Out", value: 7, type: "" },
    //   { label: "Memorandum", value: 8, type: "" },
    //   { label: "Payment", value: 9, type: "Accounting Voucher" },
    //   { label: "Physical Stock", value: 10, type: "" },
    //   { label: "Purchase", value: 11, type: "Accounting Voucher" },
    //   { label: "Purchase Order", value: 12, type: "Inventory Voucher" },
    //   { label: "Receipt", value: 13, type: "Accounting Voucher" },
    //   { label: "Receipt Note", value: 14, type: "" },
    //   { label: "Rejections In", value: 15, type: "Inventory Voucher" },
    //   { label: "Rejections Out", value: 16, type: "Inventory Voucher" },
    //   { label: "Reversing Journal", value: 17, type: "" },
    //   { label: "Sales", value: 18, type: "Accounting Voucher" },
    //   { label: "Sales Order", value: 19, type: "Inventory Voucher" },
    //   { label: "Stock Journal", value: 17, type: "" },
    // ]
    // setIsLoading(false)
    // const countries = data.map(dta => {
    //   return {
    //     label: dta?.label && ucfirst(dta.label),
    //     value: dta.id,
    //     ...dta,
    //   }
    // })

    const data = await getFinanceVoucherLi()
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label: dta?.voucher_type && ucfirst(dta.voucher_type),
        value: dta.id,
        ...dta,
      }
    })

    setCountriesData(response)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={countriesData}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className={`elevate-dropdown ${
                dashboardBg ? "elevate-dropdown-new-bg" : ""
              }`}
              menuPortalTarget={document.querySelector("body")}
            />
          )
        }}
      />
    </div>
  )
}

export default VoucherTypes
