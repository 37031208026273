import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  FormFeedback,
  ModalFooter,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Container,
  Progress,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useNavigate, Link, useParams } from "react-router-dom"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import taskImg from "../../../../assets/icons/task.svg"
import actionV from "../../../../assets/icons/actionVertical.svg"
import closeIcon from "../../../../assets/icons/close.svg"
import arrow from "../../../../assets/icons/arrow.svg"
import InvoiceView from "../../../Invoice/invoiceView/view"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import ProjectStatusUpdate from "../../../../components/Common/chooseFields/projectStatusUpdate"
import LoadingButton from "../../../../components/Common/LoadingButton"
import {
  fetchProjectDashboardPayterms,
  fetchProjectDashboardSingle,
  fetchProjectDashboardTasks,
} from "../../../../services/dashboard/dashboard"
import moment from "moment"
import NumberFormat from "../../../../utils/numberFormat"
import { formatNumberShort } from "../../../../utils/formatNumber"
import { capitalize, size } from "lodash"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const schema = yup.object().shape({})
const ProjectManagerProjectDash = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))
  const { loggedUser } = useSelector(LoginProperties)

  // modal view
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchedData, setFetchedData] = useState()
  const [payTerms, setPayterms] = useState()
  const [page, setPage] = useState(1)
  const [incomePercent, setIncomePercent] = useState([0, 0])

  const [projectProgressData, setProjectProgressData] = useState()

  const [tasks, setTasks] = useState([
    { id: 1, name: "Need to intimate finance team to raise invoice" },
    { id: 2, name: "Need to intimate finance team to raise invoice" },
    { id: 3, name: "Invoice Notifications" },
    { id: 4, name: "Need to intimate finance team to raise invoice" },
    { id: 5, name: "Need to intimate finance team to raise invoice" },
  ])

  const [menu, setMenu] = useState([
    { id: 1, name: "Material Requests" },
    { id: 2, name: "Purchase order" },
    { id: 3, name: "GRN" },
  ])

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const calculatePercentages = (
    total,
    paid,
    notPaid,
    projectInvoicedAndPaidValue
  ) =>
    total === 0
      ? [0, 0, 0]
      : [
          Math.max(parseFloat(((paid / total) * 100).toFixed(2)), 1),
          // Math.max(parseFloat(((notPaid / total) * 100).toFixed(2)), 1),
          Math.max(
            parseFloat(
              ((projectInvoicedAndPaidValue / total) * 100).toFixed(2)
            ),
            1
          ),
        ]

  useEffect(() => {
    const handleFetchDashboard = async id => {
      const response = await fetchProjectDashboardSingle(id)
      // const responseTasks = await fetchProjectDashboardTasks({
      //   params: {
      //     id,
      //     revision_id: response?.data?.estimation_revision_id,
      //     sizePerPage: 5,
      //     page: page ? page : 1,
      //   },
      // })
      // const responsePayTerms = await fetchProjectDashboardPayterms(
      //   response?.data?.estimation_revision_id
      // )
      setFetchedData(response?.data)
      // setProjectProgressData(responseTasks)
      // setPayterms(responsePayTerms)

      const { amount, projectInvoicedAndPaidValue, invoicedButNotPaidValue } =
        response.data

      setIncomePercent(
        calculatePercentages(
          amount,
          projectInvoicedAndPaidValue,
          invoicedButNotPaidValue,
          Number(amount) -
            Number(
              Number(projectInvoicedAndPaidValue) +
                Number(invoicedButNotPaidValue)
            )
        )
      )
    }

    if (id) handleFetchDashboard(id)
  }, [id])

  useEffect(() => {
    const handleFetchRevisions = async id => {
      const responseTasks = await fetchProjectDashboardTasks({
        params: {
          id,
          revision_id: fetchedData?.estimation_revision_id,
          sizePerPage: 5,
          page: page ? page : 1,
        },
      })
      setProjectProgressData(responseTasks)

      const response = await fetchProjectDashboardPayterms(
        fetchedData?.estimation_revision_id
      )
      setPayterms(response)
    }
    if (fetchedData?.estimation_revision_id) {
      handleFetchRevisions(id)
    }
  }, [fetchedData, page])

  const startDate = moment(fetchedData?.created_at).format("YYYY MM DD")
  const endDate = moment(fetchedData?.exp_completion_date)
  const currDate = moment()

  const totalDays = endDate.diff(startDate, "days")

  const completedDays = currDate.diff(startDate, "days")

  const toCompleteDays = endDate.diff(currDate, "days")

  let percentageCompletion

  // let exceededDate

  let budgetedVal =
    Number(fetchedData?.totalMaterialCost || 0) +
    Number(fetchedData?.totalManpowerCost || 0) +
    Number(fetchedData?.totalSubcontractCost || 0)

  let spendVal = Number(fetchedData?.projectSpentValue || 0)

  percentageCompletion = Number((spendVal / budgetedVal) * 100).toFixed(2)

  // if (endDate.isBefore(currDate)) {
  //   percentageCompletion = 100
  //   // exceededDate = currDate.diff(endDate, "days")
  // } else {
  //   percentageCompletion = ((completedDays / totalDays) * 100).toFixed(0)
  // }

  const chartData = {
    series: [isNaN(percentageCompletion) ? 0 : percentageCompletion],

    options3: {
      fill: {
        colors: [
          loggedUser?.settings?.theme_option != 2 ? "#171717" : "#4B80EE",
        ],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
          },

          track: {
            margin: 0,
            background:
              loggedUser?.settings?.theme_option != 2 ? "#E9E9E9" : "#D9E5FF",
            strokeWidth: "40%",
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
            },
            total: {
              show: true,
              label: [
                `${isNaN(percentageCompletion) ? "0" : percentageCompletion}%`,
                "",
              ],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  }
  const projectBudgetTotal =
    Number(fetchedData?.totalMaterialCost || 0) +
    Number(fetchedData?.totalManpowerCost || 0) +
    Number(fetchedData?.totalSubcontractCost || 0) +
    Number(fetchedData?.preliminary_per || 0) +
    Number(fetchedData?.preliminary_val || 0)
  const completedBudgetPercent = Number(
    (Number(fetchedData?.projectSpentValue || 0) / projectBudgetTotal) * 100
  ).toFixed(2)

  const chartDataBudget = {
    series: [completedBudgetPercent],

    options3: {
      fill: {
        colors: [
          loggedUser?.settings?.theme_option != 2 ? "#171717" : "#4B80EE",
        ],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 40,
            size: "80%",
            background: "#171717",
          },

          track: {
            margin: 0,
            background:
              loggedUser?.settings?.theme_option != 2 ? "#E9E9E9" : "#D9E5FF",
            strokeWidth: "80%",
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
              offsetY: 10,
            },
            total: {
              show: true,
              label: [
                formatNumberShort(
                  Number(fetchedData?.totalMaterialCost || 0) +
                    Number(fetchedData?.totalManpowerCost || 0) +
                    Number(fetchedData?.totalSubcontractCost || 0) +
                    Number(fetchedData?.preliminary_per || 0) +
                    Number(fetchedData?.preliminary_val || 0)
                ),
                "",
              ],
            },
          },
        },
      },
    },
  }

  const chartDataIncome = {
    series: incomePercent,

    options3: {
      fill: {
        colors:
          loggedUser?.settings?.theme_option != 2
            ? ["#383838", "#989898", "#DCDCDC"]
            : ["#4B80EE", "#D9E5FF"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          startAngle: -180, // Start from the bottom
          endAngle: 180,
          hollow: {
            margin: 30,
            size: "70%",
            background: "#171717",
          },

          track: {
            margin: 5,
            background: "transparent",
            strokeWidth: "40%",
            show: false,
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
              offsetY: 10,
            },
            total: {
              show: true,
              label: [formatNumberShort(fetchedData?.amount || 0), ""],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      tooltip: {
        enabled: false, // Disable tooltip to stop hover effect
      },
    },
  }

  const calculatePercent = (usedTotal, sumTotal) => {
    const totalPercent = (usedTotal / sumTotal) * 100
    return Number(totalPercent).toFixed(2)
  }
  const calculatePay = (a, b) => {
    const totalPercent = (Number(a) * Number(b)) / 100
    return totalPercent
  }

  const projectProgress = [
    {
      label: "Code",
      renderCell: item => {
        return <>{item?.code}</>
      },
    },
    {
      label: "Task Name",
      renderCell: item => {
        return <div>{item?.name ? capitalize(item?.name) : "-"}</div>
      },
    },
    // {
    //   label: "End Date",
    //   renderCell: item => <div>{item?.date ? item?.date : "-"}</div>,
    // },
    {
      label: "Description",
      renderCell: item => (
        <>
          <div
            className={`capitalize-letter word-clamp-tbl ${
              item?.description ? "pointer" : ""
            }`}
            id={`PopoverLegacy-${item?.id}`}
          >
            {item?.description ? item?.description : "-"}
          </div>
          {item?.description ? (
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="hover"
              className="popover-inset"
            >
              <PopoverBody>
                <div className="table-popup-content">
                  {item?.description ? item?.description : "-"}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        // <div
        //   className={`fs-13 ${
        //     item?.status == 1 ? "status-active-new" : "status-pending-new"
        //   }`}
        // >
        //   {item?.status == 1 ? "In progress" : "To do"}
        // </div>
        <div className="d-flex align-items-center">
          <div className=" w-50" style={{ height: "6px" }}>
            <Progress
              value={calculatePercent(
                Number(
                  Number(item?.materials_sum_quantity) +
                    Number(item?.manpower_sum_quantity) +
                    Number(item?.subcontract_sum_quantity)
                ),
                Number(
                  Number(item?.project_material_sum_quantity) +
                    Number(item?.project_manpower_sum_no_of_labours) +
                    Number(item?.project_subcontract_sum_duration)
                )
              )}
              color={"primary"}
              size="sm"
              className="progress-sm"
            />
          </div>
          <div className="ps-1">
            {item?.materials_sum_quantity
              ? calculatePercent(
                  Number(
                    Number(item?.materials_sum_quantity) +
                      Number(item?.manpower_sum_quantity) +
                      Number(item?.subcontract_sum_quantity)
                  ),
                  Number(
                    Number(item?.project_material_sum_quantity) +
                      Number(item?.project_manpower_sum_no_of_labours) +
                      Number(item?.project_subcontract_sum_duration)
                  )
                )
              : 0}
            %
          </div>
        </div>
      ),
    },
    // {
    //   label: "",
    //   renderCell: item => <div className="fs-13 view-income">View</div>,
    // },
  ]
  const projectProgressForm = [
    {
      label: "SN",
      renderCell: item => {
        return <>{item?.id}</>
      },
    },
    {
      label: "Task Name",
      renderCell: item => {
        return <div>{item?.name ? capitalize(item?.name) : "-"}</div>
      },
    },
    {
      label: "End Date",
      renderCell: item => <div>{item?.date ? item?.date : "-"}</div>,
    },
    {
      label: "Description",
      renderCell: item => (
        <div className="capitalize-letter">{item?.name ? item?.name : "-"}</div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div className="me-2">
          <ProjectStatusUpdate
            name={`project_status_${item?.id}`}
            control={control}
            dashboardBg={true}
          />
        </div>
      ),
    },
  ]

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleRegistration = () => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Card className="me-2 px-2 pb-2">
          {/* <div className="d-flex justify-content-between"> */}
          <Row className="py-3">
            <Col sm={"4"} className="secondary-text text-16">
              <div className="proj-dash-label">Project Name</div>
              <div className="proj-dash-head">
                {fetchedData ? fetchedData?.project_name : "-"}
              </div>
            </Col>
            {/* <Col lg={"auto"} className="primary-text text-16 fw-500">
                {fetchedData ? fetchedData?.project_name : "-"}
              </Col> */}

            <Col sm={"4"} className="secondary-text text-16">
              <div className="proj-dash-label">Project Location</div>
              <div className="proj-dash-head">
                {fetchedData ? fetchedData?.location?.name : "-"}
              </div>
            </Col>
            {/* <Col className="primary-text text-16 fw-500">
                {fetchedData ? fetchedData?.location_name : "-"}
              </Col> */}
          </Row>
          {/* </div> */}
          <Row>
            <Col sm={4}>
              <div className="projectmanger-card-chart">
                <Card>
                  <div className="finance-dash-head text-center">
                    Project Progress
                  </div>
                  <ReactApexChart
                    type="radialBar"
                    series={chartData?.series}
                    options={chartData?.options3}
                    height={250}
                  />
                  <Row className="px-3 mt-2">
                    <Col sm={12}>
                      <Row className="text-success">
                        <Col sm={6}>Completed Days</Col>
                        <Col sm={6}>
                          {isNaN(completedDays) ? 0 : completedDays}
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className="pt-3">
                      <Row className="text-danger">
                        <Col sm={6}>Remaining Days</Col>
                        <Col sm={6}>
                          {isNaN(toCompleteDays) ? 0 : toCompleteDays}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col sm={4}>
              <div className="projectmanger-card-chart">
                <Card>
                  <div className="finance-dash-head text-center">
                    Budget Overview
                  </div>
                  <div className="dash-chart">
                    <ReactApexChart
                      type="radialBar"
                      series={chartDataBudget?.series}
                      options={chartDataBudget?.options3}
                      height={250}
                    />
                  </div>
                  <Row className="px-3 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-income-bal"></div>
                            <div className="ps-1">Budgeted Value</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end px-2 py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(
                                  Number(fetchedData?.totalMaterialCost || 0) +
                                    Number(
                                      fetchedData?.totalManpowerCost || 0
                                    ) +
                                    Number(
                                      fetchedData?.totalSubcontractCost || 0
                                    ) +
                                    Number(fetchedData?.preliminary_per || 0) +
                                    Number(fetchedData?.preliminary_val || 0),
                                  3,
                                  3
                                )
                              : 0}{" "}
                            AED
                            {/* </div>
                            <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}></Col> */}
                      </Row>
                    </Col>
                    <Col sm={12} className="py-3">
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-pending-invoice"></div>
                            <div className="ps-1">Spend Value</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end px-2 py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(
                                  Number(fetchedData?.projectSpentValue || 0),
                                  3,
                                  3
                                )
                              : 0}{" "}
                            AED
                            {/* </div>
                            <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}>
                          <div
                            className="fs-13 view-income pointer"
                            onClick={() => {
                              handleView({ name: "pending_invoice", id: 1 })
                            }}
                          >
                            View
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-remaining-bal"></div>
                            <div className="ps-1">Remaining Value</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end px-2 py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(
                                  Number(fetchedData?.totalMaterialCost || 0) +
                                    Number(
                                      fetchedData?.totalManpowerCost || 0
                                    ) +
                                    Number(
                                      fetchedData?.totalSubcontractCost || 0
                                    ) +
                                    Number(fetchedData?.preliminary_per || 0) +
                                    Number(fetchedData?.preliminary_val || 0) -
                                    Number(fetchedData?.projectSpentValue || 0),
                                  3,
                                  3
                                )
                              : 0}{" "}
                            AED
                            {/* </div>
                            <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}></Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col sm={4}>
              <div className="projectmanger-card-chart">
                <Card>
                  <div className="finance-dash-head text-center">
                    Income Overview
                  </div>
                  <div className="dash-chart">
                    <ReactApexChart
                      type="radialBar"
                      series={chartDataIncome?.series}
                      options={chartDataIncome?.options3}
                      height={250}
                    />
                  </div>
                  <Row className="px-3 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-income-bal"></div>
                            <div className="ps-1">Project value</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(fetchedData?.amount, 3, 3)
                              : 0}{" "}
                            AED
                            {/* </div> */}
                            {/* <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}></Col> */}
                      </Row>
                    </Col>
                    <Col sm={12} className="py-3">
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-remaining-bal"></div>
                            <div className="ps-1">Invoiced</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end px-2 py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(
                                  fetchedData?.projectInvoicedAndPaidValue,
                                  3,
                                  3
                                )
                              : 0}{" "}
                            AED
                            {/* </div>
                            <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}>
                          <div
                            className="fs-13 view-income pointer"
                            onClick={() => {
                              handleView({ name: "pending_invoice", id: 1 })
                            }}
                          >
                            View
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col sm={12} className="pb-3">
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-pending-invoice"></div>
                            <div className="ps-1">Pending to Invoice</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-end px-2 py-1 dash-inp-end fw-bold">
                            {/* <div> */}
                            {fetchedData
                              ? NumberFormat(
                                  Number(fetchedData?.amount) -
                                    Number(
                                      fetchedData?.projectInvoicedAndPaidValue
                                    ),
                                  3,
                                  3
                                )
                              : 0}{" "}
                            AED
                            {/* </div>
                            <div>AED</div> */}
                          </div>
                        </Col>
                        {/* <Col sm={2}>
                          <div
                            className="fs-13 view-income pointer"
                            onClick={() => {
                              handleView({ name: "pending_invoice", id: 1 })
                            }}
                          >
                            View
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                    {/* <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <div className="d-flex align-items-center">
                            <div className="chart-markings-remaining-bal"></div>
                            <div className="ps-1">Balance</div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="d-flex justify-content-between px-2 py-1 input-chart">
                            <div>
                              {fetchedData
                                ? NumberFormat(
                                    Number(fetchedData?.amount) -
                                      Number(
                                        Number(
                                          fetchedData?.projectInvoicedAndPaidValue
                                        ) +
                                          Number(
                                            fetchedData?.invoicedButNotPaidValue
                                          )
                                      ),
                                    3,
                                    3
                                  )
                                : 0}
                            </div>
                            <div>AED</div>
                          </div>
                        </Col>
                        
                      </Row>
                    </Col> */}
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={8}>
              <div className="projectmanger-card-table">
                <Card>
                  <div className="d-flex justify-content-between p-3">
                    <div className="fw-500 fs-17">Project Progress</div>
                    <div>
                      <Button
                        color={"primary"}
                        className="btn btn-primary me-2"
                        onClick={
                          () =>
                            navigate(`/finance-manufacturing-journal/add`, {
                              state: {
                                project_id: {
                                  label: fetchedData?.project_code,
                                  value: id,
                                  id: id,
                                },
                              },
                            })
                          // handleView({
                          //   id: 2,
                          //   name: "update_progress",
                          //   value: "",
                          // })
                        }
                      >
                        Update Project Progress
                      </Button>
                    </div>
                  </div>
                  <Row className="ever-rise-table">
                    <Col xs={12}>
                      {projectProgressData?.data && (
                        <UpdateTable
                          className="mt-5"
                          nodes={projectProgressData?.data?.data}
                          paginate={projectProgressData?.data}
                          columns={projectProgress}
                          handlePagination={handlePaginate}
                          tableWidth="20% 20% 40% 20%"
                          mobileWidth="20% 40% 40% 20%"
                        />
                      )}
                    </Col>
                  </Row>
                  {/* <div
                    className="d-flex justify-content-end fs-13 fw-500 p-3 me-5 pointer"
                    onClick={() =>
                      handleView({
                        id: 3,
                        name: "view_project_progress",
                        value: "",
                      })
                    }
                  >
                    View All
                  </div> */}
                </Card>
              </div>
            </Col>
            <Col sm={4}>
              <div className="projectmanger-card-table">
                <Card>
                  <div className="fw-500 fs-17 p-3">Project Invoice</div>
                  <div className="px-3">
                    {payTerms?.data?.map((dt, i) => {
                      // Calculate the current portion
                      const currentAmount = calculatePay(
                        fetchedData?.amount,
                        dt?.invoice_portion
                      )

                      // Calculate the sum of previous percentages including the current one
                      const previousSum = payTerms?.data
                        ?.slice(0, i + 1)
                        ?.reduce(
                          (acc, curr) =>
                            Number(acc) +
                            calculatePay(
                              Number(fetchedData?.amount) || 0,
                              Number(curr?.invoice_portion) || 0
                            ),
                          0
                        )

                      const status =
                        Number(previousSum) <=
                        Number(fetchedData?.projectInvoicedAndPaidValue)
                          ? "Completed"
                          : "Pending"

                      return (
                        <Row key={i} className="align-items-center pb-2">
                          <Col sm={4}>
                            <div className="fs-13 color-black fw-400">
                              {dt?.payterms_element?.name}
                            </div>
                          </Col>
                          <Col sm={5}>
                            <div className="px-2 py-1 dash-inp-end fw-bold text-end">
                              <div>{NumberFormat(currentAmount, 3, 3)}</div>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="payroll-body">{status}</div>
                          </Col>
                        </Row>
                      )
                    })}

                    <Row className="align-items-center py-2">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">Recived</div>
                      </Col>
                      <Col sm={5}>
                        <div className="px-2 py-1 dash-inp-end fw-bold text-end">
                          <div>
                            {fetchedData
                              ? NumberFormat(
                                  fetchedData?.projectInvoicedAndPaidValue,
                                  3,
                                  3
                                )
                              : 0}
                          </div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body"></div>
                      </Col>
                      {/* <Col sm={2}>
                        <div className="fs-13 view-income">View</div>
                      </Col> */}
                    </Row>
                    <Row className="align-items-center">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">Balance</div>
                      </Col>
                      <Col sm={5}>
                        <div className="px-2 py-1 dash-inp-end fw-bold text-end">
                          <div>
                            {fetchedData
                              ? NumberFormat(
                                  Number(fetchedData?.amount) -
                                    Number(
                                      fetchedData?.projectInvoicedAndPaidValue
                                    ),
                                  3,
                                  3
                                )
                              : 0}
                          </div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body"></div>
                      </Col>
                      {/* <Col sm={2}>
                        <div className="fs-13 view-income">View</div>
                      </Col> */}
                    </Row>

                    {/* {payTerms?.data?.map((dt, i) => (
                      <Row className="align-items-center pb-2">
                        <Col sm={3}>
                          <div className="fs-13 color-black fw-400">
                            {dt?.payterms_template?.name}
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="px-2 py-1 input-chart">
                            <div>
                              {calculatePay(
                                fetchedData?.amount,
                                dt?.invoice_portion
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="payroll-body">Completed</div>
                        </Col>
                        <Col sm={2}>
                          <div className="fs-13 view-income">View</div>
                        </Col>
                      </Row>
                    ))} */}
                    {/* <Row className="align-items-center py-2">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">
                          Interim payment
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body">Completed</div>
                      </Col>
                      <Col sm={2}>
                        <div className="fs-13 view-income">View</div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">
                          Interim payment 1
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body">Pending</div>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                    <Row className="align-items-center py-2">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">
                          Interim payment 2
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body">Pending</div>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">
                          Interim payment 3
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body">Pending</div>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                    <Row className="align-items-center py-2">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">Recived</div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body"></div>
                      </Col>
                      <Col sm={2}>
                        <div className="fs-13 view-income">View</div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col sm={4}>
                        <div className="fs-13 color-black fw-400">Balance</div>
                      </Col>
                      <Col sm={3}>
                        <div className="px-2 py-1 input-chart">
                          <div>10000</div>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="payroll-body"></div>
                      </Col>
                      <Col sm={2}>
                        <div className="fs-13 view-income">View</div>
                      </Col>
                    </Row> */}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col sm={4}>
              <div className="projectmanger-card-task">
                <Card>
                  <div className="fw-500 fs-17 p-3">Upcoming Task</div>
                  <div className="px-3">
                    {tasks?.map((dt, i) => (
                      <Row key={i} className="pb-3 align-items-center">
                        <Col sm={2}>
                          <img className="" src={taskImg} alt="icon" />
                        </Col>
                        <Col sm={8}>{dt?.name}</Col>
                        <Col sm={2}>
                          <div className="d-flex justify-content-end">
                            <img className="" src={actionV} alt="icon" />
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end fs-13 fw-500 p-3">
                    View All
                  </div>
                </Card>
              </div>
            </Col>
            <Col sm={8}>
              <div className="projectmanger-card-task">
                <Card>
                  <div className="project-nav p-2">
                    <Nav tabs className="tab-active-bg">
                      <NavItem>
                        <NavLink
                        // className={
                        //   activeTab === "1" ? "active pointer" : "pointer"
                        // }
                        // onClick={() => {
                        //   toggle("1")
                        //   setPage(1)
                        // }}
                        >
                          Material Management
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                        // className={
                        //   activeTab === "1" ? "active pointer" : "pointer"
                        // }
                        // onClick={() => {
                        //   toggle("1")
                        //   setPage(1)
                        // }}
                        >
                          Manpower Management
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                        // className={
                        //   activeTab === "1" ? "active pointer" : "pointer"
                        // }
                        // onClick={() => {
                        //   toggle("1")
                        //   setPage(1)
                        // }}
                        >
                          Overheads Management
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="px-3">
                    {menu?.map((dt, i) => (
                      <Link to={dt?.linkTo}>
                        <div className="mb-1">
                          <label
                            className="sub-menu-link capitalize-letter"
                            role="button"
                          >
                            {dt?.name}
                          </label>
                          <img src={arrow} alt="" width="7" />
                        </div>
                      </Link>
                    ))}
                  </div>
                </Card>
              </div>
            </Col>
          </Row> */}
        </Card>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size={
            viewData?.id == 1 || viewData?.id == 2 || viewData?.id == 3
              ? "lg"
              : "md"
          }
          unmountOnClose={false}
        >
          <>
            <ModalHeader className="custom-modal-header">
              <div className="d-flex justify-content-between">
                <h5 className="ms-4">
                  {viewData?.id == 2 ? "Project Progress" : ""}
                </h5>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={toggle}
                  width="10"
                  role="button"
                />
              </div>
            </ModalHeader>
            <ModalBody>
              <FormProvider>
                <Form
                  id={`ev-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                >
                  {viewData?.id == 2 && (
                    <Card className="pb-2">
                      <Row className="ever-rise-table">
                        <Col xs={12}>
                          <UpdateTable
                            className="mt-5"
                            nodes={projectProgressData?.data?.data}
                            columns={projectProgressForm}
                            tableWidth="10% 20% 20% 30% 20%"
                            mobileWidth="30% 40% 40% 50% 20%"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex mt-2 justify-content-end me-2">
                        <LoadingButton
                          form={`ev-form`}
                          color={"primary"}
                          loading={isLoading}
                          className={"px-2 btn btn-primary"}
                          type="submit"
                        >
                          Save
                        </LoadingButton>
                      </div>
                    </Card>
                  )}
                  {/* {(viewData?.id === 4 ||
                    viewData?.id === 5 ||
                    viewData?.id === 7) && (
                    <>
                      <Row className="mt-2 ever-rise-table mx-2">
                        <Col xs={12}>
                          {viewData?.id == 4 && (
                            <>
                              <Row>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Project Store Availability
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UFInput
                                      control={control1}
                                      placeholder="Enter the Customer Name"
                                      name={"project_store"}
                                      invalid={!!errors1.project_store}
                                      disabled={true}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.project_store?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Quantity
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UFInput
                                      control={control1}
                                      placeholder="Enter the Quantity"
                                      name={"project_quantity"}
                                      invalid={!!errors1.project_quantity}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.project_quantity?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Issue Date
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFDate
                                      control={control1}
                                      name={"issue_date"}
                                      invalid={!!errors1.issue_date}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.issue_date?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Contact Person
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UsersList
                                      control={control1}
                                      placeholder="Select Contact Person"
                                      name={"contact_person"}
                                      invalid={!!errors1.contact_person}
                                    />

                                    <FormFeedback invalid>
                                      {errors1.contact_person?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {viewData?.id == 5 && (
                            <>
                              <Row>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Central Store Availability
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UFInput
                                      control={control1}
                                      placeholder=""
                                      name={"central_store"}
                                      invalid={!!errors1.central_store}
                                      disabled={true}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.central_store?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Quantity
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UFInput
                                      control={control1}
                                      placeholder="Enter the Quantity"
                                      name={"central_quantity"}
                                      invalid={!!errors1.central_quantity}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.central_quantity?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Description
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFEditor
                                      control={control1}
                                      placeholder="Enter the Description"
                                      rows="5"
                                      name={"description"}
                                      invalid={!!errors1.description}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.description?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          {viewData?.id == 7 && (
                            <>
                              <Row>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Category Code
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFInput
                                      control={control1}
                                      placeholder=""
                                      name={"category_code"}
                                      invalid={!!errors1.category_code}
                                      disabled={true}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.category_code?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Number of Hours
                                      <span className="mandatory">*</span>
                                    </Label>

                                    <UFInput
                                      control={control1}
                                      placeholder="Enter Hour"
                                      name={"req_manpower_no"}
                                      invalid={!!errors1.req_manpower_no}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.req_manpower_no?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      From Date - To Date
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFDate
                                      control={control1}
                                      name={"request_date"}
                                      mode="range"
                                      minDate={moment().format("YYYY-MM-DD")}
                                      // onDateChange={e => handleRequestdate(e)}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.request_date?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>Minimum Manpower (per day)</Label>
                                    <UFInput
                                      control={control1}
                                      placeholder="Expected Quantity"
                                      name={"exp_qty"}
                                      invalid={!!errors1.exp_qty}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.exp_qty?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col sm={12}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Remarks
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFEditor
                                      control={control1}
                                      placeholder="Enter the Description"
                                      rows="5"
                                      name={"req_remarks"}
                                      invalid={!!errors1.req_remarks}
                                    />
                                    <FormFeedback invalid>
                                      {errors1.req_remarks?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {(viewData?.id === 4 ||
                    viewData?.id === 5 ||
                    viewData?.id === 7) && (
                    <>
                      <hr />
                      <div className="pb-3 d-flex justify-content-end">
                        <LoadingButton
                          form={`ev-form`}
                          color={"primary"}
                          loading={isLoading}
                          disabled={isLoading}
                          className={"me-4"}
                          type="submit"
                        >
                          {viewData?.id === 4 ? "Issue Note" : "Request"}
                        </LoadingButton>
                      </div>
                    </>
                  )} */}
                </Form>
              </FormProvider>
              {viewData?.id == 1 && <InvoiceView fromDashboard={true} />}
              {viewData?.id == 3 && (
                <Card className="pb-2">
                  <Row className="ever-rise-table">
                    <Col xs={12}>
                      <UpdateTable
                        className="mt-5"
                        nodes={projectProgressData?.data?.data}
                        columns={projectProgress}
                        tableWidth="10% 20% 20% 20% 20% 10%"
                        mobileWidth="20% 40% 40% 40% 40% 20%"
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            </ModalBody>
          </>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default ProjectManagerProjectDash
