import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  UFDate,
  UFEditor,
  UFInput,
  UFInputNumber,
  UFMonthYear,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
// import closeIcon from "../../assets/icons/close.svg"
// import lightTrash from "../../assets/icons/light-trash.svg"
// import editIcon from "../../assets/images/editIcon.svg"
// import uploadicon from "../../assets/icons/import.svg"
import infoDark from "../../../assets/icons/info-dark.svg"
import {
  importFileData,
  sampleFileDownload,
} from "../../../services/Inventory/inventory"
import {
  deleteTask,
  fetchEstimation,
  postEstimation,
} from "../../../services/estimation/estimation"
import EstimationClass from "../../../components/Common/chooseFields/estimateClass"
import Customer from "../../../components/Common/chooseFields/customer"
import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory"
import EstimationProduct from "../../../components/Common/chooseFields/estimationProduct"
import Location from "../../../components/Common/chooseFields/location"
import EstimationSubCategory from "../../../components/Common/chooseFields/estimationSubCategory"
import { fetchCustomer } from "../../../services/projectManager/customer"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import EstimationMaterialCategory from "../../../components/Common/chooseFields/EstimationMaterialDrop"
import moment from "moment"
import { isEmpty } from "lodash"
import TasksList from "../../../components/Common/chooseFields/tasksList"
import PaymentTermsTempList from "../../../components/Common/chooseFields/paymentTermTempLi"
// import RenderAccordion from "./renderAccord"
import MarginLi from "../../../components/Common/chooseFields/marginLi"
import TrashIcon from "../../../assets/icons/iconComponents/trashIcon"
import EstProductsLi from "../../../components/Common/chooseFields/estProductLists"
import { quantity } from "chartist"

const EditRoughEstimation = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isRevisionLoading, setIsRevisionLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [action, setAction] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const [contractCheck, setContractCheck] = useState(false)
  const [manpowerCheck, setManpowerCheck] = useState(false)
  const [overheadsCheck, setOverheadsCheck] = useState(false)
  const [prelimsCheck, setPrelimssCheck] = useState(false)
  const [materialCheck, setMaterialCheck] = useState(false)
  const [clientData, setClientData] = useState()
  const [materialToManCheck, setMaterialToManCheck] = useState([false])
  const [materialList, setMaterialList] = useState([])
  const [materialsRemove, setMaterialRemove] = useState([])
  const [manPowersRemove, setManpowersRemove] = useState([])
  const [subContractsRemove, setSubContractsRemove] = useState([])
  const [projectDuration, setProjectDuration] = useState({
    fromDate: "",
    toDate: "",
  })
  const [projectTasks, setProjectTasks] = useState([])
  const [tasks, setTasks] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [projectProfit, setProjectProfit] = useState(
    loggedUser?.settings?.percentage ? loggedUser?.settings?.percentage : 0
  )
  const location = useLocation()
  console.log("presentage", projectProfit)

  console.log(`materialsRemove`, materialsRemove)

  const schema = yup.object().shape({
    client_code: yup.mixed().required("Customer Code is Required"),
    location_id: yup.mixed().required("Location is Required"),
    project_name: yup.string().required("Project Name is Required"),
    bid_date: yup.mixed().required("Bid Date is Required"),
    // discount: yup
    //   .string()
    //   .matches(/^([+]?)?[0-9]+$|^$/, "Discount must be only digits")
    //   .min(0, "Minimum limit is 0")
    //   .max(100, "Maximum limit is 100"),
    // estimate_created_by: yup
    //   .string()
    //   .required("Estimate Created By is Required"),

    tasks: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Task Name is Required"),
        unit_id: yup.mixed().required("Unit Code is Required"),
        qty: yup
          .number()
          .typeError("Quantity must be a number")
          .integer("Quantity must be an integer")
          .min(1, "Quantity must be at least 1")
          .required("Quantity is required"),
        unit_price: yup
          .number()
          .typeError("Rate must be a number")
          .integer("Rate must be an integer")
          .min(1, "Rate must be at least 1")
          .required("Rate is required"),
        // qty: yup
        //   .string()
        //   .min(1, "Quantity is Required")
        //   .matches(/^([+]?)?[0-9]+$|^$/, "Quantity must be only digits")
        //   .required("Quantity is required"),
        // price: yup
        //   .string()
        //   .matches(
        //     /^([+]?)\d+(\.\d{1,4})?$/,
        //     "Price must be a valid number with up to 4 digits after the decimal point"
        //   )
        //   .required("Price is required"),
      })
    ),
  })

  const toggle = () => {
    setModal(!modal)
    setFile()
    setFileName()
  }

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  const now = moment()

  // let fromDate = ""
  // let toDate = ""

  const onFileChange = event => {
    setFileName(event)
    setFile(event?.target?.files?.[0])
  }

  const handleImport = () => {
    const importFile = async () => {
      try {
        const response = await importFileData({
          importfile: file,
          url: "estimation/import",
        })
        toastSuccess(response?.message)
        setModal(false)
      } catch (err) {
        toastError(err?.message)
      }
    }
    importFile()
  }

  const handleSampleFile = () => {
    const handleCommonExport = async () => {
      const response = await sampleFileDownload("download/estimation.xlsx")
    }
    handleCommonExport()
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_name: "",
      client_code: null,
      client_name: "",
      location_id: null,
      bid_date: null,
      client_contact_person: "",
      contact_number: "",
      estimate_created_by: "",
      tax: 0,
      preliminary_per: 0,
      discount: 0,
      preliminary_val: 0,
      year_month: null,
      tasks: [
        {
          qty: "",
          unit_id: null,
          unit_price: 0,
          material: "",
          manpower: "",
          fittings: "",
          consumables: "",
          sub_contract: "",
          overhead_profit: "",
          amount: 0,
          material_id: null,
          name: "",
          description: "",
          total: 0,
        },
      ],
    },
  })

  const {
    fields: manpowerFields,
    append: manpowerAppend,
    remove: manpowerRemove,
  } = useFieldArray({
    control,
    name: "estimation_manpowers",
  })
  const {
    fields: contractFields,
    append: contractAppend,
    remove: contractRemove,
  } = useFieldArray({
    control,
    name: "estimation_subcontracts",
  })
  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "tasks",
  })
  const {
    fields: overheadsFields,
    append: overheadsAppend,
    remove: overheadsRemove,
  } = useFieldArray({
    control,
    name: "estimation_overheads",
  })

  const handleCodeChange = () => {
    const { client_code } = getValues()
    fetchCodeData(client_code?.value)
    setValue("tax", loggedUser?.settings?.tax)
    setValue("preliminary_per", loggedUser?.settings?.preliminary_per)
  }
  const fetchCodeData = async idData => {
    const response = await fetchCustomer(idData)
    if (idData == response.data?.id) {
      setClientData(response.data)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        client_name: response.data?.customer_name,
        client_contact_person: response.data?.contact_person,
        contact_number: response.data?.customer_number,
      })
    }
  }

  const calculateTotal = item => {
    const sumArray = (arr, multiplierKey) =>
      Array.isArray(arr)
        ? arr.reduce(
            (total, current) =>
              total +
              Number(current?.price || 0) *
                Number(current?.[multiplierKey] || 1),
            0
          )
        : 0

    const totalMaterial = sumArray(item?.estimation_material, "quantity")
    const totalManpower = sumArray(item?.estimation_manpower, "no_of_labours")
    const totalSubcontract = sumArray(item?.estimation_subcontract, "duration")

    // Recursively calculate total for nested tasks
    // const totalSubTasks = Array.isArray(item?.tasks)
    //   ? item.tasks.reduce((sum, subTask) => sum + calculateTotal(subTask), 0)
    //   : 0

    // Return the final numeric total
    return totalMaterial + totalManpower + totalSubcontract
    // + totalSubTasks
  }

  useEffect(() => {
    const handleFetchEstimation = async () => {
      const ids = id ? id : location?.state?.estimation_revision_id
      const response = await fetchEstimation(ids)
      setProjectTasks(response?.data)
      console.log("dadadata", response)
      const sumTotal =
        calculateTotal(projectTasks) +
        calculateTotal(projectTasks) * (projectProfit / 100)
      // console.log(`sumTotal`, sumTotal)

      setTasks(response?.data?.tasks)

      const tasksArr = response?.data?.tasks?.map(dt => ({
        ...dt,
        total:
          Number(
            Number(dt?.material || 0) +
              Number(dt?.manpower || 0) +
              Number(dt?.fittings || 0) +
              Number(dt?.consumables || 0) +
              Number(dt?.sub_contract || 0) +
              Number(dt?.overhead_profit || 0)
          ) * Number(dt?.qty),
        unit_id: { label: dt?.unit?.unit_code, value: dt?.unit?.id },
        product_id: dt?.estimation_material?.length
          ? {
              label: dt?.estimation_material[0]?.products?.product_code,
              value: dt?.estimation_material[0]?.products?.id,
            }
          : null,
        unit: dt?.estimation_material[0]?.unit_id,
        price: dt?.estimation_material[0]?.price,
        quantity: dt?.estimation_material[0]?.quantity,
        productId: dt?.estimation_material[0]?.id,
      }))

      reset({
        ...response?.data?.client_code,
        revision: response?.data?.id,

        estimation_id: response?.data?.estimation_id,
        client_code: {
          label: response?.data?.client_code?.customers?.customer_code,
          value: response?.data?.client_code?.customers?.id,
        },
        location_id: {
          label: response?.data?.client_code?.location?.name,
          value: response?.data?.client_code?.location?.id,
        },

        year_month:
          response?.data?.client_code?.from_date != null &&
          response?.data?.client_code?.to_date != null
            ? [
                new Date(
                  new Date(response?.data?.client_code?.from_date).setMonth(
                    new Date(response?.data?.client_code?.from_date).getMonth()
                  )
                ),
                new Date(
                  new Date(response?.data?.client_code?.to_date).setMonth(
                    new Date(response?.data?.client_code?.to_date).getMonth()
                  )
                ),
                // moment(response?.data?.client_code?.from_date).format(
                //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
                // ),
                // moment(response?.data?.client_code?.to_date).format(
                //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
                // ),
              ]
            : null,
        tasks: tasksArr,
      })

      setClientData(response?.data?.client_code)
      if (response?.data?.client_code?.overheads_per != null) {
        setOverheadsCheck(true)
      }
      if (response?.data?.client_code?.preliminary_per != null) {
        setPrelimssCheck(true)
      }
    }
    if (id || location?.state?.estimation_revision_id) {
      handleFetchEstimation()
    }
  }, [id, location])

  // const calculateTotalCost = (tasks, index) => {
  //   console.log("indexindex", index)
  //   let totalCost = 0

  //   tasks.forEach(task => {
  //     // Calculate manpower costs
  //     if (task.estimation_manpower && task.estimation_manpower.length > 0) {
  //       if (index?.estimation_manpowers?.length?.id === task.estimation_manpower.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_manpower.forEach(manpower => {
  //         const quantity = parseFloat(manpower.no_of_labours) || 0
  //         const price = parseFloat(manpower.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Calculate material costs
  //     if (task.estimation_material && task.estimation_material.length > 0) {
  //       if (index?.estimation_material?.length?.id === task.estimation_material.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_material.forEach(material => {
  //         const quantity = parseFloat(material.quantity) || 0
  //         const price = parseFloat(material.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Calculate subcontract costs
  //     if (
  //       task.estimation_subcontract &&
  //       task.estimation_subcontract.length > 0
  //     ) {
  //       if (index?.estimation_subcontract?.length?.id === task.estimation_subcontract.length.id) {
  //         totalCost += 0
  //       }
  //       task.estimation_subcontract.forEach(subcontract => {
  //         const quantity = parseFloat(subcontract.duration) || 0
  //         const price = parseFloat(subcontract.price) || 0
  //         totalCost += quantity * price
  //       })
  //     }

  //     // Recursively calculate costs for subtasks
  //     if (task.tasks && task.tasks.length > 0) {
  //       totalCost += calculateTotalCost(task.tasks)
  //     }
  //   })

  //   // Assuming projectProfit is defined in the scope
  //   // const profit = totalCost * (projectProfit / 100);
  //   // totalCost += profit;

  //   console.log("Total cost including profit:", totalCost)
  //   return totalCost
  // }

  {
    console.log("pay_term_temp_id", watch("pay_term_temp_id"))
  }
  const calculateTotalCost = (tasks, index) => {
    console.log("indexindex", index)
    let totalCost = 0

    tasks.forEach(task => {
      // Calculate manpower costs
      if (task.estimation_manpower && task.estimation_manpower.length > 0) {
        task.estimation_manpower.forEach(manpower => {
          if (index?.estimation_manpowers?.some(m => m.id === manpower.id)) {
            totalCost += 0
          } else {
            const quantity = parseFloat(manpower.no_of_labours) || 0
            const price = parseFloat(manpower.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (task.estimation_material && task.estimation_material.length > 0) {
        task.estimation_material.forEach(material => {
          if (index?.estimation_materials?.some(m => m.id === material.id)) {
            totalCost += 0
          } else {
            const quantity = parseFloat(material.quantity) || 0
            const price = parseFloat(material.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (
        task.estimation_subcontract &&
        task.estimation_subcontract.length > 0
      ) {
        task.estimation_subcontract.forEach(subcontract => {
          if (
            index?.estimation_subcontracts?.some(s => s.id === subcontract.id)
          ) {
            totalCost += 0
          } else {
            const quantity = parseFloat(subcontract.duration) || 0
            const price = parseFloat(subcontract.price) || 0
            totalCost += quantity * price
          }
        })
      }
      if (task.tasks && task.tasks.length > 0) {
        totalCost += calculateTotalCost(task.tasks, index)
      }
    })

    console.log("Total cost including profit:", totalCost)
    return totalCost
  }

  console.log("data", projectTasks)

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      event.returnValue = "" // This triggers the browser's built-in alert
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  const handleRegistration = async data => {
    console.log(`submitdata`, data)

    try {
      action === 1 ? setIsLoading(true) : setIsRevisionLoading(true)

      const tototaldata =
        data?.tasks?.reduce(
          (sum, payment) => sum + parseFloat(payment?.total || 0),
          0
        ) || 0

      const tasksArr = data?.tasks?.map(dt => ({
        ...dt,
        unit_id: dt?.unit_id?.value,
        unit_price: dt?.unit_price,
        // Number(dt?.material || 0) +
        // Number(dt?.manpower || 0) +
        // Number(dt?.fittings || 0) +
        // Number(dt?.consumables || 0) +
        // Number(dt?.sub_contract || 0) +
        // Number(dt?.overhead_profit || 0),
        ...(dt?.product_id != null && {
          estimation_materials: [
            {
              ...(dt?.productId && { id: dt?.productId }),
              product_id: dt?.product_id?.value,
              quantity: dt?.quantity ? dt?.quantity : dt?.qty,
              price: dt?.material,
              unit: dt?.unit,
            },
          ],
        }),
      }))

      // console.log("dadadadad", sumTotalCost)
      const updateEstimation = await postEstimation({
        ...(id && { id: data?.id }),
        ...(data?.id && { alredy_created: true }),

        edit: id ? 1 : 0,
        quotation: true,
        estimation_id: data?.estimation_id,
        estimation_revision_id: projectTasks?.id,
        bid_date: moment(data?.bid_date).format("YYYY-MM-DD"),

        client_code: data?.client_code?.value,
        location_id: data?.location_id?.value,
        project_name: data?.project_name,
        client_name: data?.client_name,
        client_contact_person: data?.client_contact_person,
        contact_number: data?.contact_number,
        estimate_created_by: loggedUser?.data?.name,
        tax: data?.tax ? data?.tax : 0,
        // discount: data?.discount ? data?.discount : 0,
        // amount: calulation("estimate_value").toFixed(3),
        // amount: sumTotalAmount.toFixed(3),
        amount: tototaldata,
        from_date: projectDuration?.fromDate,
        to_date: projectDuration?.toDate,
        tasks: tasksArr,

        action: action,
      })
      navigate(`/estimation/quotation`)
      toastSuccess(updateEstimation?.message)
      // toggle()
      setModal(false)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      // setIsUpdateLoading(false)
      setIsRevisionLoading(false)
    }
    // } else {
    //   toastError("Any one estimation should be added")
    // }
  }

  const calulation = type => {
    const materialAmount = watch("estimation_materials")
    const manpowerAmount = watch("estimation_manpowers")
    const contractAmount = watch("estimation_subcontracts")
    const overheadsAmount = watch("overheads_per")
    const taxAmount = watch("tax")
    // const discountAmount = watch("discount")
    const materialManpowerWatch = watch("product_manpowers")
    const prelimsBWatch = watch("preliminary_val")

    switch (type) {
      case "material": {
        return (
          materialAmount?.reduce(
            (a, b) => Number(b.price) * Number(b.quantity) + a,
            0
          ) || 0
        )
      }
      // case "materialMan": {
      //   return materialManpowerWatch?.reduce((a, b) => Number(b.price) + a, 0) || 0
      // }
      case "materialMan": {
        if (Array.isArray(materialManpowerWatch)) {
          return materialManpowerWatch.reduce((a, b) => Number(b.price) + a, 0)
        } else {
          return 0
        }
      }
      case "manpower": {
        return (
          manpowerAmount?.reduce(
            (a, b) => Number(b?.price) * Number(b?.no_of_labours) + a,
            0
          ) || 0
        )
      }
      case "contract": {
        return (
          contractAmount?.reduce(
            (a, b) => Number(b.price) * Number(b?.duration) + a,
            0
          ) || 0
        )
      }
      case "overheads": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("contract") +
            calulation("prelims") +
            (prelimsCheck ? calulation("materialMan") : 0)) *
            Number(watch("overheads_per"))) /
          100
        )
        // overheadsAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "prelims": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("materialMan")) *
            Number(watch("preliminary_per"))) /
          100
        )
      }
      // case "subtotal": {
      //   return (
      //     calulation("material") +
      //     calulation("manpower") +
      //     calulation("materialMan") +
      //     calulation("contract") +
      //     {overheadsCheck && calulation("overheads")}
      //   )
      // }
      case "subtotal": {
        let subtotal =
          calulation("material") +
          calulation("manpower") +
          calulation("materialMan") +
          calulation("contract") +
          Number(prelimsBWatch)

        if (overheadsCheck) {
          subtotal += calulation("overheads")
        }
        if (prelimsCheck) {
          subtotal += calulation("prelims")
        }

        return subtotal
      }
      case "estimate_value": {
        return (
          calulation("subtotal") +
          (calulation("subtotal") * Number(projectProfit)) / 100
          // +
          // (calulation("subtotal") +
          //   (calulation("subtotal") * Number(projectProfit)) / 100) *
          //   (Number(loggedUser?.settings?.tax) / 100) -
          // (calulation("subtotal") +
          //   (calulation("subtotal") * Number(projectProfit)) / 100) *
          //   (Number(discountAmount) / 100)
        )
      }
      default:
        return 0
    }
  }

  const [productPrice, setProductPrice] = useState([])

  const handleCategoryChange = (e, i) => {
    setValue(`estimation_manpowers.${i}.description`, e?.description)
  }
  const handleManpowerCategoryChange = (e, i) => {
    setValue(`product_manpowers.${i}.description`, e?.description)
  }
  const handleOverheadsCatChange = (e, i) => {
    setValue(`estimation_overheads.${i}.description`, e?.description)
  }
  const handleContractCatChange = (e, i) => {
    setValue(`estimation_subcontracts.${i}.description`, e?.description)
  }

  const handleProductChange = (e, index) => {
    const getData = getValues()
    setValue(
      `estimation_materials.${index}.price`,
      getData?.estimation_materials?.[index]?.quantity
        ? getData?.estimation_materials?.[index]?.quantity * e?.price_aed
        : e?.price_aed
    )
    setValue(
      `estimation_materials.${index}.rowprice`,
      getData?.estimation_materials?.[index]?.quantity
        ? getData?.estimation_materials?.[index]?.quantity * e?.price_aed
        : e?.price_aed
    )
    setValue(`estimation_materials.${index}.description`, e?.description)
    setValue(
      `estimation_materials.${index}.unit`,
      e?.unit?.unit_code
      // value: e?.unit?.id,
    )
    setValue(`estimation_materials.${index}.unit_id`, e?.unit?.id)
    if (productPrice.length <= index) {
      setProductPrice(prevPrices => [
        ...prevPrices,
        ...Array(index - prevPrices.length + 1).fill(null),
      ])
    }
    setProductPrice(prevPrices => {
      const updatedPrices = [...prevPrices]
      updatedPrices[index] = e?.price_aed
      return updatedPrices
    })
  }

  const handleQuantityChange = (e, index) => {
    const amt =
      Number(watch(`tasks.${index}.material`)) +
      Number(watch(`tasks.${index}.fittings`)) +
      Number(watch(`tasks.${index}.consumables`)) +
      Number(watch(`tasks.${index}.manpower`)) +
      Number(watch(`tasks.${index}.sub_contract`)) +
      Number(watch(`tasks.${index}.overhead_profit`))
    const qty = Number(e.target.value)
    const total = (amt * qty).toFixed(3)

    setValue(`tasks.${index}.total`, total)
    setValue(`tasks.${index}.unit_price`, amt)
  }
  const handleMaterialChange = (e, index) => {
    const amt =
      Number(watch(`tasks.${index}.material`)) +
      Number(watch(`tasks.${index}.fittings`)) +
      Number(watch(`tasks.${index}.consumables`)) +
      Number(watch(`tasks.${index}.manpower`)) +
      Number(watch(`tasks.${index}.sub_contract`)) +
      Number(watch(`tasks.${index}.overhead_profit`))
    const qty = Number(watch(`tasks.${index}.qty`))
    const total = (amt * qty).toFixed(3)

    setValue(`tasks.${index}.total`, total)
    setValue(`tasks.${index}.unit_price`, amt)
  }

  const handleMaterialLiChange = (e, i) => {
    setValue(`tasks.${i}.material`, e?.price_aed)
    setValue(`tasks.${i}.unit`, e?.unit_id)
    handleMaterialChange(e, i)
  }

  const handleMaterialPrice = (e, index) => {
    console.log(`productPrice?.[index]`, productPrice?.[index])
    const qty = Number(getValues(`estimation_materials.${index}.quantity`))
    const amt = Number(e.target.value) || 0
    const total = (qty * amt).toFixed(3)

    setValue(`estimation_materials.${index}.rowprice`, total)

    // setValue(
    //   `estimation_materials.${index}.rowprice`,
    //   productPrice?.[index]
    //     ? Number(Number(e?.target?.value) * Number(qty)).toFixed(3)
    //     : 0
    // )
  }

  const handleClassChange = (e, i) => {
    setValue(`estimation_materials.${i}.category_id`, null)
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleCategoryReset = (e, i) => {
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleManpowerClass = i => {
    setValue(`estimation_manpowers.${i}.category_id`, null)
    setValue(`estimation_manpowers.${i}.description`, "")
    setValue(`estimation_manpowers.${i}.no_of_labours`, "")
    setValue(`estimation_manpowers.${i}.price`, "")
  }
  const handleManpowerMaterialClass = i => {
    setValue(`product_manpowers.${i}.category_id`, null)
    setValue(`product_manpowers.${i}.description`, "")
    setValue(`product_manpowers.${i}.no_of_labours`, "")
    setValue(`product_manpowers.${i}.price`, "")
  }

  const handleContractClass = i => {
    setValue(`estimation_subcontracts.${i}.category_id`, null)
    setValue(`estimation_subcontracts.${i}.description`, "")
    setValue(`estimation_subcontracts.${i}.unit`, null)
    setValue(`estimation_subcontracts.${i}.duration`, "")
    setValue(`estimation_subcontracts.${i}.price`, "")
  }

  const handleOverheadsClass = i => {
    setValue(`estimation_overheads.${i}.category_id`, null)
    setValue(`estimation_overheads.${i}.description`, "")
    setValue(`estimation_overheads.${i}.price`, "")
  }

  // const handleUpdate = async data => {
  // }

  const handledate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data)
      ? moment(data[1]).endOf("month").format("YYYY-MM-DD")
      : ""
    setProjectDuration({ fromDate, toDate })
  }

  const scrollContainer = document.querySelector(".scroll-container")

  // function handleScroll() {
  //   console.log(`scrolling`)
  // }

  // if (scrollContainer) {
  //   scrollContainer.addEventListener("scroll", handleScroll)
  // }

  const handleAddTask = () => {
    const data = getValues()

    // List of required fields
    const requiredFields = [
      { name: "client_code", label: "Customer" },
      { name: "project_name", label: "Project Name" },
      { name: "location_id", label: "Location" },
      { name: "bid_date", label: "Bid Date" },
      // { name: "year_month", label: "Project Duration" },
      // Add more fields if needed
    ]

    // Check if any required field is empty
    for (const field of requiredFields) {
      if (!data[field.name]) {
        // Set the error for validation feedback
        setError(field.name, {
          type: "manual",
          message: `Please fill in ${field.label}`,
        })

        // Focus on the first empty field
        const element = document.querySelector(`[name="${field.name}"]`)
        if (element) element.focus()

        return // Stop execution if a required field is empty
      }
    }
    toggle()
    // const data = getValues()
    setMaterialCheck(false)
    setManpowerCheck(false)
    setContractCheck(false)
    reset({
      ...data,
      edit: 0,
      task_id: "",
      name: "",
      parent_id: null,
      description: "",
      estimation_materials: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
          id: "",
        },
      ],
      estimation_manpowers: [
        {
          class_id: null,
          category_id: null,
          description: "",
          no_of_labours: "",
          price: "",
        },
      ],
      estimation_subcontracts: [
        {
          class_id: null,
          category_id: null,
          description: "",
          duration: "",
          price: "",
          unit: null,
        },
      ],
    })
  }

  const dataArray = [
    {
      id: 1,
      head: "Task 1",
      amount: "1000",
      subgroupArray: [
        { id: 101, head: "Sub Head 1", amount: "Sub Amount 1" },
        { id: 102, head: "Sub Head 2", amount: "Sub Amount 2" },
      ],
    },
    { id: 2, head: "Task 1", amount: "200" },
    {
      id: 3,
      head: "Task 1",
      amount: "4000",
      subgroupArray: [{ id: 103, head: "Sub Head 3", amount: "Sub Amount 3" }],
    },
  ]

  const [open, setOpen] = useState("")
  const [subOpen, setSubOpen] = useState({})
  const toggleAccord = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const toggleSubAccord = id => {
    setSubOpen(prevState => ({
      ...prevState,
      [id]: prevState[id] ? null : id,
    }))
  }

  const handleStore = () => {
    const data = getValues()
    setProjectTasks([...projectTasks, data])
    toggle()
  }

  const handleRemoveItem = async id => {
    const responce = await deleteTask(id)
    navigate(
      id
        ? `/estimation/budget-edit/${responce?.estimation_revision_id}`
        : "/estimation/budget-add",
      { state: responce }
    )
    toastSuccess(responce?.message)
  }

  const handleEditItem = itm => {
    const data = getValues()
    const materialArray = itm?.estimation_material?.map(dt => ({
      class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.category_code,
      },
      quantity: dt?.quantity,
      product_id: {
        value: dt?.products?.id,
        label: dt?.products?.product_code,
      },
      price: dt?.price,
      rowprice: dt?.price * dt?.quantity,
      unit: dt?.unit?.unit_code,
      unit_id: dt?.unit?.id,
      description: dt?.products?.description,
      id: dt?.id,
      table_id: dt?.id,
    }))

    const contractArray = itm?.estimation_subcontract?.map(dt => ({
      class_id: {
        value: dt?.class_id?.id,
        label: dt?.class_id?.class_code,
      },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.category_code,
      },
      duration: dt?.duration,
      description: dt?.description,
      price: dt?.price,
      totalprice: dt?.duration * dt?.price,
      unit: {
        value: dt?.unit?.id,
        id: dt?.unit?.id,
        label: dt?.unit?.unit_code,
      },
      id: dt?.id,
      table_id: dt?.id,
    }))

    const manpowerArray = itm?.estimation_manpower?.map(dt => ({
      class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      category_id: {
        value: dt?.category_id?.id,
        label: dt?.category_id?.category_code,
      },
      description: dt?.category_id?.description,
      no_of_labours: dt?.no_of_labours,
      price: dt?.price,
      total: dt?.no_of_labours * dt?.price,
      id: dt?.id,
      table_id: dt?.id,
    }))

    setMaterialCheck(itm?.estimation_material?.length ? true : false)
    setManpowerCheck(itm?.estimation_manpower?.length ? true : false)
    setContractCheck(itm?.estimation_subcontract?.length ? true : false)

    reset({
      ...data,
      task_id: itm?.id,
      edit: 1,
      name: itm?.name ? itm?.name : "",
      parent_id: itm?.parent_id
        ? { value: itm?.parent_id, label: itm?.parent?.name }
        : null,
      description: itm?.description ? itm?.description : "",
      estimation_materials: itm?.estimation_material?.length
        ? materialArray
        : [
            {
              class_id: null,
              category_id: null,
              quantity: "",
              product_id: null,
              price: "",
              id: "",
            },
          ],
      estimation_subcontracts: itm?.estimation_subcontract?.length
        ? contractArray
        : [
            {
              class_id: null,
              category_id: null,
              description: "",
              duration: "",
              price: "",
            },
          ],
      estimation_manpowers: itm?.estimation_manpower?.length
        ? manpowerArray
        : [
            {
              class_id: null,
              category_id: null,
              no_of_labours: "",
              description: "",
              price: "",
            },
          ],
    })
    toggle()
  }

  // const calculateTotal = item => {
  //   const sumArray = arr =>
  //     Array.isArray(arr)
  //       ? arr.reduce((total, current) => total + Number(current?.price || 0), 0)
  //       : 0

  //   const totalMaterial = sumArray(item?.estimation_material)
  //   const totalManpower = sumArray(item?.estimation_manpower)
  //   const totalSubcontract = sumArray(item?.estimation_subcontract)

  //   return (totalMaterial + totalManpower + totalSubcontract).toLocaleString(
  //     "en",
  //     {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }
  //   )
  // }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handlePayTermChange = e => {
    const values = getValues()
    const payTermArr = e?.payment_terms?.map(dt => ({
      description: dt?.description,
      invoice_portion: dt?.invoice_portion,
      payterms_element_id: dt?.id,
      payterms_template_id: values?.pay_term_temp_id?.value,
      due_date: moment(now, "YYYY-MM-DD").toDate(),
    }))

    reset({ ...values, payTermUpdate: true, pay_terms: payTermArr })
  }

  useEffect(() => {
    if (watch("margin_type")?.value) {
      setValue("marginUpdate", true)
    }
  }, [watch("margin_type")])

  const handleQuantityChangess = (e, index) => {
    const qty = Number(e.target.value) || 0
    const price = getValues(`estimation_manpowers.${index}.price`) || 0

    const total = (qty * price).toFixed(3)
    console.log("lalala", total)
    setValue(`estimation_manpowers.${index}.total`, total)
  }

  const handleMaterialPricess = (e, index) => {
    const price = Number(e.target.value) || 0
    const qty = getValues(`estimation_manpowers.${index}.no_of_labours`) || 0
    const total = (qty * price).toFixed(3)
    console.log("kakaka", total)

    setValue(`estimation_manpowers.${index}.total`, total)
  }

  const handilDuration = (e, index) => {
    const quy = Number(e.target.value) || 0
    const prise = getValues(`estimation_subcontracts.${index}.price`) || 0
    const total = (quy * prise).toFixed(3)

    setValue(`estimation_subcontracts.${index}.totalprice`, total)
  }

  const handilPrise = (e, index) => {
    const prise = Number(e.target.value) || 0
    const quy = getValues(`estimation_subcontracts.${index}.duration`) || 0
    const total = (quy * prise).toFixed(3)

    setValue(`estimation_subcontracts.${index}.totalprice`, total)
  }

  const perlimspresentage = watch("preliminary_per")
  console.log("preliminary_per", perlimspresentage)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                <Breadcrumbs
                  title="Quotation"
                  breadcrumbObject={[
                    { title: "Quotation", link: "/estimation/quotation" },
                    {
                      title: id ? "Edit Quotation" : "Create Quotation",
                      link: id
                        ? `estimation/quotation/${id}`
                        : "estimation/budget-add",
                    },
                  ]}
                />
                <div>
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                    onClick={() => setAction(1)}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                  {/* <LoadingButton
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded waves-effect waves-light mb-3 me-2"
                    onClick={() => handleUpdate(1)}
                    loading={isUpdateLoading}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton> */}
                  {/* {id &&
                  (loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to revision"
                  ) ||
                    loggedUser?.data?.id === 1) ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      className="btn-sm btn-rounded waves-effect waves-light mb-3 ms-2"
                      onClick={() => setAction(2)}
                      loading={isRevisionLoading}
                    >
                      Create Revision
                    </LoadingButton>
                  ) : (
                    <></>
                  )} */}
                  {/* <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update Revision
                  </LoadingButton> */}
                </div>
              </div>
              <Container fluid className="ps-lg-0 sticky-wrapper">
                <Card className="invoice-main-card scroll-container">
                  <div className="p-3 pt-4">
                    <Row className="mb-4 mt-2">
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Customer Code<span className="mandatory">*</span>
                          </Label>
                          <Customer
                            control={control}
                            placeholder="Select"
                            name={"client_code"}
                            invalid={!!errors.client_code}
                            target={"body"}
                            onSelectChange={handleCodeChange}
                            disabled={id ? true : false}
                          />
                          <FormFeedback invalid>
                            {errors.client_code?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Name<span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Name"
                              name={"client_name"}
                              invalid={!!errors.client_name}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_name?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Contact Person
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Customer Contact Person"
                              name={"client_contact_person"}
                              invalid={!!errors.client_contact_person}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_contact_person?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={3}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Number
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Number"
                              name={"contact_number"}
                              invalid={!!errors.contact_number}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.contact_number?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Name<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Project Name"
                            name={"project_name"}
                            invalid={!!errors.project_name}
                          />
                          <FormFeedback invalid>
                            {errors.project_name?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project location<span className="mandatory">*</span>
                          </Label>
                          <Location
                            control={control}
                            placeholder="Enter the Project Location"
                            name={"location_id"}
                            invalid={!!errors.location_id}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.location_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Bid Date
                            <span className="mandatory">*</span>
                          </Label>
                          <UFDate
                            control={control}
                            placeholder="Select Date"
                            name={"bid_date"}
                            invalid={!!errors.bid_date}
                          />
                          <FormFeedback invalid>
                            {errors.bid_date?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>Project Duration</Label>
                          <UFMonthYear
                            control={control}
                            name={"year_month"}
                            invalid={!!errors.year_month}
                            month={true}
                            placeholder={"Select Date"}
                            // defaultValue={moment().format("YYYY-MM-DD")}
                            mode={"range"}
                            onDateChange={e => handledate(e)}
                          />
                          <FormFeedback invalid>
                            {errors.year_month?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <hr className="m-0" /> */}

                    <>
                      {/* <Row className="mb-4">
                        <h5>Material Details</h5>
                      </Row> */}
                      {materialFields.map((item, index) => (
                        <Row
                          key={`materialFields${index}`}
                          className="itm-card mt-2 mb-3"
                        >
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>
                                Task Name
                                <span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Task"
                                name={`tasks.${index}.name`}
                                invalid={!!errors?.tasks?.[index]?.name}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.name?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Description</Label>
                              <UFInput
                                control={control}
                                placeholder="Description"
                                name={`tasks.${index}.description`}
                                invalid={!!errors?.tasks?.[index]?.description}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.description?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>
                                Quantity
                                <span className="mandatory">*</span>
                              </Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Qty"
                                name={`tasks.${index}.qty`}
                                invalid={!!errors?.tasks?.[index]?.qty}
                                onChange={e => handleQuantityChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.qty?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Rate</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.unit_price`}
                                invalid={!!errors?.tasks?.[index]?.unit_price}
                                disabled={true}
                                // onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.unit_price?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Amount</Label>
                              <UFInput
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.total`}
                                invalid={!!errors?.tasks?.[index]?.total}
                                disabled={true}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.total?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>
                                Unit
                                <span className="mandatory">*</span>
                              </Label>
                              <Unit
                                control={control}
                                placeholder="Select Unit"
                                name={`tasks.${index}.unit_id`}
                                invalid={!!errors?.tasks?.[index]?.unit_id}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.unit_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Material</Label>
                              <EstProductsLi
                                control={control}
                                placeholder="Select Material"
                                name={`tasks.${index}.product_id`}
                                invalid={!!errors?.tasks?.[index]?.product_id}
                                onSelectChange={e =>
                                  handleMaterialLiChange(e, index)
                                }
                                groupType={1}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.product_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          {/* <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Material Quantity</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.quantity`}
                                invalid={!!errors?.tasks?.[index]?.quantity}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.quantity}
                              </FormFeedback>
                            </FormGroup>
                          </Col> */}
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Material Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.material`}
                                invalid={!!errors?.tasks?.[index]?.material}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.material?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Fittings Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.fittings`}
                                invalid={!!errors?.tasks?.[index]?.fittings}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.fittings?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Consumables Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.consumables`}
                                invalid={!!errors?.tasks?.[index]?.consumables}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.consumables?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Manpower Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.manpower`}
                                invalid={!!errors?.tasks?.[index]?.manpower}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.manpower?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>Sub Contract Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.sub_contract`}
                                invalid={!!errors?.tasks?.[index]?.sub_contract}
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {errors?.tasks?.[index]?.sub_contract?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={2}>
                            <FormGroup className="mb-4">
                              <Label>OHP Amount</Label>
                              <UFInputNumber
                                control={control}
                                placeholder="Enter Amount"
                                name={`tasks.${index}.overhead_profit`}
                                invalid={
                                  !!errors?.tasks?.[index]?.overhead_profit
                                }
                                onChange={e => handleMaterialChange(e, index)}
                              />

                              <FormFeedback invalid>
                                {
                                  errors?.tasks?.[index]?.overhead_profit
                                    ?.message
                                }
                              </FormFeedback>
                            </FormGroup>
                          </Col>

                          {/* <div className="trash-img-position"> */}
                          <span
                            className="trash-img pointer trash-img-position"
                            onClick={() => {
                              materialRemove(index)
                            }}
                          >
                            <TrashIcon size={20} />
                          </span>
                          {/* </div> */}
                        </Row>
                      ))}
                      <Row>
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-end"
                        >
                          <Button
                            color="primary"
                            className="addButton mt-2"
                            onClick={() => {
                              materialAppend({
                                qty: "",
                                unit_id: null,
                                unit_price: 0,
                                amount: 0,
                                material_id: null,
                                material: "",
                                manpower: "",
                                fittings: "",
                                consumables: "",
                                sub_contract: "",
                                overhead_profit: "",
                                total: 0,
                                name: "",
                                description: "",
                              })
                            }}
                          >
                            + Row
                          </Button>
                        </Col>
                      </Row>
                      {/* <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th className="wdt-15">Task Name</th>
                                <th className="wdt-20">Description</th>
                                <th className="wdt-7">Qty</th>
                                <th className="wdt-7">Unit</th>
                                <th className="wdt-7">Material</th>
                                <th className="wdt-7">Fittings</th>
                                <th className="wdt-7">Consumables</th>
                                <th className="wdt-7">Manpower</th>
                                <th className="wdt-7">SubContract</th>
                                <th className="wdt-7">OHP</th>
                                <th className="wdt-7">Total</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {materialFields.map((item, index) => (
                                <tr key={`materialFields${index}`}>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Task"
                                      name={`tasks.${index}.name`}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInput
                                      control={control}
                                      placeholder="Description"
                                      name={`tasks.${index}.description`}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInputNumber
                                      control={control}
                                      placeholder="Qty"
                                      name={`tasks.${index}.qty`}
                                      onChange={e =>
                                        handleQuantityChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <Unit
                                      control={control}
                                      placeholder={"Unit"}
                                      name={`tasks.${index}.unit_id`}
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.material`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.fittings`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td className="ps-1">
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.consumables`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>

                                  <td className="ps-1">
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.manpower`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.sub_contract`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <UFInputNumber
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.overhead_profit`}
                                      onChange={e =>
                                        handleMaterialChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <UFInput
                                      control={control}
                                      placeholder="0"
                                      name={`tasks.${index}.total`}
                                      disabled={true}
                                    />
                                  </td>
                                  <td className="ps-0">
                                    <div className="pb-1 px-2 d-flex justify-content-center">
                                      <span
                                        className="trash-img pointer"
                                        onClick={() => {
                                          materialRemove(index)
                                        }}
                                      >
                                        <TrashIcon size={20} />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-end"
                          >
                            <Button
                              color="primary"
                              className="addButton mt-2"
                              onClick={() => {
                                materialAppend({
                                  qty: 0,
                                  unit_id: null,
                                  unit_price: 0,
                                  amount: 0,
                                  material_id: null,
                                  material: 0,
                                  manpower: 0,
                                  fittings: 0,
                                  consumables: 0,
                                  sub_contract: 0,
                                  overhead_profit: 0,
                                  total: 0,
                                  name: "",
                                  description: "",
                                })
                              }}
                            >
                              + Row
                            </Button>
                          </Col>
                        </Col>
                      </Row> */}
                    </>

                    {/* <hr className="m-0" /> */}
                    {/* {!prelimsCheck && (
                      <Row className="d-flex align-items-center py-3 mt-5">
                        <Col lg={9} className="text-end dark-text">
                          Prelims B
                        </Col>
                        <Col lg={3} className="ps-5 dark-text ">
                          <UFInput
                            control={control}
                            placeholder="Enter the Prelims"
                            name={"preliminary_val"}
                            invalid={!!errors.preliminary_val}
                          />
                        </Col>
                      </Row>
                    )} */}
                    {/* <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Net Total(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("subtotal").toFixed(2)}
                      </Col>
                    </Row> */}
                    {/* <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Discount({watch("discount")}%)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        <UFInput
                          control={control}
                          placeholder="Enter the Discount"
                          name={"discount"}
                          invalid={!!errors.discount}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        VAT ({loggedUser?.settings?.tax}%)
                      </Col>

                      <Col lg={3} className="ps-5 dark-text ">
                        {(
                          (calulation("subtotal") *
                            Number(loggedUser?.settings?.tax)) /
                          100
                        ).toFixed(2)}
                      </Col>
                    </Row>

                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Estimate Value(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("estimate_value").toFixed(2)}
                      </Col>
                    </Row> */}
                  </div>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditRoughEstimation
