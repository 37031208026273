import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import InventoryFilter from "../filter"
import { searchPayTermTemplate } from "../../../services/Inventory/inventory"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"
import { driverSet } from "../../../store/guide/action"
import EditPayTermsTemplate from "./edit"

const PaymentTermsTemplateSearch = () => {
  document.title = "Payment Terms Template | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const dispatch = useDispatch()

  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [series, setSeries] = useState("")
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")

  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }

  const columns = [
    {
      label: "Code",
      renderCell: (item, index) => item?.code,
    },
    {
      label: "Payment Term Template Name",
      renderCell: item => (item?.name ? item?.name : "-"),
    },
    // {
    //   label: "Invoice Portion (%)",
    //   renderCell: item =>
    //     item?.invoice_portion ? `${item?.invoice_portion}%` : "0",
    // },
    // {
    //   label: "Description",
    //   renderCell: item =>
    //     item?.description ? capitalizeFirstLetter(item?.description) : "-",
    // },
    // {
    //   label: "Status",
    //   renderCell: item => (
    //     <div>
    //       <div
    //         className={`font-13 ${
    //           item?.status === 1 ? "status-active" : "status-inactive"
    //         }`}
    //       >
    //         {item?.status === 1 ? "Active" : "Inactive"}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {loggedUser?.permission?.some(
                permissionItem => permissionItem?.name === "paymentterms_edit"
              ) && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </div>
              )}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchPayTermTemplate({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
    dispatch(driverSet("", false))
  }, [refresh, status, search, series, page])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSeriesChange = id => {
    setSeries(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Settings"
              breadcrumbObject={[
                { title: "Settings", link: "/settings" },
                { title: "Payment Terms Template", link: "/settings-payment-terms-template" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "paymentterms_create"
            ) && (
              <EditPayTermsTemplate
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleReset}
                editValue={editClass}
                buttonLabel="+&nbsp; Payment Terms Template"
                className="px-2 mb-3 me-2 btn btn-primary"
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleSeriesChange={handleSeriesChange}
              sampleUrl={"download/class.xlsx"}
              importUrl={"class/import"}
              showExport={false}
              isSeries={false}
              showStatus={true}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="20% 70% 10%"
                    mobileWidth="40% 40% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentTermsTemplateSearch
