import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import {
  fetchProcurementDashRequest,
  searchManpowerhRequest,
  searchProcurmentDashRequest,
} from "../../../services/projectManager/projectMaster"

import { useNavigate, useParams } from "react-router-dom"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { FormProvider, useForm } from "react-hook-form"
import closeIcon from "../../../assets/icons/close.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// import redLeftArrow from "../../../assets/icons/redLeftArrow.svg"
// import { downloadmr } from "../../../services/procurement/materialrequest"
// import EditPurchaseOrder from "../../Procurement/purchaseOrder/edit"
// import { downloadpo, updatePOStatus } from "../../../services/procurement/purchaseOrder"
// import { toastError, toastSuccess } from "../../../helpers/msg_helper"
// import EditGRN from "../../Procurement/grn/edit"
import AssignEMployee from "../../ProjectManagement/projectDashboard/hrms/assignEmployee"
import RejectEmp from "../../ProjectManagement/projectDashboard/hrms/rejectEmp"
import moment from "moment"
import HrmsSearch from "../search"
import CustomerFilter from "../../ProjectManagement/projectMaster/filter"
import NumberFormat from "../../../utils/numberFormat"
const HrmsIndex = () => {
  document.title = "HRMS Dashboard | Ever Rise"

  const { id } = useParams()
  const navigate = useNavigate()

  //refresh state
  const [refresh, setRefresh] = useState(0)
  const [refresh1, setRefresh1] = useState(0)

  //pagination state
  const [page, setPage] = useState("")
  const [search, setSearch] = useState("")
  const [projectCode, setProjectCode] = useState("")

  //view and model chart
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  //response state
  const [materialData, setMaterialData] = useState()
  const [materialRequestData, setMaterialRequestData] = useState()

  //tab
  const [activeTab, setActiveTab] = useState("1")

  const handleActiveTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const schema = yup.object().shape({})

  const toggle = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchProcurementDashRequest()
      setMaterialData(response)
      console.log("data", response)
    }
    fetchClass()
  }, [refresh])

  // useEffect(() => {
  //   const searchData = async () => {
  //     const response = await fetchProcurementDashRequest({
  //       params: `${search ? `&searchText=${search}` : "&searchText"} `,
  //     })
  //     console.log("search",response)
  //   }
  //   if(search) {
  //     searchData()
  //   }
  // },[search])

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchManpowerhRequest({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          activeTab
            ? `&type=${
                activeTab == 1 ? 1 : activeTab == 2 ? 3 : activeTab == 3 ? 2 : 4
              }`
            : ""
        }${projectCode ? `&project_id=${projectCode}` : ""}`,
      })
      setMaterialRequestData(response)
      console.log("search", response)
    }
    fetchMaterialSearch()
  }, [activeTab, refresh1, search, projectCode])

  const newManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: "Project Code",
      renderCell: item => {
        return <div>{item?.project_id?.project_code}</div>
      }
    },
    {
      label: <div className="text-end pe-5">Number of Hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{ NumberFormat((Number(item?.req_manpower_no)))}</div>
      },
    },
    {
      label: "Project Name",
      renderCell: item => {
        return <div>{item?.project_id?.project_name}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "",
      renderCell: item => (
        <Button
          color={"primary"}
          onClick={() =>
            handleView({
              id: 1,
              name: "accept_request",
              value: item,
              product_id: "",
            })
          }
        >
          Assign
        </Button>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          <Button
            className="hover-button"
            onClick={() =>
              handleView({
                id: 2,
                name: "reject_request",
                value: item,
                product_id: "",
              })
            }
          >
            Reject
          </Button>
        </div>
      ),
    },
  ]
  const acceptedManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: <div className="text-end pe-5">Number of Hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.req_manpower_no)))}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]
  const rejectedManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: <div className="text-end pe-5">Number of Hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.req_manpower_no)))}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]
  const forwardManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return <div>{item?.category?.designation?.name}</div>
      },
    },
    {
      label: <div className="text-end pe-5">Number of Hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.hr_status)))}</div>
      },
    },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  // const mainCard = [
  //   {
  //     id: 1,
  //     name: "Manpower Requests",
  //     value: materialRequestData?.newmanpower
  //       ? materialRequestData?.newmanpower
  //       : 0,
  //   },
  //   {
  //     id: 2,
  //     name: "Accepted Requests",
  //     value: materialRequestData?.acceptedmanpower
  //       ? materialRequestData?.acceptedmanpower
  //       : 0,
  //   },
  //   {
  //     id: 3,
  //     name: "Rejected Requests",
  //     value: materialRequestData?.rejectedmanpower
  //       ? materialRequestData?.rejectedmanpower
  //       : 0,
  //   },
  // ]

  const handleRegistration = async data => {
    console.log(" console log :", data)
  }

  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
      setRefresh1(refresh1 + 1)
      setRefresh(refresh + 1)
    }
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleProjectCode = data => {
    setProjectCode(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumb
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Projects", link: "/hrms-assignstaff" },
                { title: "Assign Staff", link: "/hrms-assignstaff" },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <HrmsSearch
              handleSearchChange={handleSearchChange}
              handleProjectCode={handleProjectCode}
              status={1}
            />
            <Row className="mt-2 ever-rise-table">
              <Col lg={12} className="">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <Nav tabs className="tab-active-bg">
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "1" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("1")
                            setPage("1")
                          }}
                        >
                          New
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "2" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("2")
                            setPage("1")
                          }}
                        >
                          Accepted
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "3" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("3")
                            setPage("1")
                          }}
                        >
                          Rejected
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "4" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            handleActiveTab("4")
                            setPage("1")
                          }}
                        >
                          Forward
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <Row className="mt-2 ever-rise-table dashboardTable ">
                    <TabContent activeTab={activeTab} className="pt-3">
                      <TabPane tabId="1">
                        <Col xs={12}>
                          {materialRequestData && activeTab == 1 && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialRequestData?.data?.data}
                              paginate={materialRequestData?.data}
                              columns={newManpowerColumn}
                              handlePagination={handlePaginate}
                              tableWidth="12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%"
                              mobileWidth="25% 20% 20% 20% 15% 10%"
                            />
                          )}
                        </Col>
                      </TabPane>
                      <TabPane tabId="2">
                        <Col xs={12}>
                          {materialRequestData && activeTab == 2 && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialRequestData?.data?.data}
                              paginate={materialRequestData?.data}
                              columns={acceptedManpowerColumn}
                              handlePagination={handlePaginate}
                              tableWidth="25% 25% 25% 25%"
                              mobileWidth="40% 40% 40% 30%"
                            />
                          )}
                        </Col>
                      </TabPane>
                      <TabPane tabId="3">
                        <Col xs={12}>
                          {materialRequestData && activeTab == 3 && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialRequestData?.data?.data}
                              paginate={materialRequestData?.data}
                              columns={rejectedManpowerColumn}
                              handlePagination={handlePaginate}
                              tableWidth="25% 25% 25% 25%"
                              mobileWidth="40% 40% 40% 40%"
                            />
                          )}
                        </Col>
                      </TabPane>
                      <TabPane tabId="4">
                        <Col xs={12}>
                          {materialRequestData && activeTab == 4 && (
                            <UpdateTable
                              className="mt-5"
                              nodes={materialRequestData?.data?.data}
                              paginate={materialRequestData?.data}
                              columns={forwardManpowerColumn}
                              handlePagination={handlePaginate}
                              tableWidth="25% 25% 25% 25%"
                              mobileWidth="40% 40% 40% 40%"
                            />
                          )}
                        </Col>
                      </TabPane>
                    </TabContent>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={viewData?.id == 1 ? "xl" : "md"}
            fullscreen={viewData?.id == 1 ? "xl" : "md"}
            unmountOnClose={false}
            // className="assign-modal"
          >
            <>
              {/* <FormProvider {...methods}>
                <Form
                  id={`ev-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                > */}
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-4">
                    {viewData?.id == 1
                      ? "Assign Employee"
                      : viewData?.id == 2
                      ? "Reject Request"
                      : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
                {console.log("viewData :", viewData)}
              </ModalHeader>
              <ModalBody>
                {viewData?.id == 1 ? (
                  <AssignEMployee
                    fromDashboard={true}
                    toggle={resetAfterCreatePo}
                    projectDetails={viewData?.value}
                  />
                ) : viewData?.id == 2 ? (
                  <RejectEmp
                    fromDashboard={true}
                    projectDetails={viewData?.value}
                    toggle={resetAfterCreatePo}
                  />
                ) : (
                  ""
                )}
              </ModalBody>
              {/* </Form>
              </FormProvider> */}
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HrmsIndex
