import React, { useCallback, useEffect, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { UFInputNumber } from "../../../components/Common/Fields/Input"
import { FormFeedback } from "reactstrap"
import { postWorkProgress } from "../../../services/projectManager/projectMaster"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { useParams } from "react-router-dom"
import NumberFormat from "../../../utils/numberFormat"
import { capitalize } from "lodash"
import moment from "moment"

const RenderTable = ({
  tasks,
  isUpdateable,
  ProjectId,
  prefix = "",
  onProgressUpdate,
  onDataChange,
}) => {
  const isEditable = window.location.pathname.includes("edit")
    ? true
    : window.location.pathname.includes("view")
    ? false
    : isUpdateable
    ? isUpdateable
    : false

  console.log(
    "isUpdateable",
    isEditable,
    isUpdateable,
    window.location.pathname.includes("edit")
  )
  const {
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext()

  const debounceTimeoutRef = useRef(null)

  const { id } = useParams()

  const getExcelColumnLabel = index => {
    let label = ""
    while (index >= 0) {
      label = String.fromCharCode((index % 26) + 65) + label
      index = Math.floor(index / 26) - 1
    }
    return label
  }

  // const handleProgressChange = useCallback(async (e, rowId, type) => {
  //   const wpVal = e?.target?.value

  //   // Clear the previous timeout if it exists
  //   if (debounceTimeoutRef.current) {
  //     clearTimeout(debounceTimeoutRef.current)
  //   }

  //   // Set a new timeout to post data after delay

  //   if(wpVal != "" || !isNaN(wpVal) || wpVal > 0 || wpVal < 100)
  //   {
  //   debounceTimeoutRef.current = setTimeout(async () => {
  //     const updateVal = [
  //       {
  //         table:
  //           type == "project_material"
  //             ? "materials"
  //             : type == "project_manpower"
  //             ? "manpowers"
  //             : "subcontracts",
  //         ws_value: wpVal,
  //         id: rowId,
  //       },
  //     ]
  //     try {
  //       const updateProgress = await postWorkProgress({
  //         updates: updateVal,
  //         id: id,
  //       })
  //       toastSuccess(updateProgress?.message)
  //     } catch (err) {
  //       toastError(err?.message)
  //     }
  //   }, 500) // Adjust debounce delay as needed (500ms in this case)
  // } else {
  //   toastError(wpVal > 100 ? "Percentage Cannot exceed 100" : wpVal <= 0 ? "Percentage Cannot be negative" : "Please enter Number")
  // }
  // }, [])

  const handleProgressChange = useCallback(async (e, rowId, chkMax, type) => {
    const wpVal = e?.target?.value?.trim()

    // Clear the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
    }

    // Convert to number for validation
    const numericWpVal = parseFloat(wpVal)

    // Validation checks
    if (
      !wpVal ||
      isNaN(numericWpVal) ||
      numericWpVal < 0 ||
      numericWpVal > chkMax
    ) {
      toastError(
        isNaN(numericWpVal) || wpVal === ""
          ? "Please enter a valid number"
          : numericWpVal > chkMax
          ? `Value cannot exceed ${chkMax}`
          : "Value cannot be negative"
      )
      return
    }

    // Set a new timeout to post data after delay
    debounceTimeoutRef.current = setTimeout(async () => {
      const updateVal = [
        {
          table:
            type === "project_material"
              ? "materials"
              : type === "project_manpower"
              ? "manpowers"
              : "subcontracts",
          ws_value: numericWpVal,
          id: rowId,
        },
      ]
      if (isUpdateable) onDataChange(getValues())
      // try {
      //   const updateProgress = await postWorkProgress({
      //     updates: updateVal,
      //     id: ProjectId ? ProjectId : id,
      //   })
      //   toastSuccess(updateProgress?.message)
      // } catch (err) {
      //   toastError(err?.message)
      // }
    }, 500) // Adjust debounce delay as needed (500ms in this case)
  }, [])

  useEffect(() => {
    // reset()
    if (tasks?.length > 0) {
      tasks.forEach(task => {
        task?.project_material?.forEach((material, i) => {
          setValue(
            `project_material.${material.id}.${i}.quantity`,
            material?.quantity_for_project
              ? 0
              : material?.quantity
              ? material?.quantity
              : 0
          )
          setValue(
            `project_material.${material.id}.${i}.task_id`,
            material?.task_id
          )
          setValue(
            `project_material.${material.id}.${i}.unit_id`,
            material?.unit_id
          )
          setValue(
            `project_material.${material.id}.${i}.rate`,
            material?.price ? material?.price : material?.rate
          )
          setValue(
            `project_material.${material.id}.${i}.total_quantity`,
            material?.total_quantity
              ? material?.total_quantity
              : material?.quantity
          )
          setValue(
            `project_material.${material.id}.${i}.material_id`,
            material?.material_id
              ? material?.material_id
              : material?.products?.id
          )
        })

        task?.project_manpower?.forEach((manpower, i) => {
          setValue(
            `project_manpower.${manpower.id}.${i}.quantity`,
            manpower?.hours
              ? Number(
                  moment.duration(manpower?.hours, "minutes").asHours() -
                    Number(manpower?.alreadyCreated)
                ).toFixed(2)
              : manpower?.quantity
              ? manpower?.quantity
              : 0
          )
          setValue(
            `project_manpower.${manpower.id}.${i}.task_id`,
            manpower?.task_id
          )
          setValue(
            `project_manpower.${manpower.id}.${i}.rate`,
            manpower?.price ? manpower?.price : manpower?.rate
          )
          setValue(
            `project_manpower.${manpower.id}.${i}.total_quantity`,
            manpower?.total_quantity
              ? manpower?.total_quantity
              : manpower?.no_of_labours
          )
          setValue(
            `project_manpower.${manpower.id}.${i}.material_id`,
            manpower?.material_id
              ? manpower?.material_id
              : manpower?.category_id?.id
          )
        })

        task?.project_subcontract?.forEach((subcontract, i) => {
          setValue(
            `project_subcontract.${subcontract.id}.${i}.quantity`,
            subcontract?.quantity_for_project
              ? 0
              : subcontract?.quantity
              ? subcontract?.quantity
              : 0
          )
          setValue(
            `project_subcontract.${subcontract.id}.${i}.task_id`,
            subcontract?.task_id
          )
          setValue(
            `project_subcontract.${subcontract.id}.${i}.unit_id`,
            subcontract?.unit_id
          )
          setValue(
            `project_subcontract.${subcontract.id}.${i}.rate`,
            subcontract?.price ? subcontract?.price : subcontract?.rate
          )
          setValue(
            `project_subcontract.${subcontract.id}.${i}.total_quantity`,
            subcontract?.total_quantity
              ? subcontract?.total_quantity
              : subcontract?.duration
          )
          setValue(
            `project_subcontract.${subcontract.id}.${i}.material_id`,
            subcontract?.material_id
              ? subcontract?.material_id
              : subcontract?.category_id?.id
          )
        })
      })
      if (isUpdateable) onDataChange(getValues())
    }
  }, [tasks])

  // const state = status === 1 ? "tasks?.estimation_material" : "tasks?.project_material"

  // const handleFormSubmit = () => {
  //   const formData = getValues(); // Get all form data from react-hook-form
  //   if (onSubmit) {
  //     onSubmit(formData); // Pass the data to the parent
  //   }
  // };

  return (
    <>
      {tasks?.map((itm, i) => {
        // Generate the hierarchical numbering
        const currentNumber = prefix ? `${prefix}.${i + 1}` : `${i + 1}`

        return (
          <React.Fragment key={currentNumber}>
            <tr className="fs-13 fw-500">
              <td className="ps-1 wdt-33">
                {currentNumber} {itm?.name}
              </td>
            </tr>
            {itm?.description ? (
              <tr>
                <td className="fs-13 ps-3">{itm?.description}</td>
              </tr>
            ) : (
              <></>
            )}

            {itm?.project_material?.length > 0 && (
              <>
                <tr>
                  <td className="fs-13 fw-500 ps-3">Material</td>
                </tr>
                {itm?.project_material?.map((dt, i) => (
                  <tr key={`material-${currentNumber}-${i}`} className="fs-13">
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}){" "}
                      {capitalize(
                        dt?.products?.description
                          ? dt?.products?.description
                          : dt?.material?.description
                      )}
                    </td>
                    <td>
                      {dt?.unit?.unit_code
                        ? dt?.unit?.unit_code
                        : dt?.material?.unit?.unit_code}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity ? dt?.total_quantity : dt?.quantity
                        )
                      )}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity ? dt?.total_quantity : dt?.quantity
                        ) * Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-center">{dt?.alreadyCreated || 0}</td>
                    {isEditable ? (
                      <td className="wdt-6">
                        <UFInputNumber
                          control={control}
                          placeholder="Percentage"
                          isDecimal={true}
                          maxNum={Number(
                            dt?.total_quantity
                              ? dt?.total_quantity -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                              : dt?.quantity -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                          )}
                          name={`project_material.${dt?.id}.${i}.quantity`}
                          invalid={
                            !!errors?.project_material?.[dt?.id]?.[i]?.quantity
                          }
                          onChange={e =>
                            handleProgressChange(
                              e,
                              dt?.id,
                              Number(
                                dt?.total_quantity
                                  ? dt?.total_quantity -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                                  : dt?.quantity -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                              ),
                              "project_material"
                            )
                          }
                          className="wp-input"
                        />
                        {errors?.project_material?.[i]?.quantity && (
                          <FormFeedback invalid>
                            {errors.project_material[i].quantity.message}
                          </FormFeedback>
                        )}
                      </td>
                    ) : (
                      <td className="text-center">
                        {dt?.quantity_for_project
                          ? 0
                          : dt?.quantity
                          ? dt?.quantity
                          : 0}
                      </td>
                    )}
                    <td className="text-center">
                      {Number(dt?.alreadyCreated ? dt?.alreadyCreated : 0) +
                        Number(
                          watch(`project_material.${dt?.id}.${i}.quantity`)
                        )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.rate ? dt?.rate : dt?.price) *
                          Number(
                            watch(`project_material.${dt?.id}.${i}.quantity`)
                          ),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price) +
                          Number(dt?.rate ? dt?.rate : dt?.price) *
                            Number(
                              watch(`project_material.${dt?.id}.${i}.quantity`)
                            ),
                        3,
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}

            {itm?.project_manpower?.length > 0 && (
              <>
                <tr>
                  <td className="fs-13 fw-500 ps-3">Manpower</td>
                </tr>
                {itm?.project_manpower?.map((dt, i) => (
                  <tr key={`manpower-${currentNumber}-${i}`} className="fs-13">
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}){" "}
                      {dt?.material?.description
                        ? dt?.material?.description
                        : dt?.category_id?.description}
                    </td>
                    <td>Hrs</td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity
                            ? dt?.total_quantity
                            : dt?.no_of_labours
                        )
                      ) || 1}
                    </td>
                    <td>
                      {NumberFormat(Number(dt?.rate ? dt?.rate : dt?.price))}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity
                            ? dt?.total_quantity
                            : dt?.no_of_labours
                        ) * Number(dt?.rate ? dt?.rate : dt?.price)
                      )}
                    </td>
                    <td className="text-center">{dt?.alreadyCreated || 0}</td>
                    {isEditable ? (
                      <td className="wdt-6">
                        <UFInputNumber
                          control={control}
                          placeholder="Percentage"
                          isDecimal={true}
                          disabled={true}
                          maxNum={Number(
                            dt?.total_quantity
                              ? dt?.total_quantity -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                              : dt?.no_of_labours -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                          )}
                          name={`project_manpower.${dt?.id}.${i}.quantity`}
                          invalid={!!errors.quantity}
                          onChange={e =>
                            handleProgressChange(
                              e,
                              dt?.id,
                              Number(
                                dt?.total_quantity
                                  ? dt?.total_quantity -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                                  : dt?.no_of_labours -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                              ),
                              "project_manpower"
                            )
                          }
                          className="wp-input"
                        />
                      </td>
                    ) : (
                      <td className="text-center">
                        {dt?.quantity_for_project
                          ? 0
                          : dt?.quantity
                          ? dt?.quantity
                          : 0}
                      </td>
                    )}
                    <td className="text-center">
                      {Number(dt?.alreadyCreated ? dt?.alreadyCreated : 0) +
                        Number(
                          watch(`project_manpower.${dt?.id}.${i}.quantity`)
                        )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.rate ? dt?.rate : dt?.price) *
                          Number(
                            watch(`project_manpower.${dt?.id}.${i}.quantity`)
                          ),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price) +
                          Number(dt?.rate ? dt?.rate : dt?.price) *
                            Number(
                              watch(`project_manpower.${dt?.id}.${i}.quantity`)
                            ),
                        3,
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}

            {itm?.project_subcontract?.length > 0 && (
              <>
                <tr>
                  <td className="fs-13 fw-500 ps-3">Sub Contract</td>
                </tr>
                {itm?.project_subcontract?.map((dt, i) => (
                  <tr
                    key={`subcontract-${currentNumber}-${i}`}
                    className="fs-13"
                  >
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}){" "}
                      {dt?.material?.description
                        ? dt?.material?.description
                        : dt?.category_id?.description}
                    </td>
                    <td>{dt?.unit?.unit_code}</td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity ? dt?.total_quantity : dt?.duration
                        )
                      ) || 1}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td>
                      {NumberFormat(
                        Number(
                          dt?.total_quantity
                            ? dt?.total_quantity
                            : dt?.duration
                            ? dt?.duration
                            : 1
                        ) * Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-center">{dt?.alreadyCreated || 0}</td>
                    {isEditable ? (
                      <td className="wdt-6">
                        <UFInputNumber
                          control={control}
                          isDecimal={true}
                          maxNum={Number(
                            dt?.total_quantity
                              ? dt?.total_quantity -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                              : dt?.duration -
                                  (dt?.alreadyCreated
                                    ? Number(dt?.alreadyCreated)
                                    : 0)
                          )}
                          placeholder="Percentage"
                          name={`project_subcontract.${dt?.id}.${i}.quantity`}
                          invalid={!!errors.quantity}
                          onChange={e =>
                            handleProgressChange(
                              e,
                              dt?.id,
                              Number(
                                dt?.total_quantity
                                  ? dt?.total_quantity -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                                  : dt?.duration -
                                      (dt?.alreadyCreated
                                        ? Number(dt?.alreadyCreated)
                                        : 0)
                              ),
                              "project_subcontract"
                            )
                          }
                          className="wp-input"
                        />
                        {/* <FormFeedback invalid>
                          {
                            errors?.voucher_purchase?.[index]?.ordered_quantity
                              ?.message
                          }
                        </FormFeedback> */}
                      </td>
                    ) : (
                      <td className="text-center">
                        {dt?.quantity_for_project
                          ? 0
                          : dt?.quantity
                          ? dt?.quantity
                          : 0}
                      </td>
                    )}
                    <td className="text-center">
                      {Number(dt?.alreadyCreated ? dt?.alreadyCreated : 0) +
                        Number(
                          watch(`project_subcontract.${dt?.id}.${i}.quantity`)
                        )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.rate ? dt?.rate : dt?.price) *
                          Number(
                            watch(`project_subcontract.${dt?.id}.${i}.quantity`)
                          ),
                        3,
                        3
                      )}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(dt?.alreadyCreated || 0) *
                          Number(dt?.rate ? dt?.rate : dt?.price) +
                          Number(dt?.rate ? dt?.rate : dt?.price) *
                            Number(
                              watch(
                                `project_subcontract.${dt?.id}.${i}.quantity`
                              )
                            ),
                        3,
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {/* <tr className="light-border"></tr> */}

            {(itm?.tasks_for_project?.length > 0 || itm?.tasks?.length > 0) && (
              <RenderTable
                tasks={
                  itm?.tasks_for_project ? itm?.tasks_for_project : itm?.tasks
                }
                isUpdateable={isEditable}
                prefix={currentNumber}
                onDataChange={onDataChange}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default RenderTable
