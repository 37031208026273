import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Row,
} from "reactstrap"
import { UFMonthYear } from "../../../components/Common/Fields/Input"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LoadingButton from "../../../components/Common/LoadingButton"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import moment from "moment"

const PayrollMonth = () => {
  document.title = "Payroll Month | Ever Rise"

  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({})

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    methods,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { year_month: new Date() },
  })

  const handleRegistration = () => {}
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="bg-white w-100">
                <div className="d-flex justify-content-between pe-3">
                  <Breadcrumbs
                    title="HRMS"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      { title: "Settings", link: "/hrms" },
                      {
                        title: "Payroll Month",
                        link: "/hrms-payroll-control/add",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 me-2 btn btn-primary"}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="pt-4">
                    <Col sm={4}>
                      <FormGroup className="">
                        <UFMonthYear
                          control={control}
                          name={"year_month"}
                          invalid={!!errors.year_month}
                          month={true}
                          placeholder={"Select Date"}
                          defaultValue={moment().format("YYYY-MM-DD")}
                        />
                        <FormFeedback invalid>
                          {errors.year_month?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PayrollMonth
