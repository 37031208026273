import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import leftarrow from "../../assets/icons/breadcrumpback.svg"
import BackArrowIcon from "../../assets/icons/iconComponents/backArrowIcon"

const Breadcrumb = props => {
  const handleGoBack = () => {
    window.history.back()
  }
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h4 className="mb-0">{props.title}</h4> */}

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {/* <img src={leftarrow} width="13px" className="backarrowcrumb" alt="leftarrowicon" onClick={handleGoBack}/> */}
              <div className="pe-2">
                <BackArrowIcon onClick={handleGoBack} />
              </div>
              {/* <span className="vertiline"></span> */}
              {props?.breadcrumbObject?.map((dt, index) => (
                <BreadcrumbItem
                  key={`crumb-${index}`}
                  className={
                    props?.breadcrumbObject?.length > index + 1 ? "" : "active"
                  }
                >
                  <Link to={dt?.link}>{dt.title}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
