import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
  UFMonthYear,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { fetchPurchase } from "../../../services/procurement/purchaseOrder"
import { fetchGRN, postGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import GRNCastNo from "../../../components/Common/chooseFields/grnCosting"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import CreditPeriod from "../../../components/Common/chooseFields/creditPeriod"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import FinanceSubCat from "../../../components/Common/chooseFields/financeSubCat"
import {
  fetchCostCenterData,
  fetchGRNCosting,
  postAccPayables,
} from "../../../services/finance/grnCosting"
import Customer from "../../../components/Common/chooseFields/customer"
import CostCenterList from "../../../components/Common/chooseFields/costCenterList"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import { postFinanceSubCategory } from "../../../services/finance/finance"
import FinanceSubCatId from "../../../components/Common/chooseFields/financeSubCatId"
import AccountsPayablesList from "../../../components/Common/chooseFields/accountsPayables"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import GrnCostingList from "../../../components/Common/chooseFields/grnCostingFilterList"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { searchHrmsPayrollGenerate } from "../../../services/Hrms/hrms"
import NumberFormat from "../../../utils/numberFormat"

const schema = yup.object().shape({})

const EditAccountPayables = ({ fromDashboard, poValue, toggle }) => {
  document.title = "Account Payable | Ever Rise"

  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const [accReceivableVal, setAccReceivableVal] = useState()
  const [refresh, setRefresh] = useState(0)
  const [activeTab, setActiveTab] = useState("1")
  const [grnData, setGRNData] = useState()
  const [page, setPage] = useState("")
  const [projectCheck, setProjectCheck] = useState([])
  const [empCheck, setEmpCheck] = useState([])
  const [grnList, setGRNList] = useState()
  const [hrmsist, setHrmsList] = useState()
  const [hrmsData, setHRMSData] = useState()

  const location = useLocation()

  const toggles = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const loggedUser = useSelector(state => state.Login.loggedUser)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      year_month: new Date(),
    },
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const fetchGRN = async () => {
      const response = await fetchGRNCosting({
        params: `${"searchText"}${
          watch("project_id") ? `&project_id=${watch("project_id")?.id}` : ""
        }${`&status=1`}${page ? `&page=${page}` : ""}${
          watch("account_id")
            ? `${
                watch("account_id")?.is_company === 1 ? "&company_search=" : ""
              }`
            : ""
        }`,
      })
      setGRNData(response)
    }
    if (watch("account_id")) {
      fetchGRN()
    }
  }, [watch("project_id"), watch("account_id"), page, activeTab == 1])

  useEffect(() => {
    const controlData = getValues()
    const fetchClass = async () => {
      const response = await searchHrmsPayrollGenerate({
        params: `${"&searchText"}${
          controlData?.year_month
            ? `&year_month=${moment(controlData?.year_month).format(
                "YYYY-MM-DD"
              )}`
            : ""
        }${
          controlData?.designation_id?.value
            ? `&designation_id=${controlData?.designation_id?.value}`
            : ""
        }${page ? `&page=${page}` : ""}${`&status=1`}`,
      })
      setHRMSData(response)
    }
    fetchClass()
  }, [page, activeTab == 2, watch("year_month")])
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        grn_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        grn_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handleCostChange(poValue)
    }
  }, [fromDashboard])
  const handleRegistration = async data => {

    const payablesArr =
      activeTab == 1
        ? grnList?.map(dt => ({
            project_id: data?.project_id?.id,
            procurement_id: dt?.id,
            account_id: data?.account_id?.id,
            amount: dt?.pay,
            grn_id: dt?.id,
          }))
        : activeTab == 2
        ? hrmsist?.map(dt => ({
            amount: dt?.net_pay,
            hrms_id: dt?.id,
            account_id: data?.account_id?.id,
          }))
        : [
            {
              description: data?.description,
              amount: data?.value,
              account_id: data?.account_id?.id,
            },
          ]

    try {
      setIsLoading(true)
      const update = await postAccPayables({
        payables: payablesArr,
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/finance-account-payables")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinanceCat = () => {
    const data = getValues()
    reset({ ...data, subcategory_id: null, account_id: null })
  }
  const handleFinanceSubCat = () => {
    const data = getValues()
    reset({ ...data, account_id: null })
  }

  const handleGrnVal = () => {
    const data = getValues()
    setValue("value", data?.procurement_id?.pay)
  }

  const handleCodeChange = () => {
    const data = getValues()
    reset({ ...data, cos_id: null })
  }

  const handleCostChange = () => {
    const { cos_id } = getValues()
    fetchPotData(cos_id?.value)
    setIdPO(cos_id?.value)
  }
  const fetchPotData = async idData => {
    const response = await fetchCostCenterData(idData)
    setAccReceivableVal(response)
    if (idData == response.data?.id) {
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_id: response.data?.project_id,
        po_date: moment(response.data?.created_at).format("DD-MM-YYYY"),
        mr_no: response.data?.material_request_id?.material_requests_code,
        mr_date: moment(response.data?.material_request_id?.created_at).format(
          "DD-MM-YYYY"
        ),
        supplier_name: response.data?.supplier?.name,
        pay_terms: response.data?.pay_terms,
      })
    }
  }

  const columns = [
    {
      headerCell: item => (
        <div lg={"auto"} onClick={() => handleAllProjectChk(item)}>
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_material"}
            checked={item.every(item => projectCheck.includes(item.id))}
          />
        </div>
      ),
      checkedHead: true,
      renderCell: item => {
        return (
          <div lg={"auto"} onClick={() => handleProjectCheck(item?.id)}>
            <>
              <input
                type="checkbox"
                className="ever-rise-checkbox"
                name={"is_material"}
                checked={projectCheck?.includes(item?.id)}
              />
            </>
          </div>
        )
      },
    },
    {
      label: "GRN Cost",
      renderCell: item => {
        return (
          <>
            <div>{item?.code}</div>
          </>
        )
      },
    },
    {
      label: <div className="text-end pe-5">Amount</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat(Number(item?.pay), 3, 3)}</div>,
    },
    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
  ]
  const columnHRMS = [
    {
      headerCell: item => (
        <div lg={"auto"} onClick={() => handleAllEmpChk(item)}>
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_material"}
            checked={item.every(item => empCheck.includes(item.id))}
          />
        </div>
      ),
      checkedHead: true,
      renderCell: item => {
        return (
          <div lg={"auto"} onClick={() => handleEmpCheck(item?.id)}>
            <>
              <input
                type="checkbox"
                className="ever-rise-checkbox"
                name={"is_material"}
                checked={empCheck?.includes(item?.id)}
              />
            </>
          </div>
        )
      },
    },
    {
      label: "Employee Code",
      renderCell: item => {
        return (
          <>
            <div>{item?.employee?.employee_code}</div>
          </>
        )
      },
    },
    {
      label: "Employee Name",
      renderCell: item => item?.employee?.first_name,
    },
    {
      label: <div className="text-end pe-5">Amount</div>,
      renderCell: item => <div>{NumberFormat(Number(item?.net_pay), 3, 3)}</div>,
    },
  ]

  const handleAllProjectChk = dt => {
    const idsToAdd = dt.map(item => item.id)
    if (idsToAdd.every(id => projectCheck.includes(id))) {
      setProjectCheck([])
      //   setFilteredId([])
    } else {
      setProjectCheck(prevProjectCheck => [...prevProjectCheck, ...idsToAdd])
      //   setFilteredId(commonIds)
    }
  }

  const handleProjectCheck = id => {

    if (projectCheck.includes(id)) {
      const updatedprojectCheck = projectCheck.filter(item => item !== id)
      setProjectCheck(updatedprojectCheck)
      // setFilteredId(updatedprojectCheck)
    } else {
      setProjectCheck(prevProjectCheck => [...prevProjectCheck, id])
      // setFilteredId(prevEmployeeCheck => [...prevEmployeeCheck, id])
    }
  }

  const handleAllEmpChk = dt => {
    const idsToAdd = dt.map(item => item.id)
    if (idsToAdd.every(id => empCheck.includes(id))) {
      setEmpCheck([])
      //   setFilteredId([])
    } else {
      setEmpCheck(prevEmpCheck => [...prevEmpCheck, ...idsToAdd])
      //   setFilteredId(commonIds)
    }
  }

  const handleEmpCheck = id => {

    if (empCheck.includes(id)) {
      const updatedEmpCheck = empCheck.filter(item => item !== id)
      setEmpCheck(updatedEmpCheck)
      // setFilteredId(updatedEmpCheck)
    } else {
      setEmpCheck(prevEmpCheck => [...prevEmpCheck, id])
      // setFilteredId(prevEmployeeCheck => [...prevEmployeeCheck, id])
    }
  }

  const totalPrice =
    grnList?.reduce((total, item) => total + Number(item?.pay), 0) || 0
  const totalPay =
    hrmsist?.reduce((total, item) => total + Number(item?.net_pay), 0) || 0
  useEffect(() => {
    if (projectCheck) {
      setGRNList(
        grnData?.data?.data.filter(item => projectCheck.includes(item.id))
      )
    }
    if (empCheck) {
      setHrmsList(
        hrmsData?.data?.data.filter(item => empCheck.includes(item.id))
      )
    }
  }, [projectCheck, empCheck])

  const handlePaginate = page => {
    setPage(page)
  }

  const handleProjectChange = () => {
    setProjectCheck([])
    setGRNList()
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Finance"
                      breadcrumbObject={[
                        { title: "Finance", link: "/finance" },
                        {
                          title: "Account Payables",
                          link: "/finance-account-payables",
                        },
                        {
                          title: id
                            ? "Edit Account Payables"
                            : "Add Account Payables",
                          link: "",
                        },
                      ]}
                    />
                  )}

                  <LoadingButton
                    form={`request-form`}
                    color={"primary"}
                    loading={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Pay"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceCat
                          control={control}
                          placeholder="Enter the Category"
                          name={"category_id"}
                          invalid={!!errors.category_id}
                          onSelectChange={handleFinanceCat}
                        />
                        <FormFeedback invalid>
                          {errors.category_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Sub-Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceSubCatId
                          control={control}
                          placeholder="Sub-Category"
                          name={"subcategory_id"}
                          invalid={!!errors.subcategory_id}
                          selectedCategory={watch("category_id")}
                          onSelectChange={handleFinanceSubCat}
                        />
                        <FormFeedback invalid>
                          {errors.subcategory_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Accounts<span className="mandatory">*</span>
                        </Label>
                        <AccountsPayablesList
                          control={control}
                          placeholder="Accounts"
                          name={"account_id"}
                          invalid={!!errors.account_id}
                          selectedSubCategory={watch("subcategory_id")}
                        />
                        <FormFeedback invalid>
                          {errors.account_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {watch("account_id") ? (
                    <>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "1" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggles("1")
                              setProjectCheck([])
                            }}
                          >
                            Procurement
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "2" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggles("2")
                              setProjectCheck([])
                            }}
                          >
                            HRMS
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "3" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggles("3")
                            }}
                          >
                            Others
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="pt-3">
                        <TabPane tabId={activeTab}>
                          {watch("account_id")?.is_company === 0 &&
                          activeTab == 1 ? (
                            <>
                              <Row>
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Project Code
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <ProjectNo
                                      control={control}
                                      name={"project_id"}
                                      invalid={!!errors.project_id}
                                      onSelectChange={handleProjectChange}
                                    />
                                    <FormFeedback invalid>
                                      {errors.project_id?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {watch("project_id") ? (
                                <Row>
                                  <Col sm={12} lg={8}>
                                    <Card className="me-2 px-2 pb-2 payable-ht">
                                      <Row className="mt-2 ever-rise-table">
                                        <Col xs={12}>
                                          {grnData && (
                                            <UpdateTable
                                              className="mt-5"
                                              nodes={grnData?.data?.data}
                                              paginate={grnData?.data}
                                              columns={columns}
                                              CurrentPage={page}
                                              handlePagination={handlePaginate}
                                              tableWidth="5% 30% 30% 35%"
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                  <Col sm={12} lg={4}>
                                    <Card className="me-2 px-2 pb-2 payable-ht">
                                      <Row className="py-2">
                                        <Col sm={8}>
                                          <FormGroup className="mb-4">
                                            <ProjectNo
                                              control={control}
                                              name={"project_id"}
                                              invalid={!!errors.project_id}
                                              disabled={true}
                                            />
                                            <FormFeedback invalid>
                                              {errors.project_id?.message}
                                            </FormFeedback>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row className="pb-2 pay-slip-summary">
                                        <Col sm={12}>
                                          <table className="estimation-table mb-4">
                                            <thead>
                                              <tr>
                                                <th>Grn Costing N0</th>
                                                <th className="text-end pe-5">Price</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {grnList?.map((dt, i) => (
                                                <tr key={`grnList_${i}`}>
                                                  <td>{dt?.code}</td>
                                                  <td className="text-end pe-5">{dt?.pay}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </Col>
                                      </Row>
                                      <Row className="px-2">
                                        <Col
                                          sm={8}
                                          className="d-flex justify-content-end"
                                        >
                                          Total
                                        </Col>
                                        <Col sm={4}>{totalPrice} AED</Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                </Row>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : watch("account_id")?.is_company === 1 &&
                            activeTab == 1 ? (
                            <Row>
                              <Col sm={12} lg={8}>
                                <Card className="me-2 px-2 pb-2 payable-ht">
                                  <Row className="mt-2 ever-rise-table">
                                    <Col xs={12}>
                                      {grnData && (
                                        <UpdateTable
                                          className="mt-5"
                                          nodes={grnData?.data?.data}
                                          paginate={grnData?.data}
                                          columns={columns}
                                          CurrentPage={page}
                                          handlePagination={handlePaginate}
                                          tableWidth="5% 30% 30% 35%"
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col sm={12} lg={4}>
                                <Card className="me-2 px-2 pb-2 payable-ht">
                                  <Row className="py-2 pay-slip-summary">
                                    <Col sm={12}>
                                      <table className="estimation-table mb-4">
                                        <thead>
                                          <tr>
                                            <th>Grn Costing N0</th>
                                            <th className="text-end pe-5">Price</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {grnList?.map((dt, i) => (
                                            <tr key={`grnList_${i}`}>
                                              <td>{dt?.code}</td>
                                              <td className="text-end pe-5">{dt?.pay}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </Col>
                                  </Row>
                                  {/* {grnList?.map((dt, i) => (
                                      <Row
                                        className="p-2 summary-body"
                                        key={`grnList_${i}`}
                                      >
                                        <Col sm={8}>{dt?.code}</Col>
                                        <Col sm={4}>{dt?.pay}</Col>
                                      </Row>
                                    ))} */}
                                  <Row className="px-2">
                                    <Col
                                      sm={8}
                                      className="d-flex justify-content-end"
                                    >
                                      Total
                                    </Col>
                                    <Col sm={4}>{totalPrice} AED</Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          ) : activeTab == 2 ? (
                            <>
                              <Row>
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>
                                      Year and Month
                                      <span className="mandatory">*</span>
                                    </Label>
                                    <UFMonthYear
                                      control={control}
                                      name={"year_month"}
                                      invalid={!!errors.year_month}
                                      month={true}
                                      placeholder={"Select Date"}
                                    />
                                    <FormFeedback invalid>
                                      {errors.year_month?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} lg={8}>
                                  <Card className="me-2 px-2 pb-2 payable-ht">
                                    <Row className="mt-2 ever-rise-table">
                                      <Col xs={12}>
                                        {hrmsData && (
                                          <UpdateTable
                                            className="mt-5"
                                            nodes={hrmsData?.data?.data}
                                            paginate={hrmsData?.data}
                                            columns={columnHRMS}
                                            CurrentPage={page}
                                            handlePagination={handlePaginate}
                                            tableWidth="5% 30% 30% 35%"
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col sm={12} lg={4}>
                                  <Card className="me-2 px-2 pb-2 payable-ht">
                                    {/* <Row className="py-2">
                                      <Col sm={8}>
                                        <FormGroup className="mb-4">
                                          <UFMonthYear
                                            control={control}
                                            name={"year_month"}
                                            invalid={!!errors.year_month}
                                            month={true}
                                            placeholder={"Select Date"}
                                          />
                                          <FormFeedback invalid>
                                            {errors.year_month?.message}
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                    </Row> */}
                                    <Row className="pt-2 pb-2 pay-slip-summary">
                                      <Col sm={12}>
                                        <table className="estimation-table mb-4">
                                          <thead>
                                            <tr>
                                              <th>Employee Code</th>
                                              <th className="text-end pe-5">Price</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {hrmsist?.map((dt, i) => (
                                              <tr key={`hrmsist_${i}`}>
                                                <td>
                                                  {dt?.employee?.employee_code}
                                                </td>
                                                <td className="text-end pe-5">{dt?.net_pay}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                    {/* {grnList?.map((dt, i) => (
                                      <Row
                                        className="p-2 summary-body"
                                        key={`grnList_${i}`}
                                      >
                                        <Col sm={8}>{dt?.code}</Col>
                                        <Col sm={4}>{dt?.pay}</Col>
                                      </Row>
                                    ))} */}
                                    <Row className="px-2">
                                      <Col
                                        sm={8}
                                        className="d-flex justify-content-end"
                                      >
                                        Total
                                      </Col>
                                      <Col sm={4}>{totalPay} AED</Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row>
                              <Col sm={12} lg={12}>
                                <Card className="px-2 pb-2 others-ht">
                                  <Row className="py-2">
                                    <Col sm={4}>
                                      <FormGroup className="mb-4">
                                        <Label>
                                          Description
                                          <span className="mandatory">*</span>
                                        </Label>
                                        <UFEditor
                                          control={control}
                                          placeholder="Enter the description"
                                          name={"description"}
                                          rows="5"
                                        />
                                        <FormFeedback invalid>
                                          {errors.description?.message}
                                        </FormFeedback>
                                      </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                      <FormGroup className="mb-4">
                                        <Label>
                                          Value
                                          <span className="mandatory">*</span>
                                        </Label>
                                        <UFInput
                                          control={control}
                                          placeholder="Enter the Value"
                                          name={"value"}
                                          invalid={!!errors.value}
                                        />
                                        <FormFeedback invalid>
                                          {errors.value?.message}
                                        </FormFeedback>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                        {/* <TabPane tabId="2">

                </TabPane> */}
                      </TabContent>
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditAccountPayables
