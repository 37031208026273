import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import Location from "../../../components/Common/chooseFields/location"
import lightTrash from "../../../assets/icons/light-trash.svg"
import Priority from "../../../components/Common/chooseFields/priority"
import {
  fetchMRRequest,
  fetchRequest,
  postMaterialRequest,
  postMaterialRequestFinance,
  postRfq,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import Productcode from "../../../components/Common/chooseFields/Product"
import { fetchProjecrtMaster } from "../../../services/projectManager/projectMaster"
import MaterialProjectNo from "../../../components/Common/chooseFields/materailProject"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import MaterialProductList from "../../../components/Common/chooseFields/materialProductList"
import { useSelector } from "react-redux"
import MaterialRequest from "../../../components/Common/chooseFields/materialrequest"
import SupplierList from "../../../components/Common/chooseFields/supplier"

const schema = yup.object().shape({
  //   project_code: yup.mixed().required("project code is required"),
  //   request_date: yup
  //     .mixed()
  //     .test(
  //       "is-future-date",
  //       "Make sure the request date is forward-looking",
  //       function (value) {
  //         if (!value) return true
  //         const currentDate = moment()
  //         const selectedDate = moment(value)
  //         return selectedDate.isSameOrAfter(currentDate, "day")
  //       }
  //     )
  //     .required("Delivery Date is required"),
  //   exp_Delivery_date: yup
  //     .mixed()
  //     .test(
  //       "is-future-date",
  //       "Make sure the exp delivery date is forward-looking",
  //       function (value) {
  //         if (!value) return true
  //         const currentDate = moment()
  //         const selectedDate = moment(value)
  //         return selectedDate.isSameOrAfter(currentDate, "day")
  //       }
  //     )
  //     .required("Delivery Date is required"),
  // requested_by: yup.string().required("requested by is required"),
  // contact_number: yup
  //   .string()
  //   .matches(/^([+]?)?[0-9]+$|^$/, "Mobile no must be only digits")
  //   .required("contact number is required"),
  //   material_req: yup.array().of(
  //     yup.object().shape({
  //       product_id: yup.mixed().required("product code is required"),
  //       description: yup.string().required("description is required"),
  //       // priority: yup.mixed().required("priority is required"),
  //       quantity: yup
  //         .string()
  //         .matches(
  //           /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
  //           "Quantity must be a non-negative number and cannot be zero"
  //         )
  // .test(
  //   "max",
  //   "Received quantity must not exceed the ordered quantity",
  //   function (value) {
  //     const { initial_quantity } = this.parent
  //     const { received_quantity } = this.parent
  //     const validateqty = initial_quantity - received_quantity
  //     return parseInt(value) <= validateqty
  //   }
  // )
  // .required("Quantity is required"),
  // })
  //   ),
})

const EditRfq = ({ fromDashboard, projectValue, toggle }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const now = moment()
  const [isLoading, setIsLoading] = useState(false)
  const [isDraftLoading, setIsDraftLoading] = useState(false)
  const [projectID, setProjectID] = useState()
  const [compareIds, setCompareIds] = useState([])
  const [viewData, setViewData] = useState([])

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const location = useLocation()

  console.log(`location`, location)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_code: null,
      contact_number: "",
      requested_by: "",
      request_date: moment(now, "YYYY-MM-DD").toDate(),
      delivery_location: null,
      material_req: [
        {
          id: "",
          product_id: null,
          quantity: "",
          description: "",
          // priority: null,
          initial_quantity: "",
          received_quantity: "",
        },
      ],
    },
  })
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  // })
  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "material_req",
  })

  const handleRegistration = async data => {
    const mrIds = data?.material_request_ids?.map(dt => dt?.value)
    const vendorIds = data?.vendor_ids?.map(dt => dt?.value)
    try {
      setIsLoading(true)
      const update = await postRfq({
        material_request_ids: mrIds,
        vendor_ids: vendorIds,
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/procurement-rfq")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setIsDraftLoading(false)
    }
  }

  const handleProjectChange = () => {
    const { project_code } = getValues()
    fetchProjectData(project_code?.value)
    setProjectID(project_code?.value)
  }

  useEffect(() => {
    if (fromDashboard) {
      setValue("project_code", projectValue)
      fetchProjectData(id)
      setProjectID(id)
    }
    const fetchMrEdit = async id => {
      const responce = await fetchMRRequest(id)
      console.log(`responce edit`, responce)
      const materialsArr = responce?.data?.materials?.map(dt => ({
        product_id: {
          label: dt?.product?.product_code,
          value: dt?.product?.id,
        },
        quantity: dt?.requested_quantity,
        description: dt?.product?.description,
      }))
      reset({
        ...responce?.data,
        project_code: {
          label: responce?.data?.project?.project_code,
          value: responce?.data?.project?.id,
        },
        project_name: responce?.data?.project?.project_name,
        exp_Delivery_date: responce?.data?.exp_Delivery_date,
        material_req: materialsArr,
      })
    }
    if (id) {
      fetchMrEdit(id)
    }
  }, [fromDashboard, id])

  const fetchProjectData = async idData => {
    const response = await fetchProjecrtMaster(idData)
    if (idData == response.data?.id) {
      const Requestarray = response.data?.project_material
        ?.map(dt => {
          const projectMrQuantitySum = dt.project_mr.reduce(
            (total, mr) => total + parseFloat(mr.quantity),
            0
          )

          const alreadyReceived = dt.project_mr.length
            ? dt.quantity - projectMrQuantitySum
            : 0
          const balanceQty = dt.project_mr.length ? projectMrQuantitySum : 0
          const quantity = dt.project_mr.length ? alreadyReceived : dt.quantity
          return {
            product_id: {
              value: dt?.product_code?.id,
              label: dt?.product_code?.product_code,
              storeCount: dt?.products?.storeCount,
            },
            description: dt?.description,
            quantity: quantity,
            initial_quantity: dt?.quantity,
            received_quantity: balanceQty,
            unit_id: dt?.unit?.id,
          }
        })
        .filter(item => item.quantity !== 0)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_name: response.data?.project_name,
        material_req: Requestarray,
        delivery_location: response.data?.location?.name,
      })
    }
  }

  useEffect(() => {
    if (location?.state?.id) {
      reset({
        material_request_ids: location?.state && [
          {
            label: location?.state?.code,
            value: location?.state?.id,
          },
        ],
      })
      handleMatrial(location?.state?.id)
    }
  }, [location])
  const handleMatrial = async id => {
    const selectedIds = watch(`material_request_ids`)
    // let compareIds = []; // Initialize compareIds
    const ids = selectedIds?.map(dt => dt?.value)

    // Filter out IDs that are not in compareIds
    const newIds = ids?.filter(id => !compareIds.includes(id))

    if (newIds && newIds.length > 0) {
      try {
        const response = await fetchMRRequest(newIds)
        setCompareIds([...compareIds, ...newIds])

        setViewData([...viewData, ...response?.data?.materials])
      } catch (error) {
        console.error(`Error fetching material requests:`, error)
      }
    } else {
      setViewData(prevData =>
        prevData.filter(item => ids.includes(item.material_request?.id))
      )
      setCompareIds(prevIds => prevIds.filter(id => ids.includes(id)))
    }
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {!fromDashboard ? (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        {
                          title: "Request For Quote",
                          link: "/procurement-rfq",
                        },
                        {
                          title: id
                            ? "Edit Request For Quote"
                            : "Add Request For Quote",
                          link: "",
                        },
                      ]}
                    />
                  ) : (
                    <div></div>
                  )}

                  <LoadingButton
                    form={`request-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 ms-2 btn btn-primary"}
                    type="submit"
                  >
                    {fromDashboard ? "Save" : id ? "Update" : "Save & Share"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-1 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          MR no<span className="mandatory">*</span>
                        </Label>
                        <MaterialRequest
                          control={control}
                          name={"material_request_ids"}
                          placeholder={"MR No"}
                          target={fromDashboard ? ".modal" : "body"}
                          isMultiple={true}
                          onSelectChange={e => handleMatrial(e?.id)}
                          isApproved={true}
                          clearable={false}
                        />
                        <FormFeedback invalid>
                          {errors.material_request_ids?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"request_date"}
                          invalid={!!errors.request_date}
                        />
                        <FormFeedback invalid>
                          {errors.request_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Supplier Name <span className="mandatory">*</span>
                        </Label>
                        <SupplierList
                          control={control}
                          placeholder="select"
                          name={"vendor_ids"}
                          invalid={!!errors.vendor_ids}
                          target=".modal"
                          isMultiple={true}
                        />
                        <FormFeedback invalid>
                          {errors.vendor_ids?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  {viewData?.length ? (
                    <>
                      <hr className="m-0" />
                      <Row className="py-2 mt-1">
                        <h5>Request for Quote Details</h5>
                      </Row>
                      <Row className="mb-4">
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Product Name</th>
                                <th>Ref. MR No</th>
                                <th>Quantity </th>
                                {/* <th></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {viewData.map((item, index) => (
                                <tr key={`materialFields${index}`}>
                                  <td className="ps-1">
                                    <div className="fs-13">{index + 1}</div>
                                    {/* <MaterialProductList
                                      control={control}
                                      placeholder="Product Code"
                                      projectid={projectID}
                                      selectedProduct={watch(`material_req`)}
                                      index={index}
                                      name={`material_req.${index}.product_id`}
                                      invalid={
                                        !!errors?.material_req?.[index]
                                          ?.product_id
                                      }
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.material_req?.[index]
                                          ?.product_id?.message
                                      }
                                    </FormFeedback> */}
                                  </td>
                                  <td className="ps-1">
                                    <div className="fs-13">
                                      {item?.product?.description
                                        ? item?.product?.description
                                        : "-"}
                                    </div>
                                    {/* <UFInput
                                      control={control}
                                      placeholder="Quantity"
                                      name={`material_req.${index}.quantity`}
                                      invalid={
                                        !!errors?.material_req?.[index]
                                          ?.quantity
                                      }
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.material_req?.[index]?.quantity
                                          ?.message
                                      }
                                    </FormFeedback> */}
                                  </td>
                                  <td className="ps-1">
                                    <div className="fs-13">
                                      {item?.material_request?.code
                                        ? item?.material_request?.code
                                        : "-"}
                                      {/* {watch(`material_req.${index}.product_id`)
                                        ?.storeCount
                                        ? watch(
                                            `material_req.${index}.product_id`
                                          )?.storeCount
                                        : 0} */}
                                    </div>
                                  </td>
                                  <td className="ps-1">
                                    <div className="fs-13">
                                      {item?.requested_quantity
                                        ? item?.requested_quantity
                                        : "-"}
                                    </div>
                                    {/* <UFInput
                                      control={control}
                                      placeholder="Description"
                                      name={`material_req.${index}.description`}
                                      invalid={
                                        !!errors?.material_req?.[index]
                                          ?.description
                                      }
                                    />
                                    <FormFeedback invalid>
                                      {
                                        errors?.material_req?.[index]
                                          ?.description?.message
                                      }
                                    </FormFeedback> */}
                                  </td>

                                  {/* <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => materialRemove(index)}
                                  />
                                </div>
                              </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* {!fromDashboard && (
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-between"
                        >
                          <Button
                            color="secondary"
                            className="addButton mt-2"
                            onClick={() =>
                              materialAppend({
                                product_id: "",
                                description: "",
                                quantity: "",
                                // priority: "",
                              })
                            }
                          >
                            Add Row
                          </Button>
                        </Col>
                      )} */}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditRfq
