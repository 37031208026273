import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
// Redux
import { Link } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.svg"
import logolight from "../../assets/images/logo-light.png"
import SignImage from "../../assets/icons/sign-page.png"

//Import config
import { facebook, google } from "../../config"
import LoadingButton from "../../components/Common/LoadingButton"
import passwordlogo from "../../assets/icons/password-eye-close.svg"
import passwordlogovisible from "../../assets/icons/password-eye-open.svg"
import logiicon from "../../assets/icons/EverRiseLogo.svg"

const Login = props => {
  document.title = " Login | Ever Rise"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
    loading: login.loading,
  }))

  const { error, loading } = useSelector(LoginProperties)
  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  }, [])

  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  return (
    <React.Fragment>
      <div className="account-pages login-div">
        <Row className="align-items-center justify-content-start m-0">
          <Col md={6} lg={6} xl={6}>
            <img src={SignImage} className="sign-image p-2  login-img" alt="icon"/>
          </Col>
          <Col md={4} lg={4} xl={4}>
            <CardBody className="p-4">
              <div className="p-2 mt-4">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                <div className="logstyle d-flex flex-column p-0">
                <div>
                <img src={logiicon} className="logiconstyle" alt="logi" />
                </div>
                <div>
                  <h5 className="text-primary pb-3 fw-5">Sign In </h5>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  <div className="mb-3">
                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      className="login-input"
                      placeholder="johnsondoe@nomail.com"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-4 position-relative passBox">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      className="login-input"
                      value={validation.values.password || ""}
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    <label onClick={togglePasswordVisiblity} className="pass">
                      {passwordShown ? (
                        <img
                          src={passwordlogovisible}
                          alt="password"
                          role="button"
                        />
                      ) : (
                        <img src={passwordlogo} alt="password" role="button" />
                      )}
                    </label>
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mt-4">
                    <LoadingButton
                      color={"primary"}
                      className={
                        "btn btn-primary w-100 waves-effect waves-light login-input"
                      }
                      loading={loading}
                      disabled={loading}
                      type="submit"
                    >
                      Sign In
                    </LoadingButton>
                  </div>
                  </div>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
