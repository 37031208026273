import React from "react"

const ProcurementIcon = ({ color = "#8B909A", size = 18, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <g clip-path="url(#clip0_4003_48385)">
        <path
          d="M11.1562 0.5H8.5C7.40151 0.5 6.50781 1.3937 6.50781 2.49219V5.14844C6.50781 6.24693 7.40151 7.14062 8.5 7.14062H11.1562C12.2547 7.14062 13.1484 6.24693 13.1484 5.14844V2.49219C13.1484 1.3937 12.2547 0.5 11.1562 0.5ZM11.8203 5.14844C11.8203 5.5146 11.5224 5.8125 11.1562 5.8125H8.5C8.13384 5.8125 7.83594 5.5146 7.83594 5.14844V2.49219C7.83594 2.12602 8.13384 1.82812 8.5 1.82812H9.16406V2.49219C9.16406 2.85895 9.46136 3.15625 9.82812 3.15625C10.1949 3.15625 10.4922 2.85895 10.4922 2.49219V1.82812H11.1562C11.5224 1.82812 11.8203 2.12602 11.8203 2.49219V5.14844Z"
          fill="currentColor"
        />
        <path
          d="M14.0101 12.4234C14.831 12.3413 15.5879 11.9592 16.1415 11.3475C16.6951 10.7358 17 9.94456 17 9.11957V5.14844C17 4.78168 16.7027 4.48438 16.3359 4.48438H15.0078C14.6411 4.48438 14.3438 4.78168 14.3438 5.14844C14.3438 5.5152 14.6411 5.8125 15.0078 5.8125H15.6719V9.11957C15.6719 10.1474 14.9006 10.9996 13.8763 11.102L3.98438 12.1159V5.8125H4.6484C5.01517 5.8125 5.31247 5.5152 5.31247 5.14844C5.31247 4.78168 5.01517 4.48438 4.6484 4.48438H3.98438V3.82031C3.98438 1.98949 2.49492 0.5 0.664062 0.5C0.297301 0.5 0 0.797301 0 1.16406C0 1.53082 0.297301 1.82812 0.664062 1.82812C1.76255 1.82812 2.65625 2.72182 2.65625 3.82031V14.1797C2.65625 15.2782 3.54995 16.1719 4.64844 16.1719H5.3125V16.8359C5.3125 17.2027 5.6098 17.5 5.97656 17.5C6.34332 17.5 6.64062 17.2027 6.64062 16.8359V16.1719H14.3438V16.8359C14.3438 17.2027 14.6411 17.5 15.0078 17.5C15.3746 17.5 15.6719 17.2027 15.6719 16.8359V16.1719H16.3359C16.7027 16.1719 17 15.8746 17 15.5078C17 15.1411 16.7027 14.8438 16.3359 14.8438H4.64844C4.28227 14.8438 3.98438 14.5459 3.98438 14.1797V13.451L14.0101 12.4234Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4003_48385">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProcurementIcon
