import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import arrow from "../../assets/icons/arrow.svg"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import cardIon from "../../assets/icons/card-icon.png"
const Settings = () => {
  document.title = "Settings | Everrise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const location = useLocation()

  const menuItem = loggedUser?.smenu?.find(
    item => item.linkTo === `${location?.pathname}`
  )

  const cardData = [
    { name: "Total Series", amount: "1000", icon: cardIon },
    { name: "Total  Class", amount: "2000", icon: cardIon },
    { name: "Total  Category", amount: "3000", icon: cardIon },
    { name: "Current Financial Year", amount: "2024-2025", icon: cardIon },
  ]

  return (
    <div className="page-content">
      <Container fluid className="">
        <Card className="invoice-main-card">
          <Row className="mt-3 mx-1 mb-0">
            {cardData?.length &&
              cardData.map((ele, key) => (
                <Col md={4} xl={3} sm={6} xs={12} key={key}>
                  <Card className="rounded-0 border-0 invoice-card">
                    <CardBody className={`card-body-${key}`}>
                      <Row>
                        <Col lg={"auto"}>
                          <img src={ele?.icon} />
                        </Col>
                        <Col
                          lg={"auto"}
                          className="d-flex justify-content-center flex-column"
                        >
                          <div className="supplier-td-head pb-1">
                            {ele?.name}
                          </div>
                          <div className="dark-text fw-500 text-18">
                            {ele?.amount}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
          <hr />
          <Row className="mt-4 mx-1 mb-3">
            <Col lg={4} sm={6} xs={12}>
              <div className="sub-menu-title mb-3">Your Shortcuts</div>
              <Row>
                <Col xs={12}>
                  {menuItem?.submenus?.map(dt => (
                    <>
                      {loggedUser?.permission?.some(
                        permissionItem =>
                          permissionItem?.id === dt.id &&
                          permissionItem?.is_menu == 1 &&
                          permissionItem?.tagline == "Your Shortcuts"
                      ) && (
                        <Link to={dt?.linkTo}>
                          <div className="mb-1">
                            <label className="sub-menu-link" role="button">
                              {dt?.label}
                            </label>
                            <img src={arrow} alt="" width="7" />
                          </div>
                        </Link>
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={6} xs={12}>
              <div className="sub-menu-title mb-3">User Management</div>
              <Row>
                <Col xs={12}>
                  {menuItem?.submenus?.map(dt => (
                    <>
                      {loggedUser?.permission?.some(
                        permissionItem =>
                          permissionItem?.id === dt.id &&
                          permissionItem?.is_menu == 1 &&
                          permissionItem?.tagline == "User Management"
                      ) && (
                        <Link to={dt?.linkTo}>
                          <div className="mb-1">
                            <label className="sub-menu-link" role="button">
                              {dt?.label}
                            </label>
                            <img src={arrow} alt="" width="7" />
                          </div>
                        </Link>
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={6} xs={12}>
              <div className="sub-menu-title mb-3">Payment Terms</div>
              <Row>
                <Col xs={12}>
                  {menuItem?.submenus?.map(dt => (
                    <>
                      {loggedUser?.permission?.some(
                        permissionItem =>
                          permissionItem?.id === dt.id &&
                          permissionItem?.is_menu == 1 &&
                          permissionItem?.tagline == "Payment Terms"
                      ) && (
                        <Link to={dt?.linkTo}>
                          <div className="mb-1">
                            <label className="sub-menu-link" role="button">
                              {dt?.label}
                            </label>
                            <img src={arrow} alt="" width="7" />
                          </div>
                        </Link>
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
            {/* <Col lg={6}>
              <div className="sub-menu-title mb-3">Theme</div>
              <Row>
                <Col lg={4} sm={6} xs={12}>
                  <div className="mb-1">
                    <label className="sub-menu-link" role="button">
                      Theme
                    </label>
                    <img src={arrow} alt="" width="7" />
                  </div>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default Settings
