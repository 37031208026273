export const formatNumberShort = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B"; // Billion
  } else if (num >= 10000000) {
    return (num / 10000000).toFixed(1) + "Cr"; // Crore
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // Million
  } else if (num >= 100000) {
    return (num / 100000).toFixed(1) + "L"; // Lakh
  } else if (num >= 10000) {
    return (num / 1000).toFixed(1) + "K"; // Thousands
  }
  return num.toString();
};