import React from "react";

const DateIcon = ({ size = "13px", onClick }) => (
  <svg
    width={size}
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className="date-icon"
    style={{ cursor: onClick ? "pointer" : "default" }}
  >
    <path d="M4 1V3.4" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.8 1V3.4" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.6 2.2H2.2C1.54 2.2 1 2.74 1 3.4V11.8C1 12.46 1.54 13 2.2 13H10.6C11.26 13 11.8 12.46 11.8 11.8V3.4C11.8 2.74 11.26 2.2 10.6 2.2Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 5.8H11.8" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    {[4, 6.4, 8.8].map((x, i) => (
      <React.Fragment key={i}>
        <path d={`M${x} 8.2H${x + 0.006}`} stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d={`M${x} 10.6H${x + 0.006}`} stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </React.Fragment>
    ))}
  </svg>
);

export default DateIcon;
