import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFInput } from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import PayrollDrop from "../../../components/Common/chooseFields/payrollDrop"
import GradeChoosePayroll from "../../../components/Common/chooseFields/gradePayroll"
import {
  fetchHrmsPayrollControlEdit,
  postHrmsPayrollControl,
} from "../../../services/Hrms/hrms"
import lightTrash from "../../../assets/icons/light-trash.svg"
import TrashIcon from "../../../assets/icons/iconComponents/trashIcon"

const schema = yup.object().shape({
  grade_id: yup.mixed().required("Grade Code is Required"),
  payroll_master: yup.array().of(
    yup.object().shape({
      percentage: yup
        .string()
        .matches(/^([+]?)?[0-9]+$|^$/, "Percentage must be only digits")
        .required("Percentage is Required"),
    })
  ),
})

const EditPayrollControl = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    methods,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      grade_id: null,
      payroll_master: [
        {
          payroll_component_id: null,
          percentage: "",
          id: "",
        },
      ],
    },
  })

  const gradeId = watch("grade_id")

  useEffect(() => {
    if (id) {
      const handleFetchEstimation = async id => {
        const response = await fetchHrmsPayrollControlEdit(id)
        const payrollArr = response?.data?.map(data => ({
          payroll_components_id: {
            value: data?.payroll_components_id?.id,
            id: data?.payroll_components_id?.id,
            label: data?.payroll_components_id?.name,
          },
          percentage: data?.percentage,
          id: data?.id,
        }))

        reset({
          grade_id: {
            value: response?.data?.[0]?.grade?.id,
            label: response?.data?.[0]?.grade?.grade,
            id: response?.data?.[0]?.grade?.id,
          },
          payroll_master: response?.data?.length
            ? payrollArr
            : [
                {
                  payroll_components_id: null,
                  percentage: "",
                },
              ],
        })
        const jhg = getValues()
      }
      handleFetchEstimation(id)
    }
  }, [id])

  const handleRegistration = async data => {
    const payrollMaster = []
    data?.payroll_master?.map((dt, i) =>
      payrollMaster.push({
        payroll_components_id: dt?.payroll_components_id?.id,
        percentage: dt?.percentage,
        grade_id: data?.grade_id?.id,
        id: id ? dt?.id : "",
      })
    )
    const totalPercentage = 0
    const percentageSum = payrollMaster.reduce(
      (total, dt) => total + Number(dt?.percentage),
      totalPercentage
    )
    if (percentageSum != 100) {
      toastError("Total percentage should equall to 100")
    } else {
      setIsLoading(true)
      const createdSeries = await postHrmsPayrollControl({
        ...data,
        payroll_master: payrollMaster,
        id: id ? id : "",
      })
      toastSuccess(createdSeries?.message)
      navigate("/hrms-payroll-control")
      setIsLoading(false)
    }
  }

  const {
    fields: payrollFields,
    append: payrollAppend,
    remove: payrollRemove,
  } = useFieldArray({
    control,
    name: "payroll_master",
  })

  useEffect(() => {
    const oldData = getValues()
    reset({
      ...oldData,
      payroll_master: [
        {
          payroll_components_id: {
            value: 1,
            label: "Basic Salary",
            id: 1,
          },
        },
        {
          payroll_components_id: null,
        },
      ],
    })
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="w-100">
                <div className="d-flex justify-content-between pe-2">
                  <Breadcrumbs
                    title="Procurement"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      { title: "Settings", link: "/hrms" },
                      {
                        title: "Payroll Control",
                        link: "/hrms-payroll-control/add",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 me-1 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col lg={4}>
                          <FormGroup>
                            <Label>
                              Grade Code<span className="mandatory">*</span>
                            </Label>
                            <GradeChoosePayroll
                              control={control}
                              name={"grade_id"}
                              invalid={!!errors.grade_id}
                              target={"body"}
                              disabled={id ? true : false}
                            />
                            <FormFeedback invalid>
                              {errors.grade_id?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {gradeId && (
                    <div>
                      <Row className="pt-4">
                        <Col sm={4}>Transaction Type</Col>
                        <Col sm={2}>Percentage (100%)</Col>
                      </Row>
                      {payrollFields.map((item, index) => (
                        <Row className="pt-2" key={`payroll${item?.id || index}`}>
                          <Col sm={4}>
                            <PayrollDrop
                              control={control}
                              placeholder="select"
                              name={`payroll_master.${index}.payroll_components_id`}
                              target={"body"}
                              selectedPayroll={watch(`payroll_master`)}
                              index={index}
                              disabled={index == 0 ? true : false}
                              invalid={
                                !!errors?.payroll_master?.[index]
                                  ?.payroll_components_id
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors?.payroll_master?.[index]
                                  ?.payroll_components_id?.message
                              }
                            </FormFeedback>
                          </Col>
                          <Col sm={2}>
                            <UFInput
                              control={control}
                              placeholder="Percentage"
                              name={`payroll_master.${index}.percentage`}
                              invalid={
                                !!errors?.payroll_master?.[index]?.percentage
                              }
                            />
                            <FormFeedback invalid>
                              {
                                errors?.payroll_master?.[index]?.percentage
                                  ?.message
                              }
                            </FormFeedback>
                          </Col>
                          <Col sm={1}>
                            {index != 0 && (
                              <div
                              className="trash-img pointer"
                              onClick={() => payrollRemove(index)}
                            >
                              <TrashIcon />
                            </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        <Button
                          color="primary"
                          className="addButton mt-2"
                          onClick={() =>
                            payrollAppend({
                              // payroll_component_id: "",
                              percentage: "",
                            })
                          }
                        >
                          Add Row
                        </Button>
                      </Col>
                    </div>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditPayrollControl
