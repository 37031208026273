import axios from "axios"
import accessToken from "./jwt-token-access/auth-token-header"
import { isEmpty } from "lodash"

const token = accessToken
const API_URL = process.env.REACT_APP_APIKEY

const axiosApi = axios.create({
  baseURL: API_URL,
})

const getAccessToken = () => {
  const authUser = token().Authorization
  return authUser ? authUser : null
}

axiosApi.interceptors.request.use(
  config => {
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers["X-CSRF-TOKEN"] = window.csrf_token
    return config
  },
  error => Promise.reject(error)
)

// axiosApi.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.data) {
//       if (
//         error.response.status == 401 &&
//         !isEmpty(localStorage.getItem("authUser"))
//       ) {
//         localStorage.removeItem("authUser")
//         window.location.replace("/login")
//       }

//       return Promise.reject(error.response.data)
//     }
//     return Promise.reject(error.message)
//   }
// )

axiosApi.interceptors.response.use(
  response => response,
  error => {
    // Enhanced error logging to understand the root cause of "Network Error"
    console.error("Error:", error) // Log the complete error for debugging

    if (error.code === "ERR_CONNECTION_REFUSED") {
      // Handle ERR_CONNECTION_REFUSED error
      alert(
        "Unable to connect to the server. Please check your connection and try again later."
      )
      return Promise.reject("Connection refused by the server.")
    } else if (error.response) {
      // Server responded with a status other than 2xx
      if (
        error.response.status === 401 &&
        !isEmpty(localStorage.getItem("authUser"))
      ) {
        localStorage.removeItem("authUser")
        window.location.replace("/login")
      }
      return Promise.reject(error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received from server:", error.request)
      window.location.replace("/network-error")
      return Promise.reject("No response received from server.")
    } else {
      // An error occurred while setting up the request
      console.error("Axios error:", error.message)
      return Promise.reject("An unexpected error occurred: " + error.message)
    }
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { ...data }, { ...config })
    .then(response => response.data)
}
