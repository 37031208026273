import React, { useEffect, useState } from "react"
import { Card } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchFinanceChartOfAcc } from "../../../services/finance/finance"
import OpenArrowIcon from "../../../assets/icons/iconComponents/openArrow"
import CloseArrowIcon from "../../../assets/icons/iconComponents/closwArrow"
import NumberFormat from "../../../utils/numberFormat"
// import 'bootstrap/dist/css/bootstrap.min.css';

const financialData = [
  {
    category_name: "Assets",
    account_groups: [
      {
        group_name: "Current Asset",
        ledgers: [
          { ledger_name: "Stock In Hand" },
          { ledger_name: "Office Interior(WIP)" },
        ],
        sub_groups: [
          {
            group_name: "Bank Account",
            ledgers: [
              { ledger_name: "Bank Transfer" },
              { ledger_name: "NBD Bank" },
            ],
            sub_groups: [],
          },
          {
            group_name: "Cash-in-Hand",
            ledgers: [{ ledger_name: "Petty Cash" }],
            sub_groups: [],
          },
        ],
      },
    ],
  },
  {
    category_name: "Liabilities",
    account_groups: [
      {
        group_name: "Current Liabilities",
        ledgers: [{ ledger_name: "Sundry Creditors" }],
        sub_groups: [],
      },
    ],
  },
  {
    category_name: "Income",
    account_groups: [
      {
        group_name: "Sales",
        ledgers: [{ ledger_name: "Product Sales" }],
        sub_groups: [],
      },
    ],
  },
  {
    category_name: "Expenses",
    account_groups: [
      {
        group_name: "Operating Expenses",
        ledgers: [{ ledger_name: "Rent" }, { ledger_name: "Utilities" }],
        sub_groups: [],
      },
    ],
  },
]

const TreeNode = ({ name, children, level, defaultOpen = false, balance }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div
      className="position-relative"
      style={{ paddingLeft: `${level * 20}px` }}
    >
      <div
        onClick={toggle}
        className={`cursor-pointer d-flex align-items-center ${
          children ? "fw-500" : ""
        }`}
      >
        <span className="me-2" style={{ width: "10px" }}>
          {children?.length ? (
            isOpen ? (
              <OpenArrowIcon />
            ) : (
              <CloseArrowIcon />
            )
          ) : (
            "•"
          )}
        </span>
        {name}{" "}
        {balance !== undefined && (
          <span className="text-muted ms-2">
            {NumberFormat(Math.abs(Number(balance)), 3, 3)}{" "}
            {Number(balance) > 0 ? "Cr" : "Dr"}
          </span>
        )}
      </div>

      {isOpen && children && (
        <div className="ms-1 coa-border ps-1">{children}</div>
      )}
    </div>
  )
}

const DirectoryTree = () => {
  const [financialData, setFinancialData] = useState([])

  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const response = await fetchFinanceChartOfAcc()
        setFinancialData(response.data)
      } catch (error) {
        console.error("Error fetching financial data:", error)
      }
    }

    fetchFinancialData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Finance"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                {
                  title: "Chart Of Accounts",
                  link: "/finance-chart-of-acc",
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <div className="p-3" style={{ whiteSpace: "pre" }}>
              {financialData.map((category, i) => (
                <TreeNode
                  key={i}
                  name={category.category_name}
                  level={0}
                  defaultOpen={true}
                >
                  {category.account_groups.map((group, j) => (
                    <TreeNode key={j} name={group.group_name} level={1}>
                      {group.ledgers.map((ledger, k) => (
                        <TreeNode
                          key={k}
                          name={ledger.ledger_name}
                          balance={ledger?.closing_balance}
                          level={2}
                        />
                      ))}
                      {group.sub_groups.map((subGroup, l) => (
                        <TreeNode key={l} name={subGroup.group_name} level={2}>
                          {subGroup.ledgers.map((subLedger, m) => (
                            <TreeNode
                              key={m}
                              name={subLedger.ledger_name}
                              balance={subLedger?.closing_balance}
                              level={3}
                            />
                          ))}
                        </TreeNode>
                      ))}
                    </TreeNode>
                  ))}
                </TreeNode>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DirectoryTree
