import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider, useFieldArray } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import Series from "../../../components/Common/chooseFields/series"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  fetchPaymentTerm,
  fetchPaymentTermTemplate,
  postPaymentTerms,
  postPaymentTermsTemplate,
} from "../../../services/Inventory/inventory"
import PaymentTermsList from "../../../components/Common/chooseFields/paymentTermsLi"
import lightTrash from "../../../assets/icons/light-trash.svg"

const schema = yup.object().shape({
  name: yup.string().required("Template Name is Required"),
  payment_terms: yup.array().of(
    yup.object().shape({
      pay_term: yup.mixed().required("Payment Term is Required"),
    })
  ),
  // invoice_portion: yup
  //   .number()
  //   .min(1, "Minimun Value must be greater than 1")
  //   .max(100, "Maximun Value must be less than 100")
  //   .required("Invoice Portion Percentage is Required")
  //   .typeError("Invoice Portion Percentage must be Number"),
  // description: yup.string().required("Description is Required"),
})
const Edit = ({
  loading,
  onUpdate,
  id,
  error,
  close,
  getEditValue,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      payment_terms: [
        {
          pay_term: null,
          description: "",
          invoice_portion: "",
        },
      ],
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const {
    fields: payTemplate,
    append: payTemplateAppend,
    remove: payTemplateRemove,
  } = useFieldArray({
    control,
    name: "payment_terms",
  })

  console.log(`payTemplate`, payTemplate)

  useEffect(() => {
    if (getEditValue) {
      const payTerm = getEditValue?.data?.payment_terms?.map(dt => ({
        pay_term: { label: dt?.name, value: dt?.id },
        description: dt?.description,
        invoice_portion: dt?.invoice_portion,
      }))

      reset({
        ...getEditValue?.data,
        payment_terms: payTerm,
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    console.log(`data reg`, data)
    const totalSum = 0
    const totalSumCalc = data?.payment_terms?.reduce(
      (total, dt) => total + Number(dt?.invoice_portion),
      totalSum
    )
    if (totalSumCalc != 100) {
      toastError("Total Invoice Portion Percent must be equal to 100")
    } else {
      try {
        setIsLoading(true)
        const payTermIds = data?.payment_terms?.map(
          (dt, i) => dt?.pay_term?.value
        )
        const updateDepartment = await postPaymentTermsTemplate({
          ...data,
          payment_terms: payTermIds,
        })
        toastSuccess(updateDepartment?.message)
        onUpdate()
        close()
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handlePaymentChange = (data, i) => {
    setValue(`payment_terms.${i}.description`, data?.description)
    setValue(`payment_terms.${i}.invoice_portion`, data?.invoice_portion)
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>
            {getEditValue?.data?.id
              ? "Edit Payment Terms Template"
              : "New Payment Terms Template"}
          </h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col lg={4} sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Template Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter Name"
                        name={"name"}
                        invalid={!!errors.name}
                      />
                      <FormFeedback invalid>
                        {errors.name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <h5>Manpower</h5>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <table className="estimation-table mb-4">
                      <thead>
                        <tr>
                          <th className="wdt-20">Payment Term </th>
                          <th>Description </th>
                          <th className="wdt-20">Invoice Portion (%)</th>
                          {/* <th>No.of Hours</th>
                        <th>Price(AED)</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {payTemplate?.map((dt, i) => (
                          <tr key={`payTemplate${dt.id || i}`}>
                            <td className="ps-1">
                              <PaymentTermsList
                                control={control}
                                placeholder="Payment Term"
                                name={`payment_terms.${i}.pay_term`}
                                selectedProduct={watch(`payment_terms`)}
                                index={i}
                                onSelectChange={e => handlePaymentChange(e, i)}
                              />
                            </td>
                            <td className="p-0">
                              <UFInput
                                control={control}
                                invalid={!!errors.name}
                                placeholder="Description"
                                name={`payment_terms.${i}.description`}
                                disabled={true}
                              />
                            </td>
                            <td className="p-0">
                              <UFInput
                                control={control}
                                invalid={!!errors.name}
                                placeholder="Invoice Portion"
                                disabled={true}
                                name={`payment_terms.${i}.invoice_portion`}
                              />
                            </td>
                            <td className="ps-0">
                              <div className="pb-1 px-2 d-flex justify-content-center">
                                <img
                                  src={lightTrash}
                                  alt="light-trash"
                                  className="trash-img pointer"
                                  onClick={() => {
                                    payTemplateRemove(i)
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Col
                      lg="12"
                      xs={12}
                      md={2}
                      sm={12}
                      className="d-flex justify-content-between"
                    >
                      <Button
                        color="secondary"
                        className="addButton mt-2"
                        onClick={() => {
                          payTemplateAppend({
                            pay_term: null,
                            description: "",
                            invoice_portion: "",
                          })
                        }}
                      >
                        Add Row
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditPayTermsTemplate = ({
  buttonLabel,
  className,
  editValue,
  ...props
}) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchPaymentTermTemplate(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} size="lg">
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
            setGetEditValue("")
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditPayTermsTemplate
