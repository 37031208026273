import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import EditCustomer from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import CustomerFilter from "./filter"
import {
  searchCustomer,
  updateCustomerStatus,
} from "../../../services/projectManager/customer"
import NumberFormat from "../../../utils/numberFormat"
import MobileNumberFormat from "../../../utils/mobilenumberformat"

const Customer = () => {
  document.title = "Customer | Ever Rise"
  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customerType, setCustomerType] = useState("")
  const [page, setPage] = useState("")
  const [financial, setFinancial] = useState(true)

  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleView = item => {
    setViewData(item)
    toggle()
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateCustomerStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Type",
      renderCell: item => item?.customer_type?.label,
    },
    {
      label: "Code",
      renderCell: item => {
        return (
          <div onClick={() => handleView(item)} className="pointer fw-bold">
            {item?.customer_code}
          </div>
        )
      },
    },
    {
      label: "Customer Name",
      renderCell: item => (
        <div className="capitalize-letter tbl-word-break">{item?.customer_name}</div>
      ),
      sortKey: "customer_name",
    },
    {
      label: "Contact Person",
      renderCell: item => (
        <div className="capitalize-letter">{item?.contact_person}</div>
      ),
      sortKey: "contact_person",
    },
    {
      label: "Contact Number",
      renderCell: item => MobileNumberFormat("+" + item?.customer_number),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchCustomer({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${
          customerType == "0" || customerType == "1"
            ? `&customer_type=${customerType}`
            : ""
        }${page ? `&page=${page}` : ""}`,
      })
      setClassData(response)
    }
    fetchClass()
    // ${`&financial=${financial}`}
  }, [refresh, status, search, customerType, page, financial])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }

  const handleCatChange = id => {
    setCustomerType(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }
  const handleTblRefresh = e => {
    setRefresh(refresh + 1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                { title: "Customers", link: "/project-customer" },
              ]}
            />
            <EditCustomer
              key={"x"}
              onUpdate={handleUpdate}
              onReset={handleReset}
              editValue={editClass}
              buttonLabel="+&nbsp; Customer"
              className="px-2 mb-3 me-2 btn btn-primary"
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <CustomerFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCatChange={handleCatChange}
              handleTblRefresh={handleTblRefresh}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/customer.xlsx"}
              importUrl={"customer/import"}
              isImport={true}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 20% 15% 15% 10% 10%"
                    mobileWidth="30% 30% 40% 30% 30% 20% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-4">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Type
              </Col>
              <Col className="primary-text">
                {viewData?.customer_type?.label}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Code
              </Col>
              <Col className="primary-text">{viewData?.customer_code}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Customer Name
              </Col>
              <Col className="primary-text">
                <div className="capitalize-letter">
                  {viewData?.customer_name}
                </div>
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Email ID
              </Col>
              <Col className="primary-text">{viewData?.email_id}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Contact Person
              </Col>
              <Col className="primary-text">
                <div className="capitalize-letter">
                  {viewData?.contact_person}
                </div>
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Contact Number
              </Col>
              <Col className="primary-text">
                {MobileNumberFormat("+" + viewData?.customer_number) }
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Designation
              </Col>
              <Col className="primary-text">
                <div className="capitalize-letter">
                  {viewData?.contact_person_designation}
                </div>
              </Col>
            </Row>
            {viewData?.contact_person_two != null && (
              <Row className="py-3 ms-3">
                <Col lg={3} className="secondary-text">
                  Contact Person Two
                </Col>
                <Col className="primary-text">
                  <div className="capitalize-letter">
                    {viewData?.contact_person_two}
                  </div>
                </Col>
              </Row>
            )}
            {viewData?.customer_number_two != null && (
              <Row className="py-3 ms-3">
                <Col lg={3} className="secondary-text">
                  Contact Number Two
                </Col>
                <Col className="primary-text">
                  {MobileNumberFormat("+" + viewData?.customer_number_two)}
                </Col>
              </Row>
            )}
            {viewData?.contact_person_designation_two != null && (
              <Row className="py-3 ms-3">
                <Col lg={3} className="secondary-text">
                  Designation Two
                </Col>
                <Col className="primary-text">
                  <div className="capitalize-letter">
                    {viewData?.contact_person_designation_two}
                  </div>
                </Col>
              </Row>
            )}

            <Row className="py-3 ms-3">
              <Col lg={3} className="secondary-text">
                Address
              </Col>
              <Col className="primary-text">
                <div className="capitalize-letter">{viewData?.address}</div>
              </Col>
            </Row>
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default Customer
