import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, CardBody, UncontrolledPopover } from "reactstrap"
import { searchProjectManager } from "../../../services/projectManager/projectMaster"
import { useNavigate } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const ProjectMasterDashboard = () => {
  document.title = "Project Dashboard | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)

  const [classData, setClassData] = useState()

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchProjectManager({
        params: `${"searchText"}`,
      })
      // setClassData(
      //   loggedUser?.data?.roles?.some(item => item?.name === "Store Manager")
      //     ? response?.data?.some(item => item?.start_project != null)
      //     : response?.data
      // )
      setClassData(response)
    }
    fetchClass()
  }, [refresh])

  let data = loggedUser?.data?.roles?.some(
    item => item?.name === "Project Manager"
  )
    ? classData?.data
    : classData?.data
    // : classData?.data?.filter(dt => dt?.start_project != null)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Project Dashboard",
                  link: "/project-master-dashboard",
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <Row className="mt-3 mx-1 mb-0">
              {data?.map((ele, key) => (
                <Col md={3} xl={3} sm={4} xs={12} key={key}>
                  <Card className="rounded-0 border-0 invoice-card small-card">
                    <CardBody>
                      <Row className="py-1">
                        <Col className="secondary-text">Project Code</Col>
                        <Col className="primary-text">{ele?.project_code}</Col>
                      </Row>
                      <Row className="py-1">
                        <Col className="secondary-text">Location</Col>
                        <Col className="primary-text">
                          {ele?.location?.name}
                        </Col>
                      </Row>
                      <Row className="py-1 pb-2">
                        <Col className="secondary-text">Client Name</Col>
                        <Col className="primary-text">
                          {ele?.customers?.customer_name?.length > 14 ? (
                            <span
                              id={`Popover-${ele?.id}`}
                              className="cursor-pointer"
                            >
                              {ele?.customers?.customer_name.slice(0, 11)}...
                            </span>
                          ) : (
                            <span
                              id={`Popover-${ele?.id}`}
                              className="cursor-pointer capitalize-letter"
                            >
                              {ele?.customers?.customer_name}
                            </span>
                          )}
                          <UncontrolledPopover
                            placement="bottom"
                            target={`Popover-${ele?.id}`}
                            trigger="hover"
                          >
                            <div className="table-popup-content capitalize-letter">
                              {ele?.customers?.customer_name}
                            </div>
                          </UncontrolledPopover>
                        </Col>
                      </Row>
                      <Row className="py-2">
                        <Col>
                          {/* {ele?.status === 1 &&
                          ele?.start_project === null &&
                          (loggedUser?.permission?.some(
                            permissionItem =>
                              permissionItem?.name === "projectdashboard_create"
                          ) ||
                            loggedUser?.data?.id === 1) ? (
                            <LoadingButton
                              form={`ev-form`}
                              color={"primary"}
                              className={"me-2 "}
                              onClick={() =>
                                navigate(`/project-master/edit/${ele?.id}`)
                              }
                            >
                              Material Allotment
                            </LoadingButton>
                          ) : ( */}
                            <LoadingButton
                              form={`ev-form`}
                              color={"primary"}
                              onClick={() =>
                                loggedUser?.permission?.some(
                                  permissionItem =>
                                    permissionItem?.name === "Store Dashboard"
                                )
                                  ? navigate(`/project-store-dash/${ele?.id}`)
                                  : loggedUser?.permission?.some(
                                      permissionItem =>
                                        permissionItem?.name ===
                                        "Procurement Dashboard"
                                    )
                                  ? navigate(
                                      `/project-procurement-dash/${ele?.id}`
                                    )
                                  : loggedUser?.permission?.some(
                                      permissionItem =>
                                        permissionItem?.name ===
                                        "Hrms Dashboard"
                                    )
                                  ? navigate(`/project-hrms-dash/${ele?.id}`)
                                  : loggedUser?.permission?.some(
                                      permissionItem =>
                                        permissionItem?.name ===
                                        "Finance Dashboard"
                                    )
                                  ? navigate(`/project-finance-dash/${ele?.id}`)
                                  : loggedUser?.permission?.some(
                                      permissionItem =>
                                        permissionItem?.name ===
                                        "Time Keeper Dashboard"
                                    )
                                  ? navigate(
                                      `/project-timekeeper-dash/${ele?.id}`
                                    )
                                  : navigate(`/project-dashboard/${ele?.id}`)
                              }
                            >
                              View
                            </LoadingButton>
                          {/* )} */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectMasterDashboard
