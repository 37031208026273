import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { getUnitList } from "../../../services/Inventory/inventory";

function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Unit = ({ control, name, onSelectChange, placeholder = "Select", disabled, dropView }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchResponse, setFetchResponse] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    const loadOptions = async () => {
      try {
        const data = await getUnitList();
        const response = data?.data?.map(dta => ({
          label: dta?.unit_code ? ucfirst(dta.unit_code) : "",
          value: dta.id,
          ...dta,
        })) || [];
        setFetchResponse(response);
      } catch (error) {
        console.error("Error fetching unit list:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            options={fetchResponse}
            placeholder={placeholder}
            isLoading={isLoading}
            inputRef={ref}
            onChange={selected => {
              onChange(selected);
              if (onSelectChange) onSelectChange(selected);
            }}
            isDisabled={disabled}
            value={value || null}
            classNamePrefix="react-select"
            className="elevate-dropdown"
            // menuPortalTarget={document.querySelector(".modal")}
            menuPortalTarget={document.body} // Ensure the dropdown renders correctly within the modal
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPlacement={dropView ? "top" : "bottom"}
          />
        )}
      />
    </div>
  );
};

export default Unit;