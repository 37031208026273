import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import ReactApexChart from 'react-apexcharts';

const Dashboardesign = () => {
  document.title = " Dashboard | Everrise"

  const chartOptions = {
    series: [60, 15, 12, 8], 
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#C3EBFA', '#FAE27C', '#CFCEFF', '#AAFFF1'], 
    labels: ['Purchase', 'Salary', 'Assets', 'Food & Drink'], 
    legend: {
      position: 'bottom', 
      horizontalAlign: 'center', 
      offsetY: 10, 
      height: 100, 
      formatter: function (seriesName, opts) {
        const percentages = [60, 15, 12, 8];
        const values = [2100, 525, 420, 280];
        return `${seriesName}: ${percentages[opts.seriesIndex]}% ($${values[opts.seriesIndex]})`;
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: true,
        },
      },
    }],
  };

  return (
    <React.Fragment>
      <div className="page-content px-2">
        <Card className="me-2 px-2 pb-2 main-dashboard-bg">
          <Container fluid>
            <Col lg="12">
              <Row className="mt-4">
                <Col lg="3">
                  <Card className="styleofcard p-3">
                    <div className="d-flex flex-column gap-5">
                      <div className="d-flex justify-content-between">
                        <div>...</div>
                        <div>...</div>
                      </div>
                      <div>
                        <div className="text-of-dash mb-2">Total Project</div>
                        <h2 className="text-of-value">30</h2>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card className="styleofcard1 p-3">
                    <div className="d-flex flex-column gap-5">
                      <div className="d-flex justify-content-between">
                        <div>...</div>
                        <div>...</div>
                      </div>
                      <div>
                        <div className="text-of-dash mb-2">Total Project</div>
                        <h2 className="text-of-value">30</h2>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card className="styleofcard1 p-3">
                    <div className="d-flex flex-column gap-5">
                      <div className="d-flex justify-content-between">
                        <div>...</div>
                        <div>...</div>
                      </div>
                      <div>
                        <div className="text-of-dash mb-2">Total Project</div>
                        <h2 className="text-of-value">30</h2>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card className="styleofcard2 p-3">
                    <div className="d-flex flex-column gap-5">
                      <div className="d-flex justify-content-between">
                        <div>Income</div>
                        <div>Project Name</div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="">
                          <Nav tabs>
                                <NavItem className="nav-tab-dash">
                                  <NavLink href="#" className="py-2 px-2 nav-link-dash">Income (AED)</NavLink>
                                </NavItem>
                                <NavItem className="nav-tab-dash">
                                  <NavLink href="#" className="py-2 px-2 nav-link-dash">Expense (AED)</NavLink>
                                </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <ReactApexChart options={chartOptions} series={chartOptions.series} type="donut" width={240} />
                      </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Dashboardesign
