import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import {
  fetchHrmsGradeControl,
  updateGradeControlStatus,
} from "../../../services/Hrms/hrms"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"
import {
  deleteManufacturingJournal,
  downloadManufacture,
  searchManufacturingJournal,
  updateManufactureJournal,
} from "../../../services/finance/finance"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import ManufaturingLedgerFilter from "./filter"
import moment from "moment"
import NumberFormat from "../../../utils/numberFormat"

const ManufacturingJournal = () => {
  document.title = "Manufacturing Journal | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [country, setCountry] = useState("")
  const [page, setPage] = useState("")

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateGradeControlStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const handleDelete = async id => {
    const responce = await deleteManufacturingJournal(id)
    toastSuccess(responce?.message)
    setRefresh(refresh + 1)
  }

  const handleUpdateStatus = async (id, dt) => {
    const data = { id: id, status: dt }
    const responce = await updateManufactureJournal(data)
    toastSuccess(responce?.message)
    setRefresh(refresh + 1)
  }

  const columns = [
    {
      label: "Code",
      renderCell: item => {
        return (
          <div
            onClick={() =>
              navigate(`/finance-manufacturing-journal/view/${item?.id}`)
            }
            className="pointer fw-bold"
          >
            {item?.code ? item?.code : "-"}
          </div>
        )
      },
    },
    {
      label: "Project Code",
      renderCell: item => {
        return (
          <div
          // onClick={() =>
          //   navigate(`/finance-ledger-acc`, {
          //     state: item,
          //   })
          // }
          // className="pointer fw-bold"
          >
            {item?.project ? item?.project?.project_code : "-"}
          </div>
        )
      },
    },
    {
      label: "Date",
      renderCell: item =>
        item?.date ? moment(item?.date).format("DD-MM-YYYY") : "-",
    },
    {
      label: <div className="text-end pe-5">Amount (AED)</div>,
      renderCell: item => (
        <div className="text-end pe-5">
          {NumberFormat(item?.grand_total, 3, 3)}
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1
                ? "status-active"
                : item?.status === 2
                ? "status-inactive"
                : "status-draft"
            }`}
          >
            {item?.status === 1
              ? "Approved"
              : item?.status === 2
              ? "Rejected"
              : "New"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {loggedUser?.permission?.some(
                permissionItem => permissionItem?.name === "ledger_edit"
              ) &&
                item?.status == 0 && (
                  <>
                    <div
                      className="table-popup-content"
                      onClick={() =>
                        navigate(
                          `/finance-manufacturing-journal/edit/${item?.id}`
                        )
                      }
                    >
                      Edit
                    </div>
                    <div
                      className="table-popup-content"
                      onClick={() => handleUpdateStatus(item?.id, 1)}
                    >
                      Approve
                    </div>
                    <div
                      className="table-popup-content"
                      onClick={() => handleUpdateStatus(item?.id, 2)}
                    >
                      Reject
                    </div>
                  </>
                )}
              {loggedUser?.permission?.some(
                permissionItem => permissionItem?.name === "ledger_edit"
              ) && (
                <>
                  <div
                    className="table-popup-content"
                    onClick={() => handleDelete(item?.id)}
                  >
                    Delete
                  </div>
                </>
              )}
              <div
                className="table-popup-content"
                onClick={() => downloadManufacture(item?.id, "progress.pdf")}
              >
                Download
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchManufacturingJournal({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "searchText"
        }${country ? `&country_id=${country}` : ""}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [refresh, status, search, country, page])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCountryChange = id => {
    setCountry(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Finance", link: "/finance" },
                {
                  title: "Manufacturing Journal",
                  link: "/finance-manufacturing-journal",
                },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "ledger_create"
            ) && (
              <Link
                to="/finance-manufacturing-journal/add"
                className={"px-2 mb-3 me-2 btn btn-primary"}
              >
                {"+ Manufacturing Journal"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <ManufaturingLedgerFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCountryChange={handleCountryChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data}
                    paginate={classData}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 10% 10%"
                    mobileWidth="40% 40% 40% 40% 40% 20%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManufacturingJournal
