import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import {
  fetchProjecrtMaster,
  generateWorkProgress,
} from "../../../services/projectManager/projectMaster"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"

import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import RenderTable from "./renderTable"

const UpdateWorkProgress = () => {
  document.title = "Project Master | Ever Rise"
  const { action, id } = useParams()
  const navigate = useNavigate()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [tableData, setTableData] = useState()
  const [refresh, setRefresh] = useState(0)

  const [isGenerate, setIsGenerate] = useState(false)
  const [modal, setModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({})

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const fetchProjectMasterId = async id => {
    let response = await fetchProjecrtMaster(id)
    setTableData(response)

    reset({
      ...response?.data,
      project_name:
        action == "add"
          ? response?.data?.client_code?.project_name
          : response?.data?.project_name,
      project_location:
        action == "add"
          ? response?.data?.client_code?.location?.name
          : response?.data?.location?.name,
    })
  }
  useEffect(() => {
    if (id) {
      fetchProjectMasterId(id)
    }
  }, [id, refresh])

  const toggle = () => {
    setModal(!modal)
    if (!modal == false) {
      setIsGenerate(false)
    }
  }

  const handleGenerateConformation = async () => {
    setIsGenerate(true)
    toggle()
    const responceAwait = await fetchProjectMasterId(id)
  }
  const handleRegistration = async data => {
    const valuesChk = await getValues()

    const filteredMaterials = valuesChk?.project_material
      ?.filter(item => item.ws_value != 0 && item.ws_value !== null)
      ?.map(item => ({
        id: item.id,
        table: "materials",
        ws_value: item.ws_value,
      }))

    const filteredManpower = valuesChk?.project_manpower
      ?.filter(item => item.ws_value != 0 && item.ws_value !== null)
      ?.map(item => ({
        id: item.id,
        table: "manpowers",
        ws_value: item.ws_value,
      }))

    const filteredContract = valuesChk?.project_subcontract
      ?.filter(item => item.ws_value != 0 && item.ws_value !== null)
      ?.map(item => ({
        id: item.id,
        table: "subcontracts",
        ws_value: item.ws_value,
      }))

    // Combine all into a single array if needed
    const filteredResults = [
      ...filteredMaterials,
      ...filteredManpower,
      ...filteredContract,
    ]

    try {
      setIsLoading(true)
      const generatedPercent = await generateWorkProgress({
        updates: filteredResults,
        id: id,
      })
      toastSuccess(generatedPercent?.message)
      setRefresh(refresh + 1)
      // setIsGenerate(false)
      toggle()
      // navigate(
      //   loggedUser?.permission?.some(
      //     permissionItem => permissionItem?.name === "Convert to Project"
      //   )
      //     ? "/estimation"
      //     : loggedUser?.data?.id === 1
      //     ? "/project-master"
      //     : "/project-master-dashboard"
      // )
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
    // }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                <Breadcrumbs
                  title="Project Master"
                  breadcrumbObject={[
                    { title: "Project Managemant", link: "/estimation" },
                    {
                      title: "Project Master",
                      link: "/project-master",
                    },
                    {
                      title:
                        action == "edit"
                          ? "Edit Project"
                          : "Convert to Project",
                      link:
                        action == "edit"
                          ? `/project-master-edit/${id}`
                          : "/project-master-add",
                    },
                  ]}
                />
                {/* {loggedUser?.permission?.some(
                  permissionItem =>
                    permissionItem?.name === "generateworkprogress_edit"
                ) ? (
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    className={"px-2 mb-3 btn btn-primary"}
                    onClick={handleGenerateConformation}
                  >
                    Generate
                  </LoadingButton>
                ) : (
                  <></>
                )} */}
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  {loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  )}
                  <Row className="mb-4 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Project Name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Project name"
                          name={"project_name"}
                          invalid={!!errors.project_name}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.project_name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Project Location</Label>
                        <UFInput
                          control={control}
                          placeholder="Location"
                          name={"project_location"}
                          invalid={!!errors.project_location}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.project_location?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col lg={12}>
                      <div className="tbl-scroll">
                        <table className="estimation-table mb-4 ev-work-progress-tbl">
                          <thead>
                            <tr>
                              <th rowspan="2">Item Description</th>
                              <th rowspan="2">Unit</th>
                              <th rowspan="2">Qty</th>
                              <th rowspan="2">Rate</th>
                              <th rowspan="2">Amount</th>
                              <th colSpan="3" className="text-center">
                                Percentage of Work Done
                              </th>
                              <th colSpan="3" className="text-center">
                                Value of Work Done
                              </th>
                            </tr>
                            <tr>
                              {/* <th className=""> */}
                              <th className="text-center pe-0">
                                <div className="table-head-sub">Previous</div>
                              </th>
                              <th className="text-center px-0">
                                <div className="table-head-sub">Current</div>
                              </th>
                              <th className="text-center ps-0">
                                <div className="table-head-sub">Cumilative</div>
                              </th>
                              {/* </th> */}
                              {/* <th> */}
                              <th className="text-center pe-0 wdt-8">
                                <div className="table-head-sub">Previous</div>
                              </th>
                              <th className="text-center px-0 wdt-8">
                                <div className="table-head-sub">Current</div>
                              </th>
                              <th className="text-center ps-0 pe-2 wdt-8">
                                <div className="table-head-sub">Cumilative</div>
                              </th>
                              {/* </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.data?.estimation_revision
                              ?.tasks_for_project?.length ? (
                              <RenderTable
                                tasks={
                                  tableData?.data?.estimation_revision
                                    ?.tasks_for_project
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="md" unmountOnClose={false}>
        <FormProvider id={`leave-form`}>
          <Form
            id={`leave-form`}
            key={2}
            onSubmit={handleSubmit(handleRegistration)}
          >
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="">
                    {isGenerate && (
                      <span className={"warning-icon"}>
                        <i className={" uil-exclamation-octagon"}></i>{" "}
                      </span>
                    )}
                    Confirmation
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              <ModalBody className="pb-4">
                {isGenerate ? (
                  <>
                    This is a friendly reminder that you are in the process of
                    requesting an invoice generation
                  </>
                ) : (
                  <></>
                )}
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  form={`leave-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                >
                  Confirm
                </LoadingButton>
              </ModalFooter>
            </>
          </Form>
        </FormProvider>
      </Modal>
    </React.Fragment>
  )
}
export default UpdateWorkProgress
