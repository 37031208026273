import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { UFInput } from "../../components/Common/Fields/Input"
import Status from "../../components/Common/chooseFields/status"
import { useForm } from "react-hook-form"
import { Form } from "reactstrap"
import exportIcon from "../../assets/icons/export.svg"
import {
  importFileData,
  sampleFileDownload,
} from "../../services/Inventory/inventory"
import closeIcon from "../../assets/icons/close.svg"
import uploadicon from "../../assets/icons/upload.svg"
import resetIcon from "../../assets/icons/reset.svg"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import LoadingButton from "../../components/Common/LoadingButton"
import ClassList from "../../components/Common/chooseFields/classDropDown"
import Category from "../../components/Common/chooseFields/catergory"
import Series from "../../components/Common/chooseFields/series"

const InventoryFilter = ({
  handleStatusChange,
  handleSearchChange,
  handleCategoryChange,
  handleClassChange,
  handleSeriesChange,
  sampleUrl,
  importUrl,
  showExport = showExport,
  isCat = false,
  isClass = false,
  isSeries = false,
  showStatus = false,
}) => {
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { control, watch, reset, setValue } = useForm()

  const toggle = () => setModal(!modal)

  const handleStatus = data => {
    handleStatusChange(data?.value)
  }
  const handleCategory = data => {
    handleCategoryChange(data?.value)
  }
  const handleClass = data => {
    handleClassChange(data?.value)
  }
  const handleSeries = data => {
    handleSeriesChange(data?.value)
    if (isClass) {
      setValue("class_id", null)
      handleClassChange()
    }
  }

  const handleSampleFile = () => {
    const handleCommonExport = async () => {
      const response = await sampleFileDownload(sampleUrl)
    }
    handleCommonExport()
  }

  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  const onFileChange = event => {
    setFileName(event)
    setFile(event?.target?.files?.[0])
  }

  const handleImport = () => {
    const importFile = async () => {
      try {
        setIsLoading(true)
        const response = await importFileData({
          importfile: file,
          url: importUrl,
        })
        handleSearchChange()
        setValue("search", "")
        toastSuccess(response?.message)
        setModal(false)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
    importFile()
  }

  const handleReset = () => {
    handleStatusChange()
    handleSearchChange()
    if (isSeries) {
      handleSeriesChange()
    }
    if (isClass) {
      handleClassChange()
    }
    if (isCat) {
      handleCategoryChange()
    }
    reset({
      search: "",
      status: null,
      series_id: null,
      class_id: null,
      category_id: null,
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2">
        <Form id="user-form" className="filter-wrapper">
          <Row className="mx-0">
            {showStatus ? (
              <></>
            ) : (
              <Col sm={2} className="pe-1">
                <Status
                  control={control}
                  name={"status"}
                  onSelectChange={e => handleStatus(e)}
                />
              </Col>
            )}
            {isSeries && (
              <Col sm={3} className="pe-1">
                <Series
                  control={control}
                  name={"series_id"}
                  onSelectChange={e => handleSeries(e)}
                  placeholder={"Series"}
                  target={"body"}
                />
              </Col>
            )}
            {isClass && watch("series_id") && (
              <Col sm={3} className="pe-1">
                <ClassList
                  control={control}
                  name={"class_id"}
                  onSelectChange={e => handleClass(e)}
                  placeholder={"Class"}
                  target={"body"}
                  seriesId={watch("series_id")}
                  disabled={watch("series_id") ? false : true}
                />
              </Col>
            )}
            {isCat && (
              <Col sm={2} className="pe-1">
                <Category
                  control={control}
                  name={"category_id"}
                  onSelectChange={e => handleCategory(e)}
                  placeholder={"Category"}
                  target={"body"}
                />
              </Col>
            )}
          </Row>
        </Form>
        <div className="d-flex">
          <div
            className="export-div pointer me-2 reset-img"
            onClick={() => handleReset()}
          >
            <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
            Reset
          </div>
          <UFInput
            control={control}
            placeholder="Search"
            name={"search"}
            className={"search-input"}
          />
          {showExport && (
            <div
              className="export-div pointer import-img"
              onClick={() => toggle()}
            >
              <img src={exportIcon} />
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{"Bulk Upload"}</h5>
            <img
              src={closeIcon}
              alt=""
              onClick={() => setModal(false)}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex p-5 my-3">
            <Col className="file-import-div me-3 ">
              <label htmlFor="upload" className="m-0 pointer">
                <input
                  type="file"
                  id="upload"
                  name="upload"
                  accept=".xls,.xlsx"
                  onChange={event => {
                    onFileChange(event)
                  }}
                />
                {fileName ? (
                  <span>{fileName?.target?.files?.[0]?.name}</span>
                ) : (
                  <>
                    <img
                      src={uploadicon}
                      alt="Upload Excel"
                      className="upload-icon me-2 "
                    />
                    <span className="upload">Upload&nbsp;</span>
                    <span>the file</span>
                  </>
                )}
              </label>
            </Col>

            <LoadingButton
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </LoadingButton>
          </div>
        </ModalBody>
        <ModalFooter className="bulk-upload-footer">
          <Row className="d-flex justify-content-center">
            <Col lg={8} className="text-center">
              Please download the sample file, add items, price etc & upload it
              back to create your Documents
            </Col>
          </Row>
          <Row
            className="fw-bold d-flex justify-content-center w-100 pointer"
            onClick={() => handleSampleFile()}
          >
            Download Sample File &gt;
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default InventoryFilter
