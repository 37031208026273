import React from "react"

const EstimateIcon = ({ color = "#8B909A", size = 18, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M8.85938 4.21875C9.2477 4.21875 9.5625 3.90395 9.5625 3.51562C9.5625 3.1273 9.2477 2.8125 8.85938 2.8125C8.47105 2.8125 8.15625 3.1273 8.15625 3.51562C8.15625 3.90395 8.47105 4.21875 8.85938 4.21875Z"
        fill="currentColor"
      />
      <path
        d="M6.04688 9.5625C6.4352 9.5625 6.75 9.2477 6.75 8.85938C6.75 8.47105 6.4352 8.15625 6.04688 8.15625C5.65855 8.15625 5.34375 8.47105 5.34375 8.85938C5.34375 9.2477 5.65855 9.5625 6.04688 9.5625Z"
        fill="currentColor"
      />
      <path
        d="M6.04688 12.375C6.4352 12.375 6.75 12.0602 6.75 11.6719C6.75 11.2835 6.4352 10.9688 6.04688 10.9688C5.65855 10.9688 5.34375 11.2835 5.34375 11.6719C5.34375 12.0602 5.65855 12.375 6.04688 12.375Z"
        fill="currentColor"
      />
      <path
        d="M6.04688 15.1875C6.4352 15.1875 6.75 14.8727 6.75 14.4844C6.75 14.096 6.4352 13.7812 6.04688 13.7812C5.65855 13.7812 5.34375 14.096 5.34375 14.4844C5.34375 14.8727 5.65855 15.1875 6.04688 15.1875Z"
        fill="currentColor"
      />
      <path
        d="M3.51562 9.5625C3.90395 9.5625 4.21875 9.2477 4.21875 8.85938C4.21875 8.47105 3.90395 8.15625 3.51562 8.15625C3.1273 8.15625 2.8125 8.47105 2.8125 8.85938C2.8125 9.2477 3.1273 9.5625 3.51562 9.5625Z"
        fill="currentColor"
      />
      <path
        d="M3.51562 12.375C3.90395 12.375 4.21875 12.0602 4.21875 11.6719C4.21875 11.2835 3.90395 10.9688 3.51562 10.9688C3.1273 10.9688 2.8125 11.2835 2.8125 11.6719C2.8125 12.0602 3.1273 12.375 3.51562 12.375Z"
        fill="currentColor"
      />
      <path
        d="M3.51562 15.1875C3.90395 15.1875 4.21875 14.8727 4.21875 14.4844C4.21875 14.096 3.90395 13.7812 3.51562 13.7812C3.1273 13.7812 2.8125 14.096 2.8125 14.4844C2.8125 14.8727 3.1273 15.1875 3.51562 15.1875Z"
        fill="currentColor"
      />
      <path
        d="M2.8125 18H7.45632C7.84466 18 8.15945 17.6852 8.15945 17.2969C8.15945 16.9085 7.84466 16.5938 7.45632 16.5938H2.8125C2.03709 16.5938 1.40625 15.9629 1.40625 15.1875V6.75H11.6719C12.0602 6.75 12.375 6.43521 12.375 6.04688V2.8125C12.375 1.26169 11.1133 0 9.5625 0H2.8125C1.26169 0 0 1.26169 0 2.8125V15.1875C0 16.7383 1.26169 18 2.8125 18ZM1.40625 2.8125C1.40625 2.03709 2.03709 1.40625 2.8125 1.40625H9.5625C10.3379 1.40625 10.9688 2.03709 10.9688 2.8125V5.34375H1.40625V2.8125Z"
        fill="currentColor"
      />
      <path
        d="M14.4844 12.375H13.7812V11.6719C13.7812 11.2835 13.4665 10.9688 13.0781 10.9688C12.6898 10.9688 12.375 11.2835 12.375 11.6719V13.0781C12.375 13.4665 12.6898 13.7812 13.0781 13.7812H14.4844C14.8727 13.7812 15.1875 13.4665 15.1875 13.0781C15.1875 12.6898 14.8727 12.375 14.4844 12.375Z"
        fill="currentColor"
      />
      <path
        d="M13.0781 8.15625C10.3642 8.15625 8.15625 10.3642 8.15625 13.0781C8.15625 15.792 10.3642 18 13.0781 18C15.792 18 18 15.792 18 13.0781C18 10.3642 15.792 8.15625 13.0781 8.15625ZM13.0781 16.5938C11.1396 16.5938 9.5625 15.0166 9.5625 13.0781C9.5625 11.1396 11.1396 9.5625 13.0781 9.5625C15.0166 9.5625 16.5938 11.1396 16.5938 13.0781C16.5938 15.0166 15.0166 16.5938 13.0781 16.5938Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EstimateIcon
