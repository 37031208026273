import React from "react"
import NumberFormat from "../../../utils/numberFormat"
import { capitalize } from "lodash"

const EstimateRenderTable = ({ tasks, projectProfit, prefix = "" }) => {
  const getExcelColumnLabel = index => {
    let label = ""
    while (index >= 0) {
      label = String.fromCharCode((index % 26) + 65) + label
      index = Math.floor(index / 26) - 1
    }
    return label
  }

  return (
    <>
      {tasks?.map((itm, i) => {
        const currentNumber = prefix ? `${prefix}.${i + 1}` : `${i + 1}`
        const unitPrice =
          Number(itm?.consumables) +
          Number(itm?.fittings) +
          Number(itm?.manpower) +
          Number(itm?.material) +
          Number(itm?.overhead_profit)
        return (
          <React.Fragment key={currentNumber}>
            <tr className="fs-13 fw-500">
              <td className="wdt-33 ps-2">
                {currentNumber} {capitalize(itm?.name)}
              </td>
              <td className="text-end">{itm?.qty}</td>
              <td className="ps-3">{itm?.unit?.unit_code}</td>
              <td className="text-end">{NumberFormat(unitPrice, 3, 3) || 0}</td>
              <td className="pe text-end">
                {NumberFormat(Number(unitPrice || 0) * Number(itm?.qty), 3, 3)}
              </td>
            </tr>
            {itm?.description ? (
              <tr>
                <td className="ps-3 fs-13">{itm?.description}</td>
              </tr>
            ) : (
              <></>
            )}
            {/* {itm?.estimation_material?.length > 0 && (
              <>
                <tr>
                  <td className="ps-3 fs-13 fw-500">Estimation Material</td>
                </tr>
                {itm?.estimation_material?.map((dt, i) => (
                  <tr key={`material-${currentNumber}-${i}`} className="fs-13">
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}){" "}
                      {capitalize(dt?.products?.description)}
                    </td>
                    <td className="ps-3">{dt?.unit?.unit_code}</td>
                    <td className="text-end">
                      {NumberFormat(Number(dt?.quantity))}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(
                          Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100
                        ),
                        3
                      )}
                    </td>
                    <td className="pe text-end">
                      {NumberFormat(
                        Number(dt?.quantity) *
                          (Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100),
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {itm?.estimation_manpower?.length > 0 && (
              <>
                <tr>
                  <td className="ps-3 fs-13 fw-500">Estimation Manpower</td>
                </tr>
                {itm?.estimation_manpower.map((dt, i) => (
                  <tr key={`material-${currentNumber}-${i}`} className="fs-13">
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}) {dt?.category_id?.description}
                    </td>
                    <td className="ps-3">Hrs</td>
                    <td className="text-end">
                      {NumberFormat(Number(dt?.no_of_labours || 1))}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(
                          Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100
                        ),
                        3
                      )}
                    </td>
                    <td className="pe text-end">
                      {NumberFormat(
                        Number(dt?.no_of_labours) *
                          (Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100),
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {itm?.estimation_subcontract?.length > 0 && (
              <>
                <tr>
                  <td className="ps-3 fs-13 fw-500">Estimation Subcontract</td>
                </tr>
                {itm?.estimation_subcontract?.map((dt, i) => (
                  <tr
                    key={`subcontract-${currentNumber}-${i}`}
                    className="fs-13"
                  >
                    <td className="ps-3">
                      ({getExcelColumnLabel(i)}) {dt?.category_id?.description}
                    </td>
                    <td className="ps-3">{dt?.unit?.unit_code}</td>
                    <td className="text-end">
                      {NumberFormat(Number(dt?.duration || 1))}
                    </td>
                    <td className="text-end">
                      {NumberFormat(
                        Number(
                          Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100
                        ),
                        3
                      )}
                    </td>
                    <td className="pe text-end">
                      {NumberFormat(
                        Number(dt?.duration || 1) *
                          (Number(dt?.price) +
                            (Number(dt?.price) * Number(projectProfit)) / 100),
                        3
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )} */}
            {i != tasks?.length - 1 ? (
              <tr className="w-100">
                <td className="render-tbl-border-btm"></td>
                <td className="render-tbl-border-btm"></td>
                <td className="render-tbl-border-btm"></td>
                <td className="render-tbl-border-btm"></td>
                <td className="render-tbl-border-btm"></td>
              </tr>
            ) : (
              ""
            )}
            {itm?.tasks?.length > 0 && (
              <EstimateRenderTable
                tasks={itm?.tasks}
                projectProfit={projectProfit}
                prefix={currentNumber}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default EstimateRenderTable
