import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import Location from "../../../components/Common/chooseFields/location"
import lightTrash from "../../../assets/icons/light-trash.svg"
import Priority from "../../../components/Common/chooseFields/priority"
import {
  fetchRequest,
  postMaterialRequest,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import Productcode from "../../../components/Common/chooseFields/Product"
import { fetchProjecrtMaster } from "../../../services/projectManager/projectMaster"
import MaterialProjectNo from "../../../components/Common/chooseFields/materailProject"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import MaterialProductList from "../../../components/Common/chooseFields/materialProductList"
import { useSelector } from "react-redux"

const schema = yup.object().shape({
  project_code: yup.mixed().required("project code is required"),
  request_date: yup
    .mixed()
    .test(
      "is-future-date",
      "Make sure the request date is forward-looking",
      function (value) {
        if (!value) return true
        const currentDate = moment()
        const selectedDate = moment(value)
        return selectedDate.isSameOrAfter(currentDate, "day")
      }
    )
    .required("Delivery Date is required"),
  exp_Delivery_date: yup
    .mixed()
    .test(
      "is-future-date",
      "Make sure the exp delivery date is forward-looking",
      function (value) {
        if (!value) return true
        const currentDate = moment()
        const selectedDate = moment(value)
        return selectedDate.isSameOrAfter(currentDate, "day")
      }
    )
    .required("Delivery Date is required"),
  // requested_by: yup.string().required("requested by is required"),
  contact_number: yup
    .string()
    .matches(/^([+]?)?[0-9]+$|^$/, "Mobile no must be only digits")
    // .max(15, "Maximum limit is 15")
    .required("contact number is required"),
  material_req: yup.array().of(
    yup.object().shape({
      product_id: yup.mixed().required("product code is required"),
      description: yup.string().required("description is required"),
      priority: yup.mixed().required("priority is required"),
      quantity: yup
        .string()
        .matches(
          /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
          "Quantity must be a non-negative number and cannot be zero"
        )
        .test(
          "max",
          "Received quantity must not exceed the ordered quantity",
          function (value) {
            const { initial_quantity } = this.parent
            const { received_quantity } = this.parent
            const validateqty = initial_quantity - received_quantity
            return parseInt(value) <= validateqty
          }
        )
        .required("Quantity is required"),
    })
  ),
})

const EditMaterialRequest = ({ fromDashboard, projectValue, toggle }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const now = moment()
  const [isLoading, setIsLoading] = useState(false)
  const [projectID, setProjectID] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_code: null,
      contact_number: "",
      requested_by: "",
      request_date: moment(now, "YYYY-MM-DD").toDate(),
      delivery_location: null,
      material_req: [
        {
          id: "",
          product_id: null,
          quantity: "",
          description: "",
          priority: null,
          initial_quantity: "",
          received_quantity: "",
        },
      ],
    },
  })
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  // })
  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "material_req",
  })

  const handleRegistration = async data => {
    const Requestarray = data?.material_req?.map(dt => ({
      id: dt?.id,
      product_id: dt?.product_id?.value,
      priority: dt?.priority?.value,
      description: dt?.description,
      quantity: dt?.quantity,
      unit_id: dt?.unit_id,
    }))
    try {
      setIsLoading(true)
      const update = await postMaterialRequest({
        ...data,
        requested_by: loggedUser?.data?.name,
        contact_number: data?.contact_number,
        delivery_location: data?.delivery_location,
        project_code: data?.project_code?.value,
        material_req: Requestarray,
        exp_Delivery_date: moment(data?.exp_Delivery_date).format("YYYY-MM-DD"),
        request_date: moment(data?.request_date).format("YYYY-MM-DD"),
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/procurement-materiareq")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleProjectChange = () => {
    const { project_code } = getValues()
    fetchProjectData(project_code?.value)
    setProjectID(project_code?.value)
  }

  useEffect(() => {
    if (fromDashboard) {
      setValue("project_code", projectValue)
      fetchProjectData(id)
      setProjectID(id)
    }
  }, [fromDashboard])

  const fetchProjectData = async idData => {
    const response = await fetchProjecrtMaster(idData)
    if (idData == response.data?.id) {
      const Requestarray = response.data?.project_material
        ?.map(dt => {
          const projectMrQuantitySum = dt.project_mr.reduce(
            (total, mr) => total + parseFloat(mr.quantity),
            0
          )

          const alreadyReceived = dt.project_mr.length
            ? dt.quantity - projectMrQuantitySum
            : 0
          const balanceQty = dt.project_mr.length ? projectMrQuantitySum : 0
          const quantity = dt.project_mr.length ? alreadyReceived : dt.quantity
          return {
            product_id: {
              value: dt?.product_code?.id,
              label: dt?.product_code?.product_code,
            },
            description: dt?.description,
            quantity: quantity,
            initial_quantity: dt?.quantity,
            received_quantity: balanceQty,
            unit_id: dt?.unit?.id,
          }
        })
        .filter(item => item.quantity !== 0)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_name: response.data?.project_name,
        material_req: Requestarray,
        delivery_location: response.data?.location?.name,
      })
    }
  }
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {!fromDashboard ? (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        {
                          title: "Material Request",
                          link: "/procurement-materiareq",
                        },
                        {
                          title: id
                            ? "Edit Material Request"
                            : "Add Material Request",
                          link: "",
                        },
                      ]}
                    />
                  ) : (
                    <div></div>
                  )}
                  <LoadingButton
                    form={`request-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {fromDashboard ? "Save" : id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project Code<span className="mandatory">*</span>
                        </Label>
                        <MaterialProjectNo
                          control={control}
                          placeholder="Enter the project code"
                          name={"project_code"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={handleProjectChange}
                          invalid={!!errors.project_code}
                          disabled={fromDashboard}
                        />
                        <FormFeedback invalid>
                          {errors.project_code?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Project name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the project name"
                          name={"project_name"}
                          invalid={!!errors.project_name}
                        />
                        <FormFeedback invalid>
                          {errors.project_name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Payment Terms</Label>
                        <PaymentMode
                          control={control}
                          placeholder="select"
                          name={"credit"}
                          invalid={!!errors.credit}
                          target={fromDashboard ? ".modal" : "body"}
                        />
                        <FormFeedback invalid>
                          {errors.credit?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Requested by <span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the requested by"
                          name={"requested_by"}
                          invalid={!!errors.requested_by}
                        />
                        <FormFeedback invalid>
                          {errors.requested_by?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Request date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"request_date"}
                          invalid={!!errors.request_date}
                        />
                        <FormFeedback invalid>
                          {errors.request_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Exp. Delivery date{" "}
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"exp_Delivery_date"}
                          invalid={!!errors.exp_Delivery_date}
                        />
                        <FormFeedback invalid>
                          {errors.exp_Delivery_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Contact Number <span className="mandatory">*</span>
                        </Label>
                        {/* <UFInput
                          control={control}
                          placeholder="Enter the contact number"
                          name={"contact_number"}
                          invalid={!!errors.contact_number}
                        /> */}
                        <PhoneInputField
                          control={control}
                          name={"contact_number"}
                          invalid={!!errors.contact_number}
                        />
                        <FormFeedback invalid>
                          {errors.contact_number?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-4 mt-4">
                    <h5>Material Details</h5>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Quantity</th>
                            <th>Store Availability</th>
                            <th>Description</th>
                            <th>Priority</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {materialFields.map((item, index) => (
                            <tr key={`materialFields${index}`}>
                              <td className="ps-1">
                                <MaterialProductList
                                  control={control}
                                  placeholder="Product Code"
                                  projectid={projectID}
                                  selectedProduct={watch(`material_req`)}
                                  index={index}
                                  name={`material_req.${index}.product_id`}
                                  invalid={
                                    !!errors?.material_req?.[index]?.product_id
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.material_req?.[index]?.product_id
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td className="ps-1">
                                <UFInput
                                  control={control}
                                  placeholder="Quantity"
                                  name={`material_req.${index}.quantity`}
                                  invalid={
                                    !!errors?.material_req?.[index]?.quantity
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.material_req?.[index]?.quantity
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td className="ps-1">
                                <div className="fs-13">0</div>
                              </td>
                              <td className="ps-1">
                                <UFInput
                                  control={control}
                                  placeholder="Description"
                                  name={`material_req.${index}.description`}
                                  invalid={
                                    !!errors?.material_req?.[index]?.description
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.material_req?.[index]?.description
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td className="ps-1">
                                <Priority
                                  control={control}
                                  placeholder="Priority"
                                  name={`material_req.${index}.priority`}
                                  invalid={
                                    !!errors?.material_req?.[index]?.priority
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.material_req?.[index]?.priority
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => materialRemove(index)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {!fromDashboard && (
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-between"
                        >
                          <Button
                            color="secondary"
                            className="addButton mt-2"
                            onClick={() =>
                              materialAppend({
                                product_id: "",
                                description: "",
                                quantity: "",
                                priority: "",
                              })
                            }
                          >
                            Add Row
                          </Button>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditMaterialRequest
