import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
// import UpdateTable from "../../../../components/Common/UpdatedTable"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { capitalize, isEmpty, noConflict } from "lodash"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import Location from "../../../components/Common/chooseFields/location"
import lightTrash from "../../../assets/icons/light-trash.svg"
import Priority from "../../../components/Common/chooseFields/priority"
import {
  fetchMRRequest,
  fetchRequest,
  postMaterialRequest,
  postMaterialRequestFinance,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import Productcode from "../../../components/Common/chooseFields/Product"
import {
  fetchProjecrtMaster,
  fetchProjectDashboard,
  postRequestManpower,
} from "../../../services/projectManager/projectMaster"
import MaterialProjectNo from "../../../components/Common/chooseFields/materailProject"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import MaterialProductList from "../../../components/Common/chooseFields/materialProductList"
import { useSelector } from "react-redux"
import { fetchClass } from "../../../services/Inventory/inventory"
import closeIcon from "../../../assets/icons/close.svg"
import { UFEditor } from "../../../components/Common/Fields/Input"

// const schema = yup.object().shape({
//   project_code: yup.mixed().required("project code is required"),
//   request_date: yup
//     .mixed()
//     .test(
//       "is-future-date",
//       "Make sure the request date is forward-looking",
//       function (value) {
//         if (!value) return true
//         const currentDate = moment()
//         const selectedDate = moment(value)
//         return selectedDate.isSameOrAfter(currentDate, "day")
//       }
//     )
//     .required("Delivery Date is required"),
//   exp_Delivery_date: yup
//     .mixed()
//     .test(
//       "is-future-date",
//       "Make sure the exp delivery date is forward-looking",
//       function (value) {
//         if (!value) return true
//         const currentDate = moment()
//         const selectedDate = moment(value)
//         return selectedDate.isSameOrAfter(currentDate, "day")
//       }
//     )
//     .required("Delivery Date is required"),
//   // requested_by: yup.string().required("requested by is required"),
//   // contact_number: yup
//   //   .string()
//   //   .matches(/^([+]?)?[0-9]+$|^$/, "Mobile no must be only digits")
//   //   .required("contact number is required"),
//   material_req: yup.array().of(
//     yup.object().shape({
//       product_id: yup.mixed().required("product code is required"),
//       description: yup.string().required("description is required"),
//       // priority: yup.mixed().required("priority is required"),
//       quantity: yup
//         .string()
//         .matches(
//           /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
//           "Quantity must be a non-negative number and cannot be zero"
//         )
//         // .test(
//         //   "max",
//         //   "Received quantity must not exceed the ordered quantity",
//         //   function (value) {
//         //     const { initial_quantity } = this.parent
//         //     const { received_quantity } = this.parent
//         //     const validateqty = initial_quantity - received_quantity
//         //     return parseInt(value) <= validateqty
//         //   }
//         // )
//         .required("Quantity is required"),
//     })
//   ),
//   ...(viewData?.id === 7 && {
//         req_manpower_no: yup
//           .number()
//           .min(1, "Number of Hours must be 1 or more")
//           .max(
//             Number(viewData?.req_manpower_no) -
//               Number(
//                 viewData?.value?.manpower_requests_sum_req_manpower_no
//                   ? viewData?.value?.manpower_requests_sum_req_manpower_no
//                   : 0
//               ),
//             `Number of Hours must be ${
//               Number(viewData?.req_manpower_no) -
//               Number(
//                 viewData?.value?.manpower_requests_sum_req_manpower_no
//                   ? viewData?.value?.manpower_requests_sum_req_manpower_no
//                   : 0
//               )
//             } or less`
//           )
//           .typeError("Number of Hours Must be Number")
//           .required("Number of Hours is Required"),
//         req_remarks: yup.string().required("Remarks is Required"),
//         request_date: yup.mixed().required("Date is Required"),
//         exp_qty: yup
//           .number()
//           .min(1, "Expected Quantity must be 1 or more")
//           .typeError("Expected Quantity must be Number"),
//       }),
// })

const EditManpowerRequest = ({ fromDashboard, projectValue }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const now = moment()
  const [isLoading, setIsLoading] = useState(false)
  const [isDraftLoading, setIsDraftLoading] = useState(false)
  const [projectID, setProjectID] = useState()
  const [manpowerData, setManpowerData] = useState()
  const [page, setPage] = useState("")
  const [modal, setModal] = useState(false)
  const [viewData, setViewData] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const schema = yup.object().shape({
    project_code: yup.mixed().required("project code is required"),
    request_date: yup
      .mixed()
      .test(
        "is-future-date",
        "Make sure the request date is forward-looking",
        function (value) {
          if (!value) return true
          const currentDate = moment()
          const selectedDate = moment(value)
          return selectedDate.isSameOrAfter(currentDate, "day")
        }
      )
      .required("Delivery Date is required"),
    // exp_Delivery_date: yup
    //   .mixed()
    //   .test(
    //     "is-future-date",
    //     "Make sure the exp delivery date is forward-looking",
    //     function (value) {
    //       if (!value) return true
    //       const currentDate = moment()
    //       const selectedDate = moment(value)
    //       return selectedDate.isSameOrAfter(currentDate, "day")
    //     }
    //   )
    //   .required("Delivery Date is required"),
    // requested_by: yup.string().required("requested by is required"),
    // contact_number: yup
    //   .string()
    //   .matches(/^([+]?)?[0-9]+$|^$/, "Mobile no must be only digits")
    //   .required("contact number is required"),
    // material_req: yup.array().of(
    //   yup.object().shape({
    //     product_id: yup.mixed().required("product code is required"),
    //     description: yup.string().required("description is required"),
    //     // priority: yup.mixed().required("priority is required"),
    //     quantity: yup
    //       .string()
    //       .matches(
    //         /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
    //         "Quantity must be a non-negative number and cannot be zero"
    //       )
    //       // .test(
    //       //   "max",
    //       //   "Received quantity must not exceed the ordered quantity",
    //       //   function (value) {
    //       //     const { initial_quantity } = this.parent
    //       //     const { received_quantity } = this.parent
    //       //     const validateqty = initial_quantity - received_quantity
    //       //     return parseInt(value) <= validateqty
    //       //   }
    //       // )
    //       .required("Quantity is required"),
    //   })
    // ),
    ...(viewData?.id === 7 && {
      req_manpower_no: yup
        .number()
        .typeError("Number of Hours Must be a Number")
        .min(1, "Number of Hours must be 1 or more")
        .required("Number of Hours is Required"),
      req_remarks: yup.string().required("Remarks is Required"),
      request_date: yup.mixed().required("Date is Required"),
      exp_qty: yup
        .number()
        .min(1, "Expected Quantity must be 1 or more")
        .typeError("Expected Quantity must be Number"),
    }),
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_code: null,
      contact_number: "",
      requested_by: "",
      request_date: moment(now, "YYYY-MM-DD").toDate(),
      delivery_location: null,
      material_req: [
        {
          id: "",
          product_id: null,
          quantity: "",
          description: "",
          // priority: null,
          initial_quantity: "",
          received_quantity: "",
        },
      ],
    },
  })

  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "material_req",
  })

  const handleRegistration = async data => {
    try {
      console.log("allsubdata", data)
      setIsLoading(true)
      const requestManpower = await postRequestManpower({
        project_manpower_id: data?.project_manpower_id,
        from_date: !isEmpty(data)
          ? moment(data?.request_date[0]).format("YYYY-MM-DD")
          : "",
        to_date: !isEmpty(data)
          ? moment(data?.request_date[1]).format("YYYY-MM-DD")
          : "",
        designation_id: data?.designation_id,
        req_manpower_no: data?.req_manpower_no,
        req_remarks: data?.req_remarks,
        project_id: data?.project_id,
        exp_qty: data?.exp_qty,
      })
      reset({
        project_code: {
          label: data?.project_code?.label,
          value: data?.project_code?.value,
          id: data?.project_code?.id,
        },
        project_name: data?.project_name,
        project_id: data?.project_id,
        req_remarks: "",
        exp_qty: "",
      })
      console.log(
        "reset",
        data?.project_code?.label,
        data?.project_code?.value,
        data?.project_id
      )
      toastSuccess(requestManpower?.message)
      setIsLoading(false)
      setModal(!modal)
    } catch (err) {
      reset({
        project_code: {
          label: data?.project_code?.label,
          value: data?.project_code?.value,
          id: data?.project_code?.id,
        },
        project_name: data?.project_name,
        project_id: data?.project_id,
        req_remarks: "",
        exp_qty: "",
      })
      toastError(err?.message)
      setIsLoading(false)
      setModal(!modal)
    }
  }

  const fetchClass = async id => {
    setManpowerData([])
    const response = await fetchProjectDashboard({
      params: `${id ? `&project_id=${id}` : ""}${`&type=manpower`}`,
    })
    if (Array.isArray(response?.data?.data)) {
      setManpowerData(response)
    } else {
      console.error("Expected response data to be an array", response?.data)
    }
  }

  const handleProjectChange = () => {
    const { project_code } = getValues()
    fetchProjectData(project_code?.value)
    fetchClass(project_code?.id)
    console.log("search", project_code)
    setProjectID(project_code?.value)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const toggle = () => {
    setModal(!modal)
    const controlValue = getValues()
    reset({
      ...controlValue,
    })
    if (!modal === false) {
      setViewData()
    }
  }

  useEffect(() => {
    if (fromDashboard) {
      setValue("project_code", projectValue)
      fetchProjectData(id)
      setProjectID(id)
    }
    const fetchMrEdit = async id => {
      const responce = await fetchMRRequest(id)
      console.log(`responce edit`, responce)
      const materialsArr = responce?.data?.materials?.map(dt => ({
        product_id: {
          label: dt?.product?.product_code,
          value: dt?.product?.id,
        },
        quantity: dt?.requested_quantity,
        description: dt?.product?.description,
      }))
      reset({
        ...responce?.data,
        project_code: {
          label: responce?.data?.project?.project_code,
          value: responce?.data?.project?.id,
        },
        project_name: responce?.data?.project?.project_name,
        exp_Delivery_date: responce?.data?.exp_Delivery_date,
        material_req: materialsArr,
      })
    }
    if (id) {
      fetchMrEdit(id)
    }
  }, [fromDashboard, id])

  const fetchProjectData = async idData => {
    const response = await fetchProjecrtMaster(idData)
    if (idData == response.data?.id) {
      const Requestarray = response.data?.project_material
        ?.map(dt => {
          const projectMrQuantitySum = dt.project_mr.reduce(
            (total, mr) => total + parseFloat(mr.quantity),
            0
          )

          const alreadyReceived = dt.project_mr.length
            ? dt.quantity - projectMrQuantitySum
            : 0
          const balanceQty = dt.project_mr.length ? projectMrQuantitySum : 0
          const quantity = dt.project_mr.length ? alreadyReceived : dt.quantity
          return {
            product_id: {
              value: dt?.product_code?.id,
              label: dt?.product_code?.product_code,
              storeCount: dt?.products?.storeCount,
            },
            description: dt?.description,
            quantity: quantity,
            initial_quantity: dt?.quantity,
            received_quantity: balanceQty,
            unit_id: dt?.unit?.id,
          }
        })
        .filter(item => item.quantity !== 0)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        project_name: response.data?.project_name,
        material_req: Requestarray,
        delivery_location: response.data?.location?.name,
      })
    }
  }

  const handleView = item => {
    console.log(`--itm`, item)
    setViewData(item)
    const controlValue = getValues()
    console.log("id1", controlValue)
    console.log("id2", controlValue?.project_code)
    toggle()
    if (item?.id == 7) {
      // setValue1("manpower_req", item?.product_id)
      console.log(`item`, item)
      console.log("des", item?.category?.description)
      reset({
        // category_code: item?.data?.map((categoryItem) => categoryItem?.description) || [],
        ...controlValue,
        category_code: item?.category_code,
        req_manpower_no:0,
        project_manpower_id: item?.project_manpower_id,
        designation_id: item?.value?.category?.id,
        project_id: controlValue?.project_code?.id,
      })
    }
  }

  const columnManPower = [
    {
      label: "Designation",
      renderCell: item => item?.category?.category_code,
    },
    {
      label: "Category Description",
      renderCell: item => {
        return (
          <div>
            {item?.category?.description
              ? capitalize(item?.category?.description)
              : "-"}
          </div>
        )
      },
    },
    {
      label: "Number of Hours",
      renderCell: item => (item?.no_of_labours ? item?.no_of_labours : 0),
    },
    {
      label: "Issued Nos",
      renderCell: item => (item?.hr_issued_count ? item?.hr_issued_count : 0),
    },
    {
      label: "Availability",
      renderCell: item => (
        <>
          <Button
            color="primary"
            onClick={() =>
              handleView({
                id: 7,
                name: "manpower_req",
                category_code: item?.category?.description,
                req_manpower_no: item?.no_of_labours,
                project_manpower_id: item?.id,
                value: item,
              })
            }
          >
            Request
          </Button>
          
        </>
      ),
    },
  ]


  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-3">
                  {!fromDashboard ? (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        {
                          title: "Manpower Request",
                          link: "/procurement-manpowerreq",
                        },
                        {
                          title: id
                            ? "Edit Manpower Request"
                            : "Add Manpower Request",
                          link: "",
                        },
                      ]}
                    />
                  ) : (
                    <div></div>
                  )}
                  <div>
                  </div>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-1 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project Code<span className="mandatory">*</span>
                        </Label>
                        <MaterialProjectNo
                          control={control}
                          placeholder="Enter the project code"
                          name={"project_code"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={handleProjectChange}
                          invalid={!!errors.project_code}
                          disabled={fromDashboard}
                        />
                        <FormFeedback invalid>
                          {errors.project_code?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Project name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the project name"
                          name={"project_name"}
                          invalid={!!errors.project_name}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.project_name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Request date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"request_date"}
                          invalid={!!errors.request_date}
                        />
                        <FormFeedback invalid>
                          {errors.request_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-2 mt-1">
                    <h5>Material Details</h5>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={12}>
                      <Row className="mt-2 ever-rise-table">
                        <UpdateTable
                          className="mt-5"
                          // nodes={manpowerData?.data}
                          nodes={
                            Array.isArray(manpowerData?.data?.data)
                              ? manpowerData?.data?.data
                              : []
                          }
                          paginate={manpowerData?.data}
                          columns={columnManPower}
                          CurrentPage={page}
                          handlePagination={handlePaginate}
                          tableWidth="15% 25% 20% 20% 20%"
                          mobileWidth="30% 40% 40% 40% 40%"
                        />
                      </Row>
                     
                      {!fromDashboard && (
                        <Col
                          lg="12"
                          xs={12}
                          md={2}
                          sm={12}
                          className="d-flex justify-content-between"
                        >
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Container>
              <Modal
                isOpen={modal}
                toggle={toggle}
                // size={}
                unmountOnClose={false}
              >
                <ModalHeader className="custom-modal-header">
                  <div className="d-flex justify-content-between">
                    <h5 className="ms-4">
                      {viewData?.id == 7 ? "Manpower Request" : " "}
                    </h5>
                    <img
                        src={closeIcon}
                      alt="icon"
                      onClick={toggle}
                      width="10"
                      role="button"
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <Row className="mt-2 ever-rise-table mx-2">
                    <Col xs={12}>
                      {viewData?.id == 7 && (
                        <>
                          <Row>
                            <Col sm={12}>
                              <FormGroup className="mb-4">
                                <Label>
                                  Category Code
                                  <span className="mandatory">*</span>
                                </Label>
                                <UFInput
                                  control={control}
                                  placeholder=""
                                  name={"category_code"}
                                  invalid={!!errors.category_code}
                                  disabled={true}
                                />
                                <FormFeedback invalid>
                                  {errors.category_code?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              <FormGroup className="mb-4">
                                <Label>
                                  Number of Hours
                                  <span className="mandatory">*</span>
                                </Label>

                                <UFInput
                                  control={control}
                                  placeholder="Enter Hour"
                                  name={"req_manpower_no"}
                                  invalid={!!errors.req_manpower_no}
                                />
                                <FormFeedback invalid>
                                  {errors.req_manpower_no?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              <FormGroup className="mb-4">
                                <Label>
                                  From Date - To Date
                                  <span className="mandatory">*</span>
                                </Label>
                                <UFDate
                                  control={control}
                                  name={"request_date"}
                                  mode="range"
                                  minDate={moment().format("YYYY-MM-DD")}
                                  // onDateChange={e => handleRequestdate(e)}
                                />
                                <FormFeedback invalid>
                                  {errors.request_date?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              <FormGroup className="mb-4">
                                <Label>Minimum Manpower (per day)</Label>
                                <UFInput
                                  control={control}
                                  placeholder="Expected Quantity"
                                  name={"exp_qty"}
                                  invalid={!!errors.exp_qty}
                                />
                                <FormFeedback invalid>
                                  {errors.exp_qty?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              <FormGroup className="mb-4">
                                <Label>
                                  Remarks
                                  <span className="mandatory">*</span>
                                </Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Description"
                                  rows="5"
                                  name={"req_remarks"}
                                  invalid={!!errors.req_remarks}
                                />
                                <FormFeedback invalid>
                                  {errors.req_remarks?.message}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                  {viewData?.id === 7 && (
                    <>
                      <hr />
                      <div className="pb-3 d-flex justify-content-end">
                        <LoadingButton
                          form={`request-form`}
                          color={"primary"}
                          loading={isLoading}
                          disabled={isLoading}
                          className={"me-4"}
                          type="submit"
                        >
                          Request
                        </LoadingButton>
                      </div>
                    </>
                  )}
                  {/* </Form>
              </FormProvider> */}
                </ModalBody>
              </Modal>
            </Form>
          </FormProvider>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditManpowerRequest

