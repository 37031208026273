import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import MiniWidget from "./mini-widget"
import SalesAnalyticsChart from "./salesanalytics-chart"
import TopProduct from "./topselling-product"
import TopUser from "./topuser"
import RecentActivity from "./recent-activity"
import SocialSource from "./socialsource"
import LatestTransaction from "./latest-transaction"

//Import Image
import setupanalytics from "../../assets/images/setup-analytics-amico.svg"
import { searchProjectMaster } from "../../services/projectManager/projectMaster"
import { fetchDashboard } from "../../services/dashboard/dashboard"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import user4 from "../../assets//images/users/avatar-4.jpg"
import dollarIcon from "../../assets/icons/dollarIconBg.svg"
import dummyProd from "../../assets/icons/dummyProduct.svg"
import closeIcon from "../../assets/icons/close.svg"

import ReactApexChart from "react-apexcharts"
import Status from "../../components/Common/chooseFields/status"
import { useForm } from "react-hook-form"
import UpdateTable from "../../components/Common/UpdatedTable"
import InvoiceView from "../Invoice/invoiceView/view"

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
]

const options1 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ""
        },
      },
    },
    marker: {
      show: !1,
    },
  },
}

const series2 = [70]

const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
}

const series3 = [55]

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
}

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
]

const options4 = {
  fill: {
    colors: ["#f1b44c"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ""
        },
      },
    },
    marker: {
      show: !1,
    },
  },
}

const Dashboard = () => {
  document.title = " Dashboard | Everrise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const imgUrl = process.env.REACT_APP_IMG_APIKEY

  const { control, watch, reset } = useForm()

  const navigate = useNavigate()

  const [refresh, setRefresh] = useState(0)
  const [projectMaster, setProjectMaster] = useState([])
  const [search, setSearch] = useState("")
  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Revenue",
      value: 34152,
      prefix: "$",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Orders",
      value: 5643,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Customers",
      value: 45254,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "6.24%",
      color: "danger",
      desc: "since last week",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Growth",
      value: 12.58,
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "10.51%",
      color: "success",
      desc: "since last week",
      series: series4,
      options: options4,
    },
  ]
  const [fetchData, setFetchData] = useState()
  const [accBalance, setAccBalance] = useState()
  const [profile, setProfile] = useState()
  const [username, setusername] = useState("Admin")
  const [projectData, setProjectData] = useState({
    currentPage: 1,
    totalItems: 12,
    data: {
      current_page: 1,
      from: 1,
      last_page: 2,
      per_page: 10,
      prev_page_url: null,
      to: 10,
      total: 12,
      data: [
        {
          id: 1,
          name: "Monumental Construction",
          address: "20th floor project",
          status: 1,
        },
        {
          id: 2,
          name: "Skyline Residences",
          address: "20th floor project",
          status: 1,
        },
        {
          id: 3,
          name: "Green Meadows Apartments",
          address: "20th floor project",
          status: 0,
        },
        {
          id: 4,
          name: "Maplewood Villas",
          address: "20th floor project",
          status: 0,
        },
        {
          id: 5,
          name: "Blue Horizon Apartments",
          address: "20th floor project",
          status: 1,
        },
        {
          id: 6,
          name: "Blue Horizon Apartments",
          address: "20th floor project",
          status: 1,
        },
      ],
    },
  })
  const [mrData, setMrData] = useState({
    currentPage: 1,
    totalItems: 12,
    data: {
      current_page: 1,
      from: 1,
      last_page: 2,
      per_page: 10,
      prev_page_url: null,
      to: 10,
      total: 12,
      data: [
        {
          id: 1,
          name: "Horizon Plaza",
          address: "20th floor project",
          mr_code: "MR0026",
          date: "24/06/2024",
          status: 1,
        },
        {
          id: 2,
          name: "Pinnacle Towers",
          address: "20th floor project",
          mr_code: "MR0025",
          date: "24/06/2024",
          status: 1,
        },
        {
          id: 3,
          name: "CityGate Center",
          address: "20th floor project",
          mr_code: "MR0024",
          date: "24/06/2024",
          status: 0,
        },
        {
          id: 4,
          name: "Nexus Business Park",
          address: "20th floor project",
          mr_code: "MR0023",
          date: "24/06/2024",
          status: 0,
        },
        {
          id: 5,
          name: "Summit Corporate Park",
          address: "20th floor project",
          mr_code: "MR0022",
          date: "24/06/2024",
          status: 1,
        },
      ],
    },
  })
  const [newProjectData, setNewProjectData] = useState({
    currentPage: 1,
    totalItems: 12,
    data: {
      current_page: 1,
      from: 1,
      last_page: 2,
      per_page: 10,
      prev_page_url: null,
      to: 10,
      total: 12,
      data: [
        {
          id: 1,
          name: "Monumental Construction ",
          address: "20th floor project",
          status: 1,
        },
        {
          id: 2,
          name: "Monumental Construction",
          address: "20th floor project",
          status: 1,
        },
        {
          id: 3,
          name: "Monumental Construction",
          address: "20th floor project",
          status: 0,
        },
        {
          id: 4,
          name: "Monumental Construction",
          address: "20th floor project",
          status: 0,
        },
        {
          id: 5,
          name: "Monumental Construction",
          address: "20th floor project",
          status: 1,
        },
      ],
    },
  })
  const [page, setPage] = useState("")

  // modal view
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const chartData = {
    series: [100, 80],

    options3: {
      fill: {
        colors: ["#626354", "#989898"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          startAngle: 180, // Start from the bottom
          endAngle: 359,
          hollow: {
            margin: 0,
            size: "70%",
          },

          track: {
            margin: 5,
            background: "transparent",
            strokeWidth: "40%",
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
            },
            total: {
              show: true,
              label: [30, "Projects"],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  }
  const chartDataIncome = {
    series: [80, 90, 30],

    options3: {
      fill: {
        colors: ["#383838", "#989898", "#DCDCDC"],
      },
      chart: {
        sparkline: {
          enabled: !0,
        },
      },
      dataLabels: {
        enabled: 1,
      },
      plotOptions: {
        radialBar: {
          startAngle: 180, // Start from the bottom
          endAngle: 360,
          hollow: {
            margin: 30,
            size: "60%",
            background: "#171717",
          },

          track: {
            margin: 5,
            background: "transparent",
            strokeWidth: "40%",
          },
          dataLabels: {
            show: true,
            value: {
              color: "black",
              fontSize: "30px",
              show: false,
              fontWeight: 700,
              label: "Filled",
            },
            name: {
              show: true,
              color: "black",
              fontSize: "10px",
              fontWeight: 400,
              offsetY: 10,
            },
            total: {
              show: true,
              label: [`10L`, ""],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      // if (obj.username) {
      //   setusername(obj.username)
      // } else {
      setusername(obj.name)
      setProfile(obj?.photo)
      // }
      // }
    }
  }, [loggedUser])
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchDashboard()
      setFetchData(response)
    }
    fetchData()
    setAccBalance(
      {
        series: [
          {
            name: "Income",
            data: [
              1000, 6500, 7600, 8008, 1000.5, 2100, 2229, 1138, 1139, 4442.2,
              4000, 4732.3,
            ],
          },
          {
            name: "Expense",
            data: [
              -8000, -1000, -6000, -1900, -9000, -500, -10000, -7000, -3096,
              -4022, -4223, -4344,
            ],
          },
        ],
        chart: {
          // type: "bar", // Change to 'bar' to maintain compatibility with column charts
          height: 440,
          stacked: true,
        },
        colors: ["#525252", "#E9E9E9"],
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "all",
            horizontal: false, // Set to false for column chart
            barHeight: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          stepSize: 1,
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            },
          },
          y: {
            formatter: function (val) {
              return Math.abs(val) + "%"
            },
          },
        },
        title: {
          text: "",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "",
          },
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + "%"
            },
          },
        },
      }
      //   {
      //   series: [
      //     {
      //       name: "Positive Inflation",
      //       data: [2.3, 4.0, 3.0, 5.0, 4.0, 2.5, 3.0, 2.3, 1.4, 0.8, 0.5, 0.2],
      //     },
      //     {
      //       name: "Negative Inflation",
      //       data: [
      //         -1.1, -2.0, -1.5, -3.0, -2.5, -1.5, -1.2, -1.0, -0.8, -0.4, -0.2,
      //         -0.1,
      //       ],
      //     },
      //   ],
      //   chart: {
      //     height: 350,
      //     // type: "bar",
      //     // stacked: false, // Ensure bars are not stacked
      //   },
      //   plotOptions: {
      //     bar: {
      //       borderRadius: 10,
      //       horizontal: false,
      //       columnWidth: "50%", // Adjust bar width
      //       dataLabels: {
      //         position: "top", // top, center, bottom
      //       },
      //     },
      //   },
      //   dataLabels: {
      //     enabled: true,
      //     formatter: function (val) {
      //       return val + "%"
      //     },
      //     offsetY: -20,
      //     style: {
      //       fontSize: "12px",
      //       colors: ["#304758"],
      //     },
      //   },
      //   xaxis: {
      //     categories: [
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Dec",
      //     ],
      //     position: "bottom",
      //     axisBorder: {
      //       show: false,
      //     },
      //     axisTicks: {
      //       show: false,
      //     },
      //     tooltip: {
      //       enabled: true,
      //     },
      //   },
      //   yaxis: {
      //     axisBorder: {
      //       show: false,
      //     },
      //     axisTicks: {
      //       show: false,
      //     },
      //     labels: {
      //       formatter: function (val) {
      //         return val + "%"
      //       },
      //     },
      //     title: {
      //       text: "Inflation Rate (%)",
      //       style: {
      //         color: "#444",
      //       },
      //     },
      //   },
      //   title: {
      //     text: "",
      //     floating: true,
      //     offsetY: 330,
      //     align: "center",
      //     style: {
      //       color: "#444",
      //     },
      //   },
      //   legend: {
      //     position: "top",
      //     horizontalAlign: "center",
      //   },
      // }
    )
  }, [])

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchProjectMaster({
        params: `${search ? `&searchText=${search}` : "&searchText"}`,
      })
      setProjectMaster(response?.data)
    }
    fetchClass()
  }, [refresh])

  const handleStatus = data => {}

  const handlePaginate = page => {
    setPage(page)
  }

  const columns = [
    {
      label: "",
      renderCell: item => {
        return (
          <div className="d-flex">
            <img className="" src={dummyProd} alt="icon" />
            <div className="ps-3">
              <div
                onClick={() =>
                  navigate(`/hrms-final-settlement/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.name}
              </div>
              <div>{item?.address}</div>
            </div>
          </div>
        )
      },
    },
    {
      label: "",
      renderCell: item => (
        <div
          className={`fs-13 ${
            item?.status == 1 ? "status-active-new" : "status-pending-new"
          }`}
        >
          {item?.status == 1 ? "Completed" : "Under Process"}
        </div>
      ),
    },
  ]
  const columnsMR = [
    {
      label: "Project Name",
      renderCell: item => {
        return (
          <div className="d-flex">
            <img className="" src={dummyProd} alt="icon" />
            <div className="ps-3">
              <div
                onClick={() =>
                  navigate(`/hrms-final-settlement/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.name}
              </div>
              <div>{item?.address}</div>
            </div>
          </div>
        )
      },
    },
    {
      label: "MR No",
      renderCell: item => (
        <div className="capitalize-letter">
          {item?.mr_code ? item?.mr_code : "-"}
        </div>
      ),
    },
    {
      label: "Req. Date",
      renderCell: item => (
        <div className="capitalize-letter">{item?.date ? item?.date : "-"}</div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div
          className={`fs-13 ${
            item?.status == 1 ? "status-active-new" : "status-pending-new"
          }`}
        >
          {item?.status == 1 ? "Recived" : "Recieving"}
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => <div className="fs-13 view-income">View</div>,
    },
  ]
  const columnsNP = [
    {
      label: "Project Name",
      renderCell: item => {
        return (
          <div className="d-flex">
            <img className="" src={dummyProd} alt="icon" />
            <div className="ps-3">
              <div
                onClick={() =>
                  navigate(`/hrms-final-settlement/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.name}
              </div>
              <div>{item?.address}</div>
            </div>
          </div>
        )
      },
    },
    {
      label: "Status",
      renderCell: item => (
        <div className="fs-13 status-active-new">Est. Completed</div>
      ),
    },
    {
      label: "",
      renderCell: item => <div className="fs-13 view-income">Follow Up</div>,
    },
  ]

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <div className="page-content px-2">
        <Card className="me-2 px-2 pb-2 main-dashboard-bg">
          <Container fluid>
            {/* <Breadcrumbs title="Everrise" breadcrumbItem="Dashboard" /> */}
            {/* {loggedUser?.data?.roles?.some(
              roles => roles?.name === "Project Manager"
            ) ? (
              <>
                <Row>
                  <Col sm={4}>
                    <div className="d-flex align-items-center dashboard-card-pm p-3">
                      <img
                        className="rounded-circle dashboard-profile"
                        src={profile != "" ? `${imgUrl}${profile}` : user4}
                        alt="Header Avatar"
                      />
                      <div className="ms-3">
                        <div className="fw-600 fs-14">{username}</div>
                        <div className="fs-13 color-gray">Project Manager</div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={8}>
                    <div className="d-flex align-items-center dashboard-card-pm p-3">
                      <img
                        className="dashboard-profile"
                        src={dollarIcon}
                        alt="icon"
                      />
                      <div className="ms-3">
                        <div className="color-gray fs-15">Total Projects</div>
                        <div className="fs-24 gross-head">
                          {projectMaster ? projectMaster?.total : 0}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col sm={6}>
                    <div className="finance-chart">
                      <Card>
                        <div className="finance-dash-head">Project Status</div>
                        <Row>
                          <Col sm={5}>
                            <ReactApexChart
                              type="radialBar"
                              series={chartData?.series}
                              options={chartData?.options3}
                              height={250}
                            />
                          </Col>
                          <Col sm={7} className="align-self-center">
                            <div className="px-3">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex me-2 mb-3 align-items-center">
                                  <div className="chart-markings-completed me-2"></div>
                                  <div>Completed</div>
                                </div>
                                <div>30</div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex me-2 align-items-center">
                                  <div className="chart-markings-upcoming me-2"></div>
                                  <div>Under Process</div>
                                </div>
                                <div>2</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="finance-chart">
                      <Card>
                        <div className="finance-dash-head">Income Overview</div>
                        <Row>
                          <Col sm={5}>
                            <div className="dash-chart">
                              <ReactApexChart
                                type="radialBar"
                                series={chartDataIncome?.series}
                                options={chartDataIncome?.options3}
                                height={250}
                              />
                            </div>
                          </Col>
                          <Col sm={7} className="align-self-center">
                            <div className="px-3">
                              <Row className="d-flex align-items-center pb-2">
                                <Col sm={1}>
                                  <div className="chart-markings-income-bal me-2"></div>
                                </Col>
                                <Col sm={4}>
                                  <div>Project value </div>
                                </Col>
                                <Col sm={5}>
                                  <div className="d-flex justify-content-between px-2 py-1 input-chart">
                                    <div>1000000</div>
                                    <div>AED</div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center pb-2">
                                <Col sm={1}>
                                  <div className="chart-markings-income-invoice me-2"></div>
                                </Col>
                                <Col sm={4}>
                                  <div>Invoiced </div>
                                </Col>
                                <Col sm={5}>
                                  <div className="d-flex justify-content-between px-2 py-1 input-chart">
                                    <div>1000000</div>
                                    <div>AED</div>
                                  </div>
                                </Col>
                                <Col>
                                  <div
                                    className="fs-13 view-income pointer"
                                    onClick={() => {
                                      handleView({
                                        name: "pending_invoice",
                                        id: 1,
                                      })
                                    }}
                                  >
                                    View
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center pb-2">
                                <Col sm={1}>
                                  <div className="chart-markings-pending-invoice me-2"></div>
                                </Col>
                                <Col sm={4}>
                                  <div>Pending Invoice</div>
                                </Col>
                                <Col sm={5}>
                                  <div className="d-flex justify-content-between px-2 py-1 input-chart">
                                    <div>1000000</div>
                                    <div>AED</div>
                                  </div>
                                </Col>
                                <Col>
                                  <div
                                    className="fs-13 view-income pointer"
                                    onClick={() => {
                                      handleView({
                                        name: "pending_invoice",
                                        id: 1,
                                      })
                                    }}
                                  >
                                    View
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center pb-2">
                                <Col sm={1}>
                                  <div className="chart-markings-pending-invoice me-2"></div>
                                </Col>
                                <Col sm={4}>
                                  <div>Balance</div>
                                </Col>
                                <Col sm={5}>
                                  <div className="d-flex justify-content-between px-2 py-1 input-chart">
                                    <div>750000</div>
                                    <div>AED</div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col sm={5}>
                    <div className="projectmanager-card">
                      <Card className="p-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="finance-dash-head">
                            List of projects
                          </div>
                          <div className="status-active-new">30 Projects</div>
                          <div className="pe-2">
                            <Status
                              control={control}
                              name={"status"}
                              onSelectChange={e => handleStatus(e)}
                              dashboardBg={true}
                            />
                          </div>
                        </div>
                        <hr className="table-divide" />
                        <Row className="mt-2 ever-rise-table">
                          <Col xs={12}>
                            {projectData && (
                              <UpdateTable
                                className="mt-5"
                                nodes={projectData?.data?.data}
                                paginate={projectData?.data}
                                columns={columns}
                                CurrentPage={page}
                                handlePagination={handlePaginate}
                                tableWidth="60% 40%"
                                headerHide={true}
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                  <Col sm={7}>
                    <div className="projectmanager-card">
                      <Card className="p-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="finance-dash-head">
                            Material Request
                          </div>
                        </div>
                        <Row className="mt-2 ever-rise-table">
                          <Col xs={12}>
                            {mrData && (
                              <UpdateTable
                                className="mt-5"
                                nodes={mrData?.data?.data}
                                paginate={mrData?.data}
                                columns={columnsMR}
                                CurrentPage={page}
                                handlePagination={handlePaginate}
                                tableWidth="45% 15% 15% 15% 10%"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col sm={6}>
                    <div className="projectmanager-card">
                      <Card className="p-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="finance-dash-head">New projects</div>
                        </div>
                        <Row className="mt-2 ever-rise-table">
                          <Col xs={12}>
                            {newProjectData && (
                              <UpdateTable
                                className="mt-5"
                                nodes={newProjectData?.data?.data}
                                paginate={newProjectData?.data}
                                columns={columnsNP}
                                CurrentPage={page}
                                handlePagination={handlePaginate}
                                tableWidth="60% 25% 15%"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <> */}
                <Row className="mt-3">
                  <MiniWidget
                    fetchData={fetchData}
                    accBalance={accBalance}
                    reports={reports}
                  />
                </Row>
                {/* <LatestTransaction /> */}
                {/* <Row>
            <Col xl={8}>
              <SalesAnalyticsChart />
            </Col>
            <Col xl={4}>
              <Card className="bg-primary">
                <CardBody>
                  <Row className="align-items-center">
                    <Col sm={8}>
                      <p className="text-white font-size-18">
                        Enhance your <b>Campaign</b> for better outreach{" "}
                        <i className="mdi mdi-arrow-right"></i>
                      </p>
                      <div className="mt-4">
                        <Link
                          to="#"
                          className="btn btn-success waves-effect waves-light"
                        >
                          Upgrade Account!
                        </Link>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mt-4 mt-sm-0">
                        <img
                          src={setupanalytics}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <TopProduct />
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <TopUser />
            </Col>
            <Col xl={4}>
              <RecentActivity />
            </Col>
            <Col xl={4}>
              <SocialSource />
            </Col>
          </Row>
          <LatestTransaction /> */}
              {/* </>
            )} */}
          </Container>
        </Card>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size={
            viewData?.id == 1 || viewData?.id == 2 || viewData?.id == 3
              ? "lg"
              : "md"
          }
          unmountOnClose={false}
        >
          <>
            <ModalHeader className="custom-modal-header">
              <div className="d-flex justify-content-between">
                <h5 className="ms-4">
                  {viewData?.id == 2 ? "Project Progress" : ""}
                </h5>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={toggle}
                  width="10"
                  role="button"
                />
              </div>
            </ModalHeader>
            <ModalBody>
              {viewData?.id == 1 && <InvoiceView fromDashboard={true} />}
            </ModalBody>
          </>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
