import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import LoadingButton from "../../../components/Common/LoadingButton"
import Location from "../../../components/Common/chooseFields/location"
import lightTrash from "../../../assets/icons/light-trash.svg"
import Priority from "../../../components/Common/chooseFields/priority"
import {
  fetchMRRequest,
  fetchRequest,
  postMaterialRequest,
  postMaterialRequestFinance,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import Productcode from "../../../components/Common/chooseFields/Product"
import { fetchProjecrtMaster } from "../../../services/projectManager/projectMaster"
import MaterialProjectNo from "../../../components/Common/chooseFields/materailProject"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import MaterialProductList from "../../../components/Common/chooseFields/materialProductList"
import { useSelector } from "react-redux"
import EstimationClass from "../../../components/Common/chooseFields/estimateClass"
import EstimationSubCategory from "../../../components/Common/chooseFields/estimationSubCategory"
import SupplierCodeList from "../../../components/Common/chooseFields/supplierCodeList"
import Unit from "../../../components/Common/chooseFields/unit"
import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory"
import { fetchSubcontractRequest, postSubcontract } from "../../../services/procurement/subContracts"

// const schema = yup.object().shape({
//   project_code: yup.mixed().required("project code is required"),
//   request_date: yup
//     .mixed()
//     .test(
//       "is-future-date",
//       "Make sure the request date is forward-looking",
//       function (value) {
//         if (!value) return true
//         const currentDate = moment()
//         const selectedDate = moment(value)
//         return selectedDate.isSameOrAfter(currentDate, "day")
//       }
//     )
//     .required("Delivery Date is required"),
//   exp_Delivery_date: yup
//     .mixed()
//     .test(
//       "is-future-date",
//       "Make sure the exp delivery date is forward-looking",
//       function (value) {
//         if (!value) return true
//         const currentDate = moment()
//         const selectedDate = moment(value)
//         return selectedDate.isSameOrAfter(currentDate, "day")
//       }
//     )
//     .required("Delivery Date is required"),
//   supplier_id: yup.mixed().required("Supplier id is required"),
//   material_req: yup.array().of(
//     yup.object().shape({
//       product_id: yup.mixed().required("product code is required"),
//       descriptions: yup.string().required("description is required"),
//       quantity: yup
//         .string()
//         .matches(
//           /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
//           "Quantity must be a non-negative number and cannot be zero"
//         )
//         .required("Quantity is required"),
//     })
//   ),
// })

const EditSubcontractRequest = ({ fromDashboard, projectValue, toggle }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const now = moment()
  const [isLoading, setIsLoading] = useState(false)
  const [projectID, setProjectID] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_code: null,
      supplier_id: null,
      estimated_cost: "",
      request_date: moment(now, "YYYY-MM-DD").toDate(),
      description: "",
      subcontracts: [
        {
          class_id: "",
          category_id: "",
          description: "",
          duration: "",
          unit: "",
        },
      ],
    },
  })
  const {
    fields: subcontractFields,
    append: subcontractAppend,
    remove: subcontractRemove,
  } = useFieldArray({
    control,
    name: "subcontracts",
  })

  // const handleRegistration = async data => {
  //   const Requestarray = data?.material_req?.map(dt => ({
  //     id: dt?.id,
  //     material_id: dt?.product_id?.value,
  //     // priority: dt?.priority?.value,
  //     description: dt?.description,
  //     requested_quantity: dt?.quantity,
  //     unit_id: dt?.unit_id,
  //   }))
  //   try {
  //     action === 1 ? setIsDraftLoading(true) : setIsLoading(true)
  //     const update = await postMaterialRequestFinance({
  //       ...data,
  //       save_as_draft: action == 1 ? 0 : 1,
  //       requested_by: loggedUser?.data?.id,
  //       contact_number: 0,
  //       delivery_location: data?.delivery_location,
  //       project_id: data?.project_code?.value,
  //       materials: Requestarray,
  //       exp_Delivery_date: moment(data?.exp_Delivery_date).format("YYYY-MM-DD"),
  //       request_date: moment(data?.request_date).format("YYYY-MM-DD"),
  //     })
  //     if (fromDashboard) {
  //       toggle()
  //     } else {
  //       navigate("/procurement-materialreq")
  //     }
  //     toastSuccess(update?.message)
  //   } catch (err) {
  //     toastError(err?.message)
  //   } finally {
  //     setIsLoading(false)
  //     setIsDraftLoading(false)
  //   }
  // }
  const handleRegistration = async data => {
    console.log("datadata", data)
    const Requestarray = data?.subcontracts?.map(item => ({
      item_name: item?.category_id?.value,
      item_description: item?.description,
      quantity: item?.duration,
      unit_price: item?.unit?.value,
    }))
    try {
      setIsLoading(true)
      const update = await postSubcontract({
        ...data,
        id:id,
        project_id: data?.project_code?.value,
        supplier_id: data?.supplier_id?.value,
        request_date: moment(data?.request_date).format("YYYY-MM-DD"),
        description: data?.description,
        estimated_cost: data?.estimated_cost,
        items: Requestarray,
      })
      // reset({
      //   project_code: null,
      //   supplier_id: null,
      //   estimated_cost: "",
      //   request_date: moment(now, "YYYY-MM-DD").toDate(),
      //   description: "",
      //   subcontracts: [
      //     {
      //       class_id: "",
      //       category_id: "",
      //       description: "",
      //       duration: "",
      //       unit: "",
      //     },
      //   ],
      // })
      toastSuccess(update?.message)
      navigate("/procurement-subcontractreq")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchSCdata = async () => {
      const responce = await fetchSubcontractRequest(id)
      console.log(`responce edit`, responce)
      const contractarray = responce?.data?.items?.map((data) => ({
        class_id: {
          label: data?.category?.classcode?.class_code,
          value: data?.category?.class_id
        },
        category_id: {
          label: data?.category?.category_code,
          value: data?.category?.id
        },
        description: data?.item_description,
        duration: data?.quantity,
        // unit : {
        //   value: data?.unit_price,
        //   label: data
        // }
      }))
      reset({
        project_code: {
          label: responce?.data?.project?.project_code,
          value: responce?.data?.project?.id
        },
        supplier_id: {
          label: responce?.data?.supplier?.suppliers_code,
          value: responce?.data?.supplier?.id
        },
        estimated_cost: responce?.data?.estimated_cost,
        request_date: moment(responce?.data?.created_at).format("YYYY-MM-DD"),
        description: responce?.data?.description,
        subcontracts: contractarray,
      })
    }
    if(id) {
      fetchSCdata()
    }
  },[id])

  // const handleProjectChange = () => {
  //   const { project_code } = getValues()
  //   fetchProjectData(project_code?.value)
  //   setProjectID(project_code?.value)
  // }

  // useEffect(() => {
  //   if (fromDashboard) {
  //     setValue("project_code", projectValue)
  //     fetchProjectData(id)
  //     setProjectID(id)
  //   }
  //   const fetchMrEdit = async id => {
  //     const responce = await fetchMRRequest(id)
  //     console.log(`responce edit`, responce)
  //     const materialsArr = responce?.data?.materials?.map(dt => ({
  //       product_id: {
  //         label: dt?.product?.product_code,
  //         value: dt?.product?.id,
  //       },
  //       quantity: dt?.requested_quantity,
  //       description: dt?.product?.description,
  //     }))
  //     reset({
  //       ...responce?.data,
  //       project_code: {
  //         label: responce?.data?.project?.project_code,
  //         value: responce?.data?.project?.id,
  //       },
  //       project_name: responce?.data?.project?.project_name,
  //       exp_Delivery_date: responce?.data?.exp_Delivery_date,
  //       material_req: materialsArr,
  //     })
  //   }
  //   if (id) {
  //     fetchMrEdit(id)
  //   }
  // }, [fromDashboard, id])

  // const fetchProjectData = async idData => {
  //   const response = await fetchProjecrtMaster(idData)
  //   console.log("data", response)
  //   if (idData == response.data?.id) {
  //     const Requestarray = response.data?.project_material
  //       ?.map(dt => {
  //         const projectMrQuantitySum = dt.project_mr.reduce(
  //           (total, mr) => total + parseFloat(mr.quantity),
  //           0
  //         )

  //         const alreadyReceived = dt.project_mr.length
  //           ? dt.quantity - projectMrQuantitySum
  //           : 0
  //         const balanceQty = dt.project_mr.length ? projectMrQuantitySum : 0
  //         const quantity = dt.project_mr.length ? alreadyReceived : dt.quantity
  //         return {
  //           product_id: {
  //             value: dt?.product_code?.id,
  //             label: dt?.product_code?.product_code,
  //             storeCount: dt?.products?.storeCount,
  //           },
  //           description: dt?.description,
  //           quantity: quantity,
  //           initial_quantity: dt?.quantity,
  //           received_quantity: balanceQty,
  //           unit_id: dt?.unit?.id,
  //         }
  //       })
  //       .filter(item => item.quantity !== 0)
  //     const previousDatas = getValues()
  //     reset({
  //       ...previousDatas,
  //       project_name: response.data?.project_name,
  //       material_req: Requestarray,
  //       delivery_location: response.data?.location?.name,
  //     })
  //   }
  // }
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between pe-2">
                  {!fromDashboard ? (
                    <Breadcrumbs
                      title="Procurement"
                      breadcrumbObject={[
                        { title: "Procurement", link: "/procurement" },
                        {
                          title: "Subcontract Request",
                          link: "/procurement-subcontractreq",
                        },
                        {
                          title: id
                            ? "Edit Subcontract Request"
                            : "Add Subcontract Request",
                          link: "",
                        },
                      ]}
                    />
                  ) : (
                    <div></div>
                  )}
                  <div>
                  {id ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      className="btn-sm btn-rounded waves-effect waves-light mb-3 me-1"
                      loading={isLoading}
                    >
                      Update
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      className="btn-sm btn-rounded waves-effect waves-light mb-3 me-1"
                      loading={isLoading}
                    >
                      Submit
                    </LoadingButton>
                  )}
                  </div>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-1 mt-2">
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project Code<span className="mandatory">*</span>
                        </Label>
                        <MaterialProjectNo
                          control={control}
                          placeholder="Enter the project code"
                          name={"project_code"}
                          target={fromDashboard ? ".modal" : "body"}
                          // onSelectChange={handleProjectChange}
                          invalid={!!errors.project_code}
                          disabled={fromDashboard}
                        />
                        <FormFeedback invalid>
                          {errors.project_code?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Supplier Id<span className="mandatory">*</span>
                        </Label>
                        <SupplierCodeList
                          control={control}
                          name={"supplier_id"}
                          placeholder={"Enter the supplier id"}
                          target={fromDashboard ? ".modal" : "body"}
                          invalid={!!errors.supplier_id}
                        />
                        <FormFeedback invalid>
                          {errors.supplier_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Request date <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"request_date"}
                          invalid={!!errors.request_date}
                        />
                        <FormFeedback invalid>
                          {errors.request_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Estimated cost</Label>
                        <UFInput
                          control={control}
                          name={"estimated_cost"}
                          placeholder={"Enter estimated cost"}
                          invalid={!!errors.estimated_cost}
                        />
                        <FormFeedback invalid>
                          {errors.estimated_cost?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>Description</Label>
                        <UFEditor
                          control={control}
                          name={"description"}
                          placeholder={"Description"}
                          rows="1"
                          invalid={!!errors.sescription}
                        />
                        <FormFeedback invalid>
                          {errors.description?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  <Row className="py-2 mt-1">
                    <h5>Subcontract Details</h5>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th className="wdt-15">Class </th>
                            <th className="wdt-20">Category </th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th className="wdt-5">Qty</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {subcontractFields.map((item, index) => (
                            <tr key={`subcontracts${item.id || index}`}>
                              <td className="ps-1">
                                <EstimationClass
                                  control={control}
                                  placeholder="Class"
                                  name={`subcontracts.${index}.class_id`}
                                  type={"subcontracts"}
                                  // onSelectChange={e => handleContractClass(index)}
                                  dropView={true}
                                />
                              </td>
                              <td className="ps-1">
                                <EstimationCategory
                                  control={control}
                                  placeholder="Category"
                                  name={`subcontracts.${index}.category_id`}
                                  type={"subcontracts"}
                                  classCode={watch(
                                    `subcontracts.${index}.class_id`
                                  )}
                                  // onSelectChange={e =>
                                  //   handleContractCatChange(e, index)
                                  // }
                                  dropView={true}
                                />
                              </td>
                              <td className="ps-1">
                                <UFInput
                                  control={control}
                                  placeholder="Description"
                                  name={`subcontracts.${index}.description`}
                                />
                              </td>
                              <td className="ps-1">
                                <Unit
                                  control={control}
                                  placeholder={"Unit"}
                                  name={`subcontracts.${index}.unit`}
                                  disabled={
                                    watch(`subcontracts.${index}.category_id`)
                                      ?.value
                                      ? false
                                      : true
                                  }
                                  dropView={true}
                                />
                              </td>
                              <td className="ps-1">
                                <UFInput
                                  control={control}
                                  placeholder="Qty"
                                  name={`subcontracts.${index}.duration`}
                                />
                              </td>
                              <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => {
                                      subcontractRemove(index)
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        <Button
                          color="secondary"
                          className="addButton mt-2"
                          onClick={() =>
                            subcontractAppend({
                              class_id: "",
                              category_id: "",
                              description: "",
                              duration: "",
                            })
                          }
                        >
                          Add Row
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditSubcontractRequest

// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import {
//   Button,
//   Card,
//   Col,
//   Container,
//   Form,
//   FormFeedback,
//   FormGroup,
//   Label,
//   Row,
// } from "reactstrap";
// import { FormProvider, useFieldArray, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { toastError, toastSuccess } from "../../../helpers/msg_helper";
// import {
//   UFDate,
//   UFEditor,
//   UFInput,
// } from "../../../components/Common/Fields/Input";
// import PaymentMode from "../../../components/Common/chooseFields/paymentMode";
// import LoadingButton from "../../../components/Common/LoadingButton";
// import Location from "../../../components/Common/chooseFields/location";
// import lightTrash from "../../../assets/icons/light-trash.svg";
// import Priority from "../../../components/Common/chooseFields/priority";
// import {
//   fetchMRRequest,
//   fetchRequest,
//   postMaterialRequest,
//   postMaterialRequestFinance,
// } from "../../../services/procurement/materialrequest";
// import moment from "moment";
// import Productcode from "../../../components/Common/chooseFields/Product";
// import { fetchProjecrtMaster } from "../../../services/projectManager/projectMaster";
// import MaterialProjectNo from "../../../components/Common/chooseFields/materailProject";
// import PhoneInputField from "../../../components/Common/Fields/phoneInput";
// import MaterialProductList from "../../../components/Common/chooseFields/materialProductList";
// import { useSelector } from "react-redux";
// import EstimationClass from "../../../components/Common/chooseFields/estimateClass";
// import EstimationSubCategory from "../../../components/Common/chooseFields/estimationSubCategory";
// import SupplierCodeList from "../../../components/Common/chooseFields/supplierCodeList";
// import Unit from "../../../components/Common/chooseFields/unit";
// import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory";

// // const schema = yup.object().shape({
// //   project_code: yup.mixed().required("project code is required"),
// //   request_date: yup
// //     .mixed()
// //     .test(
// //       "is-future-date",
// //       "Make sure the request date is forward-looking",
// //       function (value) {
// //         if (!value) return true;
// //         const currentDate = moment();
// //         const selectedDate = moment(value);
// //         return selectedDate.isSameOrAfter(currentDate, "day");
// //       }
// //     )
// //     .required("Delivery Date is required"),
// //   exp_Delivery_date: yup
// //     .mixed()
// //     .test(
// //       "is-future-date",
// //       "Make sure the exp delivery date is forward-looking",
// //       function (value) {
// //         if (!value) return true;
// //         const currentDate = moment();
// //         const selectedDate = moment(value);
// //         return selectedDate.isSameOrAfter(currentDate, "day");
// //       }
// //     )
// //     .required("Delivery Date is required"),
// //   supplier_id: yup.mixed().required("Supplier id is required"),
// //   material_req: yup.array().of(
// //     yup.object().shape({
// //       product_id: yup.mixed().required("product code is required"),
// //       descriptions: yup.string().required("description is required"),
// //       quantity: yup
// //         .string()
// //         .matches(
// //           /^(?!0+(\.0+)?$)\d*(\.\d+)?$/,
// //           "Quantity must be a non-negative number and cannot be zero"
// //         )
// //         .required("Quantity is required"),
// //     })
// //   ),
// // });

// const EditSubcontractRequest = ({ fromDashboard, projectValue, toggle }) => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const now = moment();
//   const [isLoading, setIsLoading] = useState(false);
//   const [projectID, setProjectID] = useState();

//   const loggedUser = useSelector((state) => state.Login.loggedUser);

//   const {
//     control,
//     handleSubmit,
//     watch,
//     reset,
//     getValues,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     // resolver: yupResolver(schema),
//     mode: "onChange",
//     shouldFocusError: true,
//     defaultValues: {
//       project_code: null,
//       supplier_id: null,
//       estimated_cost: "",
//       request_date: moment(now, "YYYY-MM-DD").toDate(),
//       description: "",
//       subcontracts: [
//         {
//           class_id: "",
//           category_id: "",
//           description: "",
//           duration: "",
//         },
//       ],
//     },
//   });

//   const {
//     fields: subcontractFields,
//     append: subcontractAppend,
//     remove: subcontractRemove,
//   } = useFieldArray({
//     control,
//     name: "subcontracts",
//   });

//   const handleRegistration = async (data) => {
//     console.log("Form Data:", data);
//   };

//   const handleProjectChange = () => {
//     const { project_code } = getValues();
//     fetchProjectData(project_code?.value);
//     setProjectID(project_code?.value);
//   };

//   useEffect(() => {
//     if (fromDashboard) {
//       setValue("project_code", projectValue);
//       fetchProjectData(id);
//       setProjectID(id);
//     }
//     const fetchMrEdit = async (id) => {
//       const responce = await fetchMRRequest(id);
//       console.log(`responce edit`, responce);
//       const materialsArr = responce?.data?.materials?.map((dt) => ({
//         product_id: {
//           label: dt?.product?.product_code,
//           value: dt?.product?.id,
//         },
//         quantity: dt?.requested_quantity,
//         description: dt?.product?.description,
//       }));
//       reset({
//         ...responce?.data,
//         project_code: {
//           label: responce?.data?.project?.project_code,
//           value: responce?.data?.project?.id,
//         },
//         project_name: responce?.data?.project?.project_name,
//         exp_Delivery_date: responce?.data?.exp_Delivery_date,
//         material_req: materialsArr,
//       });
//     };
//     if (id) {
//       fetchMrEdit(id);
//     }
//   }, [fromDashboard, id]);

//   const fetchProjectData = async (idData) => {
//     const response = await fetchProjecrtMaster(idData);
//     console.log("data", response);
//     if (idData == response.data?.id) {
//       const Requestarray = response.data?.project_material
//         ?.map((dt) => {
//           const projectMrQuantitySum = dt.project_mr.reduce(
//             (total, mr) => total + parseFloat(mr.quantity),
//             0
//           );

//           const alreadyReceived = dt.project_mr.length
//             ? dt.quantity - projectMrQuantitySum
//             : 0;
//           const balanceQty = dt.project_mr.length ? projectMrQuantitySum : 0;
//           const quantity = dt.project_mr.length ? alreadyReceived : dt.quantity;
//           return {
//             product_id: {
//               value: dt?.product_code?.id,
//               label: dt?.product_code?.product_code,
//               storeCount: dt?.products?.storeCount,
//             },
//             description: dt?.description,
//             quantity: quantity,
//             initial_quantity: dt?.quantity,
//             received_quantity: balanceQty,
//             unit_id: dt?.unit?.id,
//           };
//         })
//         .filter((item) => item.quantity !== 0);
//       const previousDatas = getValues();
//       reset({
//         ...previousDatas,
//         project_name: response.data?.project_name,
//         material_req: Requestarray,
//         delivery_location: response.data?.location?.name,
//       });
//     }
//   };

//   return (
//     <React.Fragment>
//       <div
//         className={`${
//           fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
//         }`}
//       >
//         <div className="container-fluid">
//           <FormProvider>
//             <Form
//               id={`request-form`}
//               onSubmit={handleSubmit(handleRegistration)}
//             >
//               <div className="sticky-top w-100">
//                 <div className="d-flex justify-content-between pe-3">
//                   {!fromDashboard ? (
//                     <Breadcrumbs
//                       title="Procurement"
//                       breadcrumbObject={[
//                         { title: "Procurement", link: "/procurement" },
//                         {
//                           title: "Subcontract Request",
//                           link: "/procurement-subcontractreq",
//                         },
//                         {
//                           title: id
//                             ? "Edit Subcontract Request"
//                             : "Add Subcontract Request",
//                           link: "",
//                         },
//                       ]}
//                     />
//                   ) : (
//                     <div></div>
//                   )}
//                   <div>
//                     <LoadingButton
//                       type="submit"
//                       color="primary"
//                       className="btn-sm btn-rounded waves-effect waves-light mb-3"
//                       loading={isLoading}
//                       form={`request-form`}
//                     >
//                       Submit
//                     </LoadingButton>
//                   </div>
//                 </div>
//               </div>
//               <Container fluid className="ps-lg-0">
//                 <Card className="invoice-main-card p-3 pt-4">
//                   <Row className="mb-1 mt-2">
//                     <Col sm={3}>
//                       <FormGroup className="mb-4">
//                         <Label>
//                           Project Code<span className="mandatory">*</span>
//                         </Label>
//                         <MaterialProjectNo
//                           control={control}
//                           placeholder="Enter the project code"
//                           name={"project_code"}
//                           target={fromDashboard ? ".modal" : "body"}
//                           onSelectChange={handleProjectChange}
//                           invalid={!!errors.project_code}
//                           disabled={fromDashboard}
//                         />
//                         <FormFeedback invalid>
//                           {errors.project_code?.message}
//                         </FormFeedback>
//                       </FormGroup>
//                     </Col>
//                     <Col sm={3}>
//                       <FormGroup className="mb-4">
//                         <Label>
//                           Supplier Id<span className="mandatory">*</span>
//                         </Label>
//                         <SupplierCodeList
//                           control={control}
//                           name={"supplier_id"}
//                           placeholder={"Enter the supplier id"}
//                           target={fromDashboard ? ".modal" : "body"}
//                           invalid={!!errors.supplier_id}
//                         />
//                         <FormFeedback invalid>
//                           {errors.supplier_id?.message}
//                         </FormFeedback>
//                       </FormGroup>
//                     </Col>
//                     <Col sm={3}>
//                       <FormGroup className="mb-4">
//                         <Label>
//                           Request date <span className="mandatory">*</span>
//                         </Label>
//                         <UFDate
//                           control={control}
//                           name={"request_date"}
//                           invalid={!!errors.request_date}
//                         />
//                         <FormFeedback invalid>
//                           {errors.request_date?.message}
//                         </FormFeedback>
//                       </FormGroup>
//                     </Col>
//                     <Col sm={3}>
//                       <FormGroup className="mb-4">
//                         <Label>Estimated cost</Label>
//                         <UFInput
//                           control={control}
//                           name={"estimated_cost"}
//                           placeholder={"Enter estimated cost"}
//                           invalid={!!errors.estimated_cost}
//                         />
//                         <FormFeedback invalid>
//                           {errors.estimated_cost?.message}
//                         </FormFeedback>
//                       </FormGroup>
//                     </Col>
//                     <Col sm={3}>
//                       <FormGroup className="mb-4">
//                         <Label>Description</Label>
//                         <UFEditor
//                           control={control}
//                           name={"description"}
//                           placeholder={"Description"}
//                           rows="1"
//                           invalid={!!errors.sescription}
//                         />
//                         <FormFeedback invalid>
//                           {errors.description?.message}
//                         </FormFeedback>
//                       </FormGroup>
//                     </Col>
//                   </Row>
//                   <hr className="m-0" />
//                   <Row className="py-2 mt-1">
//                     <h5>Subcontract Details</h5>
//                   </Row>
//                   <Row className="mb-4">
//                     <Col lg={12}>
//                       <table className="estimation-table mb-4">
//                         <thead>
//                           <tr>
//                             <th className="wdt-15">Class </th>
//                             <th className="wdt-20">Category </th>
//                             <th>Description</th>
//                             <th>Unit</th>
//                             <th className="wdt-5">Qty</th>
//                             <th></th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {subcontractFields.map((item, index) => (
//                             <tr key={`subcontracts${index}`}>
//                               <td className="ps-1">
//                                 <EstimationClass
//                                   control={control}
//                                   placeholder="Class"
//                                   name={`subcontracts.${index}.class_id`}
//                                   type={"subcontracts"}
//                                   dropView={true}
//                                 />
//                               </td>
//                               <td className="ps-1">
//                                 <EstimationCategory
//                                   control={control}
//                                   placeholder="Category"
//                                   name={`subcontracts.${index}.category_id`}
//                                   type={"subcontracts"}
//                                   classCode={watch(
//                                     `subcontracts.${index}.class_id`
//                                   )}
//                                   dropView={true}
//                                 />
//                               </td>
//                               <td className="ps-1">
//                                 <UFInput
//                                   control={control}
//                                   placeholder="Description"
//                                   name={`subcontracts.${index}.descriptions`}
//                                 />
//                               </td>
//                               <td className="ps-1">
//                                 <Unit
//                                   control={control}
//                                   placeholder={"Unit"}
//                                   name={`subcontracts.${index}.unit`}
//                                   disabled={
//                                     watch(`subcontracts.${index}.category_id`)
//                                       ?.value
//                                       ? false
//                                       : true
//                                   }
//                                   dropView={true}
//                                 />
//                               </td>
//                               <td className="ps-1">
//                                 <UFInput
//                                   control={control}
//                                   placeholder="Qty"
//                                   name={`subcontracts.${index}.duration`}
//                                 />
//                               </td>
//                               <td>
//                                 <div className="pb-1 px-2 d-flex justify-content-center">
//                                   <img
//                                     src={lightTrash}
//                                     alt="light-trash"
//                                     className="trash-img pointer"
//                                     onClick={() => {
//                                       subcontractRemove(index);
//                                     }}
//                                   />
//                                 </div>
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                       <Col
//                         lg="12"
//                         xs={12}
//                         md={2}
//                         sm={12}
//                         className="d-flex justify-content-between"
//                       >
//                         <Button
//                           color="secondary"
//                           className="addButton mt-2"
//                           onClick={() =>
//                             subcontractAppend({
//                               class_id: "",
//                               category_id: "",
//                               descriptions: "",
//                               duration: "",
//                             })
//                           }
//                         >
//                           Add Row
//                         </Button>
//                       </Col>
//                     </Col>
//                   </Row>
//                 </Card>
//               </Container>
//             </Form>
//           </FormProvider>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default EditSubcontractRequest;
