import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import {
  fetchProcurementDashRequest,
  searchManpowerhRequest,
  searchProcurmentDashRequest,
} from "../../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import { FormProvider, useForm } from "react-hook-form"
import closeIcon from "../../../../assets/icons/close.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import redLeftArrow from "../../../../assets/icons/redLeftArrow.svg"
import { downloadmr } from "../../../../services/procurement/materialrequest"
import EditPurchaseOrder from "../../../Procurement/purchaseOrder/edit"
import MRView from "../../../Procurement/materialrequest/view"
import ManReqView from "../../../Procurement/manpowerRequest/view"
import POView from "../../../Procurement/purchaseOrder/view"
import SupplierView from "../../../Procurement/supplier/view"
import GrnView from "../../../Procurement/grn/view"
import ManGrnView from "../../../Procurement/manpowerGRN/view"
import {
  downloadpo,
  updatePOStatus,
} from "../../../../services/procurement/purchaseOrder"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import EditGRN from "../../../Procurement/grn/edit"
import EditManPowerPurchaseOrder from "../../../Procurement/manpowerPO/edit"
import moment from "moment"
import EditManpowerGRN from "../../../Procurement/manpowerGRN/edit"
import ViewPurchaseOrder from "../../../Procurement/manpowerPO/view"
import NumberFormat from "../../../../utils/numberFormat"
const ProcurementIndex = () => {
  document.title = "Procurement Dashboard | Ever Rise"

  const { id } = useParams()
  const navigate = useNavigate()

  //refresh state
  const [refresh, setRefresh] = useState(0)
  const [refresh1, setRefresh1] = useState(0)

  //pagination state
  const [page, setPage] = useState("")

  //view and model chart
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  //response state
  const [materialData, setMaterialData] = useState()
  const [materialRequestData, setMaterialRequestData] = useState()
  const [manpowerRequestData, setManpowerRequestData] = useState()

  const [activeTab, setActiveTab] = useState("1")

  const schema = yup.object().shape({})

  const toggle = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const fetchClass = async () => {
      const response = await fetchProcurementDashRequest(id)
      setMaterialData(response)
    }
    if (id) {
      fetchClass()
    }
  }, [refresh, id])

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchProcurmentDashRequest({
        params: `${"&searchText"}${id ? `&project_id=${id}` : ""}`,
      })
      setMaterialRequestData(response)
    }
    if (id) {
      fetchMaterialSearch()
    }
  }, [refresh1, id])

  useEffect(() => {
    const fetchManpowerSearch = async () => {
      const response = await searchManpowerhRequest({
        params: `${"&searchText"}${activeTab ? `&type=4` : ""}${
          id ? `&project_id=${id}` : ""
        }`,
      })
      setManpowerRequestData(response)
    }
    if (id) {
      fetchManpowerSearch()
    }
  }, [activeTab])

  const handlePostLPO = id => {
    const updateStatus = async id => {
      try {
        const response = await updatePOStatus(id)
        toastSuccess(response?.message)
        setRefresh(refresh + 1)
        setRefresh1(refresh1 + 1)
      } catch (err) {
        toastError(err?.message)
      }
      setRefresh(refresh + 1)
    }
    updateStatus(id)
  }

  const newRequestColumn = [
    {
      label: "MR No",
      renderCell: item => (
        <div
          className="pointer fw-bold"
          onClick={() =>
            handleView({
              id: 4,
              name: "view_MR",
              value: item,
              product_id: "",
            })
          }
        >
          {item?.material_requests_code}
        </div>
      ),
    },

    {
      label: "Date",
      renderCell: item => item?.request_date,
    },

    {
      label: "",
      renderCell: item => (
        <>
          {item?.listing_status == 0 ? (
            <Button
              color={"primary"}
              className={"px-2 btn btn-primary"}
              // onClick={() => downloadmr(item?.id, "mr.pdf")}
              onClick={() =>
                handleView({
                  id: 4,
                  name: "view_MR",
                  value: item,
                  product_id: "",
                })
              }
            >
              {/* Download */}
              View
            </Button>
          ) : (
            <Button
              className="hover-button"
              onClick={() =>
                handleView({
                  id: 1,
                  name: "create_po",
                  value: item,
                  product_id: "",
                })
              }
            >
              Generate PO
            </Button>
          )}
        </>
      ),
    },
  ]
  const recentSupplierColumn = [
    {
      label: "Supplier Name",
      renderCell: item => item?.supplier?.name,
    },

    {
      label: "",
      renderCell: item => (
        <>
          <Button
            className="hover-button"
            onClick={() =>
              handleView({
                id: 6,
                name: "view_supplier",
                value: item,
                product_id: "",
              })
            }
          >
            View
          </Button>
        </>
      ),
    },
  ]
  const recentPOColumns = [
    {
      label: "PO No",
      renderCell: item => item?.purchase_orders_code,
    },

    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div>{NumberFormat((Number(item?.purchaseorder_lists_sum_quantity)))}</div>,
    },
    {
      label: "Date",
      renderCell: item => item?.request_date,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 0
                ? "status-active "
                : item?.status == 1
                ? "status-inactive "
                : ""
            }`}
          >
            {item?.status == 1 ? "Draft" : "Posted"}
          </span>
        </div>
      ),
    },
  ]
  const recentGRNColumns = [
    {
      label: "GRN No",
      renderCell: item => item?.grn_no,
    },

    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => <div className="text-end pe-5">{NumberFormat((Number(item?.grnquantitieslist_sum_received_quantity)))}</div>,
    },
    {
      label: "Date",
      renderCell: item => item?.recieved_date,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 1
                ? "status-active "
                : item?.status == 0
                ? "status-inactive "
                : ""
            }`}
          >
            {item?.status == 1 ? "Active" : item?.status == 0 ? "Inactive" : ""}
          </span>
        </div>
      ),
    },
  ]
  const onGoingColumns = [
    {
      label: "MR No",
      renderCell: item => item?.material_requests_code,
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.requests_sum_quantity)))}</div>
      },
    },
    {
      label: <div className="text-end pe-5">PO Generated Qty</div>,
      renderCell: item => {
        const totalQuantity =
          item?.procuremrntpo?.reduce((a, b) => {
            return b.status == 0
              ? a + (Number(b?.po_list_sum_quantity) || 0)
              : a
          }, 0) || 0

        console.log(`--- test: ${totalQuantity}`)

        return <div className="text-end pe-5">{NumberFormat((Number(totalQuantity)))}</div>
      },
      // const totalQuantity = procurementpo.reduce((sum, item) => {
      //   return item.grn_status === "1" ? sum + item.po_list_sum_quantity : sum;
      // }, 0);
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 1
                ? "status-active "
                : item?.status == 0
                ? "status-draft "
                : "status-inactive "
            }`}
          >
            {item?.status === 0
              ? "Receiving"
              : item?.status === 1
              ? "New"
              : "Closed"}
          </span>
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          {item?.listing_status == 1 ? (
            <Button
              className="hover-button"
              onClick={() =>
                handleView({
                  id: 1,
                  name: "create_po",
                  value: item,
                  product_id: "",
                })
              }
            >
              Generate PO
            </Button>
          ) : (
            <Button
              color={"primary"}
              className={"px-2  btn btn-primary"}
              // onClick={() => downloadmr(item?.id, "mr.pdf")}
              onClick={() =>
                handleView({
                  id: 4,
                  name: "view_MR",
                  value: item,
                  product_id: "",
                })
              }
            >
              {/* Download */}
              View
            </Button>
          )}
        </div>
      ),
    },
  ]

  const subOnGoingColumns = [
    {
      label: "PO No",
      renderCell: item => {
        return (
          <div>
            {item?.purchase_orders_code}
            {item?.grn?.length ? (
              <img src={redLeftArrow} className="expand-arrow" />
            ) : (
              <></>
            )}
          </div>
        )
      },
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.po_list_sum_quantity)))}</div>
      },
    },
    {
      label: "GRN Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.grn_status == 1
                ? "status-active "
                : item?.grn_status == 0
                ? "status-inactive "
                : "status-active"
            }`}
          >
            {item?.grn_status == 0
              ? "Not Started"
              : item?.grn_status == 1
              ? "Receiving"
              : "Received"}
          </span>
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status == 0
                ? "status-posted "
                : item?.status == 1
                ? "status-recieving"
                : ""
            }`}
          >
            {item?.status == 1 ? "Draft" : "Posted"}
          </span>
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <Button
          color={"primary"}
          className={"px-2 btn btn-primary"}
          // onClick={() => downloadpo(item?.id, "po.pdf")}
          onClick={() =>
            handleView({
              id: 5,
              name: "view_PO",
              value: item,
              product_id: "",
            })
          }
        >
          {/* Download */}
          View
        </Button>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div>
          {item?.status == 1 ? (
            <Button
              className="hover-button"
              onClick={() => handlePostLPO(item?.id)}
            >
              Post LPO
            </Button>
          ) : item?.listing_status == 1 && item?.status == 0 ? (
            <Button
              className="hover-button"
              onClick={() =>
                handleView({
                  id: 2,
                  name: "create_grn",
                  value: item,
                  product_id: "",
                })
              }
            >
              Generate GRN
            </Button>
          ) : (
            <></>
            // <Button
            //   color={"primary"}
            //   className={"px-2 btn btn-primary"}
            //   // onClick={() => downloadpo(item?.id, "po.pdf")}
            //   onClick={() =>
            //     handleView({
            //       id: 5,
            //       name: "view_PO",
            //       value: item,
            //       product_id: "",
            //     })
            //   }
            // >
            //   {/* Download */}
            //   View
            // </Button>
          )}
        </div>
      ),
    },
  ]
  const anotherSubOnGoingColumns = [
    {
      label: "GRN No",
      renderCell: item => {
        return item?.grn.map((element, index) => (
          <div key={index} className="my-3 padd-5">
            {element?.grn_no}
          </div>
        ))
      },
    },
    {
      label: <div className="text-end pe-5">Quantity</div>,
      renderCell: item => {
        return item?.grn.map((element, index) => (
          <div key={index} className="my-3 padd-5 text-end pe-5">
            {NumberFormat((Number(element?.grnquantitieslist_sum_received_quantity)))}
          </div>
        ))
      },
    },
    {
      label: "",
      renderCell: item => {
        return item?.grn?.map((element, index) => (
          <div className="my-3 ">
            <Button
              color={"primary"}
              className={"px-2 btn btn-primary"}
              // onClick={() => downloadpo(item?.id, "po.pdf")}
              onClick={() =>
                handleView({
                  id: 7,
                  name: "view_GRN",
                  value: element,
                  product_id: "",
                })
              }
            >
              {/* Download */}
              View
            </Button>
          </div>
        ))
      },
    },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
    // {
    //   label: "",
    //   renderCell: item => <></>,
    // },
  ]

  const forwardManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => item?.category?.designation?.name,
    },
    {
      label: <div className="text-end pe-5">Number of hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.hr_status)))}</div>
      },
    },
    // {
    //   label: "Per Day",
    //   renderCell: item => item?.per_day,
    // },
    {
      label: "From Date",
      renderCell: item => {
        return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "To Date",
      renderCell: item => {
        return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
      },
    },
    {
      label: "",
      renderCell: item => {
        return (
          <div>
            {item?.hr_status != item?.manpowerpo_sum_quantity ? (
              <Button
                className="hover-button"
                onClick={() =>
                  handleView({
                    id: 3,
                    name: "create_po",
                    value: item,
                    product_id: "",
                  })
                }
              >
                Generate PO
              </Button>
            ) : (
              // <Button
              //   color={"primary"}
              //   className={"px-2  btn btn-primary"}
              //   onClick={() => downloadmr(item?.id, "mr.pdf")}
              // >
              //   Download
              // </Button>
              <Button
                color={"primary"}
                className={"px-2 btn btn-primary"}
                // onClick={() => downloadpo(item?.id, "po.pdf")}
                onClick={() =>
                  handleView({
                    id: 9,
                    name: "view_manReq",
                    value: item,
                    product_id: "",
                  })
                }
              >
                {/* Download */}
                View
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  const subForwardManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return (
          <div>
            {item?.category?.designation?.name}
            {item?.manpowergrn?.length ? (
              <img src={redLeftArrow} className="expand-arrow" />
            ) : (
              <></>
            )}
          </div>
        )
      },
    },
    {
      label: <div className="text-end pe-5">Number of hours</div>,
      renderCell: item => {
        return <div className="text-end pe-5">{NumberFormat((Number(item?.quantity)))}</div>
      },
    },
    // {
    //   label: "Per Day",
    //   renderCell: item => item?.per_day,
    // },
    // {
    //   label: "From Date",
    //   renderCell: item => {
    //     return <div>{moment(item?.from_date).format("DD-MM-YYYY")}</div>
    //   },
    // },
    // {
    //   label: "To Date",
    //   renderCell: item => {
    //     return <div>{moment(item?.to_date).format("DD-MM-YYYY")}</div>
    //   },
    // },
    {
      label: "",
      renderCell: item => {
        return (
          <div>
            {item?.quantity != Number(item?.manpowergrn_sum_no_hours) ? (
              <Button
                className="hover-button"
                onClick={() =>
                  handleView({
                    id: 8,
                    name: "create_grn",
                    value: item,
                    product_id: "",
                  })
                }
              >
                Generate GRN
              </Button>
            ) : (
              <Button
                color={"primary"}
                className={"px-2  btn btn-primary"}
                onClick={() => downloadmr(item?.id, "mr.pdf")}
              >
                Download
              </Button>
              // <Button
              //   color={"primary"}
              //   className={"px-2 btn btn-primary"}
              //   onClick={() =>
              //     handleView({
              //       id: 10,
              //       name: "view_manPo",
              //       value: item,
              //       product_id: "",
              //     })
              //   }
              // >
              //   View
              // </Button>
            )}
          </div>
        )
      },
    },
  ]

  const anotherSubForwardManpowerColumn = [
    {
      label: "Designation",
      renderCell: item => {
        return item?.manpowergrn?.map((dt, index) => (
          <div key={index} className="my-3 padd-5">
            {dt?.category?.designation?.name}
          </div>
        ))
      },
    },
    {
      label: <div className="text-end pe-5">Received hours</div>,
      renderCell: item => {
        return item?.manpowergrn?.map((dt, index) => (
          <div key={index} className="my-3 padd-5 text-end pe-5">
            {NumberFormat((Number(dt?.no_hours)))}
          </div>
        ))
      },
    },
    {
      label: "",
      renderCell: item => {
        return item?.manpowergrn.map((dt, index) => (
          <div className="my-3 ">
            {/* <Button
              color={"primary"}
              className={"px-2 btn btn-primary"}
              onClick={() => downloadpo(item?.id, "po.pdf")}
              // onClick={() =>
              //   handleView({
              //     id: 7,
              //     name: "view_GRN",
              //     value: dt,
              //     product_id: "",
              //   })
              // }
            >
              Download
            </Button> */}

            <Button
              color={"primary"}
              className={"px-2 btn btn-primary"}
              onClick={() =>
                handleView({
                  id: 11,
                  name: "view_manGRN",
                  value: dt,
                  product_id: "",
                })
              }
            >
              View
            </Button>
          </div>
        ))
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const mainCard = [
    {
      id: 1,
      name: "Material Request",
      value: NumberFormat((Number(materialData?.materialrequestcount))),
    },
    {
      id: 2,
      name: "Purchase Order",
      value: NumberFormat((Number(materialData?.purchaseordercount))),
    },
    {
      id: 3,
      name: "GRN Quantity",
      value: NumberFormat((Number(materialData?.grnquantitycount))),
    },
  ]

  const handleRegistration = async data => {
    console.log(" console log :", data)
  }

  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
      setRefresh1(refresh1 + 1)
      setRefresh(refresh + 1)
    }
  }

  //tab

  const handleActiveTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Project Dashboard",
                  link: `/project-procurement-dash/${id}`,
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <Row className="mt-3 mx-1 mb-0">
              {mainCard?.map((dt, i) => (
                <Col md={4} xl={4} sm={4} xs={12}>
                  <Card
                    className={`rounded-0 border-0 procurement-auto-cards `}
                  >
                    <CardBody className={`${dt?.id == 1 && "dark-background"} card-body-dash-${i}`}>
                      <Row className="px-2">
                        <Col className="bold-heading">{dt?.name}</Col>
                      </Row>
                      <Row className="px-2 pt-2">
                        <Col className=" extra-dark-text ">{dt?.value}</Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="mx-3 px-2 ">
              <Row>
                <Col lg={6} className=" pe-2 ps-0">
                  <div className="tab-bg border-rds-round px-2 mb-4 dashboard-tbl-5">
                    <Row className="bold-heading p-4 py-3 pe-3">
                      <Col className="p-0">New Request</Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <Col xs={12}>
                        {materialData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={materialData?.materialrequest_created}
                            columns={newRequestColumn}
                            tableWidth="50% 30% 20%"
                            mobileWidth="50% 50% 30%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} className="ps-3 pe-0">
                  <div className="tab-bg border-rds-round px-2 mb-4 dashboard-tbl-5">
                    <Row className="bold-heading p-4 py-3 pe-3">
                      <Col className="p-0">Recent Supplier</Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <Col xs={12}>
                        {materialData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={materialData?.supplierlist}
                            columns={recentSupplierColumn}
                            tableWidth="80% 20%"
                            mobileWidth="70% 30%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mx-3 px-2 ">
              <Row>
                <Col lg={12} className=" pe-0 ps-0">
                  <div className="tab-bg border-rds-round px-2 mb-4">
                    <Row className="bold-heading p-2 py-3 pe-3">
                      <Col className="p-0">
                        <Nav tabs className="tab-active-bg">
                          <NavItem>
                            <NavLink
                              className={`${
                                activeTab === "1" ? "active pointer" : "pointer"
                              } py-2`}
                              onClick={() => {
                                handleActiveTab("1")
                                setPage("1")
                              }}
                            >
                              Material Request
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${
                                activeTab === "2" ? "active pointer" : "pointer"
                              } py-2`}
                              onClick={() => {
                                handleActiveTab("2")
                                setPage("1")
                              }}
                            >
                              Manpower Request
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>

                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <TabContent activeTab={activeTab} className="pt-3">
                        <TabPane tabId="1">
                          <Col xs={12}>
                            {materialRequestData && activeTab == 1 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={materialRequestData?.data?.data}
                                paginate={materialRequestData?.data}
                                columns={onGoingColumns}
                                subColumn={subOnGoingColumns}
                                anotherSubColumn={anotherSubOnGoingColumns}
                                handlePagination={handlePaginate}
                                subName={"procuremrntpo"}
                                subHeader={true}
                                anotherSubName={"grn"}
                                tableWidth="20% 20% 20% 20% 20%"
                                mobileWidth="40% 40% 40% 40% 40%"
                                expand={true}
                              />
                            )}
                          </Col>
                        </TabPane>
                        <TabPane tabId="2">
                          <Col xs={12}>
                            {manpowerRequestData && activeTab == 2 && (
                              <UpdateTable
                                className="mt-5"
                                nodes={manpowerRequestData?.data?.data}
                                paginate={manpowerRequestData?.data}
                                columns={forwardManpowerColumn}
                                subColumn={subForwardManpowerColumn}
                                anotherSubColumn={
                                  anotherSubForwardManpowerColumn
                                }
                                subName={"manpowerpo"}
                                anotherSubName={"manpowergrn"}
                                subHeader={true}
                                expand={true}
                                handlePagination={handlePaginate}
                                tableWidth="20% 20% 20% 20% 20%"
                                mobileWidth="40% 40% 40% 40% 40%"
                              />
                            )}
                          </Col>
                        </TabPane>
                      </TabContent>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mx-3 px-2 ">
              <Row>
                <Col lg={6} className=" pe-2 ps-0">
                  <div className="tab-bg border-rds-round px-2 mb-4 dashboard-tbl-5">
                    <Row className="bold-heading p-4 py-3 pe-3">
                      <Col className="p-0">Recent PO</Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <Col xs={12}>
                        {materialData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={materialData?.purchaseorder}
                            columns={recentPOColumns}
                            tableWidth="25% 25% 25% 25%"
                            mobileWidth="40% 40% 40% 40%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} className="ps-3 pe-0">
                  <div className="tab-bg border-rds-round px-2 mb-4 dashboard-tbl-5">
                    <Row className="bold-heading p-4 py-3 pe-3">
                      <Col className="p-0">Recent GRN</Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="mt-2 ever-rise-table dashboardTable ">
                      <Col xs={12}>
                        {materialData && (
                          <UpdateTable
                            className="mt-5"
                            nodes={materialData?.grnquantity}
                            columns={recentGRNColumns}
                            tableWidth="25% 25% 25% 25%"
                            mobileWidth="40% 40% 40% 40%"
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={viewData?.id == 1 ? "xl" : "xl"}
            unmountOnClose={false}
          >
            <>
              {/* <FormProvider {...methods}>
                <Form
                  id={`ev-form`}
                  onSubmit={handleSubmit(handleRegistration)}
                > */}
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-4">
                    {viewData?.id == 1
                      ? "Purchase Order"
                      : viewData?.id == 2
                      ? "Create GRN"
                      : viewData?.id == 4
                      ? "Material Request View"
                      : viewData?.id == 5
                      ? "Purchase Order View"
                      : viewData?.id == 6
                      ? "Supplier View"
                      : viewData?.id == 7
                      ? "GRN View"
                      : viewData?.id == 9
                      ? "Manpower Request Order View"
                      : viewData?.id == 10
                      ? "Manpower Purchase Order View"
                      : viewData?.id == 11
                      ? "Manpower GRN View"
                      : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
                {console.log("viewData :", viewData)}
              </ModalHeader>
              <ModalBody>
                {viewData?.id == 1 ? (
                  <EditPurchaseOrder
                    fromDashboard={true}
                    materialValue={{
                      value: viewData?.value?.id,
                      label: viewData?.value?.material_requests_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 2 ? (
                  <EditGRN
                    fromDashboard={true}
                    poValue={{
                      value: viewData?.value?.id,
                      label: viewData?.value?.purchase_orders_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 3 ? (
                  <EditManPowerPurchaseOrder
                    fromDashboard={true}
                    materialValue={{
                      value: viewData?.value?.id,
                      label: viewData?.value?.manpower_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 4 ? (
                  <MRView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 5 ? (
                  <POView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 6 ? (
                  <SupplierView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 7 ? (
                  <GrnView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 8 ? (
                  <EditManpowerGRN
                    fromDashboard={true}
                    poValue={{
                      value: viewData?.value?.id,
                      label: viewData?.value?.manpower_requests_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 9 ? (
                  <ManReqView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 10 ? (
                  <ViewPurchaseOrder
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : viewData?.id == 11 ? (
                  <ManGrnView
                    fromDashboard={true}
                    viewValue={viewData}
                    toggle={resetAfterCreatePo}
                  />
                ) : (
                  ""
                )}
              </ModalBody>
              {/* </Form>
              </FormProvider> */}
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProcurementIndex
