import { del, get, post, put } from "../../helpers/api_helper"

// fetch Category
export const fetchFinanceCategory = data => {
  return get(`finance-cat/search?${data?.params}`)
}

// post category
export const postFinanceCategory = data => {
  return !data?.id
    ? post(`finance-cat/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`finance-cat/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// update status
export const updateCatStatus = id => {
  return get(`finance-cat/updatestatus/${id}`)
}

// fetch Sub Category
export const fetchFinanceSubCategory = data => {
  return get(`finance-sub-cat/search?${data?.params}`)
}

// post sub category
export const postFinanceSubCategory = data => {
  return !data?.id
    ? post(`finance-sub-cat/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`finance-sub-cat/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// fetch Sub Category
export const fetchFinanceCostCenter = data => {
  return get(`cost-center/search?${data?.params}`)
}
// fetch finance dash-board-values
export const fetchFinanceDashboardVal = data => {
  return get(`finance-fetch/${data}`)
}
// fetch finance dash-board-sub
export const fetchFinanceDashboardSub = data => {
  return get(`finance/search?${data?.params}`)
}
// fetch finance dash-board-alert
export const fetchFinanceDashboardAlert = data => {
  return get(`finance-alert/search?${data?.params}`)
}
// fetch finance dashboard employee attendance
export const fetchFinanceDashboardEmp = data => {
  return get(`employee-attendence/attendence-search?${data?.params}`)
}
// fetch finance dashboard employee attendance
export const fetchFinanceDashboardEmpDetail = data => {
  return get(`employee-details/fetchattendence?${data?.params}`)
}

// fetch Accounts
export const fetchFinanceAccounts = data => {
  return get(`accounts/search?${data?.params}`)
}

// post accounts
export const postFinanceAccounts = data => {
  return !data?.id
    ? post(`accounts/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`accounts/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// post mapping setup
export const postFinanceMappingSetup = data => {
  return post(`finance-mappings`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// fetch mapping setup
export const fetchFinanceMappingSetup = () => {
  return get(`finance-mappings-fetch`)
}

// fetch invoices
export const fetchFinanceInvoices = data => {
  return get(`invoice/search?${data?.params}`)
}

// post inv pay
export const postInvoicePay = data => {
  return post(`invoice-payment/${data?.id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// invoice/fetch

export const fetchSingleInvoice = id => {
  return get(`invoice/fetch/${id}`)
}

// journal entry
export const fetchFinanceJournalEntry = data => {
  return get(`journal-entry/search?${data?.params}`)
}
// journal entry
export const fetchFinanceLedgerAcc = data => {
  return get(`journal-entry-ledger/search?${data?.params}`)
}
// soa
export const fetchFinanceSOA = data => {
  return get(`soa?account_id=${data?.typeId}${data?.params}`)
}
// balance sheet
export const fetchFinanceBalanceSheet = data => {
  return get(`balance-sheet?${data?.params}`)
}

// trial balance sheet
export const fetchFinanceTrialBalance = data => {
  return get(`trial-balance-sheet?${data?.params}`)
}

// profit loss sheet
export const fetchFinanceProfitLoss = data => {
  return get(`profit-loss-sheet?${data?.params}`)
}

// bank reconciliation
export const fetchFinanceBankReconciliation = data => {
  return get(`ledger-report/bank-reconciliation?${data?.params}`)
}

// post account group
export const postFinanceAccountGroup = data => {
  return !data?.id
    ? post(`account-groups`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`account-groups/update/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// acc search
export const fetchFinanceAccountGroupSearch = data => {
  return get(`account-groups/search?${data?.params}`)
}

// acc field search
export const fetchFinanceAccountFieldsSearch = data => {
  return get(`group-fields/search?${data?.params}`)
}

// post account group data fields
export const postFinanceAccountGroupFields = data => {
  return !data?.id
    ? post(`group-fields`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`group-fields/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// post ledger
export const postFinanceLedger = data => {
  return !data?.id
    ? post(`ledger/store`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`ledger/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// finance ledger Search
export const fetchFinanceLedgerSearch = data => {
  return get(`ledger/search?${data?.params}`)
}

// finance ledger single
export const fetchFinanceLedgerSingle = id => {
  return get(`ledger/${id}`)
}

// post invoice
export const postFinanceInvoice = data => {
  return post(`invoice/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// finance Voucher Search
export const fetchFinanceVoucherEntrySearch = data => {
  return get(`voucher-entry/search?${data?.params}`)
}

// finance Voucher Search
export const deleteFinanceVoucherEntry = id => {
  return del(`voucher/remove/${id}`)
}

// finance Voucher single
export const fetchFinanceVoucherSingle = id => {
  return get(`voucher-entry/${id}`)
}

// post voucher
export const postFinanceVoucher = (data, progressCallback) => {
  return !data?.id
    ? post(`voucher/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressCallback,
      })
    : post(`voucher-entry/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressCallback,
      })
}
// post PO voucher
export const postFinancePOVoucher = data => {
  return !data?.id
    ? post(
        `material-requests/${data?.material_request_id}/purchase-orders`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    : post(`material-requests/${data?.id}/purchase-orders-update`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// fetch invoice config
export const fetchInvoiceConfig = () => {
  return get(`purchase-invoice-config`)
}

// Manufacturing Journal search
export const searchManufacturingJournal = data => {
  return get(`manufacturing-journal?${data?.params}`)
}

// Manufacturing Journal fetch
export const fetchManufacturingJournal = id => {
  return get(`manufacturing-journal/${id}`)
}

// Manufacturing Journal delete
export const deleteManufacturingJournal = id => {
  return del(`manufacturing-journal/${id}`)
}

// post Manufacturing Journal
export const postManufacturingJournal = data => {
  return !data?.id
    ? post(`manufacturing-journal`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : put(`manufacturing-journal/${data?.id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
}

// update status manufacture journal

export const updateManufactureJournal = data => {
  return post(`manufacturing-journal/updatestatus/${data?.id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const downloadManufacture = (id, fileName) => {
  return get(`/manufacturing-journal/download-pdf/${id}`, {
    responseType: "blob",
  }).then(response => {
    const blob = new Blob([response], { type: "application/pdf" })
    const url = window.URL.createObjectURL(blob)
    const newTab = window.open(url, "_blank")

    if (newTab) {
      setTimeout(() => {
        newTab.print()
      }, 1000) // Delay to ensure the PDF loads before printing
    } else {
      alert("Pop-up blocked! Please allow pop-ups for this site.")
    }
  })
}

// /finance-sales-graph
export const fetchFinanceDashGraph = data => {
  return get(`finance-sales-graph${data?.params}`)
}

// /finance-sales-graph
export const fetchFinanceChartOfAcc = data => {
  return get(`chart-of-accounts`)
}
