import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableIntegration from "./table"
import EditInventory from "./edit"
import * as Yup from "yup"
import { useFormik } from "formik"
import Filter from "./filter"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Col, Row, Card } from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"

function DatatableTables() {
  document.title =
    "Invoice List | Everrise - Responsive Bootstrap 5 Admin Dashboard"

  const handleUpdate = () => {
    // refreshInventory();
  }

  const [page, setPage] = useState("")

  const responseData = {
    last_page: 1,
    per_page: 5,
    total: 2,
    current_page: 1,
    from: 1,
    to: 2,
    data: [
      {
        id: 1,
        productCode: "EV-001",
        partNumber: "EVP-001",
        desc: "HEllo",
        status: "Replaced",
        phone: "789654123",
      },
      {
        id: 2,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 2,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 3,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 4,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 5,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 6,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 7,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 8,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 9,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 10,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 11,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 12,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 13,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 14,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
      {
        id: 15,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
    ],
  }
  const columns = [
    {
      label: <input type="checkbox" className="ever-rise-checkbox" />,
      renderCell: (item, index) => {
        const serialNumber = item?.id
        return (
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={serialNumber}
          />
        )
      },
    },
    {
      label: "SI.NO",
      renderCell: (item, index) => {
        const serialNumber = item?.id
        if (serialNumber <= 9) {
          return `0${serialNumber}`
        } else {
          return serialNumber
        }
      },
    },
    {
      label: "Product Code",
      renderCell: item => item?.productCode,
      sortKey: (a, b) => {
        return a?.productCode - b?.productCode
      },
    },
    {
      label: "Part Number",
      renderCell: item => item?.partNumber,
      sortKey: (a, b) => {
        return a?.partNumber - b?.partNumber
      },
    },
    {
      label: "Description",
      renderCell: item => item?.desc,
      sortKey: (a, b) => {
        return a?.desc - b?.desc
      },
    },
    {
      label: "Status",
      renderCell: item => item?.status,
      sortKey: (a, b) => {
        return a?.status - b?.status
      },
    },
    {
      label: "Phone",
      renderCell: item => item?.phone,
      sortKey: (a, b) => {
        return a?.phone - b?.phone
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs title="Invoices" breadcrumbItem="" />
            <EditInventory
              key={"x"}
              onUpdate={handleUpdate}
              buttonLabel="+&nbsp; Country"
              className="px-2 mb-3 btn btn-primary"
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <Filter />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {/* <TableIntegration /> */}

                <UpdateTable
                  className="mt-5"
                  nodes={responseData?.data}
                  paginate={responseData}
                  columns={columns}
                  CurrentPage={page}
                  handlePagination={handlePaginate}
                  tableWidth="20% 18% 18% 15% 15% 10%"
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
