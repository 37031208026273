import Procurement from "../../pages/Procurement"
import PurchaseOrder from "../../pages/Procurement/purchaseOrder"
import EditPurchaseOrder from "../../pages/Procurement/purchaseOrder/edit"
import Supplier from "../../pages/Procurement/supplier"
import EditSupplier from "../../pages/Procurement/supplier/edit"
import ViewSupplier from "../../pages/Procurement/supplier/view"
import MaterialRequest from "../../pages/Procurement/materialrequest"
import EditMaterialRequest from "../../pages/Procurement/materialrequest/edit"
import ViewMaterialRequest from "../../pages/Procurement/materialrequest/view"
import ViewPurchaseOrder from "../../pages/Procurement/purchaseOrder/view"
import Grn from "../../pages/Procurement/grn"
import EditGrn from "../../pages/Procurement/grn/edit"
import ViewMGrn from "../../pages/Procurement/grn/view"
import ManpowerPO from "../../pages/Procurement/manpowerPO"
import CompanyPurchaseOrder from "../../pages/Procurement/companyPO"
import PurchaseOrderVoucherSearch from "../../pages/Procurement/purchaseOrderVoucher"
import StockSummary from "../../pages/Procurement/stockSummary"
import RequestForQuote from "../../pages/Procurement/rfq"
import EditRfq from "../../pages/Procurement/rfq/edit"
import ViewRFQ from "../../pages/Procurement/rfq/view"
import ViewPurchaseOrderVoucher from "../../pages/Procurement/purchaseOrderVoucher/view"
import EditManpowerRequest from "../../pages/Procurement/manpowerRequest/edit"
import ManPowerRequest from "../../pages/Procurement/manpowerRequest"
import SubcontractRequest from "../../pages/Procurement/subcontractRequest"
import EditSubcontractRequest from "../../pages/Procurement/subcontractRequest/edit"
// import { components } from "react-select"
// import { Component } from "react"
import MaterialRequesting from "../../pages/Procurement/materialRequesting"
import EditMaterialRequesting from "../../pages/Procurement/materialRequesting/edit"
import ViewMaterialRequsting from "../../pages/Procurement/materialRequesting/view"
import ViewSubcontractRequest from "../../pages/Procurement/subcontractRequest/view"
import ViewManpower from "../../pages/Procurement/manpowerRequest/view"
export const procurementRoutes = [
  { path: "/procurement", component: <Procurement /> },
  { path: "/procurement-supplier", component: <Supplier /> },
  { path: "/procurement-supplier/add", component: <EditSupplier /> },
  { path: "/procurement-supplier/edit/:id", component: <EditSupplier /> },
  { path: "/procurement-supplier/view/:id", component: <ViewSupplier /> },
  { path: "/procurement-purchase-order", component: <PurchaseOrder /> },
  { path: "/procurement-purchase-order/add", component: <EditPurchaseOrder /> },
  {
    path: "/procurement-purchase-order/edit/:editId",
    component: <EditPurchaseOrder />,
  },
  {
    path: "/procurement-purchase-order/view/:id",
    component: <ViewPurchaseOrder />,
  },
  {
    path: "/procurement-company-purchase-order/view/:id",
    component: <ViewPurchaseOrder />,
  },
  {
    path: "/procurement-company-purchase-order",
    component: <CompanyPurchaseOrder />,
  },
  {
    path: "/procurement-purchase-order/:action",
    component: <EditPurchaseOrder />,
  },
  { path: "/procurement-materialreq", component: <MaterialRequest /> },
  { path: "/procurement-materialreq/add", component: <EditMaterialRequest /> },
  {
    path: "/procurement-materialreq/edit/:id",
    component: <EditMaterialRequest />,
  },
  { path: "/procurement-material-req", component: <MaterialRequesting /> },
  { path: "/procurement-material-req/add", component: <EditMaterialRequesting /> },
  
  {
    path: "/procurement-material-req/view/:id",
    component: <ViewMaterialRequsting />,
  },
  {
    path: "/procurement-material-req/edit/:id",
    component: <EditMaterialRequesting />,
  },
  {
    path: "/procurement-manpowerreq/add",
    component: <EditManpowerRequest />
  },
  {
    path: "/procurement-manpower-req/view/:id",
    component: <ViewManpower />
  },
  {
    path: "/procurement-manpowerreq",
    component: <ManPowerRequest />
  },
  {
    path: "/procurement-subcontractreq",
    component: <SubcontractRequest />
  },
  {
    path: "/procurement-subcontractreq/add",
    component: <EditSubcontractRequest />
  },
  {
    path: "//procurement-subcontractreq/edit/:id",
    component: <EditSubcontractRequest />
  },
  {
    path: "/procurement-subcontractreq/view/:id",
    component: <ViewSubcontractRequest />
  },
  {
    path: "/procurement-materialreq/view/:id",
    component: <ViewMaterialRequest />,
  },

  { path: "/procurement-grn", component: <Grn /> },
  { path: "/procurement-grn/add", component: <EditGrn /> },
  { path: "/procurement-grn/add/:editId", component: <EditGrn /> },
  { path: "/procurement-grn/edit/:editId", component: <EditGrn /> },
  { path: "/procurement-grn/view/:id", component: <ViewMGrn /> },
  { path: "/procurement-manpower-puechase-order", component: <ManpowerPO /> },
  {
    path: "/procurement-purchaseorder",
    component: <PurchaseOrderVoucherSearch />,
  },
  {
    path: "/procurement-purchaseorder/view/:id",
    component: <ViewPurchaseOrderVoucher />,
  },
  { path: "/procurement-stocksummary", component: <StockSummary /> },
  { path: "/procurement-rfq", component: <RequestForQuote /> },
  { path: "/procurement-rfq/add", component: <EditRfq /> },
  { path: "/procurement-rfq/view/:id", component: <ViewRFQ /> },
]
