import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"
import Chart from "react-apexcharts"
import { fetchFinanceDashGraph } from "../../services/finance/finance"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import moment from "moment"
import NumberFormat from "../../utils/numberFormat"

const FinanceDahboard = () => {
  document.title = "Finance Dashboard | Ever Rise"

  const [purchaseData, setPurchaseData] = useState([])
  const [salesData, setSalesData] = useState([])
  const [graphMonths, setGraphMonths] = useState([])
  const [cardData, setCardData] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchFinanceGraph = async () => {
      const purchaseResponce = await fetchFinanceDashGraph({
        params: `${`?from=${moment(loggedUser?.finance_year?.start_date).format(
          "DD-MM-YYYY"
        )}&to=${moment(loggedUser?.finance_year?.end_date).format(
          "DD-MM-YYYY"
        )}&type=purchase`}`,
      })
      const salesResponce = await fetchFinanceDashGraph({
        params: `${`?from=${moment(loggedUser?.finance_year?.start_date).format(
          "DD-MM-YYYY"
        )}&to=${moment(loggedUser?.finance_year?.end_date).format(
          "DD-MM-YYYY"
        )}&type=sales`}`,
      })
      const cardResponce = await fetchFinanceDashGraph({
        params: `${`?from=${moment(loggedUser?.finance_year?.start_date).format(
          "DD-MM-YYYY"
        )}&to=${moment(loggedUser?.finance_year?.end_date).format(
          "DD-MM-YYYY"
        )}&type=others`}`,
      })
      const graphMonths = Object.keys(purchaseResponce.data.graph).map(month =>
        moment(month, "MMM YYYY").format("MMM")
      )
      const purchaseData = Object.values(purchaseResponce.data.graph).map(
        Number
      )
      const salesData = Object.values(salesResponce.data.graph).map(Number)

      setGraphMonths(graphMonths)
      setPurchaseData(purchaseData)
      setSalesData(salesData)
      setCardData(cardResponce)
    }

    if (loggedUser?.finance_year) fetchFinanceGraph()
  }, [loggedUser])
  var Options = {
    Series: [
      {
        name: "",
        type: "line",
        data: salesData,
      },
      //   {
      //     name: "Issued",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.issued),
      //   },
      //   {
      //     name: "Rejected",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.rejected),
      //   },
    ],
    chart: {
      type: "line",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#383838", "#525252", "#171717"],
    xaxis: {
      categories: graphMonths,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "k"
          } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "l"
          } else {
            return value
          }
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    grid: {
      show: true,
    },
  }

  var Options1 = {
    Series: [
      {
        name: "",
        type: "line",
        data: purchaseData,
      },
      //   {
      //     name: "Issued",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.issued),
      //   },
      //   {
      //     name: "Rejected",
      //     type: "line",
      //     data: graphData?.data?.map(dt => dt?.rejected),
      //   },
    ],
    chart: {
      type: "line",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#383838", "#525252", "#171717"],
    xaxis: {
      categories: graphMonths,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "k"
          } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "l"
          } else {
            return value
          }
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    grid: {
      show: true,
    },
  }

  //   const calculateRatio = (a, b) => {
  //     const gcd = (x, y) => (y === 0 ? x : gcd(y, x % y));
  //     const divisor = gcd(a, b);
  //     return `${a / divisor}:${b / divisor}`;
  // };

  // // Example usage:
  // console.log(calculateRatio(285705, 273360));

  // const calculateSimpleRatio = (a, b) => {
  //   const gcd = (x, y) => (y === 0 ? x : gcd(y, x % y));
  //   const divisor = gcd(a, b);
  //   let x = a / divisor;
  //   let y = b / divisor;

  //   // Scale down to single-digit range
  //   const max = Math.max(x, y);
  //   if (max > 9) {
  //       const scale = max / 9;
  //       x = Math.round(x / scale);
  //       y = Math.round(y / scale);
  //   }

  //   return `${x}:${y}`;
  // };

  // // Example usage:
  // console.log(calculateSimpleRatio(285705, 273360));

  return (
    <>
      {/* <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between"> */}
            {/* <Breadcrumbs
              title="Finance Dashboard"
              breadcrumbObject={[
                { title: "Finance Dashboard", link: "/finance-dashboard" },
                {
                  title: "Finance Dashboard",
                  link: "/finance-dashboard",
                },
              ]}
            /> */}

            {/* {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-materialreq/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Material Request"}
              </Link>
            )} */}
          {/* </div> */}
          {/* <Card className="me-2 px-2 pb-2"> */}
            <Row className="px-4 pt-4 pb-2 ">
              <Col sm={6}>
                <div className="finance-chart">
                  <Card>
                    <div className="finance-dash-head">
                      Sales Trend{" "}
                      <span className="finance-dash-head-span">
                        (
                        {moment(loggedUser?.finance_year?.start_date).format(
                          "D-MMM-YYYY"
                        )}{" "}
                        to{" "}
                        {moment(loggedUser?.finance_year?.end_date).format(
                          "D-MMM-YYYY"
                        )}
                        )
                      </span>
                    </div>
                    <Chart
                      options={Options}
                      series={Options?.Series}
                      type="line"
                      width="100%"
                      height={300}
                    />
                  </Card>
                </div>
              </Col>
              <Col sm={6}>
                <div className="finance-chart">
                  <Card className="px-2">
                    <div className="finance-dash-head">Purchase Trend</div>
                    <Chart
                      options={Options1}
                      series={Options1?.Series}
                      type="line"
                      width="100%"
                      height={300}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Trading Details</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gross Profit</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.gross_profit)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.gross_profit) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Net Profit</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(
                                    Number(cardData?.net_profit_or_loss)
                                  ),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.net_profit_or_loss) >= 0
                              ? "Cr"
                              : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Sales Accounts</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_sales)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.total_sales) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Purchase Accounts</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_purchase)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.total_purchase) >= 0
                              ? "Cr"
                              : "Dr"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Fund In/Out Flow</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Net Flow</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.net_flow)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.net_flow) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>In Flow</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.in_flow)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.in_flow) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Out Flow</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.out_flow)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.out_flow) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Assets/Liabilities</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Current Assets</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.current_assets)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.current_assets) >= 0
                              ? "Cr"
                              : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Current Liabilities</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(
                                    Number(cardData?.current_liabilities)
                                  ),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.current_liabilities) >= 0
                              ? "Cr"
                              : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Ratio</td>
                          <td className="text-end pe-4">
                            {cardData &&
                            Math.abs(Number(cardData?.current_assets)) >
                              Math.abs(Number(cardData?.current_liabilities))
                              ? `1 : ${Number(
                                  Math.abs(Number(cardData?.current_assets)) /
                                    Math.abs(
                                      Number(cardData?.current_liabilities)
                                    )
                                ).toFixed(2)}`
                              : cardData &&
                                Math.abs(Number(cardData?.current_assets)) <
                                  Math.abs(
                                    Number(cardData?.current_liabilities)
                                  )
                              ? `${Number(
                                  Math.abs(Number(cardData?.current_assets)) /
                                    Math.abs(
                                      Number(cardData?.current_liabilities)
                                    )
                                ).toFixed(2)} : 1`
                              : 0.0}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row className="px-4 py-2 g-4">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Receivable/Payable</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Receivable</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_sales)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.total_sales) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Payable</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_purchase)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.total_purchase) >= 0
                              ? "Cr"
                              : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Ratio</td>
                          <td className="text-end pe-4">
                            {cardData &&
                            Math.abs(Number(cardData?.total_sales)) >
                              Math.abs(Number(cardData?.total_purchase))
                              ? `1 : ${Math.abs(
                                  Number(
                                    Math.abs(Number(cardData?.total_sales)) /
                                      Math.abs(Number(cardData?.total_purchase))
                                  ).toFixed(2)
                                )}`
                              : cardData &&
                                Math.abs(Number(cardData?.total_sales)) <
                                  Math.abs(Number(cardData?.total_purchase))
                              ? `${Math.abs(
                                  Number(
                                    Math.abs(Number(cardData?.total_sales)) /
                                      Math.abs(Number(cardData?.total_purchase))
                                  ).toFixed(2)
                                )} : 1`
                              : "0:0"}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Out Flow</td>
                          <td className="text-end pe-4">-</td>
                        </tr>
                        <tr>
                          <td>Net Flow</td>
                          <td className="text-end pe-4">-</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Cash/Bank Accounts</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cash in Hand</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.cash_in_hand)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.cash_in_hand) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Accounts</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.bank_balance)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.bank_balance) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Inventory Analysis</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Opening Stock</td>
                          <td className="text-end pe-4">
                            {cardData?.opening_stock
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.opening_stock)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.opening_stock) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Inwards</td>
                          <td className="text-end pe-4">
                            {cardData &&
                            Number(cardData?.total_inward) !== 0 &&
                            Number(cardData?.total_inward) !== null
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_inward)),
                                  3,
                                  3
                                )
                              : "0.00"}{" "}
                            {Number(cardData?.total_inward) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Outwards</td>
                          <td className="text-end pe-4">
                            {cardData &&
                            Number(cardData?.total_outward) !== 0 &&
                            Number(cardData?.total_outward) !== null
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.total_outward)),
                                  3,
                                  3
                                )
                              : "0.00"}{" "}
                            {Number(cardData?.total_outward) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                        <tr>
                          <td>Closing Stock</td>
                          <td className="text-end pe-4">
                            {cardData
                              ? NumberFormat(
                                  Math.abs(Number(cardData?.closing_stock)),
                                  3,
                                  3
                                )
                              : 0.0}{" "}
                            {Number(cardData?.closing_stock) >= 0 ? "Cr" : "Dr"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>

              {/* <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Top Groups</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td className="text-end pe-4">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col> */}
            </Row>

            {/* <Row className="px-4 pt-2 g-4">
              <Col sm={4}>
                <div className="finance-card">
                  <Card>
                    <div className="finance-dash-head">Accounting Ratios</div>
                    <table className="employee-table emp-tbl-finance mb-4">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th className="text-end pe-4">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Inventory Turnover</td>
                          <td className="text-end pe-4">0:00</td>
                        </tr>
                        <tr>
                          <td>Debt/Equity Ratio</td>
                          <td className="text-end pe-4">0:001</td>
                        </tr>
                        <tr>
                          <td>Receivable Turnover in Days</td>
                          <td className="text-end pe-4">300 Days</td>
                        </tr>
                        <tr>
                          <td>Return on Investment %</td>
                          <td className="text-end pe-4">100%</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </div>
              </Col>
            </Row> */}
          {/* </Card> */}
        {/* </div> */}
       {/* </div> */}
    </>
  )
}

export default FinanceDahboard
