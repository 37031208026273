import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import check from "../../../assets/icons/estimation_check.svg"
import EstimationFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import {
  createRevision,
  deleteEst,
  searchEstimation,
} from "../../../services/estimation/estimation"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import NumberFormat from "../../../utils/numberFormat"
import { capitalize } from "lodash"

const EstimationIndex = () => {
  document.title = "Estimation | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customer, setCustomer] = useState("")
  const [location, setLocation] = useState("")
  const [financial, setFinancial] = useState(true)
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState()
  const [dateto, setDateto] = useState()
  const [refresh, setRefresh] = useState(0)

  const columns = [
    {
      label: "Estimation Code",
      renderCell: item => {
        return (
          <div
            onClick={() => navigate(`/estimation/budget-view/${item?.id}`)}
            className="pointer fw-bold"
          >
            <span className="pe-2"> {item?.estimation_code}</span>
            {!item?.estimation_revision?.every(item => item?.status == 0) && (
              <img src={check} className="est-approve-img" />
            )}
          </div>
        )
      },
    },
    {
      label: "Customer Name",
      renderCell: item => item?.customers?.customer_name,
      // sortKey: "customer_name",
    },
    {
      label: "Project Name",
      renderCell: item => (
        <>
          <div
            className="word-clamp-tbl-est"
            id={`PopoverLegacy-projectName-${item?.id}`}
          >
            {capitalize(item?.project_name)}
          </div>
          {item?.project_name ? (
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-projectName-${item?.id}`}
              trigger="hover"
              className="popover-inset"
            >
              <PopoverBody>
                <div className="table-popup-content">
                  {item?.project_name ? item?.project_name : "-"}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          ) : (
            <></>
          )}
        </>
      ),
      sortKey: "project_name",
    },
    {
      label: "Bid date",
      renderCell: item => moment(item?.bid_date).format("DD-MM-YYYY"),
    },
    {
      label: "Update date",
      renderCell: item => moment(item?.updated_at).format("DD-MM-YYYY"),
    },
    {
      label: <div className="text-end pe-5">Amount (AED)</div>,
      renderCell: item => {
        let amount
        if (item?.estimation_revision?.some(item => item?.status == 1)) {
          amount = item?.estimation_revision.find(
            item => item?.status == 1
          )?.amount
        } else {
          amount =
            item?.estimation_revision[item?.estimation_revision?.length - 1]
              ?.amount
        }
        return (
          <div className="text-end pe-5">
            {NumberFormat(Number(amount), 3, 3)}
          </div>
        )
      },
    },

    {
      label: <div className="text-start ps-5">Action</div>,
      renderCell: item => (
        <>
          <div className="text-start ps-5">
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                {item?.estimation_revision?.some(item => item?.status == 1) &&
                item?.project_status == 0 &&
                item?.estimation_code?.includes(loggedUser?.finance) ? (
                  <>
                    <div
                      className="table-popup-content"
                      onClick={
                        () =>
                          navigate(
                            `/estimation/budget-edit/${
                              item?.estimation_revision?.find(
                                revision => revision?.status == 1
                              )?.id
                            }`
                          )
                        // navigate(
                        //   `/estimation/budget-edit/${
                        //     item?.estimation_revision?.[
                        //       item?.estimation_revision?.length - 1
                        //     ]?.id
                        //   }`
                        // )
                      }
                    >
                      Edit
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {item?.estimation_revision?.every(item => item?.status == 0) &&
                item?.estimation_code?.includes(loggedUser?.finance) ? (
                  <>
                    <div
                      className="table-popup-content"
                      // onClick={() =>
                      //   navigate(
                      //     `/estimation-edit/${
                      //       item?.estimation_revision?.[
                      //         item?.estimation_revision?.length - 1
                      //       ]?.id
                      //     }`
                      //   )
                      // }
                      onClick={() => handleCreateRevision(item?.id)}
                    >
                      {`Create New Revision (${item?.estimation_revision?.length})`}
                    </div>
                    <div
                      className="table-popup-content"
                      onClick={() => handleDeleteEst(item?.id)}
                    >
                      Delete
                    </div>
                  </>
                ) : // item?.estimation_revision?.every(
                //     item => item?.status == 0
                //   ) &&
                item?.project_status == 0 &&
                  !item?.estimation_revision?.every(
                    item => item?.status == 0
                  ) &&
                  (loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  ) ||
                    loggedUser?.data?.id === 1) ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(
                        `/project-master/add/${
                          item?.estimation_revision.find(
                            item => item?.status == 1
                          )?.id
                        }`
                      )
                    }
                  >
                    Convert to Project
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() =>
                    navigate(`/estimation/budget-view/${item?.id}`)
                  }
                >
                  View revisions
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handleCreateRevision = async id => {
    console.log(`id`, id)
    const responce = await createRevision(id)
    navigate(`/estimation/budget-edit/${responce?.new_estimation_revision_id}`)
  }

  const handleDeleteEst = async id => {
    try {
      const responce = await deleteEst(id)
      setRefresh(refresh + 1)
      toastSuccess(responce?.message)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchEstimation({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${customer ? `&client_code=${customer}` : ""}${
          location ? `&location_id=${location}` : ""
        }${page ? `&page=${page}` : ""}${
          datefrom ? `&from_date=${datefrom}` : ""
        }${
          dateto ? `&to_date=${dateto}` : ""
        }${`&sizePerPage=16`}${`&finance=1`}`,
      })
      setClassData(response)
      // ${`&financial=${financial}`}
    }
    if (loggedUser?.data?.id) {
      fetchClass()
    }
  }, [
    status,
    search,
    customer,
    page,
    location,
    loggedUser,
    financial,
    datefrom,
    dateto,
    refresh,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCustomerChange = id => {
    setCustomer(id)
    setPage(1)
  }
  const handleLocationChange = id => {
    setLocation(id)
    setPage(1)
  }
  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Budget"
              breadcrumbObject={[
                { title: "Estimation", link: "/estimation" },
                { title: "Budget List", link: "/estimation/budget" },
              ]}
            />
            {/* {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "estimations_create"
            ) && (
              <Link
                to="/estimation/budget-add"
                className={"px-2 mb-3 me-2 btn btn-primary"}
              >
                {"+ Estimation"}
              </Link>
            )} */}
          </div>
          <Card className="me-2 px-2 pb-2">
            <EstimationFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCustomerChange={handleCustomerChange}
              handleLocationChange={handleLocationChange}
              handleFinacialChange={handleFinacialChange}
              handleDateChange={handleDateChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    handlePagination={handlePaginate}
                    CurrentPage={page}
                    tableWidth="15% 20% 20% 10% 10% 15% 10%"
                    mobileWidth="40% 70% 50% 40% 40% 35% 25%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EstimationIndex
