import React from "react"

const CloseArrowIcon = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9L5 5L1 1"
      stroke="#959595"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default CloseArrowIcon
