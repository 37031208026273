import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getPORequestList } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const POMaterialRequest = ({
  control,
  name,
  onSelectChange,
  target,
  placeholder,
  disabled,
  withoutRequest,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchResponse, setFetchResponse] = useState([])
  console.log(`withoutRequest`, withoutRequest)

  const loadOptions = async () => {
    const data = await getPORequestList({ withoutRequest })
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label:
          dta?.material_requests_code && ucfirst(dta.material_requests_code),
        value: dta.id,
        ...dta,
      }
    })

    setFetchResponse(response)
  }
  useEffect(() => {
    loadOptions()
  }, [withoutRequest])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              isDisabled={disabled}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : "body"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default POMaterialRequest
