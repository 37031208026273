import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import Status from "../../../components/Common/chooseFields/status"
import { useForm } from "react-hook-form"
import { Form } from "reactstrap"
import exportIcon from "../../../assets/icons/export.svg"
import {
  importFileData,
  sampleFileDownload,
} from "../../../services/Inventory/inventory"
import closeIcon from "../../../assets/icons/close.svg"
import uploadicon from "../../../assets/icons/import.svg"
import Group from "../../../components/Common/chooseFields/group"
import MaterialList from "../../../components/Common/chooseFields/materialList"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import LoadingButton from "../../../components/Common/LoadingButton"
import resetIcon from "../../../assets/icons/reset.svg"
import EmployeeList from "../../../components/Common/chooseFields/employee"
import LeaveManage from "../../../components/Common/chooseFields/leaveManage"
import moment from "moment"
import { isEmpty } from "lodash"
import LeaveStatus from "../../../components/Common/chooseFields/leaveStatus"
import { useSelector } from "react-redux"
const ProductFilter = ({
  handleStatusChange,
  handleSearchChange,
  handleEmployeeIdChange,
  handleLeaveIdChange,
  handleDateChange,
  handleFinacialChange,
  sampleUrl,
  importUrl,
}) => {
  const loggedUser = useSelector(state => state.Login.loggedUser)

  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [finacialCheck, setfinacialCheck] = useState(true)
  const { control, watch, reset } = useForm()

  const toggle = () => {
    setModal(!modal)
    setFile()
    setFileName()
  }

  const handleStatus = data => {
    handleStatusChange(data?.value)
  }
  const handleEmployeeId = data => {
    handleEmployeeIdChange(data?.value)
  }
  const handleLeaveId = data => {
    handleLeaveIdChange(data?.value)
  }

  const handleSampleFile = () => {
    const handleCommonExport = async () => {
      const response = await sampleFileDownload(sampleUrl)
    }
    handleCommonExport()
  }

  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  const onFileChange = event => {
    setFileName(event)
    setFile(event?.target?.files?.[0])
  }

  const handleImport = () => {
    const importFile = async () => {
      try {
        setIsLoading(true)
        const response = await importFileData({
          importfile: file,
          url: importUrl,
        })
        toastSuccess(response?.message)
        setModal(false)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
    importFile()
  }

  const handleReset = () => {
    handleStatusChange()
    handleSearchChange()
    handleEmployeeIdChange()
    handleLeaveIdChange()
    handleDateChange()
    handleFinacialChange(true)
    setfinacialCheck(true)
    reset({
      search: "",
      category_id: null,
      employee_id: null,
      status: null,
      leave_id: null,
    })
  }

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    handleDateChange(fromDate, toDate)
  }

  const handleFinacialCheck = dt => {
    handleFinacialChange(!dt)
    setfinacialCheck(!dt)
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2">
        <Form id="user-form" className="filter-wrapper">
          <Row className="mx-0">
            <Col sm={3} className="pe-1">
              <LeaveManage
                control={control}
                name={"leave_id"}
                placeholder={"Leave Request"}
                target={"body"}
                onSelectChange={e => handleLeaveId(e)}
              />
            </Col>
            <Col sm={2} className="pe-1">
              <EmployeeList
                control={control}
                name={"employee_id"}
                onSelectChange={e => handleEmployeeId(e)}
                placeholder={"Employee"}
                target={"body"}
              />
            </Col>
            <Col sm={2} className="pe-1">
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Request Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </Col>
            <Col sm={2} className="pe-1">
              <LeaveStatus
                control={control}
                name={"status"}
                onSelectChange={e => handleStatus(e)}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex">
          {/* <Col
            lg={"auto"}
            onClick={() => handleFinacialCheck(finacialCheck)}
            className=" d-flex align-items-center"
          >
            <input
              type="checkbox"
              className="ever-rise-checkbox"
              name={"is_finacial_year"}
              checked={finacialCheck}
            />
            <Label className="ms-2 mb-0">
              FY {loggedUser?.finance?.replace(/\//g, "")}
            </Label>
          </Col> */}
          <div
            className="export-div pointer me-2 reset-img"
            onClick={() => handleReset()}
          >
            <img src={resetIcon} height={15} width={15} className="me-2" />{" "}
            Reset
          </div>
          <UFInput
            control={control}
            placeholder="Search"
            name={"search"}
            className={"search-input"}
          />
          {/* <div className="export-div pointer" onClick={() => toggle()}>
            <img src={exportIcon} />
          </div> */}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{"Bulk Upload"}</h5>
            <img
              src={closeIcon}
              alt=""
              onClick={() => setModal(false)}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex p-5 my-3">
            <Col className="file-import-div me-3 ">
              <label htmlFor="upload" className="m-0 pointer">
                <input
                  type="file"
                  id="upload"
                  name="upload"
                  accept=".xls,.xlsx"
                  onChange={event => {
                    onFileChange(event)
                  }}
                />
                {fileName ? (
                  <span>{fileName?.target?.files?.[0]?.name}</span>
                ) : (
                  <>
                    <img
                      src={uploadicon}
                      alt="Upload Excel"
                      className="upload-icon me-2 "
                    />
                    <span className="upload">Upload&nbsp;</span>
                    <span>the file</span>
                  </>
                )}
              </label>
            </Col>
            {/* <button
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </button> */}
            <LoadingButton
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </LoadingButton>
          </div>
        </ModalBody>
        <ModalFooter className="bulk-upload-footer">
          <Row className="d-flex justify-content-center">
            <Col lg={8} className="text-center">
              Please download the sample file, add items, price etc & upload it
              back to create your Documents
            </Col>
          </Row>
          <Row
            className="fw-bold d-flex justify-content-center w-100 pointer"
            onClick={() => handleSampleFile()}
          >
            Download Sample File &gt;
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ProductFilter
