import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import {
  fetchRevisions,
  updateRevisionStatus,
} from "../../../services/estimation/estimation"
import { toastSuccess } from "../../../helpers/msg_helper"
import downloadicon from "../../../assets/icons/download.svg"
import { samplePdfFileDownload } from "../../../services/Inventory/inventory"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import LoadingButton from "../../../components/Common/LoadingButton"
import RenderAccordion from "./renderAccord"
import EstimateRenderTable from "./renderTable"
import NumberFormat from "../../../utils/numberFormat"
import MobileNumberFormat from "../../../utils/mobilenumberformat"
import { capitalize } from "lodash"
const ViewEstimations = () => {
  const [revision, setRevisions] = useState()
  const [refresh, setRefresh] = useState(0)
  const [revisionType, setRevisionType] = useState({ index: "", status: false })
  const { id } = useParams()

  const navigate = useNavigate()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchRevisions(id)
      setRevisions(
        loggedUser?.permission?.some(
          permissionItem => permissionItem?.name === "Convert to Project"
        ) &&
          loggedUser?.data?.id !== 1 &&
          loggedUser?.data?.roles?.some(
            designation => designation?.name != "Estimation Manager"
          )
          ? response?.data?.filter(item => item?.status === "1")
          : response?.data
      )

      setOpen(
        (loggedUser?.permission?.some(
          permissionItem => permissionItem?.name === "Convert to Project"
        ) &&
          loggedUser?.data?.id !== 1) ||
          response?.data?.length == 1
          ? 0 : 0
          // : response?.data?.length - 1
      )
    }
    if (loggedUser?.data?.id) {
      fetchData(id)
    }
  }, [refresh, loggedUser])

  const handleRevisionDownload = ({ id, type }) => {
    const handleCommonExport = async id => {
      const response = await samplePdfFileDownload(
        type == "short"
          ? `estimation/pdf-mini-statement/${id}`
          : `estimation/pdf/${id}`
      )
    }
    handleCommonExport(id)
  }

  const [open, setOpen] = useState("")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const handleApprove = item => {
    const updateStatus = async item => {
      setIsLoading(true)
      const response = await updateRevisionStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
      navigate(`/project-master/add/${item}`)
      setIsLoading(false)
    }
    updateStatus(item)
  }
  const handleRevisionType = index => {
    if (revisionType?.index === index) {
      setRevisionType({ index: index, status: !revisionType?.status })
    } else {
      setRevisionType({ index: index, status: true })
    }
  }

  const calculateTaskTotal = task => {
    let total = 0
    if (task.estimation_manpower && task.estimation_manpower.length > 0) {
      total += task.estimation_manpower.reduce(
        (sum, item) => sum + parseFloat(item.price || 0),
        0
      )
    }
    if (task.estimation_material && task.estimation_material.length > 0) {
      total += task.estimation_material.reduce(
        (sum, item) =>
          sum + parseFloat(item.price || 0) * parseFloat(item?.quantity),
        0
      )
    }
    if (task.estimation_subcontract && task.estimation_subcontract.length > 0) {
      total += task.estimation_subcontract.reduce(
        (sum, item) => sum + parseFloat(item.price || 0),
        0
      )
    }
    // console.log(`profitPercentage total`, total)

    return total
  }

  const calculateTaskAndSubtasksTotal = (task, profitPercentage) => {
    let total = calculateTaskTotal(task)
    if (task.tasks && task.tasks.length > 0) {
      total += task.tasks.reduce(
        (sum, subtask) =>
          sum + calculateTaskAndSubtasksTotal(subtask, profitPercentage),
        0
      )
    }

    const sumTotal =
      Number(total) + Number(total) * (Number(profitPercentage) / 100)
    console.log(`profitPercentage profitPercentage :`, profitPercentage, total, sumTotal);

    return sumTotal
  }

  const [isLoading, setIsLoading] = useState(false)
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs
              title="Estimation"
              breadcrumbObject={[
                { title: "Estimation", link: "/estimation" },
                { title: "Revision List", link: `/estimation/budget-view/${id}` },
              ]}
            />
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div>
                <Accordion open={open} toggle={toggle}>
                  {revision &&
                    revision?.map((dt, index) => {
                      const totalMaterialPrice =
                        dt?.estimation_material?.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) || 0

                      const totalSubcontractsPrice =
                        dt?.estimation_subcontract?.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) || 0
                      const totalManpowersPrice =
                        dt?.estimation_manpower?.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) || 0
                      const totalOverheadsPrice =
                        (((dt?.estimation_material?.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) || 0) +
                          (dt?.estimation_subcontract?.reduce(
                            (total, item) => total + Number(item?.price),
                            0
                          ) || 0) +
                          (dt?.estimation_manpower?.reduce(
                            (total, item) => total + Number(item?.price),
                            0
                          ) || 0)) *
                          Number(dt?.client_code?.overheads_per)) /
                        100

                      const totalPrelimsPrice =
                        (((dt?.estimation_material?.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) || 0) +
                          (dt?.estimation_subcontract?.reduce(
                            (total, item) => total + Number(item?.price),
                            0
                          ) || 0) +
                          (dt?.estimation_manpower?.reduce(
                            (total, item) => total + Number(item?.price),
                            0
                          ) || 0)) *
                          Number(dt?.client_code?.preliminary_per)) /
                        100
                      const subTotal =
                        totalMaterialPrice +
                        totalOverheadsPrice +
                        totalSubcontractsPrice +
                        totalManpowersPrice +
                        totalPrelimsPrice
                      const taxAmount = (subTotal * Number(dt?.tax)) / 100
                      const discountAmount =
                        (subTotal * Number(dt?.discount)) / 100
                      const TotalAmount = subTotal + taxAmount - discountAmount

                      const calculateTotal = item => {
                        const sumArray = (arr, multiplierKey) =>
                          Array.isArray(arr)
                            ? arr.reduce(
                                (total, current) =>
                                  total +
                                  Number(current?.price || 0) *
                                    Number(current?.[multiplierKey] || 1),
                                0
                              )
                            : 0

                        const totalMaterial = sumArray(
                          item?.estimation_material,
                          "quantity"
                        )
                        const totalManpower = sumArray(
                          item?.estimation_manpower,
                          "no_of_labours"
                        )
                        const totalSubcontract = sumArray(
                          item?.estimation_subcontract,
                          "duration"
                        )

                        // Recursively calculate total for nested tasks
                        // const totalSubTasks = Array.isArray(item?.tasks)
                        //   ? item.tasks.reduce((sum, subTask) => sum + calculateTotal(subTask), 0)
                        //   : 0

                        // Return the final numeric total
                        return totalMaterial + totalManpower + totalSubcontract
                        // + totalSubTasks
                      }

                      const sumTotal = calculateTotal(dt)
                      // +
                      // calculateTotal(projectTasks) * (projectProfit / 100)
                      // console.log(`sumTotal`, sumTotal)

                      // setTasks(response?.data?.tasks)

                      let marginValuePercent = 0
                      if (dt?.estimation?.margin_value != 0) {
                        marginValuePercent =
                          (Number(dt?.estimation?.margin_value) /
                            Number(sumTotal)) *
                          100
                      } else {
                        marginValuePercent = 0
                      }

                      const profitPercentage =
                        dt?.estimation?.margin_percentage != 0
                          ? dt?.estimation?.margin_percentage
                          : dt?.estimation?.margin_value != 0
                          ? marginValuePercent
                          : 0

                      console.log(`profitPercentage`, profitPercentage)

                      return (
                        <AccordionItem key={index}>
                          <AccordionHeader
                            targetId={index}
                            className={
                              revision?.length > index + 1 && `account-header`
                            }
                          >
                            <div className="d-flex justify-content-between w-100">
                              <span className="text-bold">
                                {index === 0
                                  ? "Costing Sheet"
                                  : `Revision ${`${index}`.padStart(
                                      2,
                                      "0"
                                    )}`}{" "}
                                {dt?.status == 1 && (
                                  <span className="font-13 status-active">
                                    Approved
                                  </span>
                                )}
                              </span>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId={index}>
                            <Row className="">
                              <Col sm={12}>
                                <Row>
                                  <Col lg={4} md={4} sm={4}>
                                    <Row className="py-3">
                                      <Col className="text-bold">Info</Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Estimation ID
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {dt?.client_code?.estimation_code}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Estimation Date
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {moment(
                                          dt?.client_code?.created_at
                                        ).format("DD-MM-YYYY")}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Estimation Created By
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {capitalize(dt?.client_code?.estimate_created_by)}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={4} md={4} sm={4}>
                                    <Row className="py-3">
                                      <Col className="text-bold">To</Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Client Code
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {
                                          dt?.client_code?.customers
                                            ?.customer_code
                                        }
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Client Name
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {
                                          dt?.client_code?.customers
                                            ?.customer_name
                                        }
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Project Name
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {capitalize(dt?.client_code?.project_name)}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Project Location
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {dt?.client_code?.location?.name}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Client Contact Person
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {dt?.client_code?.client_contact_person}
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Contact Number
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {MobileNumberFormat(
                                          "+" + dt?.client_code?.contact_number
                                        )}
                                      </Col>
                                      {/* <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-mute pb-3"
                                      >
                                        Estimate Created By
                                      </Col>
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        className="text-medium pb-3"
                                      >
                                        {dt?.client_code?.estimate_created_by}
                                      </Col> */}
                                    </Row>
                                  </Col>
                                  <Col
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    className="d-flex justify-content-end"
                                  >
                                    <span>
                                      <img
                                        src={downloadicon}
                                        alt="Upload Excel"
                                        className="trash-img pointer me-3"
                                        onClick={() =>
                                          handleRevisionDownload({
                                            id: dt?.id,
                                            type:
                                              revisionType?.index == index &&
                                              revisionType?.status == true
                                                ? "short"
                                                : "long",
                                          })
                                        }
                                      />
                                    </span>
                                    <span>
                                      <Button
                                        form={`supplier-form`}
                                        color={"primary"}
                                        className={"px-2 mb-3 btn btn-primary"}
                                        onClick={() =>
                                          handleRevisionType(index)
                                        }
                                      >
                                        {revisionType?.index == index &&
                                        revisionType?.status == true
                                          ? "Long Revision"
                                          : "Short Revision"}
                                      </Button>
                                    </span>
                                  </Col>
                                </Row>
                                {revisionType?.index == index &&
                                revisionType?.status == true ? (
                                  <Row className="mb-4">
                                    <Col lg={12}>
                                      <table className="estimation-table my-4">
                                        <thead>
                                          <tr>
                                            {/* <th>SL No</th> */}
                                            <th>Items</th>
                                            <th className="aline-rig">
                                              Price (AED)
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {dt?.estimation_material?.length ? (
                                            dt?.tasks?.map((item, index) => (
                                              <tr key={index}>
                                                <td className="ps-2">
                                                  {item?.name}
                                                </td>
                                                <td className="aline-rig">
                                                  {NumberFormat(
                                                    Number(
                                                      calculateTaskAndSubtasksTotal(
                                                        item,
                                                        profitPercentage
                                                      )
                                                    ),
                                                    3,
                                                    3
                                                  )}
                                                </td>
                                              </tr>
                                            ))
                                          ) : (
                                            <></>
                                          )}
                                          {/* {dt?.estimation_manpower?.length ? (
                                            <tr>
                                              <td>2</td>
                                              <td>Manpower</td>
                                              <td>
                                                {Number(
                                                  Number(
                                                    totalManpowersPrice
                                                  ).toFixed(2)
                                                ).toLocaleString("en", {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )} */}
                                          {/* {dt?.estimation_subcontract
                                            ?.length ? (
                                            <tr>
                                              <td>3</td>
                                              <td>Subcontract</td>
                                              <td>
                                                {Number(
                                                  Number(
                                                    totalSubcontractsPrice
                                                  ).toFixed(2)
                                                ).toLocaleString("en", {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )} */}
                                          {/* {dt?.preliminary_per != null ? (
                                            <tr>
                                              <td>4</td>
                                              <td>Prelims</td>
                                              <td>
                                                {Number(
                                                  Number(
                                                    totalPrelimsPrice
                                                  ).toFixed(2)
                                                ).toLocaleString("en", {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )} */}
                                          {/* {dt?.overheads_per != null ? (
                                            <tr>
                                              <td>5</td>
                                              <td>Overheads</td>
                                              <td>
                                                {Number(
                                                  Number(
                                                    totalOverheadsPrice
                                                  ).toFixed(2)
                                                ).toLocaleString("en", {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )} */}
                                        </tbody>
                                      </table>
                                      <Row className="d-flex align-items-center py-2  ">
                                        <Col
                                          lg={9}
                                          className="text-end dark-text"
                                        >
                                          Net Total (AED)
                                        </Col>
                                        <Col lg={3} className="ps-5 dark-text">
                                          {NumberFormat(
                                            Number(dt?.amount),
                                            3,
                                            3
                                          )}
                                        </Col>
                                      </Row>
                                      {discountAmount ? (
                                        <Row className="d-flex align-items-center py-2">
                                          <Col
                                            lg={9}
                                            className="text-end dark-text"
                                          >
                                            Discount(
                                            {NumberFormat(
                                              Number(dt?.discount)
                                            )}{" "}
                                            %)
                                          </Col>
                                          <Col
                                            lg={3}
                                            className="ps-5 dark-text"
                                          >
                                            {NumberFormat(
                                              Number(discountAmount),
                                              3,
                                              3
                                            )}
                                          </Col>
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                      {/* {taxAmount ? (
                                        <Row className="d-flex align-items-center py-2">
                                          <Col
                                            lg={9}
                                            className="text-end dark-text"
                                          >
                                            VAT({dt?.tax} %)
                                          </Col>
                                          <Col
                                            lg={3}
                                            className="ps-5 dark-text"
                                          >
                                            {Number(
                                              Number(taxAmount).toFixed(2)
                                            ).toLocaleString("en", {
                                              minimumFractionDigits: 2,
                                            })}
                                          </Col>
                                        </Row>
                                      ) : (
                                        <></>
                                      )} */}
                                      <Row className="d-flex align-items-center py-2">
                                        {/* <Col
                                          lg={9}
                                          className="text-end dark-text"
                                        >
                                          Total Amount(AED)
                                        </Col>
                                        <Col lg={3} className="ps-5 dark-text">
                                          {Number(
                                            Number(TotalAmount).toFixed(2)
                                          ).toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                          })}
                                        </Col> */}
                                      </Row>
                                      {revision.every(
                                        item => item?.status == 0
                                      ) &&
                                        (loggedUser?.permission?.some(
                                          permissionItem =>
                                            permissionItem?.name ===
                                            "Estimation Approve"
                                        ) ||
                                          loggedUser?.data?.id === 1) && (
                                          <Row className="d-flex justify-content-end pt-4">
                                            <Col lg={3} className="ps-5">
                                              <LoadingButton
                                                form={`supplier-form`}
                                                color={"primary"}
                                                className={
                                                  "px-2 mb-3 btn btn-primary"
                                                }
                                                onClick={() =>
                                                  handleApprove(dt?.id)
                                                }
                                                loading={isLoading}
                                                disabled={isLoading}
                                              >
                                                Approve
                                              </LoadingButton>
                                            </Col>
                                          </Row>
                                        )}
                                    </Col>
                                  </Row>
                                ) : (
                                  <>
                                    {dt?.tasks?.length ? (
                                      <>
                                        <Row className="py-4 ">
                                          <Col className="text-bold">
                                            Details
                                          </Col>
                                        </Row>

                                        <Row className="mb-4">
                                          <table className="estimation-table mb-4 ev-work-progress-tbl">
                                            <thead>
                                              <tr>
                                                <th rowspan="2">
                                                  Item Description
                                                </th>
                                                <th rowspan="2">Unit</th>
                                                <th
                                                  rowspan="2"
                                                  className="text-end"
                                                >
                                                  Qty
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="text-end"
                                                >
                                                  Rate
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="pe text-end"
                                                >
                                                  Amount
                                                </th>
                                                {/* </th> */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {dt?.tasks?.length ? (
                                                <EstimateRenderTable
                                                  tasks={dt?.tasks}
                                                  projectProfit={
                                                    profitPercentage
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </tbody>
                                          </table>
                                          {/* <Col lg={12}>
                                            <table className="estimation-table mb-4">
                                              <thead>
                                                <tr>
                                                  <th>Class Code</th>
                                                  <th>Category Code</th>
                                                  <th>Product</th>
                                                  <th>Unit</th>
                                                  <th>Quantity</th>
                                                  <th>Price(AED)</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {dt?.estimation_material?.map(
                                                  (dt, index) => (
                                                    <tr>
                                                      <td>
                                                        {
                                                          dt?.class_id
                                                            ?.class_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          dt?.category_id
                                                            ?.category_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          dt?.products
                                                            ?.product_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {dt?.unit?.unit_code}
                                                      </td>
                                                      <td>{dt?.quantity}</td>
                                                      <td>
                                                        {Number(
                                                          Number(
                                                            dt?.price
                                                          ).toFixed(4)
                                                        ).toLocaleString("en", {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="text-end text-bold pe-2">
                                                    Subtotal (AED)
                                                  </td>
                                                  <td className="text-bold">
                                                    {Number(
                                                      Number(
                                                        dt?.estimation_material?.reduce(
                                                          (a, b) =>
                                                            Number(b?.price) +
                                                            a,
                                                          0
                                                        )
                                                      ).toFixed(2)
                                                    ).toLocaleString("en", {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col> */}
                                        </Row>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {/* {dt?.estimation_manpower?.length ? (
                                      <>
                                        <Row className="py-4 mt-5">
                                          <Col className="text-bold">
                                            Manpower Details
                                          </Col>
                                        </Row>
                                        <Row className="mb-4">
                                          <Col lg={12}>
                                            <table className="estimation-table mb-4">
                                              <thead>
                                                <tr>
                                                  <th>Class Code</th>
                                                  <th>Category Code</th>
                                                  <th>Number of labours</th>
                                                  <th>Price(AED)</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {dt?.estimation_manpower?.map(
                                                  (dt, index) => (
                                                    <tr>
                                                      <td>
                                                        {
                                                          dt?.class_id
                                                            ?.class_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          dt?.category_id
                                                            ?.category_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {dt?.no_of_labours}
                                                      </td>
                                                      <td>
                                                        {Number(
                                                          Number(
                                                            dt?.price
                                                          ).toFixed(4)
                                                        ).toLocaleString("en", {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="text-end text-bold pe-2">
                                                    Subtotal(AED)
                                                  </td>
                                                  <td className="text-bold">
                                                    {Number(
                                                      Number(
                                                        dt?.estimation_manpower?.reduce(
                                                          (a, b) =>
                                                            Number(b?.price) +
                                                            a,
                                                          0
                                                        )
                                                      ).toFixed(2)
                                                    ).toLocaleString("en", {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <></>
                                    )} */}
                                    {/* {dt?.estimation_subcontract?.length ? (
                                      <>
                                        <Row className="py-4 mt-5">
                                          <Col className="text-bold">
                                            Subcontract Details
                                          </Col>
                                        </Row>
                                        <Row className="mb-4">
                                          <Col lg={12}>
                                            <table className="estimation-table mb-4">
                                              <thead>
                                                <tr>
                                                  <th>Class Code</th>
                                                  <th>Category Code</th>
                                                  <th>Description</th>
                                                  <th>Unit</th>
                                                  <th>Quantity</th>
                                                  <th>Price(AED)</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {dt?.estimation_subcontract?.map(
                                                  (dt, index) => (
                                                    <tr>
                                                      <td>
                                                        {
                                                          dt?.class_id
                                                            ?.class_code
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          dt?.category_id
                                                            ?.category_code
                                                        }
                                                      </td>
                                                      <td>{dt?.description}</td>
                                                      <td>
                                                        {dt?.unit?.unit_code}
                                                      </td>
                                                      <td>{dt?.duration}</td>
                                                      <td>
                                                        {Number(
                                                          Number(
                                                            dt?.price
                                                          ).toFixed(4)
                                                        ).toLocaleString("en", {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                <tr>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="text-end text-bold pe-2">
                                                    Subtotal(AED)
                                                  </td>
                                                  <td className="text-bold">
                                                    {Number(
                                                      Number(
                                                        dt?.estimation_subcontract?.reduce(
                                                          (a, b) =>
                                                            Number(b?.price) +
                                                            a,
                                                          0
                                                        )
                                                      ).toFixed(2)
                                                    ).toLocaleString("en", {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <></>
                                    )} */}
                                    {/* {dt?.preliminary_per != null &&
                                    dt?.preliminary_per > 0 ? (
                                      <>
                                        <Row className="py-4 mt-5">
                                          <Col className="text-bold">
                                            Prelims Details
                                          </Col>
                                        </Row>
                                        <Row className="mb-4">
                                          <Col lg={12}>
                                            <table className="estimation-table mb-4">
                                              <thead>
                                                <tr>
                                                  <th className="wdt-75"></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th>Prelims Percentage</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {dt?.estimation_overhead?.map(
                                                  (dt, index) => (
                                                <tr>
                                                  <td className="border-0 wdt-75"></td>
                                                  <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                  <td>
                                                    {dt?.preliminary_per}%
                                                  </td>
                                                </tr>
                                                )  )}
                                                <tr>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>

                                                  <td className="text-end text-bold pe-2">
                                                    Subtotal(AED)
                                                  </td>
                                                  <td className="text-bold">
                                                    {Number(
                                                      Number(
                                                        ((dt?.estimation_material?.reduce(
                                                          (a, b) =>
                                                            Number(b?.price) +
                                                            a,
                                                          0
                                                        ) +
                                                          dt?.estimation_manpower?.reduce(
                                                            (a, b) =>
                                                              Number(b?.price) +
                                                              a,
                                                            0
                                                          ) +
                                                          dt?.estimation_subcontract?.reduce(
                                                            (a, b) =>
                                                              Number(b?.price) +
                                                              a,
                                                            0
                                                          )) *
                                                          dt?.preliminary_per) /
                                                          100
                                                      ).toFixed(2)
                                                    ).toLocaleString("en", {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <></>
                                    )} */}
                                    {/* {dt?.overheads_per != null &&
                                    dt?.overheads_per > 0 ? (
                                      <>
                                        <Row className="py-4 mt-5">
                                          <Col className="text-bold">
                                            Overheads Details
                                          </Col>
                                        </Row>
                                        <Row className="mb-4">
                                          <Col lg={12}>
                                            <table className="estimation-table mb-4">
                                              <thead>
                                                <tr>
                                                  <th className="wdt-75"></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th></th>
                                                  <th>Overheads Percentage</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {dt?.estimation_overhead?.map(
                                                  (dt, index) => (
                                                <tr>
                                                  <td className="border-0 wdt-75"></td>
                                                  <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                <td className="border-0"></td>
                                                  <td>{dt?.overheads_per}%</td>
                                                </tr>
                                                )  )}
                                                <tr>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>
                                                  <td className="border-0"></td>

                                                  <td className="text-end text-bold pe-2">
                                                    Subtotal(AED)
                                                  </td>
                                                  <td className="text-bold">
                                                    {Number(
                                                      Number(
                                                        ((dt?.estimation_material?.reduce(
                                                          (a, b) =>
                                                            Number(b?.price) +
                                                            a,
                                                          0
                                                        ) +
                                                          dt?.estimation_manpower?.reduce(
                                                            (a, b) =>
                                                              Number(b?.price) +
                                                              a,
                                                            0
                                                          ) +
                                                          dt?.estimation_subcontract?.reduce(
                                                            (a, b) =>
                                                              Number(b?.price) +
                                                              a,
                                                            0
                                                          )) *
                                                          dt?.overheads_per) /
                                                          100
                                                      ).toFixed(2)
                                                    ).toLocaleString("en", {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <></>
                                    )} */}

                                    <Row className="d-flex align-items-center py-2 pt-0 ">
                                      <Col
                                        lg={10}
                                        className="text-end dark-text"
                                      >
                                        Net Total (AED)
                                      </Col>
                                      <Col lg={2} className="ps-5 dark-text">
                                        {NumberFormat(Number(dt?.amount), 3, 3)}
                                      </Col>
                                    </Row>
                                    {discountAmount ? (
                                      <Row className="d-flex align-items-center py-2">
                                        <Col
                                          lg={10}
                                          className="text-end dark-text"
                                        >
                                          Discount(
                                          {NumberFormat(
                                            Number(dt?.discount)
                                          )}{" "}
                                          %)
                                        </Col>
                                        <Col lg={2} className="ps-5 dark-text">
                                          {NumberFormat(
                                            Number(discountAmount),
                                            3,
                                            3
                                          )}
                                        </Col>
                                      </Row>
                                    ) : (
                                      <></>
                                    )}
                                    {/* {taxAmount ? (
                                      <Row className="d-flex align-items-center py-2">
                                        <Col
                                          lg={10}
                                          className="text-end dark-text"
                                        >
                                          VAT({dt?.tax} %)
                                        </Col>
                                        <Col lg={2} className="ps-5 dark-text">
                                          {Number(
                                            Number(taxAmount).toFixed(2)
                                          ).toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                          })}
                                        </Col>
                                      </Row>
                                    ) : (
                                      <></>
                                    )} */}

                                    <Row className="d-flex align-items-center py-2">
                                      {/* <Col
                                        lg={10}
                                        className="text-end dark-text"
                                      >
                                        Total Amount(AED)
                                      </Col>
                                      <Col lg={2} className="ps-5 dark-text">
                                        {Number(
                                          Number(TotalAmount).toFixed(2)
                                        ).toLocaleString("en", {
                                          minimumFractionDigits: 2,
                                        })}
                                      </Col> */}
                                    </Row>

                                    {revision.every(
                                      item => item?.status == 0
                                    ) &&
                                      (loggedUser?.permission?.some(
                                        permissionItem =>
                                          permissionItem?.name ===
                                          "Estimation Approve"
                                      ) ||
                                        loggedUser?.data?.id === 1) && (
                                        <Row className="d-flex justify-content-end pt-4">
                                          <Col lg={2} className="ps-5">
                                            <LoadingButton
                                              form={`supplier-form`}
                                              color={"primary"}
                                              className={
                                                "px-2 mb-3 btn btn-primary"
                                              }
                                              onClick={() =>
                                                handleApprove(dt?.id)
                                              }
                                              loading={isLoading}
                                              disabled={isLoading}
                                            >
                                              Approve
                                            </LoadingButton>
                                          </Col>
                                        </Row>
                                      )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                      )
                    })}
                </Accordion>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewEstimations
