import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import {
  fetchUnit,
  updateUnitStatus,
} from "../../../services/Inventory/inventory"
import EditUnit from "./edit"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import InventoryFilter from "../filter"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const Unit = () => {
  document.title = "Units | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  // const location = useLocation()

  // const menuItem = loggedUser?.smenu?.find(item =>
  //   location?.pathname.includes(item?.linkTo)
  // )

  // const subMenuItem = menuItem?.submenus?.find(
  //   item => item.linkTo === `${location?.pathname}`
  // )

  // console.log(`loggedUser`, loggedUser, location, menuItem, subMenuItem)

  const [page, setPage] = useState("")

  const [unit, setUnit] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [editUnit, setEditUnit] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchUnit({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}${`&sizePerPage=16`}`,
      })
      setUnit(response)
    }
    fetchData()
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditUnit({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateUnitStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Unit Code",
      renderCell: item => item?.unit_code,
      sortKey: "unit_code",
    },
    {
      label: "Description",
      renderCell: item => (
        <div className="capitalize-letter">{item?.description}</div>
      ),
      sortKey: "description",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {loggedUser?.permission?.some(
                permissionItem => permissionItem?.name === "unit_edit"
              ) &&
                item?.status === 1 && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditUnit("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Inventory"
              breadcrumbObject={[
                { title: "Product Master", link: "/inventory" },
                { title: "Unit", link: "/inventory-unit" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "unit_create"
            ) && (
              <EditUnit
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleResetValue}
                buttonLabel="+&nbsp; Unit"
                className="px-2 mb-3 me-2 btn btn-primary"
                editItem={editUnit}
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              sampleUrl={"download/units.xlsx"}
              importUrl={"units/import"}
              isExport={
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "unit_create"
                )
                  ? true
                  : false
              }
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {unit?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={unit?.data?.data}
                    paginate={unit?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="30% 30% 30% 10%"
                    mobileWidth="40% 40% 40% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Unit
