import React from "react"

const ProductMasterIcon = ({
  color = "#8B909A",
  size = 18,
  className = "",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M18 4.5C18 2.01897 15.9813 0 13.5 0C11.6841 0 10.1198 1.08341 9.40936 2.63582L7.98015 1.98633C7.81128 1.90974 7.61729 1.90974 7.44842 1.98633L0.376988 5.20061C0.147217 5.30483 0 5.53334 0 5.78571V14.1429C0 14.3952 0.147217 14.6237 0.376988 14.728L7.44842 17.9422C7.53285 17.9805 7.62357 18 7.71429 18C7.805 18 7.89572 17.9805 7.98015 17.9422L15.0516 14.728C15.2814 14.6237 15.4286 14.3952 15.4286 14.1429V8.55957C16.9462 7.83545 18 6.29034 18 4.5ZM7.71429 3.27769L9.04736 3.88324C9.01946 4.08542 9 4.29024 9 4.5C9 5.54586 9.36176 6.50712 9.96264 7.27213L7.71429 8.29374L2.19632 5.78571L7.71429 3.27769ZM1.28571 6.78386L7.07143 9.41375V16.3587L1.28571 13.7291V6.78386ZM14.1429 13.7291L8.35714 16.3587V9.41375L10.9764 8.22334C11.6966 8.7131 12.5653 9 13.5 9C13.7188 9 13.9323 8.97897 14.1429 8.94864V13.7291ZM13.5 7.71429C11.7277 7.71429 10.2857 6.27225 10.2857 4.5C10.2857 2.72775 11.7277 1.28571 13.5 1.28571C15.2723 1.28571 16.7143 2.72775 16.7143 4.5C16.7143 6.27225 15.2723 7.71429 13.5 7.71429Z"
        fill="currentColor"
      />
      <path
        d="M15.4277 3.85631H14.142V2.57059C14.142 2.21526 13.8542 1.92773 13.4992 1.92773C13.1441 1.92773 12.8563 2.21526 12.8563 2.57059V3.85631H11.5706C11.2156 3.85631 10.9277 4.14383 10.9277 4.49916C10.9277 4.85449 11.2156 5.14202 11.5706 5.14202H12.8563V6.42773C12.8563 6.78306 13.1441 7.07059 13.4992 7.07059C13.8542 7.07059 14.142 6.78306 14.142 6.42773V5.14202H15.4277C15.7827 5.14202 16.0706 4.85449 16.0706 4.49916C16.0706 4.14383 15.7827 3.85631 15.4277 3.85631Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ProductMasterIcon
