import Finance from "../../pages/Finance"
import EditAccountPayables from "../../pages/Finance/accountPayables/edit.js"
import AccountPayables from "../../pages/Finance/accountPayables/index.js"
import ViewAccPayables from "../../pages/Finance/accountPayables/view.js"
import EditAccountReceivable from "../../pages/Finance/accountReceivable/edit.js"
import AccountReceivable from "../../pages/Finance/accountReceivable/index.js"
import ViewAccRecievable from "../../pages/Finance/accountReceivable/view.js"
import Accounts from "../../pages/Finance/accounts/index.js"
import BalanceSheet from "../../pages/Finance/balanceSheet/index.js"
import BankReconciliation from "../../pages/Finance/bankReconciliation/index.js"
import FinanceCategory from "../../pages/Finance/category"
import DirectoryTree from "../../pages/Finance/chartOfAcc/index.js"
import FinanceCostCenter from "../../pages/Finance/costCenter.js/index.js"
import GRNCosting from "../../pages/Finance/grnCosting.js"
import EditGRNCosting from "../../pages/Finance/grnCosting.js/edit.js"
import ViewCosting from "../../pages/Finance/grnCosting.js/view.js"
import FinanceAccountGroupFields from "../../pages/Finance/groupFields/index.js"
import FinanceAccountGroup from "../../pages/Finance/groups/index.js"
import EditInvoices from "../../pages/Finance/invoices/edit.js"
import InvoiceSearch from "../../pages/Finance/invoices/index.js"
import JournalEntry from "../../pages/Finance/journalEntry/index.js"
import EditLedger from "../../pages/Finance/ledger/edit.js"
import LedgerFinance from "../../pages/Finance/ledger/index.js"
import LedgerAccView from "../../pages/Finance/ledger/view.js"
import LedgerAccounts from "../../pages/Finance/ledgerAcc/index.js"
import EditManufacturingJournal from "../../pages/Finance/manufacturingJournal/edit.js"
import ManufacturingJournal from "../../pages/Finance/manufacturingJournal/index.js"
import ManufacturingJournalView from "../../pages/Finance/manufacturingJournal/view.js"
import EditMappingSetup from "../../pages/Finance/mappingSetup/edit.js"
import ProfitLossAc from "../../pages/Finance/profitLoss/index.js"
import StatementOfAcc from "../../pages/Finance/soa/index.js"
// import EditStockTransfer from "../../pages/Finance/stockTransferJournal/edit.js"
// import StockTransferJournal from "../../pages/Finance/stockTransferJournal/index.js"
// import StockTransferView from "../../pages/Finance/stockTransferJournal/view.js"
import FinanceSubCategory from "../../pages/Finance/subCategory"
import TransactionHistory from "../../pages/Finance/transactionHistory/index.js"
import TrialBalance from "../../pages/Finance/trialBalance/index.js"
import EditVoucherEntry from "../../pages/Finance/voucherEntry/edit.js"
import VoucherEntrySearch from "../../pages/Finance/voucherEntry/index.js"
import VoucherEntryView from "../../pages/Finance/voucherEntry/view.js"
import FinanceProjectDashboard from "../../pages/FinanceDashboard/FinanceProjectDashboard/index.js"
import FinanceDahboard from "../../pages/FinanceDashboard/index.js"
import InvoiceView from "../../pages/Invoice/invoiceView/view.js"

export const financeRoutes = [
  { path: "/finance-dashboard", component: <FinanceDahboard /> },
  { path: "/finance-dashboard/:id", component: <FinanceProjectDashboard /> },
  { path: "/finance", component: <Finance /> },
  { path: "/finance-category", component: <FinanceCategory /> },
  { path: "/finance-sub-category", component: <FinanceSubCategory /> },
  { path: "/finance-grn-costing", component: <GRNCosting /> },
  { path: "/finance-grn-costing/add", component: <EditGRNCosting /> },
  { path: "/finance-grn-costing/view/:id", component: <ViewCosting /> },
  { path: "/finance-account-receivable", component: <AccountReceivable /> },
  {
    path: "/finance-account-receivable/view/:id",
    component: <ViewAccRecievable />,
  },
  {
    path: "finance-account-receivable/add",
    component: <EditAccountReceivable />,
  },
  { path: "/finance-account-payables", component: <AccountPayables /> },
  {
    path: "finance-account-payables/add",
    component: <EditAccountPayables />,
  },
  {
    path: "/finance-account-payable/view/:id",
    component: <ViewAccPayables />,
  },
  { path: "finance-transaction-history", component: <TransactionHistory /> },
  { path: "finance-cost-center", component: <FinanceCostCenter /> },
  { path: "finance-account", component: <Accounts /> },
  { path: "finance-mapping-setup", component: <EditMappingSetup /> },
  { path: "finance-invoices", component: <InvoiceSearch /> },
  { path: "finance-invoice/add", component: <EditInvoices /> },
  { path: "finance-voucher", component: <VoucherEntrySearch /> },
  { path: "finance-voucher/add", component: <EditVoucherEntry /> },
  { path: "finance-voucher/edit/:editId", component: <EditVoucherEntry /> },
  { path: "finance-voucher/view/:id", component: <VoucherEntryView /> },
  { path: "finance-invoice/view/:id", component: <InvoiceView /> },
  { path: "finance-day-book", component: <JournalEntry /> },
  { path: "finance-balance-sheet", component: <BalanceSheet /> },
  { path: "finance-trial-balance", component: <TrialBalance /> },
  { path: "finance-profitloss", component: <ProfitLossAc /> },
  { path: "finance-groups", component: <FinanceAccountGroup /> },
  { path: "finance-group-fields", component: <FinanceAccountGroupFields /> },
  { path: "finance-ledger", component: <LedgerFinance /> },
  { path: "finance-ledger-acc", component: <LedgerAccounts /> },
  { path: "finance-recievable", component: <StatementOfAcc /> },
  { path: "finance-payable", component: <StatementOfAcc /> },
  { path: "finance-ledger/view/:id", component: <LedgerAccView /> },
  { path: "finance-ledger/add", component: <EditLedger /> },
  { path: "finance-ledger/edit/:id", component: <EditLedger /> },
  { path: "finance-bank-reconciliation", component: <BankReconciliation /> },
  { path: "finance-manufacturing-journal", component: <ManufacturingJournal /> },
  { path: "finance-manufacturing-journal/add", component: <EditManufacturingJournal /> },
  { path: "finance-manufacturing-journal/edit/:id", component: <EditManufacturingJournal /> },
  { path: "finance-manufacturing-journal/:action/:id", component: <EditManufacturingJournal /> },
  { path: "finance-chart-of-acc", component: <DirectoryTree /> },
  // { path: "finance-stock-transfer-journal", component: <StockTransferJournal /> },
  // { path: "finance-stock-transfer-journal/add", component: <EditStockTransfer /> },
  // { path: "finance-stock-transfer-journal/edit/:id", component: <EditStockTransfer /> },
  // { path: "finance-stock-transfer-journal/view/:id", component: <StockTransferView /> },
]
