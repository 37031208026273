import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  FormFeedback,
  ModalFooter,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import {
  searchMaterialRequesting,
  updateMRRequesting,
} from "../../../services/procurement/materialrequest"
import MaterialFilter from "./filter"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { Form, FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import closeIcon from "../../../assets/icons/close.svg"
import LoadingButton from "../../../components/Common/LoadingButton"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const MaterialRequesting = ({ fromDashboard, projectId }) => {
  document.title = "MaterialRequest | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const schema = yup.object().shape({})

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [materialRequest, setMaterialRequest] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [mrno, setMRNo] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const handleActivate = item => {
  //   const updateStatus = async item => {
  //     const response = await updateSupplierStatus(item)
  //     toastSuccess(response?.message)
  //     setRefresh(refresh + 1)
  //   }
  //   updateStatus(item)
  // }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const handleAction = async data => {
    if (data?.status == 2) {
      toggle()
      reset({ ...data })
    } else {
      const response = await updateMRRequesting(data)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  const columns = [
    {
      label: "MR no",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "materialrequest_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/procurement-material-req/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.code}
              </div>
            ) : (
              <div>{item?.code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "Project Code",
      renderCell: item => item?.project?.project_code,
    },
    {
      label: "Request date",
      renderCell: item => moment(item?.request_date).format("DD-MM-YYYY"),
    },
    // {
    //   label: "Expected Delivery Date",
    //   renderCell: item => moment(item?.exp_Delivery_date).format("DD-MM-YYYY"),
    // },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1
                ? "status-draft"
                : item?.status === 2
                ? "status-pending"
                : "status-active "
            }`}
          >
            {item?.status === 1
              ? "Accepted"
              : item?.status === 2
              ? "Rejected"
              : "New"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item =>
        fromDashboard ? (
          item?.request_status == 0 ? (
            <>
              <div>
                <Button
                  color={"primary"}
                  className="btn btn-primary me-2"
                  onClick={() =>
                    handleAction({ materialReq: item?.id, status: 1 })
                  }
                >
                  Accept
                </Button>
                <Button
                  className="hover-button"
                  onClick={() =>
                    handleAction({ materialReq: item?.id, status: 2 })
                  }
                >
                  Reject
                </Button>
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <>
            <div>
              <span id={`PopoverLegacy-${item?.id}`}>
                <img src={action} alt="" className="pointer" />
              </span>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="legacy"
            >
              <PopoverBody>
                {item?.listing_status == 1 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/procurement-purchase-order/add`, {
                        state: item,
                      })
                    }
                  >
                    Convert to PO
                  </div>
                )}
                {item?.status == 0 && (
                  <>
                    <div
                      className="table-popup-content"
                      onClick={() =>
                        navigate(`/procurement-material-req/edit/${item?.id}`, {
                          state: item,
                        })
                      }
                    >
                      Edit
                    </div>
                    <div
                      className="table-popup-content"
                      onClick={() =>
                        handleAction({ materialReq: item?.id, status: 1 })
                      }
                    >
                      Accept
                    </div>
                    <div
                      className="table-popup-content"
                      onClick={() =>
                        handleAction({ materialReq: item?.id, status: 2 })
                      }
                    >
                      Reject
                    </div>
                  </>
                )}
                {item?.status == 1 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/finance-voucher/add`, {
                        state: { ...item, chkField: "MR" },
                      })
                    }
                  >
                    Create Voucher
                  </div>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchMaterialRequest = async () => {
      const response = await searchMaterialRequesting({
        params: `${
          status == "0" || status == "1" || status == "2"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          mrno ? `&mr_no=${mrno}` : ""
        }${datefrom ? `&requestdate_from=${datefrom}` : ""}${
          dateto ? `&requestdate_to=${dateto}` : ""
        }${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}${
          fromDashboard ? `&filter_status=0` : ""
        }${fromDashboard && projectId ? `&project_id=${projectId}` : ""}`,
      })
      setMaterialRequest(response)
    }
    // ${`&financial=${financial}`}
    fetchMaterialRequest()
  }, [refresh, status, search, mrno, page, datefrom, dateto, financial])
  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleRequestChange = id => {
    setMRNo(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const response = await updateMRRequesting({ ...data })
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
      toggle()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Material Request",
                    link: "/procurement-materialreq",
                  },
                ]}
              />
            ) : (
              <></>
            )}
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-material-req/add"
                className={"px-2 mb-3 me-2 btn btn-primary"}
              >
                {"+ Material Request"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <MaterialFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleRequestChange={handleRequestChange}
              handleDateChange={handleDateChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
              fromDashboard={fromDashboard}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {materialRequest && (
                  <UpdateTable
                    className="mt-5"
                    nodes={materialRequest?.data?.data}
                    paginate={materialRequest?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="30% 20% 20% 20% 10% "
                    mobileWidth="40% 40% 30% 30% 15% "
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="md" unmountOnClose={false}>
        <FormProvider id={`leave-form`} {...methods}>
          <Form id={`leave-form`} onSubmit={handleSubmit(handleRegistration)}>
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="">
                    {/* {isGenerate && ( */}
                    <span className={"warning-icon"}>
                      <i className={" uil-exclamation-octagon"}></i>{" "}
                    </span>
                    {/* )} */}
                    Remarks
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              <ModalBody className="pb-4">
                <Row>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>Remarks</Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the remarks"
                        name={"remarks"}
                        invalid={!!errors.remarks}
                      />
                      <FormFeedback invalid>
                        {errors.remarks?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  form={`leave-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                >
                  Confirm
                </LoadingButton>
              </ModalFooter>
            </>
          </Form>
        </FormProvider>
      </Modal>
    </React.Fragment>
  )
}

export default MaterialRequesting
