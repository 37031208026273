import Settings from "../../pages/Settings"
import CampCode from "../../pages/Settings/campCode"
import Category from "../../pages/Settings/category"
import ClassCode from "../../pages/Settings/classCode"
import Country from "../../pages/Settings/country"
import State from "../../pages/Settings/state"
import Currency from "../../pages/Settings/currency"
import LocationIndex from "../../pages/Settings/location"
import PaymentType from "../../pages/Settings/paymentType"
import Series from "../../pages/Settings/series"
import EditGeneral from "../../pages/Settings/General/edit"
import User from "../../pages/User"
import Prefix from "../../pages/Settings/Prefix"
import Calender from "../../pages/Settings/Calender"
import Relationship from "../../pages/Settings/relation"
import Vaccine from "../../pages/Settings/vaccine"
import Booster from "../../pages/Settings/booster"
import EditProjectSetting from "../../pages/Settings/EstimationSetting/edit"
import PaymentTermsSearch from "../../pages/Settings/payTerms"
import PaymentTermsTemplateSearch from "../../pages/Settings/payTermTemplate"
import TermsSearch from "../../pages/Settings/terms"
import BanksSearch from "../../pages/Settings/banks"

export const settingsRoutes = [
  { path: "/settings", component: <Settings /> },
  { path: "/settings-series", component: <Series /> },
  { path: "/settings-class", component: <ClassCode /> },
  { path: "/settings-category", component: <Category /> },
  { path: "/settings-currency", component: <Currency /> },
  { path: "/settings-country", component: <Country /> },
  { path: "/settings-state", component: <State /> },
  { path: "/settings-payment-type", component: <PaymentType /> },
  { path: "/settings-location", component: <LocationIndex /> },
  { path: "/settings-camp-code", component: <CampCode /> },
  { path: "/settings-general", component: <EditGeneral /> },
  { path: "/settings-master", component: <EditProjectSetting /> },
  { path: "/settings-prefix", component: <Prefix /> },
  { path: "/user", component: <User /> },
  { path: "/settings-financial-year", component: <Calender /> },
  { path: "/settings-relationship", component: <Relationship /> },
  { path: "/settings-vaccine", component: <Vaccine /> },
  { path: "/settings-booster", component: <Booster /> },
  { path: "/settings-payment-terms", component: <PaymentTermsSearch /> },
  { path: "/settings-payment-terms-template", component: <PaymentTermsTemplateSearch /> },
  { path: "/settings-terms", component: <TermsSearch /> },
  { path: "/settings-banks", component: <BanksSearch /> },
]
