import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  UFDate,
  UFEditor,
  UFInputNumber,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchManufacturingJournal,
  postManufacturingJournal,
} from "../../../services/finance/finance"
import ProductList from "../../../components/Common/chooseFields/productList"
import lightTrash from "../../../assets/icons/light-trash.svg"
import NumberFormat from "../../../utils/numberFormat"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import moment from "moment"
import { fetchProjecrtMaster } from "../../../services/projectManager/projectMaster"
import RenderTable from "../../ProjectManagement/projectMaster/renderTable"

const schema = yup.object().shape({
  project_id: yup.mixed().required("Project is Required"),
  // materials: yup.array().of(
  //   yup.object().shape({
  //     material_id: yup.mixed().required("Product Code is Required"),
  //   })
  // ),
})

const EditManufacturingJournal = () => {
  const navigate = useNavigate()
  const { action, id } = useParams()

  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState()
  const [grandTotal, setGrandTotal] = useState(0)

  const now = moment()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: moment(now, "YYYY-MM-DD").toDate(),
      // materials: [
      //   {
      //     quantity: 0,
      //     product_id: null,
      //     unit_price: "",
      //     sub_price: "",
      //     unit: null,
      //     id: "",
      //   },
      // ],
      // manpower_rate: 0,
      // subcontract_rate: 0,
      // additional_cost: 0,
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  useEffect(() => {
    const handleEdit = async id => {
      const response = await fetchManufacturingJournal(id)
      // const materialArr = response?.data?.materials?.map(dt => ({
      //   id: dt?.id,
      //   quantity: dt?.quantity,
      //   rate: dt?.rate,
      //   amount: Number(Number(dt?.rate) * Number(dt?.quantity)).toFixed(3),
      //   material_id: {
      //     label: dt?.material_name,
      //     value: dt?.material_id,
      //   },
      // }))
      if (id) {
        reset({
          ...response?.data,
          project_id: {
            label: response?.data?.project?.code,
            value: response?.data?.project?.id,
          },
          // materials: materialArr,
        })
        setTableData(response)
      }
    }
    if (id) {
      handleEdit(id)
    }
  }, [id])

  useEffect(() => {
    if (location?.state?.project_id) {
      setValue("project_id", location?.state?.project_id)
      fetchProjectMasterId(location?.state?.project_id?.value)
    }
  }, [location])

  const fetchProjectMasterId = async id => {
    console.log(`fetchProjectMasterId`, id)
    setTableData()
    let response = await fetchProjecrtMaster(id)
    setGrandTotal(0)

    setTableData(response)

    // reset({
    //   ...response?.data,
    //   project_name:
    //     action == "add"
    //       ? response?.data?.client_code?.project_name
    //       : response?.data?.project_name,
    //   project_location:
    //     action == "add"
    //       ? response?.data?.client_code?.location?.name
    //       : response?.data?.location?.name,
    // })
  }

  // useEffect(() => {
  //   if (watch("project_id")?.value != null) {
  //     fetchProjectMasterId(watch("project_id")?.value)
  //   }
  // }, [watch("project_id")?.value])

  const handleRegistration = async data => {
    console.log(`handleReg Data`, data)

    // const materialArr = data?.materials?.map(dt => ({
    //   material_id: dt?.material_id?.value,
    //   quantity: dt?.quantity,
    //   rate: dt?.rate,
    // }))

    // const grandTotal =
    //   Number(calculateStockTotal("materials")) +
    //   Number(watch("manpower_rate") || 0) +
    //   Number(watch("subcontract_rate") || 0) +
    //   Number(watch("additional_cost") || 0)

    try {
      setIsLoading(true)
      const createdLedger = await postManufacturingJournal({
        ...data,
        project_id: data?.project_id?.value,
        date: moment(data?.date).format("YYYY-MM-DD"),
        grand_total: grandTotal,
        // materials: materialArr,
        // grand_total: grandTotal,
      })
      toastSuccess(createdLedger?.message)
      navigate("/finance-manufacturing-journal")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const {
    fields: stockTransferSourceFields,
    append: stockTransferSourceAppend,
    remove: stockTransferSourceRemove,
  } = useFieldArray({
    control,
    name: "materials",
  })

  const calculateStockTotal = type => {
    const productWatch = watch(type)
    const price = productWatch?.reduce((a, b) => Number(b.amount) + a, 0) || 0
    return Number(price)?.toFixed(3)
  }

  const handleSourceQnt = (e, i, a, b) => {
    const singlePrice = watch(`materials.${i}.${a}`)
    const subPrice = Number(watch(`materials.${i}.${b}`)) * Number(singlePrice)
    setValue(`materials.${i}.amount`, subPrice)
  }
  // const calculateTotal = items => {
  //   return items?.reduce(
  //     (sum, item) => sum + Number(item?.quantity) * Number(item?.rate),
  //     0
  //   )
  // }
  const calculateTotal = items => {
    if (!Array.isArray(items)) return 0 // Ensure it's an array

    return items?.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0
      const rate = Number(item.rate) || 0
      return sum + quantity * rate
    }, 0)
  }
  const handleRegistrationData = formData => {
    console.log(`formmmmm`, formData)

    const materialArr = formData?.project_material?.flat().filter(Boolean)
    const manPowerArr = formData?.project_manpower?.flat().filter(Boolean)
    const subContractArr = formData?.project_subcontract?.flat().filter(Boolean)
    setValue("materials", materialArr)
    setValue("manpower", manPowerArr)
    setValue("subcontract", subContractArr)
    // setValue(
    //   "grand_total",
    //   calculateTotal(materialArr) +
    //     calculateTotal(manPowerArr) +
    //     calculateTotal(subContractArr)
    // )
    setGrandTotal(
      calculateTotal(materialArr) +
        calculateTotal(manPowerArr) +
        calculateTotal(subContractArr)
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`employee-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="w-100">
                <div className="d-flex justify-content-between pe-3">
                  <Breadcrumbs
                    title="Procurement"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      {
                        title: "Manufacturing Journal",
                        link: "/finance-manufacturing-journal",
                      },
                      {
                        title: id
                          ? "Edit Manufacturing Journal"
                          : "Create Manufacturing Journal",
                        link: "",
                      },
                    ]}
                  />
                  {action != "view" && (
                    <LoadingButton
                      form={`employee-form`}
                      color={"primary"}
                      loading={isLoading}
                      disabled={grandTotal == 0 ? true : isLoading}
                      className={"px-2 mb-3 btn btn-primary"}
                      type="submit"
                    >
                      {id ? "Update" : "Save"}
                    </LoadingButton>
                  )}
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card px-3 pb-3">
                  <div className="mapping-setup-title mb-2 pt-3">
                    Manufacture Of Materials
                  </div>

                  <Row className="mb-4 mt-2">
                    <Col sm={12}>
                      <Row>
                        <Col sm={6} lg={3} xl={3}>
                          <FormGroup>
                            <Label>
                              Project
                              <span className="mandatory">*</span>
                            </Label>
                            <ProjectNo
                              control={control}
                              name={"project_id"}
                              placeholder={"Project No"}
                              target={"body"}
                              disabled={id ? true : false}
                              onSelectChange={e =>
                                fetchProjectMasterId(e?.value)
                              }
                            />
                            <FormFeedback invalid>
                              {errors.project_id?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm={6} lg={4} xl={3}>
                          <FormGroup className="mb-4">
                            <Label>Date</Label>
                            <UFDate
                              control={control}
                              placeholder="Enter the Date "
                              name={"date"}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.date?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="m-0" />

                  {tableData?.data?.estimation_revision?.tasks_for_project
                    ?.length ||
                  tableData?.data?.project?.estimation_revision?.tasks
                    ?.length ? (
                    <Row className="mb-1">
                      <Col lg={12}>
                        <div className="tbl-scroll">
                          <table className="estimation-table mb-4 ev-work-progress-tbl">
                            <thead>
                              <tr>
                                <th rowspan="2">Item Description</th>
                                <th rowspan="2">Unit</th>
                                <th rowspan="2">Qty</th>
                                <th rowspan="2">Rate</th>
                                <th rowspan="2">Amount</th>
                                <th colSpan="3" className="text-center">
                                  Used Count
                                </th>
                                <th colSpan="3" className="text-center">
                                  Value of Work Done
                                </th>
                              </tr>
                              <tr>
                                {/* <th className=""> */}
                                <th className="text-center pe-0">
                                  <div className="table-head-sub">Previous</div>
                                </th>
                                <th className="text-center px-0">
                                  <div className="table-head-sub">Current</div>
                                </th>
                                <th className="text-center ps-0">
                                  <div className="table-head-sub">
                                    Cumilative
                                  </div>
                                </th>
                                {/* </th> */}
                                {/* <th> */}
                                <th className="text-center pe-0 wdt-8">
                                  <div className="table-head-sub">Previous</div>
                                </th>
                                <th className="text-center px-0 wdt-8">
                                  <div className="table-head-sub">Current</div>
                                </th>
                                <th className="text-center ps-0 pe-2 wdt-8">
                                  <div className="table-head-sub">
                                    Cumilative
                                  </div>
                                </th>
                                {/* </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData?.data?.estimation_revision
                                ?.tasks_for_project?.length ||
                              tableData?.data?.project?.estimation_revision
                                ?.tasks?.length ? (
                                <>
                                  <RenderTable
                                    tasks={
                                      tableData?.data?.estimation_revision
                                        ?.tasks_for_project
                                        ? tableData?.data?.estimation_revision
                                            ?.tasks_for_project
                                        : tableData?.data?.project
                                            ?.estimation_revision?.tasks
                                    }
                                    isUpdateable={
                                      action == "view" ? false : true
                                    }
                                    ProjectId={watch("project_id")?.value}
                                    onDataChange={handleRegistrationData}
                                  />
                                  <tr className="fs-13">
                                    <td className="ps-3"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center"></td>

                                    <td className="text-end"></td>
                                    <td className="text-end"></td>
                                    <td className="text-end"></td>
                                    <td className="text-end fs-13 fw-500">
                                      {NumberFormat(
                                        grandTotal ? grandTotal : 0,
                                        3,
                                        3
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {/* <Row className="mt-2 h-100">
                    <Col
                      sm={6}
                      className="manufacturing-br h-100 d-flex flex-column"
                    >
                      <div className="d-flex justify-content-center mb-2 mapping-setup-title">
                        Material Cost
                      </div>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th className="w-25">Product Code</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {stockTransferSourceFields?.map((item, index) => (
                            <tr key={`stockSource_${item?.id}`}>
                              <td className="ps-1">
                                <ProductList
                                  control={control}
                                  placeholder="Product Code"
                                  selectedProduct={watch(`materials`)}
                                  index={index}
                                  projectId={watch("project_id")?.value}
                                  name={`materials.${index}.material_id`}
                                  invalid={
                                    !!errors?.voucher_receipt?.[index]
                                      ?.material_id
                                  }
                                />
                              </td>
                              <td className="ps-1">
                                <UFInputNumber
                                  control={control}
                                  isDecimal={true}
                                  decimalLength={2}
                                  placeholder="Quantity"
                                  name={`materials.${index}.quantity`}
                                  invalid={
                                    !!errors?.materials?.[index]?.quantity
                                  }
                                  onChange={e =>
                                    handleSourceQnt(
                                      e,
                                      index,
                                      "quantity",
                                      "rate"
                                    )
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.materials?.[index]?.quantity
                                      ?.message
                                  }
                                </FormFeedback>
                              </td>
                              <td className="ps-1">
                                <UFInputNumber
                                  control={control}
                                  placeholder="Rate"
                                  isDecimal={true}
                                  name={`materials.${index}.rate`}
                                  invalid={!!errors?.materials?.[index]?.rate}
                                  onChange={e =>
                                    handleSourceQnt(
                                      e,
                                      index,
                                      "rate",
                                      "quantity"
                                    )
                                  }
                                />
                                <FormFeedback invalid>
                                  {errors?.materials?.[index]?.rate?.message}
                                </FormFeedback>
                              </td>
                              <td className="p-0">
                                <UFInputNumber
                                  className="ps-2"
                                  control={control}
                                  isDecimal={true}
                                  placeholder="Amount"
                                  name={`materials.${index}.amount`}
                                  invalid={!!errors?.materials?.[index]?.amount}
                                  disabled={true}
                                  // onChange={e => handleSubPrice(e, index)}
                                />
                                <FormFeedback invalid>
                                  {errors?.materials?.[index]?.amount?.message}
                                </FormFeedback>
                              </td>

                              <td>
                                <div className="pb-1 px-2 d-flex justify-content-center">
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() =>
                                      stockTransferSourceRemove(index)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        <Button
                          color="secondary"
                          className="addButton mt-2"
                          onClick={() =>
                            stockTransferSourceAppend({
                              product_id: "",
                              description: "",
                              quantity: "",
                              discount: "",
                              unit_price: "",
                              sub_price: "",
                            })
                          }
                        >
                          Add Row
                        </Button>
                      </Col>

                      <div className="text-end mt-auto">
                        {NumberFormat(calculateStockTotal("materials"), 3, 3)}
                      </div>
                    </Col>
                    <Col sm={6} className="h-100 d-flex flex-column">
                      <div className="d-flex justify-content-center mb-2 mapping-setup-title">
                        Additional Cost
                      </div>
                      <Row>
                        <Col lg="4" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>Manpower Cost</Label>
                            <UFInputNumber
                              control={control}
                              isDecimal={true}
                              decimalLength={3}
                              placeholder="Amount"
                              name={`manpower_rate`}
                              invalid={!!errors?.manpower_rate}
                            />
                            <FormFeedback invalid>
                              {errors?.manpower_rate?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="4" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>Sub-Contract Cost</Label>
                            <UFInputNumber
                              control={control}
                              isDecimal={true}
                              decimalLength={3}
                              placeholder="Amount"
                              name={`subcontract_rate`}
                              invalid={!!errors?.subcontract_rate}
                            />
                            <FormFeedback invalid>
                              {errors?.subcontract_rate?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg="4" xs={12} md={5} sm={12}>
                          <FormGroup className="mb-4">
                            <Label>Additional Cost</Label>
                            <UFInputNumber
                              control={control}
                              isDecimal={true}
                              decimalLength={3}
                              placeholder="Amount"
                              name={`additional_cost`}
                              invalid={!!errors?.additional_cost}
                            />
                            <FormFeedback invalid>
                              {errors?.additional_cost?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="mt-auto">
                        <Row>
                          <Col sm={6}>
                            <div>
                              <FormGroup className="">
                                <Label>Narration</Label>
                                <UFEditor
                                  control={control}
                                  placeholder="Enter the Narrations"
                                  rows="5"
                                  name={"narration"}
                                  invalid={!!errors.narration}
                                  className={"pb-0"}
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          <Col sm={6} className="mt-auto">
                            <div className="text-end">
                              {NumberFormat(
                                Number(calculateStockTotal("materials")) +
                                  Number(watch("manpower_rate") || 0) +
                                  Number(watch("subcontract_rate") || 0) +
                                  Number(watch("additional_cost") || 0),
                                3,
                                3
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row> */}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditManufacturingJournal
