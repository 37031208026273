import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getGradeFields } from "../../../services/Settings/dropdown"
import { searchManufacturingJournal } from "../../../services/finance/finance"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const ManufactureLi = ({
  control,
  name,
  onSelectChange,
  disabled,
  placeholder,
  projectId,
  statusChk,
  grandTotal,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [gradeData, setGradeData] = useState([])

  const loadOptions = async () => {
    const data = await searchManufacturingJournal({
      params: `${projectId ? `project_id=${projectId}` : ""}${
        statusChk ? `&status=${statusChk}` : ""
      }`,
    })
    setIsLoading(false)
    const grade_id = data?.data?.map(dta => {
      return {
        label:
          dta?.code &&
          `${ucfirst(dta?.code)} ${
            grandTotal ? `- (${dta?.grand_total} AED)` : ""
          }`,
        value: dta.id,
        ...dta,
      }
    })

    setGradeData(grade_id)
  }

  useEffect(() => {
    loadOptions()
  }, [projectId])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={gradeData}
              placeholder={placeholder ? placeholder : "Grade"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              isDisabled={disabled}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(".modal")}
            />
          )
        }}
      />
    </div>
  )
}

export default ManufactureLi
