// import EstimationIndex from "../../../pages/Estimation"
// import EditEstimation from "../../../pages/Estimation/edit"
// import ViewEstimations from "../../../pages/Estimation/view"
import EstimationsSub from "../../pages/Estimations"
import EstimationIndex from "../../pages/Estimations/Estimation"
import EditEstimation from "../../pages/Estimations/Estimation/edit"
import ViewEstimations from "../../pages/Estimations/Estimation/view"
import RoughEstimationIndex from "../../pages/Estimations/RoughEstimation"
import EditRoughEstimation from "../../pages/Estimations/RoughEstimation/edit"
import ViewRoughEstimations from "../../pages/Estimations/RoughEstimation/view"

export const estimationRoutes = [
  { path: "/estimation", component: <EstimationsSub /> },
  { path: "/estimation/budget", component: <EstimationIndex /> },
  { path: "/estimation/budget-add", component: <EditEstimation /> },
  { path: "/estimation/budget-edit/:id", component: <EditEstimation /> },
  { path: "/estimation/budget-view/:id", component: <ViewEstimations /> },
  { path: "/estimation/quotation", component: <RoughEstimationIndex /> },
  { path: "/estimation/quotation-add", component: <EditRoughEstimation /> },
  { path: "/estimation/quotation-edit/:id", component: <EditRoughEstimation /> },
  { path: "/estimation/quotation-view/:id", component: <ViewRoughEstimations /> },
  // { path: "/estimation", component: <EstimationIndex /> },
  // { path: "/estimation/budget-add", component: <EditEstimation /> },
  // { path: "/estimation-edit/:id", component: <EditEstimation /> },
  // { path: "/estimation/budget-view/:id", component: <ViewEstimations /> },
]
