import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
// import MaterialFilter from "./filter"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  searchSubcontractRequest,
  updateSubcontractStatus,
} from "../../../services/procurement/subContracts"

const SubcontractRequest = ({ fromDashboard, projectId }) => {
  document.title = "MaterialRequest | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [subContractData, setSubContractData] = useState()
  const [page, setPage] = useState("")

  const handleAction = async data => {
    console.log("satatus", data)
    const response = await updateSubcontractStatus({
      ...data,
      status: data?.status,
    })
    toastSuccess(response?.message)
    setRefresh(refresh + 1)
  }

  useEffect(() => {
    const fetchSubContract = async () => {
      const response = await searchSubcontractRequest({
        params: `${"&searchText"}${`&sizePerPage=16`}`,
      })
      setSubContractData(response)
      console.log("value", response?.data?.data)
    }
    fetchSubContract()
  }, [refresh])

  const handlePaginate = page => {
    setPage(page)
  }

  const columns = [
    {
      label: "Project Code",
      renderCell: item => (
        <div
          onClick={() =>
            navigate(`/procurement-subcontractreq/view/${item.id}`)
          }
          className="pointer fw-bold"
        >
          {item?.project?.project_code}
        </div>
      ),
    },
    {
      label: "Supplier Code",
      renderCell: item => item?.supplier?.suppliers_code,
    },
    {
      label: "Request Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === "1"
                ? "status-active"
                : item?.status === "0"
                ? "status-posted"
                : "status-inactive"
            }`}
          >
            {item?.status === "0"
              ? "New"
              : item?.status === "1"
              ? "Accept"
              : "Reject"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status == 0 && (
                <>
                  <div
                    className="table-popup-content"
                    onClick={() => handleAction({ id: item?.id, status: 1 })}
                  >
                    Accept
                  </div>
                  <div
                    className="table-popup-content"
                    onClick={() => handleAction({ id: item?.id, status: 2 })}
                  >
                    Reject
                  </div>
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/procurement-subcontractreq/edit/${item.id}`)
                    }
                  >
                    Edit
                  </div>
                </>
              )}
              {/* <div
                  className={`table-popup-content`}
                  // onClick={() => handleActivate(item?.id)}
                >
                  {item?.status === 1 ? "Deactivate" : "Activate"}
                </div> */}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Procurement", link: "/procurement" },
                {
                  title: "Subcontract Request",
                  link: "/procurement-subcontractreq",
                },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurement-subcontractreq/add"
                className={"px-2 mb-3 me-2 btn btn-primary"}
              >
                {"+ Subcontract Request"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {subContractData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={subContractData?.data?.data}
                    paginate={subContractData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 20%"
                    mobileWidth="30% 30% 30% 30%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubcontractRequest
