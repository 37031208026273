import React, { useState, useEffect } from "react"
import { UFInput } from "../../../components/Common/Fields/Input"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import RequestType from "../../../components/Common/chooseFields/requestType"
import { Col, Form, Label, Row } from "reactstrap"
import resetIcon from "../../../assets/icons/reset.svg"
import { useForm } from "react-hook-form"
import ProductList from "../../../components/Common/chooseFields/productList"
import TransactionHistoryList from "../../../components/Common/chooseFields/transactionHistory"
import CostCenterList from "../../../components/Common/chooseFields/costCenterList"
import FinanceSubCat from "../../../components/Common/chooseFields/financeSubCat"
import { useSelector } from "react-redux"

const TransferFilter = ({
  handleCCChange,
  handleSearchChange,
  // handleProductChange,
  handleTHChange,
  handleFinacialChange,
  // handleCompanyChange,
}) => {
  const { control, watch, reset } = useForm()

  const loggedUser = useSelector(state => state.Login.loggedUser)
  const [companyCheck, setCompanyCheck] = useState(false)
  const [finacialCheck, setfinacialCheck] = useState(true)

  const handleTransactionHistory = data => {
    handleTHChange(data?.label)
  }
  const handleCCNum = data => {
    handleCCChange(data?.value)
  }
  // const handleProducttNum = data => {
  //   handleProductChange(data?.value)
  // }
  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])
  const handleReset = () => {
    handleCCChange()
    handleTHChange()
    // handleProductChange()
    handleFinacialChange(true)
    // handleCompanyChange(false)
    setfinacialCheck(true)
    reset({
      costcenter_id: null,
      product_id: null,
      search: "",
      th_no: null,
    })
  }

  const handleFinacialCheck = dt => {
    handleFinacialChange(!dt)
    setfinacialCheck(!dt)
  }

  // const handleCompanyCheck = dt => {
  //   handleCompanyChange(!dt)
  //   setCompanyCheck(!dt)
  // }

  return (
    <div className="d-flex justify-content-between align-items-center my-2 me-2">
      <Form id="user-form" className="filter-wrapper">
        <Row className="mx-0">
          <Col sm={3} className="pe-1">
            <TransactionHistoryList
              control={control}
              name={"th_no"}
              placeholder={"TH No"}
              target={"body"}
              onSelectChange={e => handleTransactionHistory(e)}
            />
          </Col>
          <Col sm={3} className="pe-1">
            <CostCenterList
              control={control}
              name={"costcenter_id"}
              placeholder={"Project Code"}
              target={"body"}
              onSelectChange={e => handleCCNum(e)}
            />
          </Col>
          {/* <Col sm={3} className="pe-1">
            <FinanceSubCat
              control={control}
              name={"product_id"}
              placeholder={"Sub-cat"}
              target={"body"}
              onSelectChange={e => handleProducttNum(e)}
              filter={true}
            />
          </Col> */}
        </Row>
      </Form>
      <div className="d-flex">
        {/* <Col
          lg={"auto"}
          onClick={() => handleCompanyCheck(companyCheck)}
          className=" d-flex align-items-center pe-3"
        >
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_finacial_year"}
            checked={companyCheck}
          />
          <Label className="ms-2 mb-0">Company</Label>
        </Col> */}
        {/* <Col
          lg={"auto"}
          onClick={() => handleFinacialCheck(finacialCheck)}
          className=" d-flex align-items-center"
        >
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_finacial_year"}
            checked={finacialCheck}
          />
          <Label className="ms-2 mb-0">
            FY {loggedUser?.finance?.replace(/\//g, "")}
          </Label>
        </Col> */}
        <div className="export-div pointer me-2 reset-img" onClick={() => handleReset()}>
          <img src={resetIcon} height={15} width={15} className="me-2" /> Reset
        </div>
        <UFInput
          control={control}
          placeholder="Search"
          name={"search"}
          className={"search-input"}
        />
      </div>
    </div>
  )
}

export default TransferFilter
