import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
    delSingleBloodGroup,
  fetchBloodGroups,
  fetchSingleBloodGroup,
  postHrmsBloodGroups,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"

const schema = yup.object().shape({
  name: yup.string().required("Blood Group is Required"),
})
const BloodGroup = () => {
  document.title = "Blood Group | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [degreeList, setDegree] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [editDegree, setEditDegree] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchBloodGroups({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${`&sizePerPage=16`}`,
      })
      setDegree(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (editDegree.id) {
      reset({ ...editDegree })
    }
  }, [editDegree.id])

  const handleDegree = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsBloodGroups(data)
      toastSuccess(createdSeries?.message)
      reset({
        name: "",
      })
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEditDegree("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchSingleBloodGroup(item)
    setEditDegree(response?.data)
  }

  const handleDelete = async id => {
    try {
      const response = await delSingleBloodGroup(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Blood Group",
      renderCell: item => <div className="capitalize-letter">{item?.name}</div>,
    //   sortKey: "name",
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <div
                className="table-popup-content"
                onClick={() => handleEdit(item?.id)}
              >
                Edit
              </div>

              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleDelete(item?.id)}
              >
                Delete
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      name: "",
    })
    setEditDegree("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Blood Group", link: "/hrms-blood-groups" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {editDegree?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form id={`ev-form`} onSubmit={handleSubmit(handleDegree)}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Blood Group<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Blood Group"
                            name={"name"}
                            invalid={!!errors.name}
                          />
                          <FormFeedback invalid>
                            {errors.name?.message}
                          </FormFeedback>
                        </FormGroup>
                        <div className="float-end">
                          <Button
                            className="btn-secondary me-2"
                            form={`ev-form`}
                            color={"secondary"}
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            form={`ev-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                          >
                            {editDegree?.id ? "Update" : "Create"}
                          </LoadingButton>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Degree"
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {degreeList?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={degreeList?.data?.data}
                        paginate={degreeList?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="85% 15%"
                        mobileWidth="60% 40%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BloodGroup
